export enum EScheduleItem {
  TASK = 'task',
  MEDICATION = 'medication',
  CARE_PLAN_ITEM = 'care_plan_item'
}

export enum ECarePlanItemRepeatCycle {
  DAILY = 'daily',
  WEEKLY = 'weekly'
}
