import * as yup from 'yup';

import { EMedicationDosageTaken } from 'entities/Medications/constants';

export const VALIDATION_SCHEMA = yup.object().shape({
  dosage_taken: yup
    .string()
    .oneOf(
      [
        EMedicationDosageTaken.FULLY_TAKEN,
        EMedicationDosageTaken.PARTIALLY_TAKEN,
        EMedicationDosageTaken.NOT_TAKEN
      ],
      'Please choose a dosage that has been taken.'
    )
    .nullable()
});
