import { useContext, useState } from 'react';
import { useTheme } from '@mui/styles';
import { useHistory } from 'react-router-dom';

import Stack from 'components/Stack';
import Typography from 'components/Typography';
import Divider from 'components/Divider';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Button from 'components/Button';
import PWAInstallPrompt from 'components/PWAInstallPrompt';
import ConfirmationDialog from 'components/ConfirmationDialog';

import { PROFILE_URL } from 'config/urls';
import { ColorModeContext } from 'theme';

import { useSignedUser, useUserLogout } from 'entities/Auth/sdk';
import { useProfile } from 'entities/Profile/sdk';
import { PWAInstallPromptContext } from 'components/PWAInstallPrompt/Provider';
import { openInNewTab } from 'utils/common';
import MenuLabelArrowButton from 'components/MenuLabelArrowButton';

interface ISettings {}

const Settings: React.FC<ISettings> = () => {
  const colorMode = useContext(ColorModeContext);
  const theme = useTheme();
  const history = useHistory();

  const { data: user } = useSignedUser();
  const { data: profile } = useProfile({
    profileId: user?.profile.id
  });

  const [showInstallAppPrompt, setShowInstallAppPrompt] =
    useState<Boolean>(false);
  const { canInstallAppWeb, canInstallAppIOS } = useContext(
    PWAInstallPromptContext
  );
  const [showLogoutDialog, setShowLogoutDialog] = useState(false);

  const handleColorThemeClick = () => {
    colorMode.toggleColorMode();
  };

  const onMyProfileClick = () => {
    history.push(PROFILE_URL);
  };

  const { handleLogout } = useUserLogout();

  const handlePWAPromptClick = () => setShowInstallAppPrompt(true);

  const handlePWAPromptClose = () => setShowInstallAppPrompt(false);

  const handleLogoutConfirmationDialogClose = () => setShowLogoutDialog(false);

  return (
    <>
      <Stack display="flex" direction="column" paddingY={3}>
        <Stack marginBottom={5}>
          <Typography variant="h1">Settings</Typography>
        </Stack>
        <Stack spacing={3}>
          <MenuLabelArrowButton
            avatar={profile?.avatar}
            label="My profile"
            onClick={onMyProfileClick}
          />

          <Divider />

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">Color theme:</Typography>
            <ToggleButtonGroup
              color="primary"
              onChange={handleColorThemeClick}
              aria-label="color-mode-toggle"
              data-gtm="settings-toggle-color-mode"
            >
              <ToggleButton
                value="light"
                size="small"
                selected={theme.palette.mode === 'light' ? true : false}
              >
                Light
              </ToggleButton>
              <ToggleButton
                value="dark"
                size="small"
                selected={theme.palette.mode === 'dark' ? true : false}
              >
                Dark
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Divider />

          {(canInstallAppWeb || canInstallAppIOS) && (
            <>
              <MenuLabelArrowButton
                label="Install Carederly"
                onClick={handlePWAPromptClick}
              />
              <Divider />
            </>
          )}
          <MenuLabelArrowButton
            label="Support"
            onClick={() => openInNewTab('https://meetcarederly.com/support/')}
          />
          <Stack paddingY={3}>
            <Button variant="outlined" color="error" onClick={handleLogout}>
              Logout
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {showInstallAppPrompt && (
        <PWAInstallPrompt onClose={handlePWAPromptClose} />
      )}
      <ConfirmationDialog
        open={showLogoutDialog}
        title="Are you sure you want to log out?"
        onClose={handleLogoutConfirmationDialogClose}
        onConfirm={handleLogout}
      />
    </>
  );
};

export default Settings;
