import { useContext } from 'react';

// TODO: move these to components/ and icons/ after we agree on the design
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IosShareIcon from '@mui/icons-material/IosShare';

import Dialog from 'components/Dialog';
import Image from 'components/Image';
import Typography from 'components/Typography';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Box from 'components/Box';
import Stack from 'components/Stack';
import { PWAInstallPromptContext } from 'components/PWAInstallPrompt/Provider';

import logo from './assets/logo.png';

interface IPWAInstallPrompt {
  onClose?: () => void;
}

const PWAInstallPrompt: React.FC<IPWAInstallPrompt> = ({
  onClose = () => {}
}) => {
  const { promptToInstallApp, canInstallAppIOS, canInstallAppWeb } = useContext(
    PWAInstallPromptContext
  );

  return (
    <Dialog open={true} onClose={onClose}>
      <Card>
        <Box display="flex" justifyContent="center">
          <Image src={logo} alt="Carederly Logo" />
        </Box>
        <CardContent>
          {canInstallAppIOS && (
            <Stack padding={3} spacing={2}>
              <Typography align="center">
                To install the Carederly app - open this website in Safari and
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography align="center">tap</Typography>
                <IconButton>
                  <IosShareIcon />
                </IconButton>
                <Typography align="center">
                  then &quot;Add to Home Screen&quot;
                </Typography>
              </Box>
              <Button variant="outlined" onClick={onClose}>
                Close
              </Button>
            </Stack>
          )}
          {canInstallAppWeb && (
            <Stack padding={3} spacing={3}>
              <Button color="primary" onClick={promptToInstallApp}>
                Install App
              </Button>
              <Button variant="outlined" onClick={onClose}>
                Close
              </Button>
            </Stack>
          )}
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default PWAInstallPrompt;
