import _ from 'lodash';
import { makeStyles } from '@mui/styles';

import { ChevronRightIcon } from 'icons';

import { colors } from 'theme/palette';

import Grid from 'components/Grid';
import Typography from 'components/Typography';
import Avatar from 'components/Avatar';

export interface IMenuLabelArrowButton {
  label: string;
  avatar?: string | null;
  description?: string;
  onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer'
  },
  labelContainer: {
    flexWrap: 'nowrap',
    marginBottom: theme.spacing(1)
  },
  chevron: {
    color: colors.darkestGrey
  },
  profileAvatar: {
    width: 40,
    height: 40
  }
}));

const MenuLabelArrowButton = ({
  label,
  avatar,
  description,
  onClick
}: IMenuLabelArrowButton) => {
  const classes = useStyles();

  return (
    <div onClick={onClick} className={classes.container}>
      <Grid
        container
        display="flex"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className={classes.labelContainer}
        gap={2}
      >
        {!_.isUndefined(avatar) && (
          <Grid item>
            <Avatar src={avatar} className={classes.profileAvatar} />
          </Grid>
        )}
        <Grid item display="flex" flexGrow={1} justifyContent="space-between">
          <Typography variant="h5">{label}</Typography>
          <ChevronRightIcon className={classes.chevron} />
        </Grid>
      </Grid>
      {description && (
        <Typography variant="subtitle2">{description}</Typography>
      )}
    </div>
  );
};

export default MenuLabelArrowButton;
