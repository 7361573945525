import { Theme, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import StylesProvider from '@mui/styles/StylesProvider';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import 'react-toastify/dist/ReactToastify.css';

import ColorModeContextProvider from 'theme';
import Routes from 'config/routes';
import { ToastContainer } from 'utils/toast';
import { AdapterDatetime } from 'utils/datetime';
import PWAInstallPromptProvider from 'components/PWAInstallPrompt/Provider';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDatetime}>
      {/* Localization provider: https://mui.com/components/date-picker/#requirements */}
      <StylesProvider injectFirst>
        <StyledEngineProvider injectFirst>
          <ColorModeContextProvider>
            <CssBaseline />
            <PWAInstallPromptProvider>
              <Routes />
              <ToastContainer hideProgressBar theme="dark" />
            </PWAInstallPromptProvider>
          </ColorModeContextProvider>
        </StyledEngineProvider>
      </StylesProvider>
    </LocalizationProvider>
  );
}

export default App;
