import { useState } from 'react';
import Tooltip from 'components/Tooltip';

interface ChildProps {
  copy: (content: string) => void;
}

export interface ICopyToClipboard {
  children: (props: ChildProps) => React.ReactElement<any>;
}

const CopyToClipboard: React.FC<ICopyToClipboard> = ({ children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const copyToClipboard = (content: any) => {
    navigator.clipboard.writeText(content);
  };

  const onCopy = (content: string) => {
    copyToClipboard(content);
    setShowTooltip(true);
  };

  return (
    <Tooltip
      open={showTooltip}
      title={'Copied to clipboard!'}
      leaveDelay={1500}
      onClose={() => setShowTooltip(false)}
    >
      {children({ copy: onCopy }) as React.ReactElement<any>}
    </Tooltip>
  );
};

export default CopyToClipboard;
