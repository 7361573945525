import _ from 'lodash';
import toast from 'utils/toast';
import { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Button from 'components/Button';
import Typography from 'components/Typography';

import {
  useSelectedCareTeam,
  careTeamMemberInvitationAccept,
  careTeamMemberInvitationDecline
} from 'entities/CareTeam/sdk';

import { useSelectedPatient } from 'entities/Patient/sdk';

import { ONBOARDING_URL } from 'config/urls';

interface IMemberInvitationActionButtons {
  setError: any;
}

const MemberInvitationActionButtons: React.FC<
  IMemberInvitationActionButtons
> = ({ setError }) => {
  const history = useHistory();

  const {
    careTeamId,
    invitationId
  }: { careTeamId: string; invitationId: string } = useParams();

  const { refetchCareTeams } = useSelectedPatient();
  const { switchCareTeam } = useSelectedCareTeam();

  const handleJoinNowClick = useCallback(() => {
    careTeamMemberInvitationAccept({ careTeamId, invitationId })
      .then(() => {
        refetchCareTeams()
          .then(() => {
            switchCareTeam(careTeamId);

            toast.info({
              content: 'You have joined the care team successfully!'
            });

            history.push(ONBOARDING_URL);
          })
          .catch(console.error);
      })
      .catch((errors) => {
        setError(_.get(errors, 'errors[0].message'));
      });
  }, [
    careTeamId,
    invitationId,
    refetchCareTeams,
    switchCareTeam,
    setError,
    history
  ]);

  const handleDeclineClick = useCallback(() => {
    careTeamMemberInvitationDecline({ careTeamId, invitationId })
      .then(() => {
        history.push(ONBOARDING_URL);
        toast.info({
          content: 'You have declined the care team invitation.'
        });
      })
      .catch((errors) => {
        setError(_.get(errors, 'errors[0].message'));
      });
  }, [careTeamId, invitationId, history, setError]);

  return (
    <>
      <Typography variant="body1">
        Click the join now button in order to accept the invitation.
      </Typography>
      <Button
        onClick={handleJoinNowClick}
        data-gtm="button-submit-accept-team-invitation-and-join"
      >
        Join now
      </Button>
      <Button color="error" onClick={handleDeclineClick}>
        Decline
      </Button>
    </>
  );
};

export default MemberInvitationActionButtons;
