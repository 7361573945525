import Stack from 'components/Stack';
import Typography from 'components/Typography';

interface IDataLine {
  label: string;
}

const DataLine: React.FC<IDataLine> = ({ label, children }) => (
  <Stack direction="row" spacing={1} alignItems="baseline">
    <Typography variant="body2">{label}</Typography>
    <Typography variant="body1">{children}</Typography>
  </Stack>
);

export default DataLine;
