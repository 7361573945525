import _ from 'lodash';
import { FormikProps } from 'formik';

import { BACKEND_DATE_FORMAT } from 'constants/time';

import MobileDatePicker, {
  MobileDatePickerProps
} from 'components/MobileDatePicker';
import TextField, { IOutlinedTextField } from 'components/TextField';

interface IFormikDateField
  extends Omit<MobileDatePickerProps, 'value' | 'onChange' | 'renderInput'> {
  name: string;
  label: string;
  formik: FormikProps<any>;
  renderInput?: MobileDatePickerProps['renderInput'];
}

interface IGenericDateField
  extends Omit<MobileDatePickerProps, 'onChange' | 'renderInput'> {
  onChange: (arg0: any) => void;
  renderInput?: MobileDatePickerProps['renderInput'];
}

/*
IMPORTANT Notes for this component!

- MobileDatePicker is always giving us the new value as a dateTime instance on onChange.
- MobileDatePicker accepts a string value in this BACKEND_DATE_FORMAT format.

That's why we've got a custom handleChange handler, which transforms the newly
updated datetime object to the date format we're working in the app with - BACKEND_DATE_FORMAT
*/
const GenericDateField: React.FC<IGenericDateField> = ({
  inputFormat = 'MMM DD, YYYY',
  disableCloseOnSelect = false,
  renderInput = (params: IOutlinedTextField) => (
    <TextField.Outlined fullWidth {...params} />
  ),
  onChange,
  ...props
}) => {
  const handleChange = (value: any) => {
    let formattedDate: any = null;

    if (!_.isNil(value)) {
      formattedDate = value.format(BACKEND_DATE_FORMAT);
    }

    return onChange(formattedDate);
  };

  return (
    <MobileDatePicker
      inputFormat={inputFormat}
      renderInput={renderInput}
      disableCloseOnSelect={disableCloseOnSelect}
      onChange={handleChange}
      {...props}
    />
  );
};

const FormikDateField: React.FC<IFormikDateField> = ({
  formik,
  renderInput,
  ...props
}) => {
  const { name } = props;

  const value = _.get(formik, `values.${name}`); // The value is a string in BACKEND_DATE_FORMAT format
  const touched = _.get(formik, `touched.${name}`);
  const error = _.get(formik, `errors.${name}`);

  if (!renderInput) {
    // eslint-disable-next-line
    renderInput = (params: any) => (
      <TextField.Outlined
        fullWidth
        error={touched && !_.isEmpty(error)}
        helperText={touched ? error : ''}
        InputLabelProps={{
          shrink: true
        }}
        {...params}
      />
    );
  }

  const handleChange = (value: string | null) => {
    formik.setFieldTouched(name, true);
    formik.setFieldValue(name, value);
  };

  return (
    <GenericDateField
      value={value}
      onChange={handleChange}
      renderInput={renderInput}
      {...props}
    />
  );
};

const DateField = {
  Formik: FormikDateField,
  Generic: GenericDateField
};

export default DateField;
