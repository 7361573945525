import _ from 'lodash';
import { useCallback } from 'react';
import { useFormik, FormikHelpers } from 'formik';

import Stack from 'components/Stack';
import Alert from 'components/Alert';
import Button from 'components/Button';
import DateField from 'components/DateField';
import TextField from 'components/TextField';
import Dialog, { IDialog } from 'components/Dialog';

import { GENERIC_FORM_ERRORS_KEY, IFormError } from 'utils/sdk';

import { IGeneralNoteFormValues } from 'entities/Diary/sdk';

import { VALIDATION_SCHEMA } from './constants';

export interface IGeneralNoteFormDialog extends IDialog {
  onDiaryNoteSubmit: ({
    diaryNote
  }: {
    diaryNote: IGeneralNoteFormValues;
  }) => Promise<void>;
  initialValues: IGeneralNoteFormValues;
  title: string;
}

const GeneralNoteFormDialog = ({
  onClose,
  onDiaryNoteSubmit,
  initialValues,
  title
}: IGeneralNoteFormDialog) => {
  const handleSubmit = useCallback(
    (
      values: IGeneralNoteFormValues,
      formikHelpers: FormikHelpers<IGeneralNoteFormValues>
    ) =>
      onDiaryNoteSubmit({
        diaryNote: values
      }).catch(formikHelpers.setErrors),
    [onDiaryNoteSubmit]
  );

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA
  });

  const formErrors: Array<IFormError> = _.get(
    formik.errors,
    GENERIC_FORM_ERRORS_KEY,
    []
  );

  return (
    <Dialog open onClose={onClose} title={title}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2} padding={2}>
          {formErrors.map((error, index) => (
            <Alert key={index} severity="error">
              {error.message}
            </Alert>
          ))}

          <TextField.Outlined
            fullWidth
            label="Subject *"
            name="subject"
            value={formik.values.subject}
            onChange={formik.handleChange}
            error={formik.touched.subject && !_.isEmpty(formik.errors.subject)}
            helperText={formik.touched.subject ? formik.errors.subject : ''}
            InputLabelProps={{
              shrink: true
            }}
          />

          <DateField.Formik
            label="Date *"
            name="schedule_date"
            formik={formik}
          />

          <TextField.Outlined
            fullWidth
            multiline
            minRows={3}
            InputLabelProps={{
              shrink: true
            }}
            label="Notes *"
            name="text"
            value={formik.values.text}
            onChange={formik.handleChange}
            placeholder="Notes"
            error={formik.touched.text && !_.isEmpty(formik.errors.text)}
            helperText={formik.touched.text ? formik.errors.text : ''}
          />

          <Button
            type="submit"
            disabled={formik.isSubmitting}
            data-gtm="button-submit-custom-note-create"
          >
            Save
          </Button>
        </Stack>
      </form>
    </Dialog>
  );
};

export default GeneralNoteFormDialog;
