import { RESOLUTION } from 'entities/HealthTrends/constants';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

const RESOLUTIONS_OPTIONS = [
  { label: 'Weekly', value: RESOLUTION.WEEKLY, mobileLabel: 'Week' },
  { label: 'Monthly', value: RESOLUTION.MONTHLY, mobileLabel: 'Month' },
  { label: 'Quarterly', value: RESOLUTION.QUARTERLY, mobileLabel: 'Quarter' }
];

interface ISelectResolutionButtons {
  selectedResolution: string;
  onChange: (value: string) => void;
}

const SelectResolutionButtons: React.FC<ISelectResolutionButtons> = ({
  selectedResolution,
  onChange
}) => {
  const handleResolutionClick = (
    event: React.MouseEvent<HTMLElement>,
    newResolution: string
  ) => onChange(newResolution);

  return (
    <ToggleButtonGroup
      fullWidth
      color="standard"
      size="small"
      exclusive
      onChange={handleResolutionClick}
      aria-label="health-trends-resolution-toggle"
      data-gtm="health-trends-resolution-toggle"
      sx={{ paddingBottom: 2 }}
    >
      {RESOLUTIONS_OPTIONS.map((resolution) => (
        <ToggleButton
          key={resolution.value}
          value={resolution.value}
          selected={resolution.value === selectedResolution ? true : false}
        >
          {resolution.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default SelectResolutionButtons;
