export const TIME_WITH_PERIOD_FORMAT = 'hh:mm A';
export const DATETIME_WITH_PERIOD_FORMAT = 'MMMM DD, YYYY hh:mm A'; // Example: December 14, 2021 11:05 AM
export const DATETIME_WITH_PERIOD_SHORTENED_FORMAT = 'MMM DD, YYYY hh:mm A'; // Example: Dec 14, 2021 11:05 AM
export const DATETIME_FILENAME_FORMAT = 'YYYYMMDDHHmm';

export const DATE_WITH_YEAR_SHORTENED_FORMAT = 'MMM Do, YYYY'; // Example: Dec 14, 2021

export const BACKEND_TIME_FORMAT = 'HH:mm:ss';
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const BACKEND_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss[Z]';

export const HTML_INPUT_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';

export const TIMEZONE_FOR_TESTING = 'US/Pacific';
