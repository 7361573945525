import _ from 'lodash';
import { FormikProps } from 'formik';

import TextField, { IOutlinedTextField } from 'components/TextField';

export interface INumberField extends Omit<IOutlinedTextField, 'type'> {
  name: string;
  label: string;
  formik: FormikProps<any>;
  [key: string]: any;
}

const NumberField: React.FC<INumberField> = ({ formik, name, ...props }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | null = event.target.value;

    if (value === '') {
      value = null;
    }

    formik.setFieldTouched(name);
    formik.setFieldValue(name, value);
  };

  return (
    <TextField.Outlined
      type="number"
      name={name}
      value={formik.values[name]}
      onChange={handleChange}
      error={formik.touched[name] && !_.isEmpty(formik.errors[name])}
      helperText={formik.touched[name] ? formik.errors[name] : ''}
      {...props}
    />
  );
};

export default NumberField;
