import { ETemperatureUnit } from 'constants/common';
import { VITALS } from 'entities/HealthTrends/constants';

export const VITAL_TO_KEYS_MAPPING: { [key: string]: Array<string> } = {
  temperature: ['temperature'],
  blood_pressure: ['blood_pressure_diastolic', 'blood_pressure_systolic'],
  pulse: ['pulse'],
  blood_oxygen: ['blood_oxygen'],
  blood_sugar: ['blood_sugar_non_fasting', 'blood_sugar_fasting']
};

export const VITALS_NORMAL_RANGE_COLORS = {
  temperature: '#94E31560',
  blood_pressure_systolic: '#94E31560',
  blood_pressure_diastolic: '#6315E360',
  pulse: '#94E31560',
  blood_oxygen: '#94E31560',
  blood_sugar_fasting: '#94E31560',
  blood_sugar_non_fasting: '#6315E360'
};

export const VITALS_LINE_COLORS = {
  temperature: '#94E315',
  blood_pressure_systolic: '#94E315',
  blood_pressure_diastolic: '#6315E3',
  pulse: '#94E315',
  blood_oxygen: '#94E315',
  blood_sugar_fasting: '#94E315',
  blood_sugar_non_fasting: '#6315E3'
};

export const VITAL_TO_DOMAINS_MAPPING = ({
  selectedVital,
  temperatureUnit = ETemperatureUnit.CELSIUS
}: {
  selectedVital: string;
  temperatureUnit?: ETemperatureUnit;
}) =>
  ({
    [VITALS.BLOOD_SUGAR]: [60, 120],
    [VITALS.BLOOD_OXYGEN]: [90, 100],
    [VITALS.BLOOD_PRESSURE]: [50, 150],
    [VITALS.PULSE]: [50, 120],
    [VITALS.TEMPERATURE]:
      temperatureUnit === ETemperatureUnit.FAHRENHEIT ? [95, 100] : [34, 42]
  }[selectedVital]);
