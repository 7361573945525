import { makeStyles } from '@mui/styles';

import { ChevronLeftIcon, ChevronRightIcon } from 'icons';

import Stack from 'components/Stack';
import DateField from 'components/DateField';

import { getPreviousDate, getNextDate } from 'utils/datetime';

export interface ICalendar {
  selectedDate: string;
  setSelectedDate: (arg0: string) => void;
}

const useStyles = makeStyles((theme) => ({
  cursorPointer: {
    cursor: 'pointer'
  }
}));

const Calendar = ({ selectedDate, setSelectedDate }: ICalendar) => {
  const classes = useStyles();

  const handleChange = (value: string | null) => {
    if (!value) {
      return;
    }

    setSelectedDate(value);
  };

  return (
    <Stack spacing={1} maxHeight="100%">
      <Stack direction="row" spacing={1} alignItems="center">
        <ChevronLeftIcon
          onClick={() =>
            setSelectedDate(getPreviousDate({ date: selectedDate }))
          }
          className={classes.cursorPointer}
        />
        <DateField.Generic
          inputFormat="dddd, MMM DD, YYYY"
          value={selectedDate}
          onChange={handleChange}
        />
        <ChevronRightIcon
          onClick={() => setSelectedDate(getNextDate({ date: selectedDate }))}
          className={classes.cursorPointer}
        />
      </Stack>
    </Stack>
  );
};

export default Calendar;
