import _ from 'lodash';
import { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { useFormik, FormikHelpers, FormikProps } from 'formik';

import { datetime } from 'utils/datetime';
import { getNearestHalfHour } from 'utils/common';
import { GENERIC_FORM_ERRORS_KEY, IFormError } from 'utils/sdk';
import { DAYS_OF_THE_WEEK_CHOICES } from 'constants/common';

import Grid from 'components/Grid';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Select from 'components/Select';
import Switch from 'components/Switch';
import DateField from 'components/DateField';
import TextField from 'components/TextField';
import Dialog, { IDialog } from 'components/Dialog';
import FormControlLabel from 'components/FormControlLabel';

import {
  ICarePlanItemCategory,
  ICarePlanItemValues
} from 'entities/CarePlan/sdk';
import { ECarePlanItemRepeatCycle } from 'entities/CarePlan/constants';

import {
  REPEAT_CYCLES,
  VALIDATION_SCHEMA,
  DEFAULT_REPEAT_CYCLE_VALUE,
  CUSTOM_CATEGORY_VALUE
} from './constants';

import { addMinutesToTime } from 'utils/datetime';
import Typography from 'components/Typography';

export interface ICarePlanItemFormDialog extends IDialog {
  onCarePlanItemSubmit: ({
    carePlanItem
  }: {
    carePlanItem: ICarePlanItemValues;
  }) => Promise<void>;
  categories: Array<ICarePlanItemCategory>;
  initialValues: ICarePlanItemValues;
  title: string;
}

const useStyles = makeStyles({
  switchField: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 0
  }
});

const CarePlanItemFormDialog = ({
  onClose,
  onCarePlanItemSubmit,
  categories,
  initialValues,
  title,
  ...props
}: ICarePlanItemFormDialog) => {
  const classes = useStyles();

  const handleSubmit = useCallback(
    (
      values: ICarePlanItemValues,
      formikHelpers: FormikHelpers<ICarePlanItemValues>
    ) => {
      return onCarePlanItemSubmit({
        carePlanItem: values
      })
        .then(() => {
          formikHelpers.resetForm();
        })
        .catch(formikHelpers.setErrors);
    },
    [onCarePlanItemSubmit]
  );

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA,
    enableReinitialize: true
  });

  const formErrors: Array<IFormError> = _.get(
    formik.errors,
    GENERIC_FORM_ERRORS_KEY,
    []
  );

  const onIsAllDayChange = ({
    event,
    formik
  }: {
    event: any;
    formik: FormikProps<any>;
  }) => {
    if (event.target.checked) {
      formik.setFieldValue('start_time', null);
      formik.setFieldValue('end_time', null);
    } else {
      formik.setFieldValue(
        'start_time',
        getNearestHalfHour({ time: datetime() }).format('HH:mm')
      );
      formik.setFieldValue(
        'end_time',
        getNearestHalfHour({ time: datetime() }).add(30, 'm').format('HH:mm')
      );
    }

    formik.handleChange(event);
  };

  const handleDialogClose = () => {
    onClose();
    formik.resetForm();
  };

  const onStartTimeChange = (e: any) => {
    formik.handleChange(e);

    formik.setFieldValue(
      'end_time',
      addMinutesToTime({ time: e.target.value, minutesToAdd: 30 })
    );
  };

  return (
    <Dialog {...props} onClose={handleDialogClose} title={title}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {!_.isEmpty(formErrors) && (
            <Grid item xs={12}>
              {formErrors.map((error, index) => (
                <Alert key={index} severity="error">
                  {error.message}
                </Alert>
              ))}
            </Grid>
          )}
        </Grid>

        <Grid container flexDirection="column" rowGap={2} padding={2}>
          <Grid item>
            <Select
              label="Category"
              options={categories}
              name="category"
              value={formik.values.category}
              onChange={formik.handleChange}
              error={
                formik.touched.category && !_.isEmpty(formik.errors.category)
              }
              helperText={formik.touched.category ? formik.errors.category : ''}
            />
          </Grid>

          {formik.values.category === CUSTOM_CATEGORY_VALUE && (
            <Grid item>
              <TextField.Outlined
                fullWidth
                label="Custom category *"
                name="custom_category"
                value={formik.values.custom_category}
                onChange={formik.handleChange}
                error={
                  formik.touched.custom_category &&
                  !_.isEmpty(formik.errors.custom_category)
                }
                helperText={
                  formik.touched.custom_category
                    ? formik.errors.custom_category
                    : ''
                }
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          )}

          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={formik.values.is_added_to_schedule}
                  color="primary"
                />
              }
              label="Add to schedule?"
              labelPlacement="start"
              name="is_added_to_schedule"
              value={formik.values.is_added_to_schedule}
              className={classes.switchField}
              onChange={formik.handleChange}
            />
            {!formik.values.is_added_to_schedule && (
              <Typography variant="subtitle2">
                Tasks not added to the schedule can be viewed from the Care Plan
                under the Care Panel.
              </Typography>
            )}
          </Grid>

          {formik.values.is_added_to_schedule && (
            <>
              <Grid item>
                <Select
                  label="Repeat"
                  defaultValue={DEFAULT_REPEAT_CYCLE_VALUE}
                  options={REPEAT_CYCLES}
                  name="repeat_cycle"
                  value={formik.values.repeat_cycle}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.repeat_cycle &&
                    !_.isEmpty(formik.errors.repeat_cycle)
                  }
                  helperText={
                    formik.touched.repeat_cycle
                      ? formik.errors.repeat_cycle
                      : ''
                  }
                />
              </Grid>

              {formik.values.repeat_cycle ===
                ECarePlanItemRepeatCycle.WEEKLY && (
                <Grid item maxWidth="100%">
                  <Select
                    multiple
                    label="Choose days"
                    options={DAYS_OF_THE_WEEK_CHOICES}
                    name="repeat_cycle_days"
                    value={formik.values.repeat_cycle_days}
                    onChange={formik.handleChange}
                    error={
                      _.isArray(formik.touched.repeat_cycle_days) &&
                      _.isEmpty(formik.touched.repeat_cycle_days) && // formik.touched.repeat_cycle_days is [] when touched
                      !_.isEmpty(formik.errors.repeat_cycle_days)
                    }
                    helperText={
                      _.isArray(formik.touched.repeat_cycle_days) &&
                      _.isEmpty(formik.touched.repeat_cycle_days) && // formik.touched.repeat_cycle_days is [] when touched
                      !_.isEmpty(formik.errors.repeat_cycle_days)
                        ? `${formik.errors.repeat_cycle_days}`
                        : ''
                    }
                  />
                </Grid>
              )}

              <Grid item>
                <DateField.Formik
                  label="Start date *"
                  name="repeat_after"
                  formik={formik}
                />
              </Grid>
              <Grid item>
                <DateField.Formik
                  clearable
                  label="End date"
                  name="repeat_before"
                  formik={formik}
                />
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked={formik.values.is_all_day}
                      color="primary"
                    />
                  }
                  label="Can this activity happen at any time during the day?"
                  labelPlacement="start"
                  name="is_all_day"
                  value={formik.values.is_all_day}
                  className={classes.switchField}
                  onChange={(event) => {
                    onIsAllDayChange({ event, formik });
                  }}
                />
              </Grid>

              {!formik.values.is_all_day && (
                <>
                  <Grid item>
                    <TextField.Outlined
                      fullWidth
                      label="Start time *"
                      type="time"
                      name="start_time"
                      value={formik.values.start_time}
                      onChange={onStartTimeChange}
                      error={
                        formik.touched.start_time &&
                        !_.isEmpty(formik.errors.start_time)
                      }
                      helperText={
                        formik.touched.start_time
                          ? formik.errors.start_time
                          : ''
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <TextField.Outlined
                      fullWidth
                      label="End time *"
                      type="time"
                      name="end_time"
                      value={formik.values.end_time}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.end_time &&
                        !_.isEmpty(formik.errors.end_time)
                      }
                      helperText={
                        formik.touched.end_time ? formik.errors.end_time : ''
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </>
              )}
            </>
          )}

          <Grid item>
            <TextField.Outlined
              fullWidth
              multiline
              minRows={5}
              name="description"
              label="Description"
              value={formik.values.description}
              onChange={formik.handleChange}
              placeholder="Add relevant notes to ensure care is tailored accordingly"
              InputLabelProps={{
                shrink: true
              }}
              error={
                formik.touched.description &&
                !_.isEmpty(formik.errors.description)
              }
              helperText={
                formik.touched.description ? formik.errors.description : ''
              }
            />
          </Grid>
          <Grid item>
            <Button
              type="submit"
              disabled={formik.isSubmitting}
              data-gtm="button-submit-care-plan-item-create"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default CarePlanItemFormDialog;
