import _ from 'lodash';
import { post, useFetch } from 'utils/sdk';

import { BASE_API_URL } from 'config/urls';

import {
  IScheduleCarePlanItem,
  IScheduleMedication,
  IScheduleTask
} from 'entities/CarePlan/sdk';

import { useSelectedPatient } from 'entities/Patient/sdk';

import { EMedicationIntakeFrequency } from 'entities/Medications/constants';

import {
  BACKEND_DATETIME_FORMAT,
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT
} from 'constants/time';

import { EScheduleItem } from 'entities/CarePlan/constants';

import {
  localToUtc,
  datetime,
  deepTransformUTCToLocal,
  getTodayDate,
  utcToLocal
} from 'utils/datetime';
import { orderDaysOfTheWeek } from 'utils/common';

import {
  transformWeeklyMedicationIntakeTimesUtcToLocal,
  transformDailyMedicationIntakeTimesUtcToLocal
} from 'entities/Medications/utils';

export interface ITaskValues {
  name: string;
  description: string;
  location: string;
  start: string;
  end: string;
}

export type IScheduleItem =
  | IScheduleCarePlanItem
  | IScheduleTask
  | IScheduleMedication;

export const usePatientScheduleItemList = ({
  patientId,
  selectedDate
}: {
  patientId: string | null | undefined;
  selectedDate: string;
}) => {
  const startDatetime = localToUtc(
    datetime(selectedDate, BACKEND_DATE_FORMAT) // Turns 5th Nov to 05/11/2022 00:00:00
  ).format(BACKEND_DATETIME_FORMAT);
  const endDatetime = localToUtc(
    datetime(selectedDate, BACKEND_DATE_FORMAT).add(24, 'h')
  ).format(BACKEND_DATETIME_FORMAT);

  const result = useFetch<Array<IScheduleItem>>(
    patientId
      ? `${BASE_API_URL}/activities/patient/${patientId}/schedule/list/?start_datetime=${startDatetime}&end_datetime=${endDatetime}&selected_date=${selectedDate}`
      : null
  );

  const transformedData = deepTransformUTCToLocal({ data: result.data }) || [];

  const transformedCarePlanItems = transformedData
    .filter(
      (scheduleItem: IScheduleItem) =>
        scheduleItem.type === EScheduleItem.CARE_PLAN_ITEM
    )
    .map((carePlanItem: IScheduleCarePlanItem) => {
      const startTime = carePlanItem.start_time
        ? datetime(carePlanItem.start_time, BACKEND_TIME_FORMAT, true)
        : null;
      const transformedStartTime = startTime
        ? utcToLocal(
            datetime.utc().hour(startTime.hour()).minute(startTime.minute())
          ).format(BACKEND_TIME_FORMAT)
        : null;

      const endTime = carePlanItem.end_time
        ? datetime(carePlanItem.end_time, BACKEND_TIME_FORMAT, true)
        : null;
      const transformedEndTime = endTime
        ? utcToLocal(
            datetime.utc().hour(endTime.hour()).minute(endTime.minute())
          ).format(BACKEND_TIME_FORMAT)
        : null;

      // We need to transform each of the repeat cycle days.
      let transformedRepeatCycleDays: Array<string> = [];
      if (!_.isEmpty(carePlanItem.repeat_cycle_days)) {
        // If the care plan item does not have a start time(it's all day) we don't transform the days.
        if (!carePlanItem.start_time) {
          transformedRepeatCycleDays = carePlanItem.repeat_cycle_days;
        } else {
          const cursorStart = datetime(
            carePlanItem.repeat_after,
            BACKEND_DATETIME_FORMAT,
            true
          ).utc(); // We have previously transformed the repeat after to local so we need to bring it back to UTC.
          let cursorDate = cursorStart;

          while (cursorDate.isBefore(cursorStart.add(7, 'days'), 'day')) {
            // We go through the next 7 days and find the selected days of the week.
            // For each of them we append the day transformed in UTC to transformedRepeatCycleDays.
            const cursorDateDayOfTheWeek = cursorDate
              .format('dddd')
              .toLowerCase();
            const selectedRepeatCycleDays = carePlanItem.repeat_cycle_days.map(
              (day) => day.toString()
            );

            if (selectedRepeatCycleDays.includes(cursorDateDayOfTheWeek)) {
              const transformedCursorDate = utcToLocal(cursorDate);
              const transformedCursorDateDayOfTheWeek = transformedCursorDate
                .format('dddd')
                .toLowerCase();

              transformedRepeatCycleDays.push(
                transformedCursorDateDayOfTheWeek
              );
            }

            cursorDate = cursorDate.add(1, 'days');
          }
        }
        transformedRepeatCycleDays = orderDaysOfTheWeek({
          days: transformedRepeatCycleDays
        });
      }

      return {
        ...carePlanItem,
        repeat_cycle_days: transformedRepeatCycleDays,
        start_time: transformedStartTime,
        end_time: transformedEndTime
      };
    });

  const transformedTasks = transformedData.filter(
    (scheduleItem: IScheduleItem) => scheduleItem.type === EScheduleItem.TASK
  );

  const transformedMedications = transformedData
    .filter(
      (scheduleItem: IScheduleItem) =>
        scheduleItem.type === EScheduleItem.MEDICATION
    )
    .map((medication: IScheduleMedication) => {
      if (medication.intake_frequency === EMedicationIntakeFrequency.DAILY) {
        return {
          ...medication,
          intake_time: _.head(
            transformDailyMedicationIntakeTimesUtcToLocal({
              intakeTimes: [medication.intake_time]
            })
          )
        };
      }

      if (medication.intake_frequency === EMedicationIntakeFrequency.WEEKLY) {
        return {
          ...medication,
          intake_time: _.head(
            transformWeeklyMedicationIntakeTimesUtcToLocal({
              intakeTimes: [medication.intake_time],
              startMoment: medication.repeat_after
            })
          )
        };
      }

      return medication;
    });

  return {
    ...result,
    data: [
      ...transformedTasks,
      ...transformedCarePlanItems,
      ...transformedMedications
    ]
  };
};

// Task actions:

export const taskDelete = ({
  patientId,
  taskId
}: {
  patientId: string;
  taskId: string;
}) =>
  post(
    `${BASE_API_URL}/activities/patient/${patientId}/schedule/task/${taskId}/delete/`
  );

export const taskUpdate = ({
  patientId,
  taskId,
  task
}: {
  patientId: string;
  taskId: string;
  task: ITaskValues;
}) => {
  const data = {
    ...task,
    start: localToUtc(datetime(task.start, BACKEND_DATETIME_FORMAT)).format(
      BACKEND_DATETIME_FORMAT
    ),
    end: task.end
      ? localToUtc(datetime(task.end, BACKEND_DATETIME_FORMAT)).format(
          BACKEND_DATETIME_FORMAT
        )
      : null
  };

  return post(
    `${BASE_API_URL}/activities/patient/${patientId}/schedule/task/${taskId}/update/
    `,
    data
  );
};

export const taskCreate = ({
  patientId,
  task
}: {
  patientId: string;
  task: ITaskValues;
}) => {
  const data = {
    ...task,
    start: localToUtc(datetime(task.start, BACKEND_DATETIME_FORMAT)).format(
      BACKEND_DATETIME_FORMAT
    ),
    end: task.end
      ? localToUtc(datetime(task.end, BACKEND_DATETIME_FORMAT)).format(
          BACKEND_DATETIME_FORMAT
        )
      : null
  };

  return post(
    `${BASE_API_URL}/activities/patient/${patientId}/schedule/task/create/`,
    data
  );
};

export const taskCompletionStatusUpdate = ({
  patientId,
  taskId,
  isCompleted
}: {
  patientId: string;
  taskId: string;
  isCompleted: boolean;
}) =>
  post<{ is_completed: boolean }>(
    `${BASE_API_URL}/activities/patient/${patientId}/schedule/task/${taskId}/update-completion/`,
    { is_completed: isCompleted }
  );

// Care plan item actions:

export const carePlanItemCompletionStatusUpdate = ({
  patientId,
  carePlanItemId,
  isCompleted,
  selectedDatetime
}: {
  patientId: string;
  carePlanItemId: string;
  isCompleted: boolean;
  selectedDatetime: string;
}) =>
  post(
    `${BASE_API_URL}/activities/patient/${patientId}/schedule/care-plan-item/${carePlanItemId}/update-completion/`,
    {
      is_completed: isCompleted,
      selected_datetime: selectedDatetime
    }
  );

export const useUpdatesForToday = () => {
  const { patient } = useSelectedPatient();

  const { data: items = [] } = usePatientScheduleItemList({
    patientId: patient?.id,
    selectedDate: getTodayDate()
  });

  const completedActivitiesCount = items.filter(
    (item: IScheduleItem) => item.completed_at
  ).length;
  const allActivitiesCount = items.length;
  const remainingActivities = allActivitiesCount - completedActivitiesCount;

  return { remainingActivities, allActivitiesCount };
};
