import _ from 'lodash';
import * as yup from 'yup';

import {
  EMedicationIntakeFrequency,
  MEDICATION_INTAKE_FREQUENCIES_THAT_REQUIRE_INTAKE_TIME
} from 'entities/Medications/constants';

export const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required('Name is required.'),
  quantity: yup
    .number()
    .positive('Must be positive.')
    .integer('Must be an integer.')
    .nullable(),
  repeat_startdatetime: yup.string().required('Repeat after is required'),
  repeat_enddatetime: yup.string().nullable(),
  description: yup.string(),
  intake_days: yup.array().when('intake_frequency', {
    is: EMedicationIntakeFrequency.WEEKLY,
    then: yup.array().min(1, 'Select at least one intake day.'),
    otherwise: yup.array()
  }),
  intake_times: yup.array().when('intake_frequency', {
    is: (intakeFrequency: EMedicationIntakeFrequency) =>
      MEDICATION_INTAKE_FREQUENCIES_THAT_REQUIRE_INTAKE_TIME.includes(
        intakeFrequency
      ),
    then: yup
      .array()
      .of(yup.string())
      .min(1, 'Enter at least one intake time.')
      .test(
        'uniqueness',
        'Duplicate intake times are not allowed',
        (intakeTimes) =>
          !_.isNil(intakeTimes) &&
          intakeTimes.length === new Set(intakeTimes).size
      ),
    otherwise: yup.array().of(yup.string())
  })
});
