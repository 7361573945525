import _ from 'lodash';
import { useTheme } from '@mui/material/styles';
import { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import toast from 'utils/toast';
import { useRedirectToOnboarding } from 'utils/hooks';

import Grid from 'components/Grid';
import Fab from 'components/Fab';
import ExtendedAvatar from 'components/ExtendedAvatar';
import Divider from 'components/Divider';
import PageLoader from 'components/PageLoader';
import Stack from 'components/Stack';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Button from 'components/Button';

import {
  useCareTeamMembers,
  useSelectedCareTeam,
  careTeamMemberDelete,
  useMyCareTeams
} from 'entities/CareTeam/sdk';

import CareTeamListSlider from 'entities/CareTeam/components/ListSlider';
import CareTeamMemberDetails from 'entities/CareTeam/components/CareTeamMemberDetail';

import MemberInvitationCreateDialog from 'entities/CareTeam/components/MemberInvitationCreateDialog';
import FamilyMemberRoleUpdateDialog from 'entities/CareTeam/components/FamilyMemberRoleUpdateDialog';
import MemberRole from 'entities/CareTeam/components/MemberRole';

import BlankState from 'components/BlankState';
import { GroupAddIcon, AddIcon } from 'icons';

import { useSignedUser } from 'entities/Auth/sdk';

import { IFormError } from 'utils/sdk';
import { Typography } from '@mui/material';
import { ONBOARDING_STEPPER_URL } from 'config/urls';

interface ICareTeam {}

const useStyles = makeStyles((theme) => ({
  fab: {
    zIndex: 2,
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(3)
    }
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  newTeamButton: {
    color: theme.palette.text.secondary
  },
  leaveTeamButton: {
    color: theme.palette.secondary.light,
    '&.MuiButton-root': {
      borderColor: theme.palette.secondary.light
    }
  }
}));

const CareTeam: React.FC<ICareTeam> = () => {
  useRedirectToOnboarding();

  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();

  const [errorsInConfirmDialog, setErrorsInConfirmDialog] = useState<
    Array<IFormError>
  >([]);
  const { data: user, loading: userLoading } = useSignedUser();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const {
    careTeamId = '',
    userIsPatient,
    userIsFamilyMember,
    careTeam,
    switchCareTeam,
    loading: isFetchingCareTeam
  } = useSelectedCareTeam();

  const {
    data: members = [],
    loading: careTeamMembersLoading,
    mutate: mutateCareTeamMembers,
    refetch: refetchCareTeamMembers
  } = useCareTeamMembers({
    careTeamId
  });

  const { refetch: refetchCareTeams } = useMyCareTeams();

  const [selectedMemberIndex, setSelectedMemberIndex] = useState(0);
  const [
    isMemberInvitationCreateDialogOpen,
    setIsMemberInvitationCreateDialogOpen
  ] = useState<boolean>(false);

  const [isConfirmationDialogOpened, setIsConfirmationModalOpened] =
    useState<boolean>(false);

  const [
    isFamilyMemberRoleUpdateDialogOpened,
    setIsFamilyMemberRoleUpdateDialogOpened
  ] = useState<boolean>(false);

  const selectedMember = _.get(members, selectedMemberIndex);

  const selectedMemberDelete = () =>
    careTeamMemberDelete({
      careTeamId,
      memberId: selectedMember.care_team_member_id
    })
      .then(() => {
        if (selectedMember.profile_id === user?.profile.id) {
          toast.info({ content: 'You have left the care team successfully!' });
          refetchCareTeams().then((data) => {
            switchCareTeam(_.get(data, '[0]?.care_team_id', ''));
          });
        } else {
          toast.info({ content: 'Team member has been removed successfully!' });
          refetchCareTeamMembers();
        }
        setSelectedMemberIndex(0);
        setIsConfirmationModalOpened(false);
      })
      .catch(({ errors }) => setErrorsInConfirmDialog(errors));

  const handleFamilyMemberRoleUpdate = () => {
    if (_.isNil(selectedMember)) {
      return;
    }

    setIsFamilyMemberRoleUpdateDialogOpened(true);
  };

  const loading = careTeamMembersLoading || userLoading || isFetchingCareTeam;

  const userIsPatientOrFamilyMember = userIsPatient || userIsFamilyMember;
  const requestUserIsSelectedMember =
    selectedMember?.profile_id === user?.profile.id;

  const confirmationMessage = requestUserIsSelectedMember
    ? 'Are you sure you want to leave the team?'
    : 'Are you sure you want to remove this member from the team?';

  const membersForSlider = useMemo(
    () =>
      members.map((member) => ({
        ...member,
        display_name:
          member?.profile_id === user?.profile.id ? 'You' : member.first_name
      })),
    [members, user]
  );

  const hasCareTeam = !isFetchingCareTeam && !_.isNil(careTeam);

  const handleAddNewCareTeamClick = () => history.push(ONBOARDING_STEPPER_URL);

  return (
    <Stack paddingBottom={6}>
      <Grid container gap={2} direction="column" paddingY={3}>
        <Grid
          item
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h1">Care Team</Typography>
          <Button
            fullWidth={false}
            variant="text"
            onClick={handleAddNewCareTeamClick}
            className={classes.newTeamButton}
          >
            <Typography variant="caption">New Team</Typography>
          </Button>
        </Grid>

        {loading && <PageLoader />}

        {!loading && hasCareTeam && (
          <>
            {_.isEmpty(members) ? (
              <Grid item>
                <BlankState
                  icon={GroupAddIcon}
                  title="No care team members"
                  subtitle="Click on the '+' icon to add a new team member"
                  onClick={() => setIsMemberInvitationCreateDialogOpen(true)}
                  paddingTop={mobile ? 3 : 11}
                />
              </Grid>
            ) : (
              <CareTeamListSlider
                members={membersForSlider}
                activeIndex={selectedMemberIndex}
                setActiveIndex={setSelectedMemberIndex}
              />
            )}

            <Divider />

            {selectedMember && (
              <>
                <Grid item>
                  <Grid container>
                    <Stack paddingY={2} alignItems="center" direction="row">
                      <ExtendedAvatar
                        image={selectedMember.avatar}
                        heading={
                          requestUserIsSelectedMember
                            ? 'You'
                            : selectedMember.first_name
                        }
                        subHeading={
                          requestUserIsSelectedMember
                            ? ''
                            : selectedMember.last_name
                        }
                        helpText={
                          <MemberRole
                            mutateCareTeamMembers={mutateCareTeamMembers}
                            member={selectedMember}
                          />
                        }
                      />
                    </Stack>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container display="flex" direction="column">
                    {/* TODO: Validate that the selected member is a family member in this care team. Not any care team in general. */}
                    <CareTeamMemberDetails
                      member={selectedMember}
                      onFamilyMemberRoleEdit={handleFamilyMemberRoleUpdate}
                    />
                  </Grid>
                </Grid>

                {userIsPatientOrFamilyMember && (
                  <Grid item paddingTop={2}>
                    <Button
                      variant="outlined"
                      //color="warning"
                      onClick={() => setIsConfirmationModalOpened(true)}
                      size="small"
                      className={classes.leaveTeamButton}
                    >
                      {selectedMember.profile_id === user?.profile.id
                        ? 'Leave Team'
                        : 'Remove Member'}
                    </Button>
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </Grid>

      {userIsPatientOrFamilyMember && (
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          onClick={() => setIsMemberInvitationCreateDialogOpen(true)}
        >
          <AddIcon />
        </Fab>
      )}

      {isMemberInvitationCreateDialogOpen && (
        <MemberInvitationCreateDialog
          onClose={() => setIsMemberInvitationCreateDialogOpen(false)}
        />
      )}

      <ConfirmationDialog
        open={isConfirmationDialogOpened}
        title={confirmationMessage}
        primaryButtonColor="error"
        onClose={() => {
          setErrorsInConfirmDialog([]);
          setIsConfirmationModalOpened(false);
        }}
        onConfirm={selectedMemberDelete}
        errors={errorsInConfirmDialog}
      />

      {isFamilyMemberRoleUpdateDialogOpened && (
        <FamilyMemberRoleUpdateDialog
          onClose={() => setIsFamilyMemberRoleUpdateDialogOpened(false)}
          mutateCareTeamMembers={mutateCareTeamMembers}
          member={selectedMember}
        />
      )}
    </Stack>
  );
};

export default CareTeam;
