import { datetime } from 'utils/datetime';

import Typography from 'components/Typography';
import Button from 'components/Button';
import ButtonGroup from 'components/ButtonGroup';
import { EditIcon, DeleteIcon } from 'icons';

import { IScheduleCarePlanItem } from 'entities/CarePlan/sdk';
import { useSelectedCareTeam } from 'entities/CareTeam/sdk';

import { BACKEND_TIME_FORMAT, TIME_WITH_PERIOD_FORMAT } from 'constants/time';

interface ICarePlanItemPeriod {
  item: IScheduleCarePlanItem;
  onEditClick: () => void;
  onDeleteClick: () => void;
}

const CarePlanItemPeriod: React.FC<ICarePlanItemPeriod> = ({
  item,
  onEditClick,
  onDeleteClick
}) => {
  const { userIsPatient, userIsFamilyMember } = useSelectedCareTeam();

  return (
    <>
      {item.is_deleted ? (
        <Typography variant="subtitle2" color="error" textAlign="right">
          Deleted
        </Typography>
      ) : (
        (userIsPatient || userIsFamilyMember) && (
          <ButtonGroup fullWidth variant="text" color="inherit">
            <Button color="inherit" onClick={onEditClick}>
              <EditIcon />
            </Button>
            <Button color="inherit" onClick={onDeleteClick}>
              <DeleteIcon />
            </Button>
          </ButtonGroup>
        )
      )}
      {item.is_all_day && (
        <Typography variant="subtitle1" textAlign="end">
          all day
        </Typography>
      )}
      {!item.is_all_day && (
        <>
          <Typography variant="subtitle1" textAlign="end">
            {datetime(item.start_time, BACKEND_TIME_FORMAT).format(
              TIME_WITH_PERIOD_FORMAT
            )}
          </Typography>
          <Typography variant="subtitle1" textAlign="end">
            {datetime(item.end_time, BACKEND_TIME_FORMAT).format(
              TIME_WITH_PERIOD_FORMAT
            )}
          </Typography>
        </>
      )}
    </>
  );
};

export default CarePlanItemPeriod;
