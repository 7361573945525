import { forwardRef } from 'react';
import Slide, { ISlide } from 'components/Slide';
import MuiDialog, { DialogProps } from '@mui/material/Dialog';

export interface ISimpleDialog extends DialogProps {}

// eslint-disable-next-line
const Transition = forwardRef<unknown, ISlide>((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
Transition.displayName = 'Transition';

const SimpleDialog: React.FC<ISimpleDialog> = ({ open, onClose, children }) => {
  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      fullWidth
      TransitionComponent={Transition}
    >
      {children}
    </MuiDialog>
  );
};

export default SimpleDialog;
