import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { datetime } from 'utils/datetime';
import { queryStringParse } from 'utils/common';

import { useRedirectUrl, useRedirectToOnboarding } from 'utils/hooks';
import { MEDICATIONS_URL, SCHEDULE_URL } from 'config/urls';
import {
  BACKEND_TIME_FORMAT,
  TIME_WITH_PERIOD_FORMAT,
  BACKEND_DATE_FORMAT
} from 'constants/time';
import toast from 'utils/toast';
import { DAYS_OF_THE_WEEK_CHOICES } from 'constants/common';
import { GENERIC_FORM_ERRORS_KEY, IFormError } from 'utils/sdk';

import { useSelectedPatient } from 'entities/Patient/sdk';
import { useGrantAccessToParticularCareTeamRoles } from 'entities/CareTeam/sdk';
import {
  IMedicationCreateFormValues,
  medicationCreate
} from 'entities/Medications/sdk';
import { ECareTeamRole } from 'entities/CareTeam/constants';
import {
  EMedicationIntakeFrequency,
  EMedicationRelationToMeals,
  MEDICATION_INTAKE_FREQUENCIES_THAT_REQUIRE_INTAKE_TIME,
  MEDICATION_INTAKE_FREQUENCY_CHOICES,
  MEDICATION_RELATION_TO_MEALS_CHOICES
} from 'entities/Medications/constants';

import Grid from 'components/Grid';
import Alert from 'components/Alert';
import Stack from 'components/Stack';
import Radio from 'components/Radio';
import Select from 'components/Select';
import Button from 'components/Button';
import Loading from 'components/Loading';
import TextField from 'components/TextField';
import InputLabel from 'components/InputLabel';
import DateField from 'components/DateField';
import PageHeader from 'components/PageHeader';
import RadioGroup from 'components/RadioGroup';
import FormControl from 'components/FormControl';
import NumberField from 'components/NumberField';
import ArrayTimeField from 'components/ArrayTimeField';
import FormControlLabel from 'components/FormControlLabel';
import MedicationsAutocomplete from 'pages/MedicationCreate/MedicationsAutocomplete';

import { VALIDATION_SCHEMA } from './constants';

interface IMedicationCreate {}

const MedicationCreate: React.FC<IMedicationCreate> = () => {
  useRedirectToOnboarding();
  useGrantAccessToParticularCareTeamRoles({
    roles: [ECareTeamRole.PATIENT, ECareTeamRole.FAMILY_MEMBER]
  });

  const history = useHistory();

  const [redirectUrl, setRedirectUrl] = useState<string>(useRedirectUrl());

  const { patient } = useSelectedPatient();
  const {
    intakeFrequency: initialIntakeFrequency = EMedicationIntakeFrequency.DAILY
  }: { intakeFrequency: EMedicationIntakeFrequency } = useParams();

  const location = useLocation();

  const queryParams = queryStringParse({
    queryParams: location.search
  });
  const selectedDate = _.get(queryParams, 'selectedDate');

  const handleClick = () => {
    setRedirectUrl(SCHEDULE_URL);
  };

  const repeatAfterDatetime = useMemo(
    () =>
      !_.isNil(selectedDate)
        ? datetime(selectedDate as string, BACKEND_DATE_FORMAT).format(
            BACKEND_DATE_FORMAT
          )
        : datetime().format(BACKEND_DATE_FORMAT),
    [selectedDate]
  );

  const handleSubmit = useCallback(
    (
      values: IMedicationCreateFormValues,
      formikHelpers: FormikHelpers<IMedicationCreateFormValues>
    ) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient.");
      }

      const formattedValues = { ...values };

      formattedValues.intake_times = _.map(
        formattedValues.intake_times,
        (time) =>
          datetime(time, TIME_WITH_PERIOD_FORMAT).format(BACKEND_TIME_FORMAT)
      );
      let redirectTo = redirectUrl || MEDICATIONS_URL;

      if (selectedDate) {
        redirectTo = `${SCHEDULE_URL}?selectedDate=${formattedValues.repeat_startdatetime}`;
      }

      return medicationCreate({
        patientId: patient.id,
        medication: formattedValues
      })
        .then(() => {
          formikHelpers.resetForm();
          history.push(redirectTo);

          if (redirectTo === MEDICATIONS_URL) {
            toast.info({ content: 'Medication has been created sucessfully!' });
          } else {
            toast.actionable({
              content: 'Medication has been created sucessfully!',
              action: () => history.push(MEDICATIONS_URL),
              actionButtonText: 'View all'
            });
          }
        })
        .catch(formikHelpers.setErrors);
    },
    [history, patient?.id, redirectUrl, selectedDate]
  );

  const initialValues = useMemo(
    () => ({
      name: '',
      quantity: null,
      intake_days: [],
      intake_times: [datetime().format(TIME_WITH_PERIOD_FORMAT)],
      relation_to_meals: EMedicationRelationToMeals.DOES_NOT_APPLY,
      intake_frequency: initialIntakeFrequency,
      repeat_startdatetime: repeatAfterDatetime,
      repeat_enddatetime: null,
      description: ''
    }),
    [initialIntakeFrequency, repeatAfterDatetime]
  );

  const formik = useFormik<IMedicationCreateFormValues>({
    initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA
  });

  const formErrors: Array<IFormError> = _.get(
    formik.errors,
    GENERIC_FORM_ERRORS_KEY,
    []
  );

  const displayQuantity =
    formik.values.intake_frequency !== EMedicationIntakeFrequency.FORBIDDEN;
  const displayRelationToMeals =
    formik.values.intake_frequency !== EMedicationIntakeFrequency.FORBIDDEN;
  const displayIntakeDays =
    formik.values.intake_frequency === EMedicationIntakeFrequency.WEEKLY;
  const displayIntakeTimes =
    MEDICATION_INTAKE_FREQUENCIES_THAT_REQUIRE_INTAKE_TIME.includes(
      formik.values.intake_frequency
    );

  return (
    <Stack gap={2} marginBottom={2}>
      <PageHeader heading="Add Medication" backTo={MEDICATIONS_URL} />

      {patient ? (
        <>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2} marginTop={3}>
              {!_.isEmpty(formErrors) && (
                <Stack spacing={1}>
                  {formErrors.map((error, index) => (
                    <Alert key={index} severity="error">
                      {error.message}
                    </Alert>
                  ))}
                </Stack>
              )}

              <MedicationsAutocomplete
                value={formik.values.name}
                onChange={(value) => formik.setFieldValue('name', value)}
                renderInput={(params) => (
                  <TextField.Outlined
                    {...params}
                    name="name"
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Name *"
                    placeholder="Enter medication (e.g., Aspirin 1 tablet)"
                    error={
                      formik.touched.name && !_.isEmpty(formik.errors.name)
                    }
                    helperText={formik.touched.name ? formik.errors.name : ''}
                  />
                )}
              />

              <FormControl component="fieldset">
                <InputLabel shrink>Intake frequency</InputLabel>
                <RadioGroup
                  defaultValue={initialIntakeFrequency}
                  name="intake_frequency"
                  onChange={formik.handleChange}
                >
                  {MEDICATION_INTAKE_FREQUENCY_CHOICES.map(
                    ({ value, label }) => (
                      <FormControlLabel
                        value={value}
                        control={<Radio />}
                        label={label}
                        key={value}
                      />
                    )
                  )}
                </RadioGroup>
              </FormControl>

              {displayIntakeDays && (
                <Select
                  multiple
                  label="Intake days"
                  options={DAYS_OF_THE_WEEK_CHOICES}
                  name="intake_days"
                  value={formik.values.intake_days}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.intake_days &&
                    !_.isEmpty(formik.errors.intake_days)
                  }
                  helperText={
                    formik.touched.intake_days
                      ? `${formik.errors.intake_days}`
                      : ''
                  }
                />
              )}

              {displayIntakeTimes && (
                <ArrayTimeField
                  addFieldText="Add intake time"
                  label="Intake time"
                  name="intake_times"
                  formik={formik}
                />
              )}

              {displayQuantity && (
                <NumberField
                  fullWidth
                  label="Quantity"
                  name="quantity"
                  formik={formik}
                  placeholder="Enter number of units (e.g., 1, 2, 3)"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}

              {displayRelationToMeals && (
                <FormControl component="fieldset">
                  <InputLabel shrink>Relation to meals</InputLabel>
                  <RadioGroup
                    defaultValue={formik.values.relation_to_meals}
                    name="relation_to_meals"
                    onChange={formik.handleChange}
                  >
                    {MEDICATION_RELATION_TO_MEALS_CHOICES.map(
                      ({ value, label }) => (
                        <FormControlLabel
                          value={value}
                          control={<Radio />}
                          label={label}
                          key={value}
                        />
                      )
                    )}
                  </RadioGroup>
                </FormControl>
              )}

              <TextField.Outlined
                fullWidth
                multiline
                minRows={3}
                InputLabelProps={{
                  shrink: true
                }}
                label="Description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                placeholder="Enter relevant information (e.g., side effects) to assist the caregiver."
                error={
                  formik.touched.description &&
                  !_.isEmpty(formik.errors.description)
                }
                helperText={
                  formik.touched.description ? formik.errors.description : ''
                }
              />

              <DateField.Formik
                label="Start date *"
                name="repeat_startdatetime"
                formik={formik}
              />

              <DateField.Formik
                clearable
                label="End date"
                name="repeat_enddatetime"
                formik={formik}
              />

              <Button
                type="submit"
                disabled={formik.isSubmitting}
                onClick={handleClick}
                data-gtm="button-submit-medications-create"
              >
                Save
              </Button>
            </Stack>
          </form>
        </>
      ) : (
        <Grid container justifyContent="center">
          <Grid item>
            <Loading />
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};

export default MedicationCreate;
