import MuiSelect, { SelectProps } from '@mui/material/Select';
import { FormHelperText } from '@mui/material';
import { useTheme } from '@mui/styles';

import Menu from 'components/Menu';
import InputLabel from 'components/InputLabel';
import FormControl from 'components/FormControl';

interface IOption {
  value: string;
  label: string;
  disabled?: boolean;
}

interface ISelectWithOptions {
  children?: never;
  options: Array<IOption>;
  helperText?: string;
}

interface ISelectWithChildren {
  children: React.ReactNode;
  options?: never;
  helperText?: string;
}

// We either have a select with Options or one with Children
export type ISelect = SelectProps & (ISelectWithOptions | ISelectWithChildren);

const Select = ({
  label,
  options,
  helperText,
  error,
  children,
  ...props
}: ISelect) => {
  const theme = useTheme();
  return (
    <FormControl fullWidth>
      <InputLabel
        shrink
        id={props.name}
        sx={{
          //backgroundColor: 'transparent',
          backgroundColor: theme.palette.background.default,
          // theme.palette.mode === 'dark'
          //   ? theme.palette.background.default
          //   : 'white',
          //color: theme.palette.background,
          paddingX: '5px'
        }}
      >
        {label}
      </InputLabel>
      <MuiSelect {...props}>
        {options
          ? options.map((option) => (
              <Menu.Item
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </Menu.Item>
            ))
          : children}
      </MuiSelect>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

Select.MenuItem = Menu.Item;

export default Select;
