import { BASE_API_URL } from 'config/urls';

import { useFetch, IPaginated, post } from 'utils/sdk';

import { deepTransformUTCToLocal } from 'utils/datetime';
export interface IWebAppNotification {
  id: string;
  message: string;
  created_at: string;
  seen: boolean;
}

export const useUserWebAppNotifications = ({
  careTeamId,
  pageNumber
}: {
  pageNumber: number;
  careTeamId: string | null | undefined;
}) => {
  const response = useFetch<IPaginated<IWebAppNotification>>(
    careTeamId
      ? `${BASE_API_URL}/notifications/web-app-notifications/for-team/${careTeamId}/list/?limit=10&offset=${
          pageNumber * 10
        }`
      : null
  );

  return {
    ...response,
    data: response.data
      ? deepTransformUTCToLocal({ data: response.data })
      : { results: [], next: null, previous: null }
  };
};

export const webAppNotificationUpdateSeen = ({
  webAppNotificationId,
  seen
}: {
  webAppNotificationId: string;
  seen: boolean;
}) =>
  post(
    `${BASE_API_URL}/notifications/web-app-notifications/${webAppNotificationId}/update-seen/`,
    { seen }
  );

export const webAppNotificationMarkAllAsSeen = ({
  careTeamId
}: {
  careTeamId: string;
}) =>
  post(
    `${BASE_API_URL}/notifications/web-app-notifications/for-team/${careTeamId}/mark-all-as-seen/`
  );

export const useUserUnseenWebAppNotificationsCount = ({
  careTeamId
}: {
  careTeamId: string | null | undefined;
}) =>
  useFetch<number>(
    careTeamId
      ? `${BASE_API_URL}/notifications/web-app-notifications/for-team/${careTeamId}/unseen-count/`
      : null
  );
