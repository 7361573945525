import { useContext } from 'react';

import { BASE_API_URL } from 'config/urls';

import { post, useFetch } from 'utils/sdk';

import { IProfile } from 'entities/Profile/sdk';

import { SelectedPatientContext } from 'components/SelectedPatientContext';
import { ETemperatureUnit } from 'constants/common';

export interface IPatient {
  id: string;
  profile: IProfile;
}

export interface IMedicalCondition {
  medical_condition_id: string;
  condition_type: string;
  description: string;
}

export interface IMedicalConditionChoice {
  label: string;
  value: string;
}

export interface ICustomVitals {
  temperature_max: string;
  temperature_unit: ETemperatureUnit;
  blood_pressure_systolic_min: string;
  blood_pressure_diastolic_min: string;
  blood_pressure_systolic_max: string;
  blood_pressure_diastolic_max: string;
  pulse_min: string;
  pulse_max: string;
  blood_oxygen_min: string;
  blood_sugar_fasting_min: string;
  blood_sugar_fasting_max: string;
  blood_sugar_non_fasting_min: string;
  blood_sugar_non_fasting_max: string;
}

export interface IPatientProfileCreateFormValues {
  first_name: string;
  last_name: string;
}

export interface INewMedicalCondition
  extends Omit<IMedicalCondition, 'medical_condition_id'> {}

export interface IVitalsToTrack {
  temperature: boolean;
  blood_pressure: boolean;
  pulse: boolean;
  blood_oxygen: boolean;
  blood_sugar: boolean;
}

export interface IVitalsToTrackFromValues {
  temperature: boolean;
  blood_pressure: boolean;
  pulse: boolean;
  blood_oxygen: boolean;
  blood_sugar: boolean;
}

export const usePatientMedicalConditions = ({
  patientId
}: {
  patientId: string | null | undefined;
}) =>
  useFetch<Array<IMedicalCondition>>(
    patientId
      ? `${BASE_API_URL}/patients/patient/${patientId}/conditions/list/`
      : null
  );

export const usePatientCustomVitals = ({
  patientId
}: {
  patientId: string | null | undefined;
}) =>
  useFetch<ICustomVitals>(
    patientId
      ? `${BASE_API_URL}/patients/patient/${patientId}/custom_vitals/retrieve/`
      : null
  );

export const customVitalsUpdate = ({
  customVitals,
  patientId
}: {
  customVitals: ICustomVitals;
  patientId: string;
}) =>
  post<ICustomVitals>(
    `${BASE_API_URL}/patients/patient/${patientId}/custom_vitals/update/`,
    customVitals
  );

export const useMedicalConditionChoices = () =>
  useFetch<Array<IMedicalConditionChoice>>(
    `${BASE_API_URL}/patients/condition/choices/list/`
  );

export const conditionCreate = ({
  patientId,
  condition
}: {
  patientId: string;
  condition: INewMedicalCondition;
}) =>
  post<IMedicalCondition>(
    `${BASE_API_URL}/patients/patient/${patientId}/condition/create/`,
    condition
  );

export const conditionUpdate = ({
  patientId,
  conditionId,
  condition
}: {
  patientId: string;
  conditionId: string;
  condition: IMedicalCondition;
}) =>
  post<IMedicalCondition>(
    `${BASE_API_URL}/patients/patient/${patientId}/condition/${conditionId}/update/`,
    condition
  );

export const conditionDelete = ({
  patientId,
  conditionId
}: {
  patientId: string;
  conditionId: string;
}) =>
  post<undefined>(
    `${BASE_API_URL}/patients/${patientId}/condition/${conditionId}/delete/`
  );

export const usePatient = ({ careTeamId }: { careTeamId: string }) =>
  useFetch<IPatient>(
    careTeamId
      ? `${BASE_API_URL}/careteam/team/${careTeamId}/patient/details/`
      : null
  );

export const useSelectedPatient = () => useContext(SelectedPatientContext);

export const vitalsToTrackUpdate = ({
  vitalsToTrack,
  patientId
}: {
  vitalsToTrack: IVitalsToTrackFromValues;
  patientId: string;
}) =>
  post<IVitalsToTrack>(
    `${BASE_API_URL}/patients/patient/${patientId}/vitals-to-track/update/`,
    vitalsToTrack
  );

export const useVitalsToTrack = ({
  patientId
}: {
  patientId: string | null | undefined;
}) =>
  useFetch<IVitalsToTrack>(
    patientId
      ? `${BASE_API_URL}/patients/patient/${patientId}/vitals-to-track/retrieve/`
      : null
  );
