import Grid from 'components/Grid';
import Button from 'components/Button';
import Typography from 'components/Typography';

import { useUserLogout } from 'entities/Auth/sdk';

const RegisterActivateNotAllowed: React.FC<{}> = () => {
  const { handleLogout } = useUserLogout();

  return (
    <Grid
      container
      height="100%"
      justifyContent="center"
      alignItems="center"
      direction="column"
      rowGap={5}
    >
      <Grid item>
        <Typography variant="h4" textAlign="center">
          You are logged into Carederly with another account.
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant="subtitle1" textAlign="center">
          In order to activate your account, logout first and then open the
          activation link again.
        </Typography>
      </Grid>

      <Grid item>
        <Button
          sx={{ textTransform: 'none' }}
          onClick={handleLogout}
          data-gtm="button-logout"
        >
          Log out
        </Button>
      </Grid>
    </Grid>
  );
};

export default RegisterActivateNotAllowed;
