import _ from 'lodash';
import { FormikHelpers, useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import { useHistory, useParams } from 'react-router-dom';

import logo from 'assets/blue_logo.png';
import { LOGIN_URL, PASSWORD_RESET_SUCCESS_URL } from 'config/urls';
import { GENERIC_FORM_ERRORS_KEY, IFormError } from 'utils/sdk';

import Alert from 'components/Alert';
import Stack from 'components/Stack';
import Image from 'components/Image';
import Button from 'components/Button';
import PasswordField from 'components/PasswordField';

import { IResetPasswordFormValues, passwordReset } from 'entities/Auth/sdk';

import {
  ERROR_TO_READABLE_MESSAGE_MAPPING,
  VALIDATION_SCHEMA
} from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    backgroundColor: theme.palette.secondary.main,
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center'
  }
}));

const ResetPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const { token, email }: { token: string; email: string } = useParams();

  const handleSubmit = (
    values: IResetPasswordFormValues,
    formikHelpers: FormikHelpers<IResetPasswordFormValues>
  ) =>
    passwordReset(values)
      .then(() => history.push(PASSWORD_RESET_SUCCESS_URL))
      .catch(formikHelpers.setErrors);

  const formik = useFormik<IResetPasswordFormValues>({
    initialValues: {
      email,
      token,
      password: '',
      confirm_password: ''
    },
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA
  });

  const formErrors: Array<IFormError> = _.get(
    formik.errors,
    GENERIC_FORM_ERRORS_KEY,
    []
  );

  return (
    <form onSubmit={formik.handleSubmit} className={classes.container}>
      <Stack
        width="100%"
        maxWidth="600px"
        minHeight="100%"
        justifyContent="center"
        padding={4}
        gap={3}
      >
        <Image src={logo} alt="logo" />

        {formErrors.map((error, index) => (
          <Alert key={index} severity="error">
            {ERROR_TO_READABLE_MESSAGE_MAPPING[error.message] || error.message}
          </Alert>
        ))}

        <PasswordField
          name="password"
          label="Password"
          placeholder="Password"
          formik={formik}
        />
        <PasswordField
          name="confirm_password"
          label="Confirm password"
          placeholder="Confirm password"
          formik={formik}
        />

        <Button type="submit" data-gtm="button-submit-update-password">
          Update password
        </Button>

        <Button
          variant="outlined"
          onClick={() => history.push(LOGIN_URL)}
          data-gtm="button-login"
        >
          Back to Login
        </Button>
      </Stack>
    </form>
  );
};

export default ResetPassword;
