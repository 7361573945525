import { useState } from 'react';
import { makeStyles } from '@mui/styles';

import { CheckCircleOutlineIcon, ErrorOutlineOutlinedIcon } from 'icons';

import Grid from 'components/Grid';
import Stack from 'components/Stack';
import Divider from 'components/Divider';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';

import { IProfile } from 'entities/Profile/sdk';
import { useSignedUser } from 'entities/Auth/sdk';
import PhoneNumberVerificationDialog from 'components/PhoneNumberVerificationDialog';

export interface IDetails {
  profile: Partial<IProfile>;
}

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  icon: {
    textAlign: 'end'
  },
  address: {
    wordBreak: 'break-word'
  }
}));

const Details: React.FC<IDetails> = ({ profile }) => {
  const [
    isPhoneNumberVerificationDialogOpen,
    setIsPhoneNumberVerificationDialogOpen
  ] = useState(false);

  const classes = useStyles();

  const { data: signedUser } = useSignedUser();

  const phoneNumber = profile.phone_number;

  const showPhoneNumberVerification =
    signedUser?.profile.id === profile.id && phoneNumber;

  return (
    <>
      <Grid container columnSpacing={2} alignItems="center">
        <Grid item xs={5} sm={4}>
          <Typography variant="body2">Phone</Typography>
        </Grid>
        <Grid item flexGrow={1}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body1">
              {profile?.phone_number?.number || '-'}
            </Typography>

            {showPhoneNumberVerification ? (
              phoneNumber?.is_verified ? (
                <Stack alignItems="center" direction="row">
                  <CheckCircleOutlineIcon color="success" />
                  <Typography marginLeft={0.5}>Verified</Typography>
                </Stack>
              ) : (
                <>
                  <Tooltip
                    title="Click to verify"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setIsPhoneNumberVerificationDialogOpen(true)}
                  >
                    <Stack direction="row">
                      <ErrorOutlineOutlinedIcon color="error" />
                      <Typography marginLeft={0.5}>Click to verify</Typography>
                    </Stack>
                  </Tooltip>
                  {isPhoneNumberVerificationDialogOpen && (
                    <PhoneNumberVerificationDialog
                      open
                      phoneNumber={phoneNumber}
                      onClose={() =>
                        setIsPhoneNumberVerificationDialogOpen(false)
                      }
                    />
                  )}
                </>
              )
            ) : null}
          </Stack>
        </Grid>
      </Grid>

      {profile.user?.email && (
        <>
          <Divider className={classes.divider} />
          <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={5} sm={4}>
              <Typography variant="body2">Email</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {profile.user?.email || '-'}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}

      <Divider className={classes.divider} />

      <Grid container columnSpacing={2}>
        <Grid item xs={5} sm={4}>
          <Typography variant="body2">Address</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.address}>
            {profile.address || '-'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Details;
