import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Grid from 'components/Grid';
import Box from 'components/Box';
import Container from 'components/Container';
import TopNavigation from 'components/Navigation/TopNavigation';
import BottomNavigation from 'components/Navigation/BottomNavigation';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    backgroundColor: theme.palette.background.default,
    display: 'grid',
    gridTemplateRows: '72px 56px 1fr',
    gridTemplateColumns: '100%',
    gridTemplateAreas: `
      "topNavigation"
      "topMenu"
      "content"
    `
  },
  mobileRoot: {
    gridTemplateRows: '72px 1fr 72px',
    gridTemplateAreas: `
      "topNavigation"
      "content"
      "bottomNavigation"
    `
  },
  content: {
    maxWidth: '100% !important',
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    gridArea: 'content'
  },
  mobileContainer: {
    backgroundColor: theme.palette.background.default
  },
  topNavigation: {
    height: 72,
    backgroundColor: theme.palette.background.default,
    gridArea: 'topNavigation'
  },
  navigationContainer: {
    height: '100%'
  },
  topMenu: {
    position: 'relative',
    zIndex: 1,
    borderBottom: '1px solid white',
    // background:
    //   'radial-gradient(89.79% 663.85% at 94.23% 11.61%, #6315E3 15.1%, #9E59D5 51.56%, #C376CF 88.02%);',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    gridArea: 'topMenu'
  },
  bottomNavigation: {
    zIndex: 2,
    gridArea: 'bottomNavigation',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: theme.palette.background.default
  }
}));

interface IPageLayout {}

const PageLayout: React.FC<IPageLayout> = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className={classnames(classes.root, { [classes.mobileRoot]: mobile })}>
      <Box className={classes.topNavigation}>
        <Container maxWidth="lg" className={classes.navigationContainer}>
          <TopNavigation />
        </Container>
      </Box>
      {!mobile && (
        <>
          <Box className={classes.topMenu}>
            <Container maxWidth="lg">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs>
                  <BottomNavigation variant="horizontal" underline="bottom" />
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      )}

      <Box
        className={classnames(classes.content, {
          [classes.mobileContainer]: mobile
        })}
      >
        <Container sx={{ height: '100%' }} maxWidth="sm">
          <Box>{children}</Box>
        </Container>
      </Box>
      {mobile && (
        <Box className={classes.bottomNavigation}>
          <BottomNavigation />
        </Box>
      )}
    </Box>
  );
};

export default PageLayout;
