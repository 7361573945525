import { useHistory, useParams } from 'react-router-dom';

import {
  MY_CARE_TEAMS_URL,
  CUSTOM_VITALS_URL,
  MEDICAL_HISTORY_URL,
  PATIENT_PROFILE_BASE_URL,
  NOTIFICATION_PREFERENCES_URL
} from 'config/urls';
import { useRedirectToOnboarding } from 'utils/hooks';

import Grid from 'components/Grid';
import Divider from 'components/Divider';
import PageHeader from 'components/PageHeader';
import Typography from 'components/Typography';
import ExtendedAvatar from 'components/ExtendedAvatar';

import Details from 'entities/Profile/components/Details';
import MenuLabelArrowButton from 'components/MenuLabelArrowButton';

import { usePatient } from 'entities/Patient/sdk';
import { useCareTeamMemberRole } from 'entities/CareTeam/sdk';
import { ECareTeamRole } from 'entities/CareTeam/constants';

interface IPatientProfile {}

const PatientProfile: React.FC<IPatientProfile> = () => {
  useRedirectToOnboarding();

  const history = useHistory();

  const { careTeamId }: { careTeamId: string } = useParams();

  const { data: patient } = usePatient({ careTeamId });

  const { data: care_team_role } = useCareTeamMemberRole({ careTeamId });

  const profile = patient?.profile;

  const enableProfileUpdate =
    care_team_role?.care_team_role === ECareTeamRole.FAMILY_MEMBER ||
    care_team_role?.care_team_role === ECareTeamRole.PATIENT
      ? true
      : false;

  return (
    <Grid container gap={2} direction="column">
      <Grid item>
        <PageHeader
          backTo={MY_CARE_TEAMS_URL}
          updateTo={
            enableProfileUpdate
              ? `${PATIENT_PROFILE_BASE_URL}/${careTeamId}/update`
              : undefined
          }
        />
      </Grid>

      {profile && (
        <>
          <Grid item paddingBottom={2}>
            <ExtendedAvatar
              image={profile.avatar}
              size="medium"
              heading={profile.first_name}
              subHeading={profile.last_name}
            />
          </Grid>

          <Grid item width="100%">
            <Details profile={profile} />
          </Grid>

          <Grid item width="100%">
            <Divider />
          </Grid>

          <Grid item width="100%">
            <MenuLabelArrowButton
              label="Medical history"
              description="Provide a brief description of your loved one’s medical history to help aides get up to speed"
              onClick={() => history.push(MEDICAL_HISTORY_URL)}
            />
          </Grid>

          <Grid item width="100%">
            <Divider />
          </Grid>

          <Grid item width="100%">
            <MenuLabelArrowButton
              label="Vitals"
              description="Set your loved one’s custom vital ranges and settings"
              onClick={() => history.push(CUSTOM_VITALS_URL)}
            />
          </Grid>

          <Grid item width="100%">
            <Divider />
          </Grid>

          <Grid item width="100%">
            <MenuLabelArrowButton
              label="Notification preferences"
              description="Manage the notifications your loved one will receive"
              onClick={() =>
                history.push(
                  `${NOTIFICATION_PREFERENCES_URL}?profile=${profile.id}`
                )
              }
            />
          </Grid>
        </>
      )}

      {!profile && (
        <Grid item container justifyContent="space-between">
          <Grid item paddingY={3}>
            <Typography variant="h2">No profile</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default PatientProfile;
