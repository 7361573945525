import { useParams } from 'react-router-dom';

import Link from 'components/Link';
import Grid from 'components/Grid';
import Loading from 'components/Loading';
import Typography from 'components/Typography';

import { LOGIN_URL } from 'config/urls';
import { useRedirectUrl } from 'utils/hooks';

import { useRegisterActivate } from 'entities/Auth/sdk';

const RegisterActivate: React.FC<{}> = () => {
  const { userId }: { userId: string } = useParams();
  const redirectUrl = useRedirectUrl();

  const { loading, error } = useRegisterActivate({ userId });

  let loginUrl = LOGIN_URL;
  if (redirectUrl) {
    loginUrl = `${LOGIN_URL}?redirectUrl=${redirectUrl}`;
  }

  return (
    <Grid
      container
      height="100%"
      justifyContent="center"
      alignItems="center"
      direction="column"
      rowGap={5}
    >
      {loading && (
        <>
          <Grid item>
            <Typography variant="h4">Confirming your registration</Typography>
          </Grid>
          <Grid item>
            <Loading />
          </Grid>
        </>
      )}
      {!loading && (
        <Grid item>
          <Typography variant="h4" textAlign="center">
            {error || 'You have successfully activated your account!'}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Link to={loginUrl} variant="h6" underline="none" data-gtm="link-login">
          Login to Carederly
        </Link>
      </Grid>
    </Grid>
  );
};

export default RegisterActivate;
