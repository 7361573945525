export const IS_TASK_CREATE_DIALOG_OPENED_URL_PARAM_KEY =
  'isTaskCreateDialogOpened';

export const IS_ADD_CARE_PLAN_DIALOG_OPENED_URL_PARAM_KEY =
  'isAddCarePlanDialogOpened';

export enum EScheduleMenuItems {
  NEW_CARE_TASK = 'New care task',
  NEW_MEDICATION = 'New medication',
  NEW_APPOINTMENT = 'New appointment'
}
