import _ from 'lodash';
import { getItem, setItem } from 'config/storage';
import { ICareTeam } from 'entities/CareTeam/sdk';

const RECENTLY_SEEN_CARE_TEAM_IDS_LOCAL_STORAGE_KEY = 'recentlySeenCareTeamIds';

export const getRecentlySeenCareTeamIds = (): Array<string> => {
  const previousValue = getItem(RECENTLY_SEEN_CARE_TEAM_IDS_LOCAL_STORAGE_KEY);

  if (!_.isNil(previousValue)) {
    return JSON.parse(previousValue);
  }

  return [];
};

export const updateRecentlySeenCareTeamIds = ({
  careTeamId
}: {
  careTeamId: string;
}) => {
  const previousValue = getRecentlySeenCareTeamIds();

  const newValue = _.uniq([careTeamId, ...previousValue]);
  const stringifiedNewValue = JSON.stringify(newValue);

  setItem({
    key: RECENTLY_SEEN_CARE_TEAM_IDS_LOCAL_STORAGE_KEY,
    value: stringifiedNewValue
  });
};

export const sortCareTeamsByRecentlySeen = ({
  careTeams
}: {
  careTeams: Array<ICareTeam>;
}): Array<ICareTeam> => {
  const recentlySeenCareTeamIds = getRecentlySeenCareTeamIds();

  return _.sortBy(careTeams, (careTeam) => {
    const careTeamId = careTeam.care_team_id;
    const hasBeenRecentlySeen = _.includes(recentlySeenCareTeamIds, careTeamId);

    if (hasBeenRecentlySeen) {
      // If it has already been seen - use the index from the "seen" array.
      return recentlySeenCareTeamIds.indexOf(careTeamId);
    }

    // If it has not been seen yet - use infinity, so that we're sure it'll be last.
    return Infinity;
  });
};
