import * as yup from 'yup';

export const VALIDATION_SCHEMA = [
  yup.object().shape({
    care_team_role: yup.string().required('You must pick an option.')
  }),
  yup.object().shape({
    patient: yup.object({
      first_name: yup.string().required('First name is required.'),
      last_name: yup.string().required('Last name is required.')
    })
  })
];

export const onboardingSteps = ['Your goal', 'About the patient'];
