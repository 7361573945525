/*
Image processing functions
*/

export class UserImage extends Image {
  constructor(public mimeType: string) {
    super();
  }

  get imageType(): string {
    return this.mimeType.split('/')[1];
  }
}

export const createNewImage = (dataURL: string): Promise<UserImage> => {
  return new Promise((resolve, reject) => {
    const image = new UserImage('image/jpeg');

    image.onload = () => {
      URL.revokeObjectURL(image.src);
      resolve(image);
    };

    image.src = dataURL;
  });
};

const getOrientation = (file: File) =>
  new Promise<number | undefined>((resolve) => {
    const reader = new FileReader();

    reader.onload = () =>
      resolve(
        (() => {
          const view = new DataView(reader.result as ArrayBuffer);

          if (view.getUint16(0, false) !== 0xffd8) {
            return;
          }

          const length = view.byteLength;

          let offset = 2;

          while (offset < length) {
            const marker = view.getUint16(offset, false);

            offset += 2;

            if (marker === 0xffe1) {
              offset += 2;

              if (view.getUint32(offset, false) !== 0x45786966) {
                return;
              }

              offset += 6;

              const little = view.getUint16(offset, false) === 0x4949;

              offset += view.getUint32(offset + 4, little);

              const tags = view.getUint16(offset, little);

              offset += 2;

              for (let i = 0; i < tags; i++) {
                if (view.getUint16(offset + i * 12, little) === 0x0112) {
                  return view.getUint16(offset + i * 12 + 8, little);
                }
              }
            } else if ((marker & 0xff00) !== 0xff00) {
              break;
            } else {
              offset += view.getUint16(offset, false);
            }
          }
        })()
      );

    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  });

const applyRotation = (file: File, orientation: number, maxWidth: number) =>
  new Promise<string>((resolve) => {
    const reader = new FileReader();

    reader.onload = () => {
      const url = reader.result as string;

      const image = new Image();

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d')!;

        // image width and height is already adjusted for orientation
        let { width, height } = image;

        const scale = width > maxWidth ? maxWidth / width : 1;

        // Scaling image width
        width = width * scale;
        height = height * scale;

        // set proper canvas dimensions and context position, rotation
        canvas.width = width;
        canvas.height = height;

        context.setTransform(1, 0, 0, 1, 0, 0);

        // draw image
        context.drawImage(image, 0, 0, width, height);

        // export base64
        resolve(canvas.toDataURL('image/jpeg'));
      };

      image.src = url;
    };

    reader.readAsDataURL(file);
  });

export const getImageUrl = (
  file: File,
  maxWidth?: number | undefined
): Promise<string> => {
  return new Promise((resolve) => {
    getOrientation(file).then((orientation) => {
      applyRotation(file, orientation || 1, maxWidth || 999999).then(
        (dataURL) => resolve(dataURL)
      );
    });
  });
};
