import { useState } from 'react';

import Grid from 'components/Grid';

import { ICareTeamMember } from 'entities/CareTeam/sdk';
import {
  PROFILE_ROLES_MAPPER,
  PROFILE_ROLES
} from 'entities/Profile/constants';

import { EditOutlinedIcon } from 'icons';

import MemberRoleUpdateDialog from './components/MemberRoleUpdateDialog';
import { useSelectedCareTeam } from 'entities/CareTeam/sdk';

interface IMemberRole {
  mutateCareTeamMembers: () => void;
  member: ICareTeamMember;
}

const MemberRole: React.FC<IMemberRole> = ({
  member,
  mutateCareTeamMembers
}) => {
  const [isMemberRoleUpdateDialogOpened, setIsMemberRoleUpdateDialogOpened] =
    useState<boolean>(false);

  const { userIsPatient, userIsFamilyMember } = useSelectedCareTeam();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          {PROFILE_ROLES_MAPPER[member.care_team_role || PROFILE_ROLES.AIDE]}
        </Grid>
        {(userIsFamilyMember || userIsPatient) && (
          <Grid item sx={{ cursor: 'pointer' }}>
            <EditOutlinedIcon
              fontSize="small"
              onClick={() => setIsMemberRoleUpdateDialogOpened(true)}
            />
          </Grid>
        )}
      </Grid>
      {isMemberRoleUpdateDialogOpened && (
        <MemberRoleUpdateDialog
          onClose={() => setIsMemberRoleUpdateDialogOpened(false)}
          mutateCareTeamMembers={mutateCareTeamMembers}
          member={member}
        />
      )}
    </>
  );
};

export default MemberRole;
