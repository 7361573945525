import { IDialog } from 'components/Dialog';

import { ITaskValues } from 'entities/Schedule/sdk';
import { IScheduleTask } from 'entities/CarePlan/sdk';
import TaskFormDialog from 'entities/Schedule/components/TaskFormDialog';

export interface ITaskUpdateDialog extends IDialog {
  onTaskEdit: ({ task }: { task: ITaskValues }) => Promise<void>;
  task: IScheduleTask;
}

const TaskUpdateDialog: React.FC<ITaskUpdateDialog> = ({
  onTaskEdit,
  task,
  ...props
}) => {
  const initalValues = {
    name: task.name,
    description: task.description,
    location: task.location,
    start: task.start,
    end: task.end
  };

  return (
    <TaskFormDialog
      {...props}
      onTaskSubmit={onTaskEdit}
      title="Edit appointment"
      initialValues={initalValues}
    />
  );
};

export default TaskUpdateDialog;
