import { BASE_API_URL } from 'config/urls';
import { useFetch, post } from 'utils/sdk';

import { deepTransformUTCToLocal } from 'utils/datetime';

export interface IUserDocument {
  id: string;
  document: string;
  file_name: string;
  added_by: string;
  added_by_full_name: string;
  created_at: string;
  number_of_permissioned_users: number;
  user_permission: string;
  care_team_permissions: IUserDocumentPermissions[];
  tags: IUserDocumentTag[];
}

export interface IUserDocumentEditableValues {
  file_name: string;
  tags: IUserDocumentTag[];
}

export interface IUserDocumentTag {
  tag_id: string;
  tag_name: string;
  tag_color: string;
}

export interface IUserDocumentPermissions {
  profile_id: string;
  first_name: string;
  last_name: string;
  full_name: string;
  avatar: string;
  user_id: string;
  permission_level: string;
  care_team_role: string;
}

export interface IUserDocumentPermissionValues {
  id: string;
  document_id: string;
  avatar: string;
  care_team_user_id: string;
  care_team_user_role: string;
  permission_level: string;
}

export interface IUserDocumentPermissionLevels {
  value: string;
  label: string;
}

export const useDocumentsList = ({
  patientId,
  searchTerm
}: {
  patientId: string | undefined;
  searchTerm?: string | undefined;
}) => {
  const apiSearchTerm = searchTerm?.replace(' ', '+');

  const result = useFetch<IUserDocument[]>(
    patientId && searchTerm
      ? `${BASE_API_URL}/records/${patientId}/documents/retrieve/?q=${apiSearchTerm}`
      : patientId && !searchTerm
      ? `${BASE_API_URL}/records/${patientId}/documents/retrieve/`
      : null
  );

  return {
    ...result,
    data: deepTransformUTCToLocal({ data: result.data })
  };
};

export const useDocument = (url: string) => useFetch(url);

export const documentDelete = ({
  patientId,
  documentId
}: {
  patientId: string;
  documentId: string;
}) =>
  post(`${BASE_API_URL}/records/${patientId}/document/${documentId}/delete/`);

export const documentUpdate = ({
  patientId,
  documentId,
  documentData
}: {
  patientId: string;
  documentId: string;
  documentData: IUserDocumentEditableValues;
}) => {
  const fileNameWithExtension =
    documentData.file_name.search('.pdf') > 0
      ? documentData.file_name
      : documentData.file_name.concat('.pdf');

  const postData = {
    ...documentData,
    file_name: fileNameWithExtension
  };

  return post(
    `${BASE_API_URL}/records/${patientId}/document/${documentId}/update/`,
    postData
  );
};

export const useDocumentMostCommonTags = ({
  patientId
}: {
  patientId: string | undefined;
}) => {
  const result = useFetch<IUserDocumentTag[]>(
    patientId
      ? `${BASE_API_URL}/records/${patientId}/documents/tags/retrieve/`
      : null
  );

  return result;
};

export const useDocumentPermissionList = ({
  documentId,
  patientId
}: {
  documentId: string;
  patientId: string | undefined;
}) => {
  const result = useFetch<IUserDocumentPermissions[]>(
    patientId
      ? `${BASE_API_URL}/records/${patientId}/document/${documentId}/permissions/retrieve/`
      : null
  );

  return result;
};

export const documentPermissionsUpdate = ({
  documentId,
  patientId,
  documentPermissionsData
}: {
  documentId: string;
  patientId: string | undefined;
  documentPermissionsData: IUserDocumentPermissions[];
}) => {
  const user_permission_data = documentPermissionsData.map((item) => {
    return {
      care_team_user: item.user_id,
      permission_level: item.permission_level
    };
  });
  return post(
    `${BASE_API_URL}/records/${patientId}/document/${documentId}/permissions/update/`,
    user_permission_data
  );
};

export const useDocumentPermissionLevelsList = () =>
  useFetch<Array<IUserDocumentPermissionLevels>>(
    `${BASE_API_URL}/records/permissionlevels/list/`
  );
