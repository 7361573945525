import { useRedirectToOnboarding } from 'utils/hooks';

import Grid from 'components/Grid';
import Typography from 'components/Typography';

interface IMessages {}

const Messages: React.FC<IMessages> = () => {
  useRedirectToOnboarding();

  return (
    <Grid container alignItems="center" justifyContent="center" paddingY={4}>
      <Grid item>
        <Typography variant="h3">Coming soon</Typography>
      </Grid>
    </Grid>
  );
};

export default Messages;
