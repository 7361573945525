import { useMemo } from 'react';
import _ from 'lodash';
import { FormikProps } from 'formik';

import TextField, { IOutlinedTextField } from 'components/TextField';

import { datetime } from 'utils/datetime';
import {
  BACKEND_DATETIME_FORMAT,
  HTML_INPUT_LOCAL_DATETIME_FORMAT
} from 'constants/time';

interface IDateTimeField extends Omit<IOutlinedTextField, 'type'> {
  name: string;
  label?: string;
  formik: FormikProps<any>;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  [key: string]: any;
}

/*
IMPORTANT Notes for this component!

- DateTimeField accepts a string value in this BACKEND_DATETIME_FORMAT format.
- TextField.Outlined component works only with strings in this format HTML_INPUT_LOCAL_DATETIME_FORMAT format !== BACKEND_DATETIME_FORMAT.

That's why in this component, we convert the value parameter from BACKEND_DATETIME_FORMAT format to HTML_INPUT_LOCAL_DATETIME_FORMAT.
Also, we've got a custom handleChange handler that transforms the newly
selected value from HTML_INPUT_LOCAL_DATETIME_FORMAT to BACKEND_DATETIME_FORMAT format
*/

const DateTimeField = ({
  name,
  label,
  formik,
  onChange,
  ...props
}: IDateTimeField) => {
  const value = _.get(formik, `values.${name}`);
  const touched = _.get(formik, `touched.${name}`);
  const error = _.get(formik, `errors.${name}`);

  const valueFormatted = useMemo(
    () =>
      value
        ? datetime(value, BACKEND_DATETIME_FORMAT).format(
            HTML_INPUT_LOCAL_DATETIME_FORMAT
          )
        : null,
    [value]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    let formattedDateTime: string | null = null;

    if (!_.isNil(value)) {
      formattedDateTime = datetime(
        value,
        HTML_INPUT_LOCAL_DATETIME_FORMAT
      ).format(BACKEND_DATETIME_FORMAT);
    }

    formik.setFieldTouched(name, true);
    formik.setFieldValue(name, formattedDateTime);

    onChange && onChange(event);
  };

  return (
    <TextField.Outlined
      fullWidth
      label={label}
      type="datetime-local"
      name={name}
      value={valueFormatted}
      onChange={handleChange}
      error={touched && !_.isEmpty(error)}
      helperText={touched ? error : ''}
      InputLabelProps={{
        shrink: true
      }}
      {...props}
    />
  );
};

export default DateTimeField;
