import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  BACKEND_DATETIME_FORMAT,
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT
} from 'constants/time';

dayjs.extend(advancedFormat);

dayjs.extend(utc);

dayjs.extend(timezone);

dayjs.extend(customParseFormat);

export type IDatetime = dayjs.Dayjs;

export const AdapterDatetime = AdapterDayjs;

export const datetime = dayjs;

export const addMinutesToTime = ({
  time,
  minutesToAdd
}: {
  time: string;
  minutesToAdd: number;
}) => datetime(time, 'HH:mm').add(minutesToAdd, 'minute').format('HH:mm');

export const utcToLocal = (dt: IDatetime) => {
  if (dt.isUTC()) {
    return dt.local();
  }
  return dt;
};

export const localToUtc = (dt: IDatetime) => {
  if (!dt.isUTC()) {
    return dt.utc();
  }
  return dt;
};

export const setLocalTimezone = (timezoneCode: string) => {
  dayjs.tz.setDefault(timezoneCode);
};

export const isDatetime = ({
  object,
  format = BACKEND_DATETIME_FORMAT
}: {
  object: any;
  format?: string;
}) => datetime(object, format, true).isValid();

export const deepTransformDates = ({
  data,
  transformFunc,
  format = BACKEND_DATETIME_FORMAT
}: {
  data: any;
  transformFunc: (x: string) => any;
  format?: string;
}): any => {
  if (_.isArray(data)) {
    return data.map((el) =>
      deepTransformDates({ data: el, format, transformFunc })
    );
  }

  if (_.isObject(data)) {
    return _.mapValues(data, (el) =>
      deepTransformDates({ data: el, format, transformFunc })
    );
  }

  if (isDatetime({ object: data })) {
    return transformFunc(data);
  }

  return data;
};

export const deepTransformUTCToLocal = ({
  data,
  format = BACKEND_DATETIME_FORMAT
}: {
  data: any;
  format?: string;
}) => {
  // transform to Local
  const transformFunc = (dateString: string) =>
    utcToLocal(datetime.utc(dateString, format)).format(format);

  return deepTransformDates({
    data,
    format,
    transformFunc
  });
};

export const deepTransformLocalToUTC = ({
  data,
  format = BACKEND_DATETIME_FORMAT
}: {
  data: any;
  format?: string;
}) => {
  // transform to UTC
  const transformFunc = (dateString: string) =>
    localToUtc(datetime(dateString, format)).format(format);

  return deepTransformDates({
    data,
    format,
    transformFunc
  });
};

export const getTodayDate = () => datetime().format(BACKEND_DATE_FORMAT);

export const getPreviousDate = ({ date }: { date: any }) =>
  datetime(date, BACKEND_DATE_FORMAT)
    .subtract(1, 'd')
    .format(BACKEND_DATE_FORMAT);

export const getNextDate = ({ date }: { date: any }) =>
  datetime(date, BACKEND_DATE_FORMAT).add(1, 'd').format(BACKEND_DATE_FORMAT);

export const getNow = () => datetime().format(BACKEND_DATETIME_FORMAT);

export const getDatetimeObjectFromDate = ({ date }: { date: string }) => {
  // Attaching current hour and minutes because otherwise datetime will be with 00:00:00 time.
  return datetime(date, BACKEND_DATE_FORMAT)
    .hour(datetime().hour())
    .minute(datetime().minute());
};

// time format here is "HH:mm:ss"
export const getUtcTimeFromLocalTime = ({ time }: { time: string }) => {
  // We are interested in time, so current date works for us.
  const dateTimeString = `${getTodayDate()} ${time}`;

  // Create local datetime object
  const dateTimeObject = datetime(dateTimeString);

  return localToUtc(dateTimeObject).format(BACKEND_TIME_FORMAT);
};
