import _ from 'lodash';
import { colors } from 'theme/palette';
import { makeStyles } from '@mui/styles';
import { useCallback, useState } from 'react';
import { useFormik, FormikHelpers } from 'formik';

import Grid from 'components/Grid';
import Stack from 'components/Stack';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import TextField from 'components/TextField';
import DateTimeField from 'components/DateTimeField';
import Link from 'components/Link';

import {
  TemperatureIcon,
  BloodPressureIcon,
  PulseIcon,
  BloodOxygenIcon,
  BloodSugarIcon
} from 'icons';

import Dialog, { IDialog } from 'components/Dialog';

import { GENERIC_FORM_ERRORS_KEY, IFormError } from 'utils/sdk';

import { IVitalNoteFormValues } from 'entities/Diary/sdk';

import { IVitalsToTrack, ICustomVitals } from 'entities/Patient/sdk';

import { VALIDATION_SCHEMA } from './constants';

import { CUSTOM_VITALS_URL, HEALTH_DIARY_URL } from 'config/urls';
import { REDIRECT_URL_PARAM_KEY } from 'constants/common';

const useStyles = makeStyles((theme) => ({
  vitalsRow: {
    display: 'flex',
    alignItems: 'center',
    gridGap: theme.spacing(1),
    justifyContent: 'space-between'
  },
  highlightedInput: {
    color: colors.purpleMain,
    backgroundColor: colors.purpleLight,
    fontWeight: 'bold'
  },
  vitalIcons: {
    height: '50px',
    width: '50px'
  },
  vitalTextField: {
    maxWidth: '100%',
    flexGrow: 1,
    size: 'small'
  },
  vitalLabelTypography: {
    fontSize: '16px',
    fontWeight: 300
  },
  vitalLabelItem: {
    flexBasis: '10%',
    flexGrow: 1,
    marginRight: 2
  },
  vitalInputAdornment: {
    fontSize: '14px',
    fontweight: 300,
    color: 'grey'
  }
}));

export interface IVitalNoteFormDialog extends IDialog {
  onVitalNoteSubmit: ({
    vitalNote
  }: {
    vitalNote: IVitalNoteFormValues;
  }) => Promise<void>;
  initialValues: IVitalNoteFormValues;
  title: string;
  vitalsToTrack: IVitalsToTrack;
  customVitals: ICustomVitals;
  vitalsOutOfRange: Array<string>;
}

const VitalNoteFormDialog = ({
  onClose,
  onVitalNoteSubmit,
  initialValues,
  title,
  vitalsToTrack,
  customVitals,
  vitalsOutOfRange
}: IVitalNoteFormDialog) => {
  const [vitalsOutOfNormalRange, setVitalsOutOfNormalRange] =
    useState<Array<string>>(vitalsOutOfRange);

  const classes = useStyles();

  const handleSubmit = useCallback(
    (
      values: IVitalNoteFormValues,
      formikHelpers: FormikHelpers<IVitalNoteFormValues>
    ) =>
      onVitalNoteSubmit({
        vitalNote: values
      }).catch(formikHelpers.setErrors),
    [onVitalNoteSubmit]
  );

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA,
    enableReinitialize: true
  });

  const formErrors: Array<IFormError> = _.get(
    formik.errors,
    GENERIC_FORM_ERRORS_KEY,
    []
  );

  const onTemperatureBlur = () => {
    const value = formik.values.temperature;

    if (value && Number(value) > Number(customVitals.temperature_max)) {
      const vitals = _.uniq([...vitalsOutOfNormalRange, 'temperature']);
      return setVitalsOutOfNormalRange(vitals);
    }

    const vitals = vitalsOutOfNormalRange.filter(
      (item) => item !== 'temperature'
    );
    return setVitalsOutOfNormalRange(vitals);
  };

  const onBloodPressureSystolicBlur = () => {
    const value = formik.values.blood_pressure_systolic;

    if (
      value &&
      Number(value) < Number(customVitals.blood_pressure_systolic_min)
    ) {
      const vitals = _.uniq([
        ...vitalsOutOfNormalRange,
        'blood_pressure_systolic'
      ]);

      return setVitalsOutOfNormalRange(vitals);
    }

    if (
      value &&
      Number(value) > Number(customVitals.blood_pressure_systolic_max)
    ) {
      const vitals = _.uniq([
        ...vitalsOutOfNormalRange,
        'blood_pressure_systolic'
      ]);

      return setVitalsOutOfNormalRange(vitals);
    }

    const vitals = vitalsOutOfNormalRange.filter(
      (item) => item !== 'blood_pressure_systolic'
    );
    return setVitalsOutOfNormalRange(vitals);
  };

  const onBloodPressureDiastolicBlur = () => {
    const value = formik.values.blood_pressure_diastolic;

    if (
      value &&
      Number(value) < Number(customVitals.blood_pressure_diastolic_min)
    ) {
      const vitals = _.uniq([
        ...vitalsOutOfNormalRange,
        'blood_pressure_diastolic'
      ]);

      return setVitalsOutOfNormalRange(vitals);
    }

    if (
      value &&
      Number(value) > Number(customVitals.blood_pressure_diastolic_max)
    ) {
      const vitals = _.uniq([
        ...vitalsOutOfNormalRange,
        'blood_pressure_diastolic'
      ]);

      return setVitalsOutOfNormalRange(vitals);
    }

    const vitals = vitalsOutOfNormalRange.filter(
      (item) => item !== 'blood_pressure_diastolic'
    );

    return setVitalsOutOfNormalRange(vitals);
  };

  const onPulseBlur = () => {
    const value = formik.values.pulse;

    if (value && Number(value) < Number(customVitals.pulse_min)) {
      const vitals = _.uniq([...vitalsOutOfNormalRange, 'pulse']);

      return setVitalsOutOfNormalRange(vitals);
    }

    if (value && Number(value) > Number(customVitals.pulse_max)) {
      const vitals = _.uniq([...vitalsOutOfNormalRange, 'pulse']);

      return setVitalsOutOfNormalRange(vitals);
    }

    const vitals = vitalsOutOfNormalRange.filter((item) => item !== 'pulse');

    return setVitalsOutOfNormalRange(vitals);
  };

  const onBloodOxygenBlur = () => {
    const value = formik.values.blood_oxygen;

    if (value && Number(value) < Number(customVitals.blood_oxygen_min)) {
      const vitals = _.uniq([...vitalsOutOfNormalRange, 'blood_oxygen']);
      return setVitalsOutOfNormalRange(vitals);
    }

    const vitals = vitalsOutOfNormalRange.filter(
      (item) => item !== 'blood_oxygen'
    );
    return setVitalsOutOfNormalRange(vitals);
  };

  const onBloodSugarFastingBlur = () => {
    const value = formik.values.blood_sugar_fasting;

    if (value && Number(value) < Number(customVitals.blood_sugar_fasting_min)) {
      const vitals = _.uniq([...vitalsOutOfNormalRange, 'blood_sugar_fasting']);

      return setVitalsOutOfNormalRange(vitals);
    }

    if (value && Number(value) > Number(customVitals.blood_sugar_fasting_max)) {
      const vitals = _.uniq([...vitalsOutOfNormalRange, 'blood_sugar_fasting']);

      return setVitalsOutOfNormalRange(vitals);
    }

    const vitals = vitalsOutOfNormalRange.filter(
      (item) => item !== 'blood_sugar_fasting'
    );

    return setVitalsOutOfNormalRange(vitals);
  };

  const onBloodSugarNonFastingBlur = () => {
    const value = formik.values.blood_sugar_non_fasting;

    if (
      value &&
      Number(value) < Number(customVitals.blood_sugar_non_fasting_min)
    ) {
      const vitals = _.uniq([
        ...vitalsOutOfNormalRange,
        'blood_sugar_non_fasting'
      ]);

      return setVitalsOutOfNormalRange(vitals);
    }

    if (
      value &&
      Number(value) > Number(customVitals.blood_sugar_non_fasting_max)
    ) {
      const vitals = _.uniq([
        ...vitalsOutOfNormalRange,
        'blood_sugar_non_fasting'
      ]);

      return setVitalsOutOfNormalRange(vitals);
    }

    const vitals = vitalsOutOfNormalRange.filter(
      (item) => item !== 'blood_sugar_non_fasting'
    );

    return setVitalsOutOfNormalRange(vitals);
  };

  return (
    <Dialog open onClose={onClose} title={title}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2} padding={2}>
          {formErrors.map((error, index) => (
            <Alert key={index} severity="error">
              {error.message}
            </Alert>
          ))}

          {!_.isEmpty(vitalsOutOfNormalRange) && (
            <Alert
              sx={{ color: 'white', backgroundColor: 'black' }}
              severity="warning"
            >
              Highlighted reading is outside of the normal range
            </Alert>
          )}

          <Typography variant="subtitle2" paddingBottom={2}>
            Enter only those vitals you wish to track. You can customize this
            list from{' '}
            <Link
              to={`${CUSTOM_VITALS_URL}?${REDIRECT_URL_PARAM_KEY}=${HEALTH_DIARY_URL}`}
            >
              here
            </Link>
            .
          </Typography>

          <Grid
            container
            gap={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs className={classes.vitalLabelItem}>
              <Typography variant="h5">Measured on*</Typography>
            </Grid>
            <Grid item xs>
              <DateTimeField
                size="small"
                name="measured_at"
                formik={formik}
                className={classes.vitalTextField}
              />
            </Grid>
          </Grid>

          <Divider />

          {vitalsToTrack.temperature && (
            <>
              <Grid container className={classes.vitalsRow}>
                <Grid item>
                  <TemperatureIcon className={classes.vitalIcons} />
                </Grid>
                <Grid item xs className={classes.vitalLabelItem}>
                  <Typography variant="h5">Temperature</Typography>
                </Grid>

                <Grid item xs>
                  <TextField.Outlined
                    className={classes.vitalTextField}
                    error={
                      formik.touched.temperature &&
                      !_.isNil(formik.errors.temperature)
                    }
                    helperText={
                      formik.touched.temperature
                        ? formik.errors.temperature
                        : ''
                    }
                    name="temperature"
                    value={formik.values.temperature}
                    type="number"
                    size="small"
                    inputProps={{
                      step: '.1'
                    }}
                    InputProps={{
                      className: _.includes(
                        vitalsOutOfNormalRange,
                        'temperature'
                      )
                        ? classes.highlightedInput
                        : undefined
                    }}
                    onChange={formik.handleChange}
                    onBlur={onTemperatureBlur}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography className={classes.vitalInputAdornment}>
                    °{customVitals.temperature_unit}
                  </Typography>
                </Grid>
              </Grid>

              <Divider />
            </>
          )}

          {vitalsToTrack.blood_pressure && (
            <>
              <Grid container className={classes.vitalsRow} alignItems="center">
                <Grid item>
                  <BloodPressureIcon className={classes.vitalIcons} />
                </Grid>
                <Grid item xs className={classes.vitalLabelItem}>
                  <Typography variant="h5">Blood pressure</Typography>
                </Grid>

                <Grid item xs>
                  <TextField.Outlined
                    className={classes.vitalTextField}
                    error={
                      formik.touched.blood_pressure_systolic &&
                      !_.isNil(formik.errors.blood_pressure_systolic)
                    }
                    helperText={
                      formik.touched.blood_pressure_systolic
                        ? formik.errors.blood_pressure_systolic
                        : ''
                    }
                    name="blood_pressure_systolic"
                    value={formik.values.blood_pressure_systolic}
                    type="number"
                    size="small"
                    InputProps={{
                      className: _.includes(
                        vitalsOutOfNormalRange,
                        'blood_pressure_systolic'
                      )
                        ? classes.highlightedInput
                        : undefined
                    }}
                    onChange={formik.handleChange}
                    onBlur={onBloodPressureSystolicBlur}
                  />
                </Grid>

                <Grid item>
                  <div>/</div>
                </Grid>

                <Grid item xs>
                  <TextField.Outlined
                    className={classes.vitalTextField}
                    error={
                      formik.touched.blood_pressure_diastolic &&
                      !_.isNil(formik.errors.blood_pressure_diastolic)
                    }
                    helperText={
                      formik.touched.blood_pressure_diastolic
                        ? formik.errors.blood_pressure_diastolic
                        : ''
                    }
                    name="blood_pressure_diastolic"
                    value={formik.values.blood_pressure_diastolic}
                    type="number"
                    size="small"
                    InputProps={{
                      className: _.includes(
                        vitalsOutOfNormalRange,
                        'blood_pressure_diastolic'
                      )
                        ? classes.highlightedInput
                        : undefined
                    }}
                    onChange={formik.handleChange}
                    onBlur={onBloodPressureDiastolicBlur}
                  />
                </Grid>
                <Grid item>
                  <Typography className={classes.vitalInputAdornment}>
                    mmHg
                  </Typography>
                </Grid>
              </Grid>

              <Divider />
            </>
          )}

          {vitalsToTrack.pulse && (
            <>
              <Grid container className={classes.vitalsRow} alignItems="center">
                <Grid item>
                  <PulseIcon className={classes.vitalIcons} />
                </Grid>
                <Grid item xs className={classes.vitalLabelItem}>
                  <Typography variant="h5">Pulse</Typography>
                </Grid>

                <Grid item xs>
                  <TextField.Outlined
                    className={classes.vitalTextField}
                    error={
                      formik.touched.pulse && !_.isNil(formik.errors.pulse)
                    }
                    helperText={formik.touched.pulse ? formik.errors.pulse : ''}
                    name="pulse"
                    value={formik.values.pulse}
                    type="number"
                    size="small"
                    InputProps={{
                      className: _.includes(vitalsOutOfNormalRange, 'pulse')
                        ? classes.highlightedInput
                        : undefined
                    }}
                    onChange={formik.handleChange}
                    onBlur={onPulseBlur}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography className={classes.vitalInputAdornment}>
                    bpm
                  </Typography>
                </Grid>
              </Grid>

              <Divider />
            </>
          )}

          {vitalsToTrack.blood_oxygen && (
            <>
              <Grid container className={classes.vitalsRow} alignItems="center">
                <Grid item>
                  <BloodOxygenIcon className={classes.vitalIcons} />
                </Grid>
                <Grid item xs className={classes.vitalLabelItem}>
                  <Typography variant="h5">Blood oxygen</Typography>
                </Grid>

                <Grid item xs>
                  <TextField.Outlined
                    className={classes.vitalTextField}
                    error={
                      formik.touched.blood_oxygen &&
                      !_.isNil(formik.errors.blood_oxygen)
                    }
                    helperText={
                      formik.touched.blood_oxygen
                        ? formik.errors.blood_oxygen
                        : ''
                    }
                    name="blood_oxygen"
                    value={formik.values.blood_oxygen}
                    type="number"
                    size="small"
                    inputProps={{
                      step: '.1'
                    }}
                    InputProps={{
                      className: _.includes(
                        vitalsOutOfNormalRange,
                        'blood_oxygen'
                      )
                        ? classes.highlightedInput
                        : undefined
                    }}
                    onChange={formik.handleChange}
                    onBlur={onBloodOxygenBlur}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography className={classes.vitalInputAdornment}>
                    %
                  </Typography>
                </Grid>
              </Grid>

              <Divider />
            </>
          )}

          {vitalsToTrack.blood_sugar && (
            <>
              <Grid
                container
                className={classes.vitalsRow}
                height="100%"
                //seomthing
              >
                <Grid item>
                  <BloodSugarIcon className={classes.vitalIcons} />
                </Grid>
                <Grid item xs>
                  <Typography variant="h5">Blood sugar</Typography>
                </Grid>

                <Grid container xs direction="column" flexGrow={1}>
                  <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    marginBottom={2}
                  >
                    <Grid item xs>
                      <TextField.Outlined
                        className={classes.vitalTextField}
                        error={
                          formik.touched.blood_sugar_fasting &&
                          !_.isNil(formik.errors.blood_sugar_fasting)
                        }
                        helperText={
                          formik.touched.blood_sugar_fasting
                            ? formik.errors.blood_sugar_fasting
                            : ''
                        }
                        label="Fasting"
                        name="blood_sugar_fasting"
                        value={formik.values.blood_sugar_fasting}
                        type="number"
                        size="small"
                        InputProps={{
                          className: _.includes(
                            vitalsOutOfNormalRange,
                            'blood_sugar_fasting'
                          )
                            ? classes.highlightedInput
                            : undefined
                        }}
                        onChange={formik.handleChange}
                        onBlur={onBloodSugarFastingBlur}
                      />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.vitalInputAdornment}>
                        mg/dL
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    marginTop={1}
                    marginBottom={1}
                  >
                    <Grid item xs>
                      <TextField.Outlined
                        className={classes.vitalTextField}
                        error={
                          formik.touched.blood_sugar_non_fasting &&
                          !_.isNil(formik.errors.blood_sugar_non_fasting)
                        }
                        helperText={
                          formik.touched.blood_sugar_non_fasting
                            ? formik.errors.blood_sugar_non_fasting
                            : ''
                        }
                        label="Non-fasting"
                        name="blood_sugar_non_fasting"
                        value={formik.values.blood_sugar_non_fasting}
                        type="number"
                        size="small"
                        InputProps={{
                          className: _.includes(
                            vitalsOutOfNormalRange,
                            'blood_sugar_non_fasting'
                          )
                            ? classes.highlightedInput
                            : undefined
                        }}
                        onChange={formik.handleChange}
                        onBlur={onBloodSugarNonFastingBlur}
                      />
                    </Grid>
                    <Grid item alignItems="right">
                      <Typography className={classes.vitalInputAdornment}>
                        mg/dL
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          <Button
            type="submit"
            disabled={formik.isSubmitting}
            data-gtm="button-submit-vital-note-create"
          >
            Save
          </Button>
        </Stack>
      </form>
    </Dialog>
  );
};

export default VitalNoteFormDialog;
