import * as yup from 'yup';

import {
  PROFILE_ROLES,
  PROFILE_ROLES_MAPPER
} from 'entities/Profile/constants';

export const VALIDATION_SCHEMA = yup.object().shape({
  role: yup.string().required('Role is required.')
});

export const MEMBER_ROLE_CHOICES = [
  {
    label: PROFILE_ROLES_MAPPER[PROFILE_ROLES.AIDE],
    value: PROFILE_ROLES.AIDE
  },
  {
    label: PROFILE_ROLES_MAPPER[PROFILE_ROLES.FAMILY_MEMBER],
    value: PROFILE_ROLES.FAMILY_MEMBER
  }
];
