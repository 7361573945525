import { LOGIN_URL } from 'config/urls';

import Box from 'components/Box';
import Link from 'components/Link';
import Stack from 'components/Stack';
import Typography from 'components/Typography';
import { useTheme } from '@mui/material/styles';

const PasswordResetPending: React.FC<{}> = () => {
  const theme = useTheme();
  return (
    <Box sx={{ height: '100%', bgcolor: theme.palette.common.white }}>
      <Stack
        height="100%"
        justifyContent="center"
        alignItems="center"
        gap={2}
        paddingX={1}
      >
        <Typography variant="h2" textAlign="center">
          We've emailed you instructions to reset your password.
        </Typography>

        <Link
          to={LOGIN_URL}
          variant="h6"
          underline="none"
          data-gtm="link-login"
        >
          Back to Login
        </Link>
      </Stack>
    </Box>
  );
};

export default PasswordResetPending;
