import _ from 'lodash';
import { getItem, setItem } from 'config/storage';
import { PaletteMode } from '@mui/material';

const THEME_COLOR_MODE_STORAGE_KEY = 'themeColorMode';

export const getCurrentColorMode = (): PaletteMode => {
  const colorMode = getItem(THEME_COLOR_MODE_STORAGE_KEY) as PaletteMode;
  if (_.isNil(colorMode)) {
    return 'light';
  } else return colorMode;
};

export const setCurrentColorMode = (colorMode: PaletteMode) => {
  setItem({
    key: THEME_COLOR_MODE_STORAGE_KEY,
    value: colorMode
  });
};
