export const VITALS: { [key: string]: string } = {
  TEMPERATURE: 'temperature',
  BLOOD_PRESSURE: 'blood_pressure',
  PULSE: 'pulse',
  BLOOD_OXYGEN: 'blood_oxygen',
  BLOOD_SUGAR: 'blood_sugar'
};

export const RESOLUTION = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly'
};

export const MEASUREMENT_TO_UNIT_MAPPING = ({
  vital,
  temperatureMeasuringUnit
}: {
  vital: string;
  temperatureMeasuringUnit?: string;
}) => {
  const UNITS = {
    [VITALS.TEMPERATURE]: ` °${temperatureMeasuringUnit}`,
    [VITALS.BLOOD_PRESSURE]: ' mmHg',
    [VITALS.PULSE]: ' bpm',
    [VITALS.BLOOD_OXYGEN]: '%',
    [VITALS.BLOOD_SUGAR]: ' mg/dL'
  };

  return UNITS[vital];
};
