import { useState, useEffect, useMemo, SyntheticEvent } from 'react';
import _ from 'lodash';
import { useTheme } from '@mui/material/styles';
import { useRouteMatch, useHistory, matchPath } from 'react-router-dom';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MuiBottomNavigation, {
  BottomNavigationProps
} from '@mui/material/BottomNavigation';
import MuiBottomNavigationAction from '@mui/material/BottomNavigationAction';

import {
  ONBOARDING_URL,
  CARE_TEAM_URL,
  SCHEDULE_URL,
  HOME_URL,
  MEDICATION_CREATE_URL,
  MEDICATION_CREATE_WITH_FREQUENCY_URL,
  MEDICAL_HISTORY_URL,
  CARE_TEAM_MEMBER_INVITATION_URL,
  MEDICATIONS_URL,
  MEDICATION_UPDATE_URL,
  PATIENT_PROFILE_BASE_URL,
  PATIENT_PROFILE_UPDATE_URL,
  PATIENT_PROFILE_URL,
  CUSTOM_VITALS_URL,
  CUSTOM_VITALS_UPDATE_URL,
  PROFILE_URL,
  PROFILE_UPDATE_URL,
  HEALTH_DIARY_URL,
  HEALTH_TRENDS_URL,
  SETTINGS_URL,
  MY_CARE_TEAMS_URL,
  DOCUMENTS_URL
} from 'config/urls';

import { GroupsIcon, HomeIcon, CalendarTodayIcon, SettingsIcon } from 'icons';

import { colors } from 'theme/palette';

const useStyles = makeStyles((theme) => ({
  actionRoot: {
    minWidth: 'auto',
    padding: 0,
    color: colors.darkestGrey,
    '& svg': {
      fill: colors.darkestGrey
    }
  },
  mobileRoot: {
    paddingBottom: 16
  },
  actionRootHorizontal: {
    flexDirection: 'row'
  },
  actionRootWhite: {
    color: colors.white,
    '& svg': {
      fill: colors.white
    }
  },
  selected: {
    borderTop: `3px solid ${theme.palette.secondary.main}`,
    '& svg': {
      fill:
        theme.palette.mode === 'light'
          ? theme.palette.primary.main
          : theme.palette.primary.contrastText
    },
    '& .MuiBottomNavigationAction-label': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.primary.main
          : theme.palette.primary.contrastText
    }
  },
  selectedBottomUnderline: {
    borderTop: `none`,
    borderBottom: `3px solid ${theme.palette.secondary.main}`
  },
  label: {
    color: 'inherit',
    borderTop: 0,
    borderBottom: 0
  },
  labelHorizontal: {
    paddingLeft: 10
  }
}));

export interface IBottomNavigation extends BottomNavigationProps {
  variant?: 'horizontal' | 'vertical';
  underline?: 'top' | 'bottom';
  white?: boolean;
}

const NAVIGATION_TABS = {
  HOME: 'Home',
  CARE_TEAM: 'Care Team',
  MESSAGES: 'Messages',
  SCHEDULE: 'Schedule',
  SETTINGS: 'Settings'
};

const menuItems = [
  {
    icon: <HomeIcon />,
    value: NAVIGATION_TABS.HOME
  },
  {
    icon: <CalendarTodayIcon />,
    value: NAVIGATION_TABS.SCHEDULE
  },
  {
    icon: <GroupsIcon />,
    value: NAVIGATION_TABS.CARE_TEAM
  },
  {
    icon: <SettingsIcon />,
    value: NAVIGATION_TABS.SETTINGS
  }
];

// Used for redirecting
const TAB_DATA = {
  [NAVIGATION_TABS.HOME]: {
    path: ONBOARDING_URL,
    activePaths: [
      ONBOARDING_URL,
      HOME_URL,
      MEDICATIONS_URL,
      MEDICATION_CREATE_URL,
      MEDICATION_CREATE_WITH_FREQUENCY_URL,
      MEDICATION_UPDATE_URL,
      HEALTH_DIARY_URL,
      HEALTH_TRENDS_URL,
      DOCUMENTS_URL
    ]
  },
  [NAVIGATION_TABS.SCHEDULE]: {
    path: SCHEDULE_URL,
    activePaths: [SCHEDULE_URL]
  },
  [NAVIGATION_TABS.CARE_TEAM]: {
    path: CARE_TEAM_URL,
    activePaths: [
      CARE_TEAM_URL,
      MY_CARE_TEAMS_URL,
      CARE_TEAM_MEMBER_INVITATION_URL,
      PATIENT_PROFILE_BASE_URL,
      PATIENT_PROFILE_UPDATE_URL,
      PATIENT_PROFILE_URL,
      MEDICAL_HISTORY_URL,
      CUSTOM_VITALS_URL,
      CUSTOM_VITALS_UPDATE_URL
    ]
  },
  [NAVIGATION_TABS.SETTINGS]: {
    path: SETTINGS_URL,
    activePaths: [SETTINGS_URL, PROFILE_URL, PROFILE_UPDATE_URL]
  }
};

const getSelectedTab = (url: string) =>
  _.findKey(TAB_DATA, (v) => matchPath(url, v.activePaths));

const BottomNavigation: React.FC<IBottomNavigation> = ({
  variant = 'vertical',
  underline = 'top',
  white = false
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [tab, setTab] = useState(getSelectedTab(url));
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const onChange = (event: SyntheticEvent, newTab: string) => {
    history.push(TAB_DATA[newTab].path);
  };

  useEffect(() => {
    // Updates the selected tab on url change
    const newSelectedTab = getSelectedTab(url);

    setTab(newSelectedTab);
  }, [url]);

  const navigationActionClasses = useMemo(
    () => ({
      root: classnames(classes.actionRoot, {
        [classes.actionRootHorizontal]: variant === 'horizontal',
        [classes.actionRootWhite]: white,
        [classes.mobileRoot]: mobile
      }),
      selected: classnames(classes.selected, {
        [classes.selectedBottomUnderline]: underline === 'bottom'
      }),
      label: classnames(classes.label, {
        [classes.labelHorizontal]: variant === 'horizontal'
      })
    }),
    [classes, variant, underline, white, mobile]
  );

  return (
    <MuiBottomNavigation
      value={tab}
      //showLabels
      onChange={onChange}
      sx={{
        background: 'transparent',
        justifyContent: mobile ? 'space-evenly' : 'flex-start'
      }}
    >
      {menuItems.map(({ icon, value }) => (
        <MuiBottomNavigationAction
          key={value}
          icon={icon}
          label={value}
          value={value}
          classes={navigationActionClasses}
        />
      ))}
    </MuiBottomNavigation>
  );
};

export default BottomNavigation;
