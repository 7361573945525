import _ from 'lodash';
import { useState } from 'react';

import toast from 'utils/toast';
import { CloseIcon } from 'icons';

import Stack from 'components/Stack';
import Drawer from 'components/Drawer';
import Typography from 'components/Typography';
import CircularProgress from 'components/Loading';

import {
  IWebAppNotification,
  useUserWebAppNotifications,
  webAppNotificationUpdateSeen,
  webAppNotificationMarkAllAsSeen,
  useUserUnseenWebAppNotificationsCount
} from 'entities/Notifications/sdk';
import { useSelectedCareTeam } from 'entities/CareTeam/sdk';

import BellIconButton from './BellIconButton';
import WebAppNotificationBox from './WebAppNotificationBox';

const NotificationsBellIconButton: React.FC<{}> = () => {
  const [open, setOpen] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);

  const { careTeamId } = useSelectedCareTeam();
  const {
    loading,
    refetch: refetchWebAppNotifications,
    data: { results: webAppNotifications = [], next, previous }
  } = useUserWebAppNotifications({
    careTeamId,
    pageNumber: activePage
  });
  const {
    data: unseenNotificationsCount = 0,
    refetch: refetchUnseenWebAppNotificationsCount
  } = useUserUnseenWebAppNotificationsCount({ careTeamId });

  const handleWebAppNotificationSeenUpdate = ({
    webAppNotification
  }: {
    webAppNotification: IWebAppNotification;
  }) => {
    if (isUpdating) {
      return;
    }

    setIsUpdating(true);

    webAppNotificationUpdateSeen({
      webAppNotificationId: webAppNotification.id,
      seen: !webAppNotification.seen
    })
      .then(() => {
        refetchWebAppNotifications();
        refetchUnseenWebAppNotificationsCount();
        toast.info({ content: 'Notification has been updated sucessfully!' });
      })
      .finally(() => setIsUpdating(false));
  };

  const handleWebAppNotificationMarkAllAsSeen = () => {
    if (isUpdating) {
      return;
    }

    if (_.isNil(careTeamId)) {
      return Promise.reject('Care team is required');
    }

    setIsUpdating(true);

    webAppNotificationMarkAllAsSeen({ careTeamId })
      .then(() => {
        refetchWebAppNotifications();
        refetchUnseenWebAppNotificationsCount();
        toast.info({ content: 'Notifications have been updated sucessfully!' });
      })
      .finally(() => setIsUpdating(false));
  };

  const noNotifications = !loading && webAppNotifications.length === 0;

  return (
    <>
      <BellIconButton
        onClick={() => setOpen(true)}
        notificationsCount={unseenNotificationsCount}
      />
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <Stack padding={2} alignItems="center" height="100%">
          <Stack
            direction="row"
            marginBottom={2}
            justifyContent="space-between"
            alignItems="center"
            alignSelf="stretch"
          >
            <Typography variant="h3">Notifications</Typography>

            <CloseIcon
              onClick={() => setOpen(false)}
              sx={{ cursor: 'pointer' }}
            />
          </Stack>
          {noNotifications ? (
            'You have no new notifications at this time.'
          ) : (
            <Typography
              color="primary.main"
              alignSelf="flex-end"
              sx={{
                cursor: isUpdating ? 'wait' : 'pointer',
                userSelect: 'none'
              }}
              onClick={handleWebAppNotificationMarkAllAsSeen}
              variant="subtitle2"
              marginBottom={1}
            >
              Mark all as seen
            </Typography>
          )}

          <Stack height="100%">
            {webAppNotifications.map(
              (webAppNotification: IWebAppNotification) => (
                <WebAppNotificationBox
                  disabled={isUpdating}
                  key={webAppNotification.id}
                  webAppNotification={webAppNotification}
                  handleSeenUpdate={handleWebAppNotificationSeenUpdate}
                />
              )
            )}
          </Stack>
          {loading && <CircularProgress />}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignSelf="stretch"
          >
            <div>
              {!_.isNil(previous) && (
                <Typography
                  color="primary.main"
                  sx={{ cursor: 'pointer', userSelect: 'none' }}
                  onClick={() => setActivePage((page) => page - 1)}
                  variant="subtitle2"
                >
                  Load newer
                </Typography>
              )}
            </div>
            <div>
              {!_.isNil(next) && (
                <Typography
                  color="primary.main"
                  sx={{ cursor: 'pointer', userSelect: 'none' }}
                  onClick={() => setActivePage((page) => page + 1)}
                  variant="subtitle2"
                >
                  Load older
                </Typography>
              )}
            </div>
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};

export default NotificationsBellIconButton;
