import Grid from 'components/Grid';
import Typography from 'components/Typography';
import { useHistory } from 'react-router-dom';
import { useTheme, makeStyles } from '@mui/styles';

import { useRedirectUrl } from 'utils/hooks';
import IconButton from 'components/IconButton';

import { ArrowBackIcon, EditOutlinedIcon } from 'icons';

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginLeft: -12,
    marginTop: -12
  }
}));

export interface IPageHeader {
  heading?: string;
  backTo: string;
  updateTo?: string;
}

const PageHeader: React.FC<IPageHeader> = ({ heading, backTo, updateTo }) => {
  const redirectUrl = useRedirectUrl();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();

  const goBackUrl = redirectUrl || backTo;

  return (
    <Grid container paddingTop={3} direction="column" gap={1}>
      <Grid
        container
        display="flex"
        direction="row"
        justifyContent="space-between"
        alignItems="end"
      >
        <Grid item>
          <IconButton
            className={classes.backButton}
            onClick={() => history.push(goBackUrl)}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        {!heading && updateTo && (
          <Grid item>
            <IconButton onClick={() => history.push(updateTo)}>
              <EditOutlinedIcon fontSize="medium" />
            </IconButton>
          </Grid>
        )}
      </Grid>

      {heading && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Grid item>
            <Typography variant="h1" color={theme.palette.text.primary}>
              {heading}
            </Typography>
          </Grid>

          {heading && updateTo && (
            <Grid item>
              <IconButton onClick={() => history.push(updateTo)}>
                <EditOutlinedIcon fontSize="medium" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default PageHeader;
