import * as yup from 'yup';

export const VALIDATION_SCHEMA = yup.object().shape({
  password: yup.string().required('Password is required.'),
  confirm_password: yup
    .string()
    .required('Password confirmation is required.')
    .oneOf([yup.ref('password')], 'Passwords must match.')
});

export const ERROR_TO_READABLE_MESSAGE_MAPPING: { [key: string]: string } = {
  'Invalid token provided.':
    'This reset password link is either invalid or has expired.'
};
