import { useTheme } from '@mui/styles';

import { makeStyles } from '@mui/styles';
import { SvgIconTypeMap } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { OverridableComponent } from '@mui/material/OverridableComponent';

import Link from 'components/Link';
import Grid from 'components/Grid';
import Typography from 'components/Typography';

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: 'pointer',
    transition: 'all 0.2s linear',
    '&:hover': {
      transform: 'scale(1.1)',
      'box-shadow': '0px 6px 6px rgba(0, 0, 0, 0.25)'
    }
  }
}));

export interface IBlankState {
  href?: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  title?: string;
  subtitle?: string;
  onClick?: () => void;
  [key: string]: any;
}

const BlankState: React.FC<IBlankState> = ({
  href,
  icon: Icon,
  title,
  subtitle,
  onClick,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      paddingTop={mobile ? 7 : 11}
      {...props}
    >
      <Link.DarkGrey to={href || '#'} underline="none" onClick={onClick}>
        <Grid
          item
          container
          height={130}
          width={130}
          borderRadius="50%"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          alignItems="center"
          justifyContent="center"
          className={classes.icon}
        >
          <Icon fontSize="large" />
        </Grid>
      </Link.DarkGrey>

      <Grid item paddingTop={3}>
        <Typography variant="h3" textAlign="center">
          {title}
        </Typography>
      </Grid>

      <Grid item paddingTop={3}>
        <Typography variant="subtitle1" textAlign="center">
          {subtitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BlankState;
