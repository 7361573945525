import MuiLink, { LinkProps } from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import { colors } from 'theme/palette';

export interface ILink extends LinkProps {
  to: string;
}

export interface IColoredLink extends Omit<ILink, 'color'> {}

const Link = (props: ILink) => <MuiLink {...props} component={RouterLink} />;

const PrimaryLink = (props: IColoredLink) => (
  <MuiLink {...props} component={RouterLink} color={colors.purpleMain} />
);

const DarkGreyLink = (props: IColoredLink) => (
  <MuiLink {...props} component={RouterLink} color={colors.darkGrey} />
);

Link.Primary = PrimaryLink;
Link.DarkGrey = DarkGreyLink;

export default Link;
