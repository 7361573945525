import Fab from 'components/Fab';

import { makeStyles } from '@mui/styles';

import { AddIcon } from 'icons';

export interface IBottomFabAdd {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const useStyles = makeStyles((theme) => ({
  fab: {
    zIndex: 2,
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(3)
    }
  }
}));

const BottomFabAdd: React.FC<IBottomFabAdd> = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Fab
      color="primary"
      aria-label="add"
      className={classes.fab}
      onClick={onClick}
    >
      <AddIcon />
    </Fab>
  );
};

export default BottomFabAdd;
