import { useDropzone, FileRejection } from 'react-dropzone';

import { NoteAddRoundedIcon } from 'icons';

interface IDropzone {
  className?: string;
  acceptedFileTypes: string[];
  onAddedFile?: (files: File[]) => void;
  onRejectedFile?: (rejections: FileRejection[]) => void;
  validator?: (file: File) => null | { code: string; message: string };
}

const Dropzone: React.FC<IDropzone> = ({
  className,
  acceptedFileTypes,
  onAddedFile = () => {},
  onRejectedFile = () => {},
  validator = () => null,
  children
}) => {
  const {
    // acceptedFiles,
    // fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: acceptedFileTypes,
    multiple: true,
    onDropAccepted: onAddedFile,
    onDropRejected: onRejectedFile,
    validator
  });

  return (
    <div {...getRootProps({ className })}>
      <input {...getInputProps()} />
      {!children && <NoteAddRoundedIcon />}
      {children}
    </div>
  );
};

export default Dropzone;
