import { IGeneralNoteFormValues, IGeneralNote } from 'entities/Diary/sdk';

import DiaryNoteFormDialog from 'entities/Diary/components/GeneralNoteFormDialog';
import { BACKEND_DATETIME_FORMAT, BACKEND_DATE_FORMAT } from 'constants/time';
import { datetime } from 'utils/datetime';

export interface IGeneralNoteUpdateDialog {
  onDiaryNoteUpdate: ({
    diaryNote
  }: {
    diaryNote: IGeneralNoteFormValues;
  }) => Promise<void>;
  onClose: () => void;
  diaryNote: IGeneralNote;
}

const GeneralNoteUpdateDialog = ({
  onClose,
  onDiaryNoteUpdate,
  diaryNote
}: IGeneralNoteUpdateDialog) => {
  const initialValues: IGeneralNoteFormValues = {
    subject: diaryNote.subject,
    text: diaryNote.text,
    schedule_date: datetime(
      diaryNote.schedule_datetime,
      BACKEND_DATETIME_FORMAT,
      true
    ).format(BACKEND_DATE_FORMAT)
  };

  return (
    <DiaryNoteFormDialog
      open
      title="Update Note"
      onClose={onClose}
      initialValues={initialValues}
      onDiaryNoteSubmit={onDiaryNoteUpdate}
    />
  );
};

export default GeneralNoteUpdateDialog;
