import { ARE_WEB_APP_NOTIFICATIONS_ENABLED } from 'constants/common';

import Stack from 'components/Stack';

import PatientSwitchButton from 'entities/Patient/components/SwitchButton';
import NotificationsBellIconButton from 'entities/Notifications/components/NotificationsBellIconButton';

const TopNavigation: React.FC<{}> = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      height="100%"
      direction="row"
    >
      <PatientSwitchButton />
      <Stack spacing={2} direction="row">
        {ARE_WEB_APP_NOTIFICATIONS_ENABLED && <NotificationsBellIconButton />}
      </Stack>
    </Stack>
  );
};

export default TopNavigation;
