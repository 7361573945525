import Link from 'components/Link';
import Stack from 'components/Stack';
import Typography from 'components/Typography';

import { LOGIN_URL } from 'config/urls';

import { useRedirectUrl } from 'utils/hooks';
import { REDIRECT_URL_PARAM_KEY } from 'constants/common';

const RegisterPending: React.FC<{}> = () => {
  const redirectUrl = useRedirectUrl();

  const loginUrl = redirectUrl
    ? `${LOGIN_URL}?${REDIRECT_URL_PARAM_KEY}=${redirectUrl}`
    : LOGIN_URL;

  return (
    <Stack
      height="100%"
      justifyContent="center"
      alignItems="center"
      paddingX={1}
    >
      <Typography variant="h2" textAlign="center" marginBottom={2}>
        Check your email to activate your account
      </Typography>

      <Typography variant="subtitle1" marginBottom={5} textAlign="center">
        If you do not see an email in your inbox, check your junk mail for an
        email from Carederly.
      </Typography>

      <Typography variant="subtitle1">
        Already activated your account?
      </Typography>

      <Link to={loginUrl} variant="h6" underline="none" data-gtm="link-login">
        Login
      </Link>
    </Stack>
  );
};

export default RegisterPending;
