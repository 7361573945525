import _ from 'lodash';
import React, { useCallback, useState, useRef } from 'react';
import { makeStyles } from '@mui/styles';

import Dialog from 'components/Dialog';
import { TextField } from '@mui/material';
import Button from 'components/Button';
import Stack from 'components/Stack';
import Typography from 'components/Typography';
import Autocomplete from 'components/Autocomplete';
import { useFormik, FormikHelpers } from 'formik';
import Chip from 'components/Chip';
import { CompactPicker, ColorResult } from 'react-color';
import { colors } from 'theme/palette';

import {
  IUserDocument,
  IUserDocumentEditableValues,
  IUserDocumentTag,
  useDocumentMostCommonTags
} from 'entities/Documents/sdk';
import {
  VALIDATION_SCHEMA_FILENAME,
  COLOR_PICKER_WIDTH,
  COLOR_PICKER_BUFFER
} from 'entities/Documents/constants';

import { isBackgroundDark } from 'entities/Documents/utils';

const useStyles = makeStyles({
  popover: {
    position: 'absolute',
    zIndex: 3
  },
  tag: {
    '&:hover': {
      backgroundColor: colors.mediumGrey,
      color: 'black',
      'box-shadow': '0px 6px 6px rgba(0, 0, 0, 0.25)'
    }
  }
});

interface IDocumentEdit {
  patientId: string | undefined;
  document: IUserDocument;
  onClose: () => void;
  onDocumentEdit: (
    documentData: IUserDocumentEditableValues
  ) => Promise<never> | undefined;
}

const DocumentEditDialog: React.FC<IDocumentEdit> = ({
  patientId,
  document,
  onClose,
  onDocumentEdit
}) => {
  const classes = useStyles();

  const tagRefs = useRef<HTMLDivElement[]>([]);

  const { data: mostCommonTags = [] } = useDocumentMostCommonTags({
    patientId
  });

  const [tagList, setTagList] = useState<IUserDocumentTag[]>(
    _.isUndefined(document.tags) ? [] : document.tags
  );
  const [showColorPicker, setShowColorPicker] = useState<Boolean>(false);
  const [selectedTag, setSelectedTag] = useState<IUserDocumentTag | null>(null);
  const [selectedColor, setSelectedColor] = useState<string>('#eeeeee');

  const handleSubmit = useCallback(
    (
      values: IUserDocumentEditableValues,
      formikHelpers: FormikHelpers<IUserDocumentEditableValues>
    ) => {
      return onDocumentEdit(values)
        ?.then(() => {
          formikHelpers.resetForm();
          formikHelpers.setFieldTouched('file_name', false, false);
        })
        .catch(formikHelpers.setErrors);
    },
    [onDocumentEdit]
  );

  const formik = useFormik({
    initialValues: {
      file_name: document.file_name,
      tags: tagList
    },
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA_FILENAME,
    enableReinitialize: true
  });

  const handleColorPickerClose = () => {
    setShowColorPicker(false);
    setSelectedTag(null);
  };

  const handleColorSelect = (color: ColorResult, tag: IUserDocumentTag) => {
    const updatedTagList = tagList?.map((tag) => {
      if (tag.tag_name === selectedTag?.tag_name) {
        return {
          ...tag,
          tag_color: color.hex
        };
      } else return tag;
    });
    setTagList(updatedTagList);
  };

  const handleTagClick = (option: IUserDocumentTag) => {
    setSelectedTag(option);
    setShowColorPicker(true);
  };

  const getColorPickerPosition = (index: number) => {
    const parentTag = tagRefs.current[index].getBoundingClientRect();

    if (window.innerWidth - parentTag.x < 245) {
      const position =
        COLOR_PICKER_WIDTH +
        parentTag.x -
        window.innerWidth +
        COLOR_PICKER_BUFFER;

      const offset = -1 * position;

      const strOffset = String(offset).concat('px');

      return strOffset;
    } else return '0px';
  };

  const isTypeIUserDocumentTag = (value: any): value is IUserDocumentTag => {
    return (value as IUserDocumentTag).tag_id !== undefined;
  };

  return (
    <Dialog open onClose={onClose} title="Edit Document">
      <form onSubmit={formik.handleSubmit}>
        <Stack margin={2} spacing={3}>
          <Stack direction="row" alignItems="center" spacing={1} paddingTop={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Document name"
              name="file_name"
              value={formik.values.file_name.replace('.pdf', '')}
              onChange={formik.handleChange}
              error={formik.touched.file_name}
              helperText={
                formik.touched.file_name ? formik.errors.file_name : ''
              }
            />
            <Typography variant="body1">.pdf</Typography>
          </Stack>
          <Stack paddingY={2}>
            <Autocomplete
              multiple
              id="tags"
              freeSolo
              value={tagList}
              getOptionLabel={(option: IUserDocumentTag) => option.tag_name}
              options={mostCommonTags}
              renderTags={(value: readonly IUserDocumentTag[], getTagProps) =>
                value.map((option: IUserDocumentTag, index: number) => (
                  <div key={index} style={{ marginRight: '2px' }}>
                    <Chip
                      {...getTagProps({ index })}
                      ref={(el) => {
                        if (!_.isNull(el)) {
                          tagRefs.current[index] = el;
                        }
                      }}
                      id={option.tag_name}
                      variant="filled"
                      label={option.tag_name}
                      onClick={() => handleTagClick(option)}
                      onDelete={() => {
                        const newTagList = _.remove(tagList, (tag) => {
                          return tag.tag_name !== option.tag_name;
                        });
                        setTagList(newTagList);
                      }}
                      className={classes.tag}
                      sx={{
                        backgroundColor: option.tag_color,
                        color: isBackgroundDark(option.tag_color)
                          ? 'black'
                          : 'white'
                      }}
                    />
                    {showColorPicker &&
                      selectedTag?.tag_name === option.tag_name && (
                        <div className={classes.popover}>
                          <div
                            style={{
                              position: 'absolute',
                              left: getColorPickerPosition(index)
                            }}
                            onClick={handleColorPickerClose}
                          >
                            <CompactPicker
                              color={selectedColor}
                              onChange={(color) =>
                                handleColorSelect(color, option)
                              }
                            />
                          </div>
                        </div>
                      )}
                  </div>
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="acTextField"
                  fullWidth
                  variant="standard"
                  placeholder="Tags to organize your documents"
                />
              )}
              onChange={(event, newValue) => {
                const newTagList = newValue.map((item) => {
                  if (isTypeIUserDocumentTag(item)) {
                    return item;
                  } else
                    return {
                      tag_id: item,
                      tag_name: item,
                      tag_color: '#eeeeee'
                    };
                });
                setTagList(newTagList);

                setSelectedColor('#eeeeee');
              }}
            />
          </Stack>
          <Stack paddingBottom={1} paddingTop={2}>
            <Button
              type="submit"
              disabled={formik.isSubmitting}
              data-gtm="button-submit-document-edit"
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
};

export default DocumentEditDialog;
