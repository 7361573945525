import { datetime } from 'utils/datetime';
import Grid from 'components/Grid';

import { IScheduleTask } from 'entities/CarePlan/sdk';

import {
  TIME_WITH_PERIOD_FORMAT,
  BACKEND_DATETIME_FORMAT,
  BACKEND_DATE_FORMAT
} from 'constants/time';

import Typography from 'components/Typography';

export const DATE_WITH_TIME_FORMAT = 'MMM DD, hh:mm A';

interface ITaskDatePeriod {
  task: IScheduleTask;
}

const TaskDatePeriod: React.FC<ITaskDatePeriod> = ({ task }) => {
  const taskStartDatetime = datetime(task.start, BACKEND_DATETIME_FORMAT);
  const taskEndDatetime = datetime(task.end, BACKEND_DATETIME_FORMAT);

  const format =
    taskStartDatetime.format(BACKEND_DATE_FORMAT) ===
    taskEndDatetime.format(BACKEND_DATE_FORMAT)
      ? TIME_WITH_PERIOD_FORMAT
      : DATE_WITH_TIME_FORMAT;

  return (
    <Grid item xs="auto" alignItems="end" textAlign="end">
      <Typography variant="subtitle1">
        {taskStartDatetime.format(format)}
      </Typography>
      <Typography variant="subtitle1">
        {taskEndDatetime.format(format)}
      </Typography>
    </Grid>
  );
};

export default TaskDatePeriod;
