import { CheckCircleIcon, CircleOutlinedIcon, ErrorIcon } from 'icons';
import { Theme } from '@mui/material/styles';

import { IScheduleMedication } from 'entities/CarePlan/sdk';
import { EMedicationDosageTaken } from 'entities/Medications/constants';

// const useStyles = makeStyles((theme) => ({
//   disabledIcon: {
//     color:
//       theme.palette.mode === 'light' ? theme.palette.action.disabled : 'grey'
//   }
// }));

export const getMedicationCompletionIcon = ({
  medication,
  theme
}: {
  medication: IScheduleMedication;
  theme: Theme;
}) => {
  switch (medication.dosage_taken) {
    case EMedicationDosageTaken.FULLY_TAKEN:
      return <CheckCircleIcon fontSize="small" color="success" />;
    case EMedicationDosageTaken.PARTIALLY_TAKEN:
      return <CheckCircleIcon fontSize="small" color="warning" />;
    case EMedicationDosageTaken.NOT_TAKEN:
      return <ErrorIcon fontSize="small" color="error" />;
    default:
      return (
        <CircleOutlinedIcon
          fontSize="small"
          sx={{
            color:
              theme.palette.mode === 'light'
                ? theme.palette.action.disabled
                : 'grey'
          }}
        />
      );
  }
};
