import { useCallback, useMemo } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import _ from 'lodash';
import axios from 'axios';
import Cookies from 'js-cookie';

import { BASE_API_URL } from 'config/urls';

import { history } from 'config/routes';
import { getToken, removeToken } from 'config/storage';
import { LOGIN_URL } from 'config/urls';

export interface IPaginated<T> {
  next: string | null;
  previous: string | null;
  count: number;
  results: Array<T>;
}

export const GENERIC_FORM_ERRORS_KEY = 'errors';

export interface IFormError {
  message: string;
  code: string;
}

const getConfig = () => {
  const token = getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`
    },
    withCredentials: true
  };

  return config;
};

export const getCSRFConfig = () => {
  const csrf = Cookies.get('csrftoken');

  const config = getConfig();

  return {
    ...config,
    headers: {
      ...config.headers,
      'X-CSRFToken': csrf
    }
  };
};

const handleInvalidToken = (error: any) => {
  // When we add a permissions structure - 401 won't log you out, but it will bring up a message.
  if (
    error.response?.status === 401 ||
    (error.response?.status === 403 &&
      _.get(error.response, 'data.detail') === 'Invalid token.')
  ) {
    removeToken();
    if (error.data) {
      // Log error.data only if there is data to log.
      // Most of these occurrences will be due to expired
      // login session, so no need to log it to the console.
      console.log(error.data);
    }
    history.push(LOGIN_URL);
  }

  return Promise.reject(_.get(error, 'response.data'));
};

export const get = <T>(url: string, params?: any): Promise<T> =>
  axios
    .get<T>(url, { ...getConfig(), params })
    .then((response) => response.data)
    .catch(handleInvalidToken);

export const post = <T>(url: string, data?: any, params?: any): Promise<T> =>
  axios
    .post<T>(url, data, { ...getCSRFConfig(), ...params })
    .then((response) => response.data)
    .catch(handleInvalidToken);

/*
data is undefined when the request is not yet finished;
isValidating is true when there's a request or revalidation loading;

loading will be true only if we have fired a get/post request & the data has not been fetched yet
*/

export const useFetch = <T>(url: string | null, options = {}) => {
  const { data, error, mutate, isValidating } = useSWR<T>(url, get, options);

  const refetch = useCallback(() => {
    return mutate();
  }, [mutate]);

  const noData = data === undefined;

  return useMemo(
    () => ({
      data,
      error,
      loading: noData && isValidating,
      refetch,
      mutate
    }),
    [data, error, noData, isValidating, refetch, mutate]
  );
};

export const useSWRCache = () => {
  const { cache } = useSWRConfig();

  const clearSWRCache = () => {
    // TODO: Invalidate more cache keys if needed
    cache.delete(`${BASE_API_URL}/careteam/team/list/`);
    cache.delete(`${BASE_API_URL}/users/me/`);
  };

  return { clearSWRCache };
};
