import * as yup from 'yup';

export const VALIDATION_SCHEMA = yup.object().shape({
  name: yup.string().required('Name is required.'),
  location: yup.string(),
  start: yup
    .date()
    .required('Start date is required')
    .typeError('Start date is required'),
  end: yup
    .date()
    .required('End date is required.')
    .typeError('End date is required.'),
  description: yup.string()
});
