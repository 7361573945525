import { useHistory } from 'react-router-dom';

import toast from 'utils/toast';
import { CUSTOM_VITALS_URL } from 'config/urls';
import { useRedirectToOnboarding } from 'utils/hooks';

import Grid from 'components/Grid';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';

import {
  customVitalsUpdate,
  ICustomVitals,
  usePatientCustomVitals
} from 'entities/Patient/sdk';
import { useSelectedPatient } from 'entities/Patient/sdk';
import CustomVitalsUpdateTable from 'entities/Patient/components/CustomVitalsUpdateTable';

const CustomVitalsUpdate = () => {
  useRedirectToOnboarding();

  const history = useHistory();
  const { patient } = useSelectedPatient();
  const { data: customVitals } = usePatientCustomVitals({
    patientId: patient?.id
  });

  const handleCustomVitalsUpdate = async ({
    values
  }: {
    values: ICustomVitals;
  }) => {
    if (!patient?.id) {
      return Promise.reject("There's no patient in the care team.");
    }

    return customVitalsUpdate({
      customVitals: values,
      patientId: patient.id
    }).then(() => {
      history.push(CUSTOM_VITALS_URL);
      toast.info({
        content: 'Custom vitals have been updated successfully!'
      });
    });
  };

  return (
    <Grid container gap={2} direction="column">
      <Grid item>
        <PageHeader heading="Edit Custom Vitals" backTo={CUSTOM_VITALS_URL} />
      </Grid>

      <Grid item>
        {customVitals ? (
          <CustomVitalsUpdateTable
            customVitals={customVitals}
            onSubmit={handleCustomVitalsUpdate}
          />
        ) : (
          <Loading />
        )}
      </Grid>
    </Grid>
  );
};

export default CustomVitalsUpdate;
