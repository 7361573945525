import classnames from 'classnames';
import { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from 'components/Grid';
import Avatar from 'components/Avatar';
import Typography from 'components/Typography';
import { colors } from 'theme/palette';

const SIZE_TO_DIMENSIONS_MAPPING = {
  small: {
    width: 40,
    height: 40
  },
  medium: {
    width: 82,
    height: 82
  },
  large: {
    width: 100,
    height: 100
  }
};

const useStyles = makeStyles({
  small: {
    '& > img': {
      ...SIZE_TO_DIMENSIONS_MAPPING.small
    }
  },
  medium: {
    '& > img': {
      ...SIZE_TO_DIMENSIONS_MAPPING.medium
    }
  },
  large: {
    '& > img': {
      ...SIZE_TO_DIMENSIONS_MAPPING.large
    }
  },
  white: {
    color: colors.white
  },
  heading: {
    wordBreak: 'break-word'
  }
});

export interface IExtendedAvatar {
  image: string | null | undefined;
  heading?: string;
  subHeading?: string;
  helpText?: string | ReactNode;
  size?: 'small' | 'medium' | 'large';
  white?: boolean;
}

const ExtendedAvatar: React.FC<IExtendedAvatar> = ({
  image,
  heading,
  subHeading,
  helpText,
  size = 'medium',
  white = false
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      gap={size === 'small' ? 1 : 3}
      alignItems="center"
      classes={{
        root: classnames({
          [classes.small]: size === 'small',
          [classes.medium]: size === 'medium',
          [classes.large]: size === 'large',
          [classes.white]: white
        })
      }}
    >
      <Grid item>
        <Avatar
          alt={heading}
          src={image}
          sx={SIZE_TO_DIMENSIONS_MAPPING[size]}
        />
      </Grid>
      <Grid item>
        <Typography
          variant={size === 'small' ? 'h6' : 'h1'}
          className={classes.heading}
        >
          {heading}
        </Typography>
        <Typography variant="h4">{subHeading}</Typography>
        {helpText}
      </Grid>
    </Grid>
  );
};

export default ExtendedAvatar;
