import _ from 'lodash';
import { FormikProps } from 'formik';

import { AddIcon, CloseIcon } from 'icons';

import { datetime, IDatetime } from 'utils/datetime';
import { TIME_WITH_PERIOD_FORMAT } from 'constants/time';

import Alert from 'components/Alert';
import Stack from 'components/Stack';
import Button from 'components/Button';
import TextField from 'components/TextField';
import TimePicker from 'components/TimePicker';
import IconButton from 'components/IconButton';
import Typography from 'components/Typography';

interface IArrayTimeField {
  name: string;
  label: string;
  addFieldText: string;
  formik: FormikProps<any>;
}

const ArrayTimeField: React.FC<IArrayTimeField> = ({
  formik,
  name,
  label,
  addFieldText
}) => {
  const fieldCount = formik.values[name].length;

  const handleChange = ({
    fieldIndex,
    value
  }: {
    fieldIndex: number;
    value: IDatetime | null;
  }) => {
    if (value === null) {
      value = datetime();
    }

    const newFieldValue = formik.values[name];
    newFieldValue[fieldIndex] = value.format(TIME_WITH_PERIOD_FORMAT);

    formik.setFieldValue(name, newFieldValue);
    formik.setFieldTouched(name);
  };

  const addField = () =>
    // We append a field with value equal to the current moment.
    handleChange({ fieldIndex: fieldCount, value: datetime() });

  const removeField = ({ fieldIndex }: { fieldIndex: number }) => {
    const newFieldValue = _.filter(
      formik.values[name],
      (_, i) => parseInt(i) !== fieldIndex
    );

    formik.setFieldValue(name, newFieldValue);
  };

  return (
    <>
      {_.range(fieldCount).map((fieldIndex) => {
        const value = datetime(
          formik.values[name][fieldIndex],
          TIME_WITH_PERIOD_FORMAT
        );

        return (
          <Stack direction="row" alignItems="center" gap={1} key={fieldIndex}>
            <TimePicker
              orientation="landscape"
              label={`${label} ${fieldIndex + 1}`}
              value={value}
              onChange={(value: IDatetime | null) =>
                handleChange({
                  value,
                  fieldIndex
                })
              }
              renderInput={(props) => (
                <TextField.Outlined fullWidth {...props} />
              )}
            />

            <IconButton onClick={() => removeField({ fieldIndex })}>
              <CloseIcon />
            </IconButton>
          </Stack>
        );
      })}
      {formik.errors[name] && (
        <Alert severity="error">{formik.errors[name]}</Alert>
      )}
      <Button variant="text" onClick={addField}>
        <AddIcon fontSize="small" />
        <Typography variant="subtitle2">{addFieldText}</Typography>
      </Button>
    </>
  );
};

export default ArrayTimeField;
