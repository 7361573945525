import { datetime, getTodayDate } from 'utils/datetime';

import {
  BACKEND_DATE_FORMAT,
  BACKEND_DATETIME_FORMAT,
  BACKEND_TIME_FORMAT
} from 'constants/time';

import { IDialog } from 'components/Dialog';

import {
  ICarePlanItemCategory,
  ICarePlanItemValues,
  ICarePlanItem
} from 'entities/CarePlan/sdk';

import CarePlanItemFormDialog from 'entities/CarePlan/components/CarePlanItemFormDialog';

export interface ICarePlanItemUpdateDialog extends IDialog {
  onCarePlanItemUpdate: ({
    carePlanItem
  }: {
    carePlanItem: ICarePlanItemValues;
  }) => Promise<void>;
  categories: Array<ICarePlanItemCategory>;
  carePlanItem: ICarePlanItem;
}

const CarePlanItemUpdateDialog = ({
  onClose,
  onCarePlanItemUpdate,
  categories,
  carePlanItem,
  ...props
}: ICarePlanItemUpdateDialog) => {
  const initialValues: ICarePlanItemValues = {
    category: carePlanItem.category,
    custom_category: carePlanItem.custom_category,
    repeat_cycle: carePlanItem.repeat_cycle,
    repeat_cycle_days: carePlanItem.repeat_cycle_days,
    is_all_day: carePlanItem.is_all_day,
    start_time: carePlanItem.start_time
      ? datetime(carePlanItem.start_time, BACKEND_TIME_FORMAT, true).format(
          'HH:mm'
        )
      : null,
    end_time: carePlanItem.end_time
      ? datetime(carePlanItem.end_time, BACKEND_TIME_FORMAT, true).format(
          'HH:mm'
        )
      : null,
    repeat_after:
      datetime(
        carePlanItem?.repeat_after,
        BACKEND_DATETIME_FORMAT,
        true
      ).format(BACKEND_DATE_FORMAT) || getTodayDate(),
    repeat_before: carePlanItem?.repeat_before
      ? datetime(
          carePlanItem?.repeat_before,
          BACKEND_DATETIME_FORMAT,
          true
        ).format(BACKEND_DATE_FORMAT)
      : null,
    is_added_to_schedule: carePlanItem.is_added_to_schedule,
    description: carePlanItem.description
  };

  return (
    <CarePlanItemFormDialog
      {...props}
      title="Edit care task"
      onClose={onClose}
      categories={categories}
      initialValues={initialValues}
      onCarePlanItemSubmit={onCarePlanItemUpdate}
    />
  );
};

export default CarePlanItemUpdateDialog;
