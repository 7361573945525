import { useState, useMemo } from 'react';
import _ from 'lodash';

import { makeStyles } from '@mui/styles';
import Autocomplete from 'components/Autocomplete';
import Typography from 'components/Typography';

import { useMedicationsNames } from 'entities/Medications/sdk';

const useStyles = makeStyles({
  listItem: {
    padding: 4,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }
  }
});

interface IMedicationsAutocomplete {
  value: string;
  onChange: (name: string) => void;
  renderInput: (params: any) => React.ReactNode;
}

const MedicationsAutocomplete: React.FC<IMedicationsAutocomplete> = ({
  renderInput,
  value,
  onChange: onChangeProp
}) => {
  const [searchString, setSearchString] = useState<string | null>(null);
  const classes = useStyles();

  const { data: options = [], loading } = useMedicationsNames({
    name: searchString
  });

  const debounceFetch = useMemo(
    () =>
      _.debounce((inputValue) => {
        setSearchString(inputValue);
      }, 300),
    []
  );

  return (
    <Autocomplete
      fullWidth
      clearOnBlur={false}
      options={options}
      value={value}
      loading={loading}
      onChange={(event: any, newValue: string | null) => {
        onChangeProp(newValue ? newValue : '');
      }}
      filterOptions={(option) => option}
      onInputChange={(event, newInputValue) => {
        onChangeProp(newInputValue);
        debounceFetch(newInputValue);
      }}
      renderInput={renderInput}
      renderOption={(props, option) => (
        <li {...props} className={classes.listItem}>
          <Typography variant="body2">{option}</Typography>
        </li>
      )}
    />
  );
};

export default MedicationsAutocomplete;
