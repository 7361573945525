export const colors = {
  purpleMain: '#6315E3', // Logo color -- main

  // Shades of purple
  purpleLight: '#8243E8', // Figma Very Light
  purpleDark: '#450E9E', // Figma Light

  // Figma Graph Purples
  purpleDarkest: '#3A0690',
  purpleMedium: '#8044E8',
  purpleLightMedium: '#AE83F8',
  purpleLighter: '#D6C4F5',
  purpleVeryLight: '#EFE8FA',

  // Shades of Grey
  darkestGrey: '#787878',
  darkGrey: '#565454',
  mediumGrey: '#D6D4D4',
  mediumLightGrey: '#EAE9E9',
  lightGrey: '#A09F9F',
  lightestGrey: '#F3F2F2',

  border: '#D6D4D4',
  background: '#f2f2f2',

  veryGreen: '#6ABD00',

  // Secondary
  pinkMain: '#f50057',

  white: '#ffffff',
  disabledWhite: '#F9F9F9'
};
