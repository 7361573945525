import {
  Router,
  Route,
  Redirect,
  Switch,
  RouteComponentProps
} from 'react-router-dom';
import { createBrowserHistory } from 'history';

import {
  LOGIN_URL,
  REGISTER_URL,
  HOME_URL,
  FORGOT_PASSWORD_URL,
  MEDICATIONS_URL,
  MEDICATION_CREATE_URL,
  MEDICATION_CREATE_WITH_FREQUENCY_URL,
  CARE_TEAM_URL,
  MESSAGES_URL,
  SCHEDULE_URL,
  PATIENT_PROFILE_URL,
  MEDICAL_HISTORY_URL,
  CARE_PLAN_URL,
  CUSTOM_VITALS_URL,
  NOTIFICATION_PREFERENCES_URL,
  CUSTOM_VITALS_UPDATE_URL,
  REGISTER_PENDING_URL,
  REGISTER_ACTIVATE_URL,
  PATIENT_PROFILE_UPDATE_URL,
  MEDICATION_UPDATE_URL,
  PROFILE_URL,
  PROFILE_UPDATE_URL,
  CARE_TEAM_MEMBER_INVITATION_URL,
  RESET_PASSWORD_URL,
  PASSWORD_RESET_PENDING_URL,
  PASSWORD_RESET_SUCCESS_URL,
  ONBOARDING_URL,
  HEALTH_DIARY_URL,
  VITALS_TO_TRACK_UPDATE_URL,
  HEALTH_TRENDS_URL,
  DOCUMENTS_URL,
  ONBOARDING_STEPPER_URL,
  SETTINGS_URL,
  MY_CARE_TEAMS_URL
} from 'config/urls';
import PageLayout from 'components/PageLayout';
import SelectedPatientContextProvider from 'components/SelectedPatientContext';
import LoginLayout from 'entities/Auth/Login/components/Layout';
import RegisterLayout from 'entities/Auth/Register/components/Layout';
import BlankPageLayout from 'components/BlankPageLayout';

import Login from 'pages/Auth/Login';
import Register from 'pages/Auth/Register';
import RegisterPending from 'pages/Auth/RegisterPending';
import RegisterActivate from 'pages/Auth/RegisterActivate';
import RegisterActivateNotAllowed from 'pages/Auth/RegisterActivateNotAllowed';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import ResetPassword from 'pages/Auth/ResetPassword';
import PasswordResetPending from 'pages/Auth/PasswordResetPending';
import PasswordResetSuccess from 'pages/Auth/PasswordResetSuccess';

import Home from 'pages/Home';
import Medications from 'pages/Medications';
import MedicationCreate from 'pages/MedicationCreate';
import MedicationUpdate from 'pages/MedicationUpdate';
import Messages from 'pages/Messages';
import Schedule from 'pages/Schedule';
import PatientProfile from 'pages/PatientProfile';
import MedicalHistory from 'pages/MedicalHistory';
import CustomVitals from 'pages/CustomVitals';
import CustomVitalsUpdate from 'pages/CustomVitalsUpdate';
import VitalsToTrackUpdate from 'pages/VitalsToTrackUpdate';
import CarePlan from 'pages/CarePlan';
import PatientProfileUpdate from 'pages/PatientProfileUpdate';
import Profile from 'pages/Profile';
import ProfileUpdate from 'pages/ProfileUpdate';
import Onboarding from 'pages/Onboarding';
import OnboardingStepper from 'pages/Onboarding/components/OnboardingStepper';
import NotificationPreferences from 'pages/NotificationPreferences';

import MyCareTeams from 'pages/MyCareTeams';
import CareTeam from 'pages/CareTeam';
import CareTeamMemberInvitationDetail from 'pages/CareTeamMemberInvitationDetail';
import Documents from 'pages/Documents';

import HealthDiary from 'pages/HealthDiary';

import HealthTrends from 'pages/HealthTrends';
import Settings from 'pages/Settings';

import { getToken } from 'config/storage';

export const history = createBrowserHistory();

const AuthenticationRouter: React.FC<RouteComponentProps<{}>> = (props) => {
  const token = getToken();

  // Do not allow going to private page if the user is not logged in
  if (!token) {
    return <Redirect to={LOGIN_URL} />;
  }

  return (
    <SelectedPatientContextProvider>
      <Switch>
        <Route exact path={HOME_URL}>
          <PageLayout {...props}>
            <Home />
          </PageLayout>
        </Route>
        <Route exact path={MY_CARE_TEAMS_URL}>
          <PageLayout {...props}>
            <MyCareTeams />
          </PageLayout>
        </Route>
        <Route exact path={CARE_TEAM_URL}>
          <PageLayout {...props}>
            <CareTeam />
          </PageLayout>
        </Route>
        <Route exact path={MESSAGES_URL}>
          <PageLayout {...props}>
            <Messages />
          </PageLayout>
        </Route>
        <Route exact path={SCHEDULE_URL}>
          <PageLayout {...props}>
            <Schedule />
          </PageLayout>
        </Route>
        <Route exact path={SETTINGS_URL}>
          <PageLayout {...props}>
            <Settings />
          </PageLayout>
        </Route>
        <Route exact path={PATIENT_PROFILE_URL}>
          <PageLayout {...props}>
            <PatientProfile />
          </PageLayout>
        </Route>
        <Route exact path={MEDICAL_HISTORY_URL}>
          <PageLayout {...props}>
            <MedicalHistory />
          </PageLayout>
        </Route>
        <Route exact path={CUSTOM_VITALS_URL}>
          <PageLayout {...props}>
            <CustomVitals />
          </PageLayout>
        </Route>
        <Route exact path={CUSTOM_VITALS_UPDATE_URL}>
          <PageLayout {...props}>
            <CustomVitalsUpdate />
          </PageLayout>
        </Route>
        <Route exact path={NOTIFICATION_PREFERENCES_URL}>
          <PageLayout {...props}>
            <NotificationPreferences />
          </PageLayout>
        </Route>
        <Route exact path={CARE_PLAN_URL}>
          <PageLayout {...props}>
            <CarePlan />
          </PageLayout>
        </Route>
        <Route exact path={PATIENT_PROFILE_UPDATE_URL}>
          <PageLayout {...props}>
            <PatientProfileUpdate />
          </PageLayout>
        </Route>

        <Route exact path={MEDICATIONS_URL}>
          <PageLayout {...props}>
            <Medications />
          </PageLayout>
        </Route>
        <Route exact path={MEDICATION_CREATE_WITH_FREQUENCY_URL}>
          <PageLayout {...props}>
            <MedicationCreate />
          </PageLayout>
        </Route>
        <Route exact path={MEDICATION_CREATE_URL}>
          <PageLayout {...props}>
            <MedicationCreate />
          </PageLayout>
        </Route>
        <Route exact path={MEDICATION_UPDATE_URL}>
          <PageLayout {...props}>
            <MedicationUpdate />
          </PageLayout>
        </Route>
        <Route exact path={PROFILE_URL}>
          <PageLayout {...props}>
            <Profile />
          </PageLayout>
        </Route>
        <Route exact path={PROFILE_UPDATE_URL}>
          <PageLayout {...props}>
            <ProfileUpdate />
          </PageLayout>
        </Route>
        <Route exact path={ONBOARDING_URL}>
          <BlankPageLayout {...props}>
            <Onboarding />
          </BlankPageLayout>
        </Route>
        <Route exact path={ONBOARDING_STEPPER_URL}>
          <BlankPageLayout {...props}>
            <OnboardingStepper />
          </BlankPageLayout>
        </Route>

        <Route exact path={HEALTH_DIARY_URL}>
          <PageLayout {...props}>
            <HealthDiary />
          </PageLayout>
        </Route>

        <Route exact path={HEALTH_TRENDS_URL}>
          <PageLayout {...props}>
            <HealthTrends />
          </PageLayout>
        </Route>

        <Route exact path={VITALS_TO_TRACK_UPDATE_URL}>
          <PageLayout {...props}>
            <VitalsToTrackUpdate />
          </PageLayout>
        </Route>

        <Route exact path={DOCUMENTS_URL}>
          <PageLayout {...props}>
            <Documents />
          </PageLayout>
        </Route>

        <Route
          exact
          path="*"
          component={() => {
            return <div>Not found fallback in routes.tsx</div>;
          }}
        />
      </Switch>
    </SelectedPatientContextProvider>
  );
};

const LoginRouter: React.FC<{}> = () => {
  const token = getToken();

  // Do not allow going to /login if the user is logged in
  if (token) {
    return <Redirect to={ONBOARDING_URL} />;
  }

  return (
    <LoginLayout>
      <Login />
    </LoginLayout>
  );
};

const RegisterRouter: React.FC<{}> = () => {
  const token = getToken();

  // Do not allow going to /register if the user is logged in
  if (token) {
    return <Redirect to={ONBOARDING_URL} />;
  }

  return (
    <RegisterLayout>
      <Register />
    </RegisterLayout>
  );
};

const RegisterPendingRouter: React.FC<{}> = () => {
  const token = getToken();

  // Do not allow going to /register/pending if the user is logged in
  if (token) {
    return <Redirect to={ONBOARDING_URL} />;
  }

  return (
    <RegisterLayout>
      <RegisterPending />
    </RegisterLayout>
  );
};

const RegisterActivateRouter: React.FC<RouteComponentProps<{}>> = (props) => {
  const token = getToken();

  return (
    <RegisterLayout {...props}>
      {token && <RegisterActivateNotAllowed />}
      {!token && <RegisterActivate />}
    </RegisterLayout>
  );
};

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path={LOGIN_URL} component={LoginRouter} />

        <Route exact path="/">
          <Redirect to={LOGIN_URL} />
        </Route>

        <Route exact path={REGISTER_URL} component={RegisterRouter} />
        <Route
          exact
          path={REGISTER_PENDING_URL}
          component={RegisterPendingRouter}
        />
        <Route
          exact
          path={REGISTER_ACTIVATE_URL}
          component={RegisterActivateRouter}
        />
        <Route
          exact
          path={CARE_TEAM_MEMBER_INVITATION_URL}
          component={CareTeamMemberInvitationDetail}
        />

        <Route exact path={FORGOT_PASSWORD_URL} component={ForgotPassword} />
        <Route exact path={RESET_PASSWORD_URL} component={ResetPassword} />
        <Route
          exact
          path={PASSWORD_RESET_PENDING_URL}
          component={PasswordResetPending}
        />
        <Route
          exact
          path={PASSWORD_RESET_SUCCESS_URL}
          component={PasswordResetSuccess}
        />

        <Route exact path="*" component={AuthenticationRouter} />
      </Switch>
    </Router>
  );
};

export default Routes;
