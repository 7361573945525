import _ from 'lodash';

import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { BASE_API_URL, LOGIN_URL } from 'config/urls';
import { removeItem, removeToken } from 'config/storage';

import { useFetch, post, useSWRCache } from 'utils/sdk';
import TagManager from 'react-gtm-module';

export interface IRegisterFormValues {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
  user_platform_type: 'web';
}

export interface IForgotPasswordFormValues {
  email: string;
}

export interface IResetPasswordFormValues {
  email: string;
  token: string;
  password: string;
  confirm_password: string;
}

export interface IUser {
  id: string;
  email: string;
  profile: {
    id: string;
    avatar: string;
    first_name: string;
    last_name: string;
  };
  patient?: {
    id: string;
  };
}

export const login = ({
  email,
  password
}: {
  email: string;
  password: string;
}) =>
  post<{ token: string; user_id: string }>(`${BASE_API_URL}/auth/login/`, {
    username: email,
    password
  });

export const passwordResetEmailSend = ({ email }: IForgotPasswordFormValues) =>
  post<undefined>(`${BASE_API_URL}/auth/password-reset-email-send/`, { email });

export const passwordReset = ({
  email,
  token,
  password
}: IResetPasswordFormValues) =>
  post<undefined>(`${BASE_API_URL}/auth/password-reset/`, {
    email,
    password,
    token
  });

export const activationEmailResend = ({
  email,
  redirectUrl
}: {
  email: string;
  redirectUrl?: string;
}) =>
  post<undefined>(`${BASE_API_URL}/auth/activation-email-resend/`, {
    email,
    redirect_url: redirectUrl
  });

export const register = ({
  values,
  redirectUrl
}: {
  values: IRegisterFormValues;
  redirectUrl: string;
}) =>
  post<undefined>(`${BASE_API_URL}/auth/register/`, {
    ...values,
    redirect_url: redirectUrl
  });

export const logout = () => post(`${BASE_API_URL}/auth/logout/`, {});

export const useRegisterActivate = ({ userId }: { userId: string }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);

    post(`${BASE_API_URL}/auth/register/${userId}/activate/`, {})
      .then((data) => {
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        setError(_.get(errors, 'errors[0].message'));
      });
  }, [userId]);

  return { loading, error };
};

export const useUserLogout = () => {
  const history = useHistory();

  const { clearSWRCache } = useSWRCache();

  const handleLogout = useCallback(() => {
    logout().finally(() => {
      removeToken();

      clearSWRCache();

      removeItem('userId');
      TagManager.dataLayer({
        dataLayer: {
          userId: null
        }
      });

      history.push(LOGIN_URL);
    });
  }, [history, clearSWRCache]);

  return { handleLogout };
};

export const useSignedUser = () => useFetch<IUser>(`${BASE_API_URL}/users/me/`);
