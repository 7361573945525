import _ from 'lodash';
import { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useRedirectToOnboarding } from 'utils/hooks';
import { getTodayDate } from 'utils/datetime';
import { queryStringParse } from 'utils/common';

import Stack from 'components/Stack';
import DiaryCalendar from 'components/DiaryCalendar';
import PageHeader from 'components/PageHeader';
import ConfirmationDialog from 'components/ConfirmationDialog';
import DiaryMenu from 'entities/Diary/components/DiaryMenu';

import {
  useSelectedPatient,
  usePatientCustomVitals
} from 'entities/Patient/sdk';
import DiaryNoteUpdateDialog from 'entities/Diary/components/GeneralNoteUpdateDialog';
import DiaryNoteCreateDialog from 'entities/Diary/components/GeneralNoteCreateDialog';
import DiarySchedule from 'entities/Diary/components/DiarySchedule';
import VitalNoteCreateDialog from 'entities/Diary/components/VitalNoteCreateDialog';
import VitalNoteUpdateDialog from 'entities/Diary/components/VitalNoteUpdateDialog';

import {
  IGeneralNote,
  generalNoteCreate,
  IGeneralNoteFormValues,
  useDiarySchedule,
  generalNoteUpdate,
  generalNoteDelete,
  IVitalNoteFormValues,
  vitalNoteCreate,
  IVitalNote,
  vitalNoteDelete,
  vitalNoteUpdate
} from 'entities/Diary/sdk';

import toast from 'utils/toast';

import { HOME_URL } from 'config/urls';

import {
  IS_VITAL_NOTE_CREATE_DIALOG_OPEN_URL_PARAM_KEY,
  IS_DIARY_NOTE_CREATE_DIALOG_OPEN_URL_PARAM_KEY,
  EDiaryMenuItems
} from './constants';

const HealthDiary: React.FC<{}> = () => {
  const location = useLocation();
  const queryParams = queryStringParse({
    queryParams: location.search
  });

  const initialIsVitalNoteCreateDialogOpen = _.get(
    queryParams,
    IS_VITAL_NOTE_CREATE_DIALOG_OPEN_URL_PARAM_KEY
  );

  const initialIsDiaryNoteCreateDialogOpen = _.get(
    queryParams,
    IS_DIARY_NOTE_CREATE_DIALOG_OPEN_URL_PARAM_KEY
  );

  const [isDiaryNoteCreateDialogOpen, setIsDiaryNoteCreateDialogOpen] =
    useState<boolean>(Boolean(initialIsDiaryNoteCreateDialogOpen));

  const [isDiaryNoteUpdateDialogOpen, setIsDiaryNoteUpdateDialogOpen] =
    useState<boolean>(false);

  const [isVitalNoteCreateDialogOpen, setIsVitalNoteCreateDialogOpen] =
    useState<boolean>(Boolean(initialIsVitalNoteCreateDialogOpen));

  const [
    isDiaryNoteDeleteConfirmationDialogOpen,
    setIsDiaryNoteDeleteConfirmationDialogOpen
  ] = useState<boolean>(false);

  const [isVitalNoteUpdateDialogOpen, setIsVitalNoteUpdateDialogOpen] =
    useState<boolean>(false);

  const [
    isVitalNoteDeleteConfirmationDialogOpen,
    setIsVitalNoteDeleteConfirmationDialogOpen
  ] = useState<boolean>(false);

  const [selectedDiaryNote, setSelectedDiaryNote] =
    useState<null | IGeneralNote>(null);

  const [selectedVitalNote, setSelectedVitalNote] = useState<null | IVitalNote>(
    null
  );

  useRedirectToOnboarding();

  const [selectedDate, setSelectedDate] = useState<string>(getTodayDate());

  const { patient } = useSelectedPatient();
  const { data: customVitals } = usePatientCustomVitals({
    patientId: patient?.id
  });
  const {
    data: diarySchedule = [],
    loading: diaryScheduleLoading,
    refetch: refetchDiarySchedule
  } = useDiarySchedule({
    patientId: patient?.id,
    selectedDate,
    isForFullMonth: false
  });

  const addMenuItems = [
    {
      label: EDiaryMenuItems.NEW_VITALS_NOTE,
      onClick: () => setIsVitalNoteCreateDialogOpen(true)
    },
    {
      label: EDiaryMenuItems.NEW_GENERAL_NOTE,
      onClick: () => setIsDiaryNoteCreateDialogOpen(true)
    }
  ];

  const handleDiaryNoteCreate = useCallback(
    ({ diaryNote }: { diaryNote: IGeneralNoteFormValues }) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      return generalNoteCreate({
        patientId: patient.id,
        diaryNote,
        schedule_item_id: null,
        schedule_item_type: null
      }).then(() => {
        setIsDiaryNoteCreateDialogOpen(false);
        refetchDiarySchedule();
        toast.info({
          content: 'Diary note has been created successfully!'
        });
      });
    },
    [patient, refetchDiarySchedule]
  );

  const onDiaryNoteUpdate = ({ diaryNote }: { diaryNote: IGeneralNote }) => {
    setSelectedDiaryNote(diaryNote);
    setIsDiaryNoteUpdateDialogOpen(true);
  };

  const handleDiaryNoteUpdate = useCallback(
    ({ diaryNote }: { diaryNote: IGeneralNoteFormValues }) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      if (!selectedDiaryNote) {
        return Promise.reject("There's no selected diary note.");
      }

      return generalNoteUpdate({
        diaryNoteId: selectedDiaryNote.id,
        patientId: patient.id,
        diaryNote
      }).then(() => {
        setIsDiaryNoteUpdateDialogOpen(false);
        refetchDiarySchedule();
        toast.info({
          content: 'Diary note has been updated successfully!'
        });
      });
    },
    [patient, refetchDiarySchedule, selectedDiaryNote]
  );

  const onDiaryNoteDelete = ({ diaryNote }: { diaryNote: IGeneralNote }) => {
    setSelectedDiaryNote(diaryNote);
    setIsDiaryNoteDeleteConfirmationDialogOpen(true);
  };

  const handleDiaryNoteDelete = useCallback(() => {
    if (!patient?.id) {
      return Promise.reject("There's no patient in the care team.");
    }

    if (!selectedDiaryNote) {
      return Promise.reject("There's no selected diary note.");
    }

    generalNoteDelete({
      diaryNoteId: selectedDiaryNote.id,
      patientId: patient.id
    }).then(() => {
      setIsDiaryNoteDeleteConfirmationDialogOpen(false);
      refetchDiarySchedule();
      toast.info({
        content: 'Diary note has been deleted successfully!'
      });
    });
  }, [patient, refetchDiarySchedule, selectedDiaryNote]);

  const onDiaryNoteCreate = () => {
    setIsDiaryNoteCreateDialogOpen(true);
  };

  const handleVitalNoteCreate = useCallback(
    ({ vitalNote }: { vitalNote: IVitalNoteFormValues }) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      return vitalNoteCreate({
        patientId: patient.id,
        vitalNote
      }).then(() => {
        setIsVitalNoteCreateDialogOpen(false);
        refetchDiarySchedule();
        toast.info({
          content: 'Vital note has been created successfully!'
        });
      });
    },
    [patient, refetchDiarySchedule]
  );

  const onVitalNoteDelete = ({ vitalNote }: { vitalNote: IVitalNote }) => {
    setSelectedVitalNote(vitalNote);
    setIsVitalNoteDeleteConfirmationDialogOpen(true);
  };

  const handleVitalNoteDelete = useCallback(() => {
    if (!patient?.id) {
      return Promise.reject("There's no patient in the care team.");
    }

    if (!selectedVitalNote) {
      return Promise.reject("There's no selected vital note.");
    }

    vitalNoteDelete({
      vitalNoteId: selectedVitalNote.id,
      patientId: patient.id
    }).then(() => {
      setIsVitalNoteDeleteConfirmationDialogOpen(false);
      refetchDiarySchedule();
      toast.info({
        content: 'Vital note has been deleted successfully!'
      });
    });
  }, [patient, refetchDiarySchedule, selectedVitalNote]);

  const onVitalNoteUpdate = ({ vitalNote }: { vitalNote: IVitalNote }) => {
    setSelectedVitalNote(vitalNote);
    setIsVitalNoteUpdateDialogOpen(true);
  };

  const handleVitalNoteUpdate = useCallback(
    ({ vitalNote }: { vitalNote: IVitalNoteFormValues }) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      if (!selectedVitalNote) {
        return Promise.reject("There's no selected vital note.");
      }

      return vitalNoteUpdate({
        vitalNoteId: selectedVitalNote.id,
        patientId: patient.id,
        vitalNote
      }).then(() => {
        setIsVitalNoteUpdateDialogOpen(false);
        refetchDiarySchedule();
        toast.info({
          content: 'Vital note has been updated successfully!'
        });
      });
    },
    [patient, refetchDiarySchedule, selectedVitalNote]
  );

  return (
    <Stack spacing={2}>
      <PageHeader heading="Health diary" backTo={HOME_URL} />

      <DiaryMenu items={addMenuItems} />

      <DiaryCalendar
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />

      <DiarySchedule
        isLoading={diaryScheduleLoading}
        data={diarySchedule}
        onDiaryNoteDelete={onDiaryNoteDelete}
        onDiaryNoteUpdate={onDiaryNoteUpdate}
        onDiaryNoteCreate={onDiaryNoteCreate}
        onVitalNoteDelete={onVitalNoteDelete}
        onVitalNoteUpdate={onVitalNoteUpdate}
        customVitals={customVitals}
      />

      {isDiaryNoteCreateDialogOpen && (
        <DiaryNoteCreateDialog
          onClose={() => setIsDiaryNoteCreateDialogOpen(false)}
          onDiaryNoteCreate={handleDiaryNoteCreate}
          selectedDate={selectedDate}
        />
      )}
      {isDiaryNoteUpdateDialogOpen && selectedDiaryNote && (
        <DiaryNoteUpdateDialog
          diaryNote={selectedDiaryNote}
          onClose={() => {
            setSelectedDiaryNote(null);
            setIsDiaryNoteUpdateDialogOpen(false);
          }}
          onDiaryNoteUpdate={handleDiaryNoteUpdate}
        />
      )}

      {isDiaryNoteDeleteConfirmationDialogOpen && selectedDiaryNote && (
        <ConfirmationDialog
          open
          title="Are you sure you want to remove this diary note?"
          primaryButtonColor="error"
          onClose={() => {
            setSelectedDiaryNote(null);
            setIsDiaryNoteDeleteConfirmationDialogOpen(false);
          }}
          onConfirm={handleDiaryNoteDelete}
        ></ConfirmationDialog>
      )}

      {isVitalNoteDeleteConfirmationDialogOpen && selectedVitalNote && (
        <ConfirmationDialog
          open
          title="Are you sure you want to remove this vital note?"
          primaryButtonColor="error"
          onClose={() => {
            setSelectedVitalNote(null);
            setIsVitalNoteDeleteConfirmationDialogOpen(false);
          }}
          onConfirm={handleVitalNoteDelete}
        ></ConfirmationDialog>
      )}

      {isVitalNoteUpdateDialogOpen && selectedVitalNote && (
        <VitalNoteUpdateDialog
          vitalNote={selectedVitalNote}
          onClose={() => {
            setSelectedVitalNote(null);
            setIsVitalNoteUpdateDialogOpen(false);
          }}
          onVitalNoteUpdate={handleVitalNoteUpdate}
        />
      )}

      {isVitalNoteCreateDialogOpen && (
        <VitalNoteCreateDialog
          onClose={() => setIsVitalNoteCreateDialogOpen(false)}
          onVitalNoteCreate={handleVitalNoteCreate}
          selectedDate={selectedDate}
        />
      )}
    </Stack>
  );
};

export default HealthDiary;
