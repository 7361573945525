import { useTheme } from '@mui/material/styles';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import Link from 'components/Link';
import Image from 'components/Image';
import Grid from 'components/Grid';
import ExternalLink from 'components/ExternalLink';
import Typography from 'components/Typography';

import { register } from 'entities/Auth/sdk';
import { IRegisterFormValues } from 'entities/Auth/sdk';
import RegisterForm from 'entities/Auth/Register/components/Form';

import { LOGIN_URL, REGISTER_PENDING_URL } from 'config/urls';

import { useRedirectUrl } from 'utils/hooks';
import { REDIRECT_URL_PARAM_KEY } from 'constants/common';

import logo from 'assets/blue_logo.png';

const Register: React.FC<{}> = () => {
  const theme = useTheme();
  const history = useHistory();
  const redirectUrl = useRedirectUrl();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const registerPendingUrl = redirectUrl
    ? `${REGISTER_PENDING_URL}?${REDIRECT_URL_PARAM_KEY}=${redirectUrl}`
    : REGISTER_PENDING_URL;

  const loginRedirect = redirectUrl
    ? `${LOGIN_URL}?${REDIRECT_URL_PARAM_KEY}=${redirectUrl}`
    : LOGIN_URL;

  const handleSubmit = useCallback(
    ({ values }: { values: IRegisterFormValues }) =>
      register({ values, redirectUrl }).then(() => {
        history.push(registerPendingUrl);
      }),
    [history, registerPendingUrl, redirectUrl]
  );

  return (
    <Grid
      container
      minHeight="100%"
      justifyContent="center"
      alignItems="center"
      direction="column"
      wrap="nowrap"
      padding={4}
    >
      <Grid item>
        <Image src={logo} alt="logo" />
      </Grid>

      <Grid item margin={4} width={mobile ? '100%' : '390px'}>
        <RegisterForm onSubmit={handleSubmit} />
      </Grid>

      <Grid item marginX={4} marginBottom={4} textAlign="center">
        <Typography variant="subtitle2">
          By clicking register, you agree to Carederly's{' '}
          <ExternalLink to="https://meetcarederly.com/terms-of-use/">
            Terms of Use
          </ExternalLink>{' '}
          and{' '}
          <ExternalLink to="https://meetcarederly.com/privacy-policy/">
            Privacy Policy
          </ExternalLink>
          .
        </Typography>
      </Grid>

      <Grid item>
        <Typography variant="subtitle1">Already have an account?</Typography>
      </Grid>

      <Grid item>
        <Link.DarkGrey
          to={loginRedirect}
          variant="h6"
          underline="none"
          data-gtm="link-login"
        >
          Login
        </Link.DarkGrey>
      </Grid>
    </Grid>
  );
};

export default Register;
