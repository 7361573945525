export enum EDocumentsMenuItems {
  NEW_DOC_FROM_DEVICE = "Upload from your device's files",
  NEW_DOC_FROM_PHOTOS = 'Take a photo'
}

export const SHOW_DOCUMENTS_ADD_PHOTOS_DIALOG_OPEN =
  'showDocumentFromPhotosDialog';

export const SHOW_DOCUMENTS_ADD_DEVICE_DIALOG_OPEN =
  'showDocumentFromDeviceDialog';
