import { useMemo } from 'react';
import { datetime, IDatetime } from 'utils/datetime';

import { BACKEND_DATETIME_FORMAT } from 'constants/time';

type PeriodStrings = {
  startDate: string;
  endDate: string;
};

type Period = {
  start: IDatetime;
  end: IDatetime;
};

export const useTicksForChart = ({ startDate, endDate }: PeriodStrings) => {
  return useMemo(() => {
    const start = datetime(startDate, BACKEND_DATETIME_FORMAT);
    const end = datetime(endDate, BACKEND_DATETIME_FORMAT);

    if (start.add(8, 'days').isAfter(end, 'hours')) {
      return getTicksForWeekly({ start, end });
    }
    if (start.add(32, 'days').isAfter(end)) {
      return getTicksForMonthly({ start, end });
    }
    return getTicksForQuarterly({ start, end });
  }, [startDate, endDate]);
};

const getTicksForWeekly = ({ start, end }: Period) => {
  const res = [];
  let stepper = start;
  while (stepper.isBefore(end)) {
    res.push(stepper.valueOf());
    stepper = stepper.add(2, 'day');
  }
  return res;
};

const getTicksForMonthly = ({ start, end }: Period) => {
  const res = [];
  let stepper = start.day(1); // Sets the day to "Monday" in the current week
  while (stepper.isBefore(end)) {
    res.push(stepper.valueOf());
    stepper = stepper.add(1, 'week');
  }
  return res;
};

const getTicksForQuarterly = ({ start, end }: Period) => {
  const res = [];
  let stepper = start.date(1);
  // remove the first value if it's outside the range
  if (start.date() !== 1) {
    stepper = stepper.add(1, 'month');
  }
  // add the values for the 1st date of every month
  while (stepper.isBefore(end)) {
    res.push(stepper.valueOf());
    stepper = stepper.add(1, 'month');
  }
  return res;
};
