import Badge from 'components/Badge';
import IconButton from 'components/IconButton';

import { NotificationsIcon } from 'icons';

interface IBellIconButton {
  onClick: () => void;
  notificationsCount: number;
}

const BellIconButton: React.FC<IBellIconButton> = ({
  onClick,
  notificationsCount
}) => (
  <IconButton onClick={onClick}>
    <Badge badgeContent={notificationsCount} color="error">
      <NotificationsIcon color="secondary" />
    </Badge>
  </IconButton>
);

export default BellIconButton;
