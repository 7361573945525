import { useTheme } from '@mui/material/styles';
import { colors } from 'theme/palette';

import _ from 'lodash';
import { Fragment } from 'react';
import { datetime } from 'utils/datetime';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';

import Stack from 'components/Stack';
import Grid from 'components/Grid';
import Typography from 'components/Typography';
import IconButton from 'components/IconButton';
import Link from 'components/Link';
import Divider from 'components/Divider';
import Chip from 'components/Chip';

import { GroupIcon, MoreHorizIcon } from 'icons';

import { IUserDocument } from 'entities/Documents/sdk';

import {
  BACKEND_DATETIME_FORMAT,
  DATETIME_WITH_PERIOD_SHORTENED_FORMAT
} from 'constants/time';

import { DocumentAction } from 'pages/Documents/index';
import { isBackgroundDark } from 'entities/Documents/utils';

const useStyles = makeStyles({
  tag: {
    '&:hover': {
      backgroundColor: colors.mediumGrey,
      color: 'black',
      'box-shadow': '0px 6px 6px rgba(0, 0, 0, 0.25)'
    }
  }
});

export interface IDocumentsContent {
  documents: Array<IUserDocument>;
  isFetchingDocuments: boolean;
  dispatchIsDialogOpen: (type: DocumentAction) => void;
  setSelectedDocument: (arg0: IUserDocument) => void;
  documentAnchorEl: null | HTMLElement;
  setDocumentAnchorEl: (item: null | HTMLElement) => void;
  setSearchTerm: (arg0: string) => void;
  refetchDocumentsListInParent: () => void;
}

const DocumentsContent = ({
  documents,
  isFetchingDocuments,
  dispatchIsDialogOpen,
  setSelectedDocument,
  documentAnchorEl,
  setDocumentAnchorEl,
  setSearchTerm,
  refetchDocumentsListInParent
}: IDocumentsContent) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const classes = useStyles();

  const orderedDocumentList = _.orderBy(
    documents,
    (documents) => documents.created_at,
    'desc'
  );

  const handleDocumentMoreClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => setDocumentAnchorEl(event.currentTarget);

  return (
    <>
      <Stack paddingY={1}>
        {!isFetchingDocuments &&
          orderedDocumentList.map((document, i) => (
            <Fragment key={document.id}>
              <Grid container alignItems="baseline">
                <Grid
                  item
                  paddingY={2}
                  flexGrow={1}
                  xs={mobile ? 10 : 11}
                  alignItems="center"
                >
                  <Grid container>
                    <Grid item flexWrap="wrap" overflow="hidden" xs>
                      <Link.Primary
                        to="#"
                        onClick={() => {
                          dispatchIsDialogOpen({ type: 'preview-open' });
                          setSelectedDocument(document);
                        }}
                        variant="body1"
                        underline="none"
                      >
                        {document.file_name}
                      </Link.Primary>
                    </Grid>
                    <Grid item xs={1}>
                      {document.number_of_permissioned_users > 1 && (
                        <GroupIcon
                          fontSize="small"
                          sx={{
                            color: colors.lightGrey,
                            marginLeft: '5px',
                            paddingTop: '2px'
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Typography variant="subtitle2">
                    Added by {document.added_by_full_name} on{' '}
                    {datetime(
                      document.created_at,
                      BACKEND_DATETIME_FORMAT
                    ).format(DATETIME_WITH_PERIOD_SHORTENED_FORMAT)}
                  </Typography>
                  <Grid container spacing={1}>
                    {!_.isUndefined(document.tags) &&
                      document.tags.map((tag) => (
                        <Grid item key={tag.tag_id}>
                          <Chip
                            id={tag.tag_id}
                            label={tag.tag_name}
                            onClick={() => {
                              setSearchTerm(tag.tag_name);
                              refetchDocumentsListInParent();
                            }}
                            size="small"
                            className={classes.tag}
                            sx={{
                              backgroundColor: tag.tag_color,
                              color: isBackgroundDark(tag.tag_color)
                                ? 'black'
                                : 'white'
                            }}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>

                <Grid item xs textAlign="end">
                  <IconButton
                    onClick={(event) => {
                      setSelectedDocument(document);
                      handleDocumentMoreClick(event);
                    }}
                    aria-controls={
                      Boolean(documentAnchorEl) ? 'document-menu' : undefined
                    }
                    aria-haspopup={true}
                    aria-expanded={
                      Boolean(documentAnchorEl) ? 'true' : undefined
                    }
                  >
                    <MoreHorizIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />
            </Fragment>
          ))}
      </Stack>
    </>
  );
};

export default DocumentsContent;
