import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
    overflow: 'auto'
  }
}));

interface IRegisterLayout {}

const RegisterLayout: React.FC<IRegisterLayout> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default RegisterLayout;
