import _ from 'lodash';
import { FormikProps } from 'formik';

import Stack from 'components/Stack';
import Typography from 'components/Typography';

import { TextField } from '@mui/material';

import { INewPatientCareTeamCreateFormValues } from 'entities/CareTeam/sdk';

interface IPatientProfileCreate {
  formik: FormikProps<INewPatientCareTeamCreateFormValues>;
}

const PatientProfileCreate: React.FC<IPatientProfileCreate> = ({ formik }) => {
  return (
    <Stack padding={3}>
      <Stack gap={3}>
        <Typography variant="h4" fontWeight="normal">
          Who is your loved one?
        </Typography>

        <TextField
          name="patient.first_name"
          placeholder="First name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.patient.first_name}
          error={
            formik.touched.patient?.first_name &&
            !_.isEmpty(formik.errors.patient?.first_name)
          }
          helperText={
            formik.touched.patient?.first_name
              ? formik.errors.patient?.first_name
              : ''
          }
        />
        <TextField
          name="patient.last_name"
          placeholder="Last name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.patient.last_name}
          error={
            formik.touched.patient?.last_name &&
            !_.isEmpty(formik.errors.patient?.last_name)
          }
          helperText={
            formik.touched.patient?.last_name
              ? formik.errors.patient?.last_name
              : ''
          }
        />
      </Stack>
    </Stack>
  );
};
export default PatientProfileCreate;
