import _ from 'lodash';
import { useCallback } from 'react';
import { useFormik, FormikHelpers } from 'formik';

import Grid from 'components/Grid';
import Button from 'components/Button';
import Switch from 'components/Switch';
import FormControlLabel from 'components/FormControlLabel';

import { IVitalsToTrackFromValues, IVitalsToTrack } from 'entities/Patient/sdk';

import { VALIDATION_SCHEMA } from './constants';

interface IVitalsToTrackUpdateForm {
  vitalsToTrack: IVitalsToTrack;
  onVitalsToTrackUpdate: ({
    vitalsToTrack
  }: {
    vitalsToTrack: IVitalsToTrackFromValues;
  }) => Promise<void>;
}

const VitalsToTrackUpdateForm: React.FC<IVitalsToTrackUpdateForm> = ({
  vitalsToTrack,
  onVitalsToTrackUpdate
}) => {
  const handleSubmit = useCallback(
    (
      values: IVitalsToTrackFromValues,
      formikHelpers: FormikHelpers<IVitalsToTrackFromValues>
    ) =>
      onVitalsToTrackUpdate({
        vitalsToTrack: values
      }).catch(formikHelpers.setErrors),
    [onVitalsToTrackUpdate]
  );

  const initialValues = _.pick(vitalsToTrack, [
    'temperature',
    'blood_pressure',
    'pulse',
    'blood_oxygen',
    'blood_sugar'
  ]);

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={formik.values.temperature}
                color="primary"
              />
            }
            label="Temperature"
            name="temperature"
            labelPlacement="start"
            value={formik.values.temperature}
            sx={{ marginLeft: 0 }}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={formik.values.blood_pressure}
                color="primary"
              />
            }
            label="Blood Pressure"
            name="blood_pressure"
            labelPlacement="start"
            value={formik.values.blood_pressure}
            sx={{ marginLeft: 0 }}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item>
          <FormControlLabel
            control={
              <Switch defaultChecked={formik.values.pulse} color="primary" />
            }
            label="Pulse"
            name="pulse"
            labelPlacement="start"
            value={formik.values.pulse}
            sx={{ marginLeft: 0 }}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={formik.values.blood_oxygen}
                color="primary"
              />
            }
            label="Blood Oxygen"
            name="blood_oxygen"
            labelPlacement="start"
            value={formik.values.blood_oxygen}
            sx={{ marginLeft: 0 }}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={formik.values.blood_sugar}
                color="primary"
              />
            }
            label="Blood Sugar"
            name="blood_sugar"
            labelPlacement="start"
            value={formik.values.blood_sugar}
            sx={{ marginLeft: 0 }}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item>
          <Button type="submit" disabled={formik.isSubmitting}>
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default VitalsToTrackUpdateForm;
