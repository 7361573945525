import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Box from 'components/Box';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Image from 'components/Image';
import Stack from 'components/Stack';
import Container from 'components/Container';
import PageLoader from 'components/PageLoader';
import Typography from 'components/Typography';

import { useCareTeamMemberInvitation } from 'entities/CareTeam/sdk';

import MemberInvitationActionButtons from 'entities/CareTeam/components/MemberInvitationActionButtons';
import SelectedPatientContextProvider from 'components/SelectedPatientContext';

import {
  PROFILE_ROLES_MAPPER,
  PROFILE_ROLES
} from 'entities/Profile/constants';
import { getRolePrefix } from './utils';

import { getToken } from 'config/storage';
import { REDIRECT_URL_PARAM_KEY } from 'constants/common';
import { LOGIN_URL, REGISTER_URL, ONBOARDING_URL } from 'config/urls';

import logo from 'assets/logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
    overflow: 'auto'
  },
  imageContainer: {
    height: '76px',
    backgroundColor: theme.palette.primary.main
  }
}));

interface ICareTeamMemberInvitationDetail {}

const CareTeamMemberInvitationDetail: React.FC<
  ICareTeamMemberInvitationDetail
> = () => {
  const classes = useStyles();

  const [error, setError] = useState();

  const history = useHistory();
  const {
    careTeamId,
    invitationId
  }: { careTeamId: string; invitationId: string } = useParams();

  const { loading: invitationLoading, data: invitation } =
    useCareTeamMemberInvitation({ careTeamId, invitationId });

  const redirectToLogin = useCallback(() => {
    history.push(
      `${LOGIN_URL}?${REDIRECT_URL_PARAM_KEY}=${window.location.pathname}`
    );
  }, [history]);

  const redirectToRegister = useCallback(() => {
    history.push(
      `${REGISTER_URL}?${REDIRECT_URL_PARAM_KEY}=${window.location.pathname}`
    );
  }, [history]);

  const invitationAccepted = invitation?.accepted;
  const invitationExpired = invitation?.expired;
  const invitationDeclined = invitation?.declined;
  const invitationValid =
    !invitationAccepted && !invitationExpired && !invitationDeclined;

  const userIsLoggedIn = !_.isNil(getToken());

  if (invitationLoading) {
    return <PageLoader />;
  }

  return (
    <div className={classes.root}>
      <Box className={classes.imageContainer} paddingX={2}>
        <Image src={logo} />
      </Box>
      <Container sx={{ height: '100%' }} maxWidth="sm">
        <Stack paddingTop={10} spacing={2}>
          {error && <Alert severity="error">{error}</Alert>}
          {invitationAccepted && (
            <Typography variant="h5">
              This invitation has already been used!
            </Typography>
          )}

          {invitationExpired && (
            <Typography variant="h5">This invitation has expired!</Typography>
          )}

          {invitationDeclined && (
            <Typography variant="h5">
              This invitation has been declined!
            </Typography>
          )}

          {invitationValid && (
            <Box>
              <Typography variant="h5" component="span">
                Hi, you have been invited to join{' '}
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: 'bold' }}
                component="span"
              >
                {invitation?.care_team.patient.profile.full_name}
              </Typography>
              <Typography variant="h5" component="span">
                's care team as {getRolePrefix(invitation?.role || '')}{' '}
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: 'bold' }}
                component="span"
              >
                {
                  PROFILE_ROLES_MAPPER[
                    invitation?.role || PROFILE_ROLES.FAMILY_MEMBER
                  ]
                }
              </Typography>
              <Typography variant="h5" component="span">
                . The invite has been sent by{' '}
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: 'bold' }}
                component="span"
              >
                {invitation?.created_by.profile.full_name}.
              </Typography>
            </Box>
          )}

          {invitationValid && userIsLoggedIn && (
            <SelectedPatientContextProvider>
              <MemberInvitationActionButtons setError={setError} />
            </SelectedPatientContextProvider>
          )}

          {invitationValid && !userIsLoggedIn && (
            <>
              <Typography variant="body1">
                Log in to Carederly to accept this invitation.
              </Typography>
              <Button
                onClick={redirectToLogin}
                data-gtm="button-submit-accept-team-invitation-and-log-in"
              >
                Login
              </Button>
              <Typography variant="body1">
                If you do not have an existing Carederly account, please
                register to accept this invitation.
              </Typography>
              <Button
                variant="outlined"
                onClick={redirectToRegister}
                data-gtm="button-submit-accept-team-invitation-and-create-account"
              >
                Create Account
              </Button>
            </>
          )}

          {userIsLoggedIn && (
            <Button onClick={() => history.push(ONBOARDING_URL)}>
              Back to Home
            </Button>
          )}

          {!userIsLoggedIn && invitationExpired && (
            <Button onClick={redirectToLogin}>Login</Button>
          )}
        </Stack>
      </Container>
    </div>
  );
};

export default CareTeamMemberInvitationDetail;
