import { makeStyles } from '@mui/styles';

import { ICustomVitals } from 'entities/Patient/sdk';

import Grid from 'components/Grid';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import { removeTrailingZeros } from 'utils/common';

const useStyles = makeStyles((theme) => ({
  customVitalsRow: {
    display: 'grid',
    alignItems: 'center',
    gridGap: theme.spacing(1),
    gridTemplateColumns: '125px 13px 1fr'
  },
  doubleCustomVitalsRow: {
    display: 'grid',
    alignItems: 'center',
    gridGap: theme.spacing(1),
    gridTemplateColumns: '125px 1fr'
  },
  doubleCustomVitalsRowData: {
    display: 'grid',
    alignItems: 'center',
    gridGap: theme.spacing(1),
    gridTemplateColumns: '13px 1fr',
    gridColumnStart: 2
  }
}));

const CustomVitalsTable = ({
  customVitals
}: {
  customVitals: ICustomVitals;
}) => {
  const classes = useStyles();

  return (
    <Grid item container spacing={1}>
      <Grid item container className={classes.customVitalsRow}>
        <Grid item>
          <Typography variant="h5">Temperature</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">&lt;</Typography>
        </Grid>
        <Grid item>
          {removeTrailingZeros(customVitals.temperature_max)} °
          {customVitals.temperature_unit}
        </Grid>
      </Grid>

      <Grid item width="100%">
        <Divider />
      </Grid>

      <Grid item container className={classes.doubleCustomVitalsRow}>
        <Grid item gridRow="span 2">
          <Typography variant="h5">Blood pressure</Typography>
        </Grid>

        <Grid item container className={classes.doubleCustomVitalsRowData}>
          <Grid item>
            <Typography variant="h5">&gt;</Typography>
          </Grid>
          <Grid item>
            {removeTrailingZeros(customVitals.blood_pressure_systolic_min)} /{' '}
            {removeTrailingZeros(customVitals.blood_pressure_diastolic_min)}{' '}
            mmHg
          </Grid>
        </Grid>
        <Grid item container className={classes.doubleCustomVitalsRowData}>
          <Grid item>
            <Typography variant="h5">&lt;</Typography>
          </Grid>
          <Grid item>
            {removeTrailingZeros(customVitals.blood_pressure_systolic_max)} /{' '}
            {removeTrailingZeros(customVitals.blood_pressure_diastolic_max)}{' '}
            mmHg
          </Grid>
        </Grid>
      </Grid>

      <Grid item width="100%">
        <Divider />
      </Grid>

      <Grid item container className={classes.doubleCustomVitalsRow}>
        <Grid item gridRow="span 2">
          <Typography variant="h5">Pulse</Typography>
        </Grid>

        <Grid item container className={classes.doubleCustomVitalsRowData}>
          <Grid item>
            <Typography variant="h5">&gt;</Typography>
          </Grid>
          <Grid item>{removeTrailingZeros(customVitals.pulse_min)} bpm</Grid>
        </Grid>
        <Grid item container className={classes.doubleCustomVitalsRowData}>
          <Grid item>
            <Typography variant="h5">&lt;</Typography>
          </Grid>
          <Grid item>{removeTrailingZeros(customVitals.pulse_max)} bpm</Grid>
        </Grid>
      </Grid>

      <Grid item width="100%">
        <Divider />
      </Grid>

      <Grid item container className={classes.customVitalsRow}>
        <Grid item>
          <Typography variant="h5">Blood oxygen</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">&gt;</Typography>
        </Grid>
        <Grid item>{removeTrailingZeros(customVitals.blood_oxygen_min)} %</Grid>
      </Grid>

      <Grid item width="100%">
        <Divider />
      </Grid>

      <Grid item container className={classes.doubleCustomVitalsRow}>
        <Grid item gridRow="span 2">
          <Typography variant="h5">
            Blood sugar <Typography variant="subtitle2">Fasting</Typography>
          </Typography>
        </Grid>

        <Grid item container className={classes.doubleCustomVitalsRowData}>
          <Grid item>
            <Typography variant="h5">&gt;</Typography>
          </Grid>
          <Grid item>
            {removeTrailingZeros(customVitals.blood_sugar_fasting_min)} mg/dL
          </Grid>
        </Grid>
        <Grid item container className={classes.doubleCustomVitalsRowData}>
          <Grid item>
            <Typography variant="h5">&lt;</Typography>
          </Grid>
          <Grid item>
            {removeTrailingZeros(customVitals.blood_sugar_fasting_max)} mg/dL
          </Grid>
        </Grid>
      </Grid>

      <Grid item container className={classes.doubleCustomVitalsRow}>
        <Grid item gridRow="span 2">
          <Typography variant="h5">
            Blood sugar <Typography variant="subtitle2">Non Fasting</Typography>
          </Typography>
        </Grid>

        <Grid item container className={classes.doubleCustomVitalsRowData}>
          <Grid item>
            <Typography variant="h5">&gt;</Typography>
          </Grid>
          <Grid item>
            {removeTrailingZeros(customVitals.blood_sugar_non_fasting_min)}{' '}
            mg/dL
          </Grid>
        </Grid>
        <Grid item container className={classes.doubleCustomVitalsRowData}>
          <Grid item>
            <Typography variant="h5">&lt;</Typography>
          </Grid>
          <Grid item>
            {removeTrailingZeros(customVitals.blood_sugar_non_fasting_max)}{' '}
            mg/dL
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CustomVitalsTable;
