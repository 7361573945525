// ECareTeamUserRole can likely be removed
// no relevant use cases found
export enum ECareTeamUserRole {
  FAMILY_MEMBER = 'Family member',
  AIDE = 'Aide',
  PATIENT = 'Patient'
}

export enum ECareTeamRole {
  FAMILY_MEMBER = 'family_member',
  AIDE = 'aide',
  PATIENT = 'patient'
}
