import { CUSTOM_VITALS_UPDATE_URL, PATIENT_PROFILE_URL } from 'config/urls';
import { useRedirectToOnboarding } from 'utils/hooks';

import Grid from 'components/Grid';
import PageLoader from 'components/PageLoader';
import PageHeader from 'components/PageHeader';
import Typography from 'components/Typography';

import {
  useSelectedPatient,
  usePatientCustomVitals,
  useVitalsToTrack
} from 'entities/Patient/sdk';
import { useSelectedCareTeam } from 'entities/CareTeam/sdk';
import CustomVitalsTable from 'entities/Patient/components/CustomVitalsTable';
import VitalsToTrack from 'entities/Patient/components/VitalsToTrack';

const CustomVitals = () => {
  useRedirectToOnboarding();

  const { patient } = useSelectedPatient();
  const { data: customVitals, loading: areCustomVitalsLoading } =
    usePatientCustomVitals({
      patientId: patient?.id
    });

  const { data: vitalsToTrack, loading: areVitalsToTrackLoading } =
    useVitalsToTrack({ patientId: patient?.id });

  const { userIsPatient, userIsFamilyMember } = useSelectedCareTeam();

  const isLoading = areCustomVitalsLoading || areVitalsToTrackLoading;

  return (
    <Grid container gap={2} direction="column">
      <Grid item>
        <PageHeader
          heading="Custom Vitals"
          backTo={PATIENT_PROFILE_URL}
          updateTo={
            userIsPatient || userIsFamilyMember
              ? CUSTOM_VITALS_UPDATE_URL
              : undefined
          }
        />
      </Grid>

      <Grid item>
        <Typography variant="subtitle2">
          Please customize these ranges to tell us what is normal for you.
        </Typography>
      </Grid>

      <Grid item>
        {isLoading ? (
          <PageLoader />
        ) : (
          <>
            {customVitals && <CustomVitalsTable customVitals={customVitals} />}
            {vitalsToTrack && <VitalsToTrack vitals={vitalsToTrack} />}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default CustomVitals;
