import { IGeneralNoteFormValues } from 'entities/Diary/sdk';

import DiaryNoteFormDialog from 'entities/Diary/components/GeneralNoteFormDialog';

export interface IGeneralNoteCreateDialog {
  onDiaryNoteCreate: ({
    diaryNote
  }: {
    diaryNote: IGeneralNoteFormValues;
  }) => Promise<void>;
  onClose: () => void;
  selectedDate: string;
  initialFormValues?: IGeneralNoteFormValues;
}

const GeneralNoteCreateDialog = ({
  onClose,
  onDiaryNoteCreate,
  selectedDate,
  initialFormValues
}: IGeneralNoteCreateDialog) => {
  const initialValues: IGeneralNoteFormValues = {
    subject: '',
    text: '',
    schedule_date: selectedDate
  };

  return (
    <DiaryNoteFormDialog
      open
      title="Add Note"
      onClose={onClose}
      initialValues={initialFormValues || initialValues}
      onDiaryNoteSubmit={onDiaryNoteCreate}
    />
  );
};

export default GeneralNoteCreateDialog;
