import Stack from 'components/Stack';
import PageLoader from 'components/PageLoader';
import Typography from 'components/Typography';

import { useRedirectToOnboarding } from 'utils/hooks';
import { useSignedUser } from 'entities/Auth/sdk';
import { useProfile } from 'entities/Profile/sdk';

import HomeDashboard from './components/HomeDashboard';

interface IHome {}

const Home: React.FC<IHome> = () => {
  useRedirectToOnboarding();

  const { data: user, loading: userLoading } = useSignedUser();
  const { data: profile, loading: profileLoading } = useProfile({
    profileId: user?.profile.id
  });

  const loading = userLoading || profileLoading;

  return (
    <Stack>
      {loading && <PageLoader />}

      {!loading && profile && (
        <>
          <Stack paddingTop={3} paddingBottom={5}>
            <Typography variant="h1">Hi {profile.first_name}!</Typography>
          </Stack>
          <HomeDashboard />
        </>
      )}
    </Stack>
  );
};

export default Home;
