import * as yup from 'yup';

export const VALIDATION_SCHEMA = yup.object().shape({
  measured_at: yup.string().required('Measurement date is required.'),
  temperature: yup.number().positive('Must be positive.').nullable(),
  blood_pressure_systolic: yup
    .number()
    .nullable()
    .positive('Must be positive.'),
  blood_pressure_diastolic: yup
    .number()
    .nullable()
    .positive('Must be positive.'),
  pulse: yup.number().positive('Must be positive.').nullable(),
  blood_oxygen: yup.number().positive('Must be positive.').nullable(),
  blood_sugar_fasting: yup.number().positive('Must be positive.').nullable(),
  blood_sugar_non_fasting: yup.number().positive('Must be positive.').nullable()
});
