import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import toast from 'utils/toast';
import { PATIENT_PROFILE_BASE_URL } from 'config/urls';
import { useRedirectToOnboarding } from 'utils/hooks';

import Grid from 'components/Grid';
import PageHeader from 'components/PageHeader';

import { usePatient } from 'entities/Patient/sdk';
import ProfileUpdateForm from 'entities/Patient/components/ProfileUpdateForm';
import { profileUpdate, IProfileUpdateFormValues } from 'entities/Profile/sdk';

const PatientProfileUpdate = () => {
  useRedirectToOnboarding();

  const history = useHistory();
  const { careTeamId }: { careTeamId: string } = useParams();

  const { data: patient, refetch: refetchPatient } = usePatient({ careTeamId });

  const profile = patient?.profile;
  const returnUrl = `${PATIENT_PROFILE_BASE_URL}/${careTeamId}`;

  const handleSubmit = useCallback(
    ({
      profileId,
      profile,
      imageId
    }: {
      profileId: string;
      profile: IProfileUpdateFormValues;
      imageId?: string;
    }) =>
      profileUpdate({
        profileId,
        profile,
        imageId
      }).then(() => {
        refetchPatient(); // So that we can refetch the patient information in the top navigation
        history.push(returnUrl);
        toast.info({ content: 'Profile updated!' });
      }),
    [refetchPatient, history, returnUrl]
  );

  return (
    <Grid container direction="column" gap={2}>
      <Grid item>
        <PageHeader heading="Edit Patient Profile" backTo={returnUrl} />
      </Grid>

      {profile && (
        <Grid item>
          <ProfileUpdateForm profile={profile} onSubmit={handleSubmit} />
        </Grid>
      )}
    </Grid>
  );
};
export default PatientProfileUpdate;
