import { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { EditOutlinedIcon } from 'icons';

import Loading from 'components/Loading';
import Dropzone from 'components/Dropzone';
import Avatar, { IAvatar } from 'components/Avatar';
import { imageUpload } from 'entities/Files/sdk';

import { EAcceptedFileTypes } from 'constants/common';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'inline-block'
  },
  avatar: {
    opacity: 0.6,
    cursor: 'pointer'
  },
  editIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

interface IAvatarDropzone {
  avatar: IAvatar['src'];
  width?: number;
  height?: number;
  onAvatarUploaded?: ({ imageId }: { imageId: string }) => void;
}

const AvatarDropzone: React.FC<IAvatarDropzone> = ({
  avatar,
  onAvatarUploaded = () => {},
  width = 82,
  height = 82
}) => {
  const classes = useStyles();
  const [image, setImage] = useState<IAvatar['src']>(avatar);
  const [imageLoading, setImageLoading] = useState<boolean>(false);

  const handleAddedFile = useCallback(
    (files: File[]) => {
      const file = _.head(files);
      if (file) {
        setImageLoading(true);
        imageUpload({ image: file }).then(({ imageId }) => {
          setImage(URL.createObjectURL(file));
          onAvatarUploaded({ imageId });
          setImageLoading(false);
        });
      }
    },
    [onAvatarUploaded]
  );

  useEffect(() => {
    setImage(avatar);
  }, [avatar]);

  return (
    <Dropzone
      onAddedFile={handleAddedFile}
      acceptedFileTypes={[EAcceptedFileTypes.IMAGE]}
      className={classes.root}
    >
      {imageLoading ? (
        <Loading />
      ) : (
        <Avatar sx={{ width, height }} src={image} className={classes.avatar} />
      )}
      <EditOutlinedIcon className={classes.editIcon} />
    </Dropzone>
  );
};

export default AvatarDropzone;
