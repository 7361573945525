import MuiTextField, {
  FilledTextFieldProps,
  OutlinedTextFieldProps
} from '@mui/material/TextField';

export interface IOutlinedTextField
  extends Omit<OutlinedTextFieldProps, 'variant'> {}

export interface IFilledTextField
  extends Omit<FilledTextFieldProps, 'variant'> {}

const OutlinedTextField: React.FC<IOutlinedTextField> = (props) => {
  return <MuiTextField {...props} variant="outlined" />;
};

const FilledTextField: React.FC<IFilledTextField> = (props) => {
  return <MuiTextField {...props} variant="filled" />;
};

const TextField = {
  Filled: FilledTextField,
  Outlined: OutlinedTextField
};

export default TextField;
