import _ from 'lodash';
import { useState, useCallback } from 'react';

import { PlaylistAddIcon } from 'icons';

import toast from 'utils/toast';
import { datetime } from 'utils/datetime';
import { HOME_URL } from 'config/urls';
import { useRedirectToOnboarding } from 'utils/hooks';

import Grid from 'components/Grid';
import PageHeader from 'components/PageHeader';
import Typography from 'components/Typography';
import BlankState from 'components/BlankState';
import BottomFabAdd from 'components/BottomFabAdd';
import ConfirmationDialog from 'components/ConfirmationDialog';

import {
  ICarePlanItem,
  carePlanItemDelete,
  carePlanItemCreate,
  carePlanItemUpdate,
  ICarePlanItemValues,
  useCarePlanItemCategories,
  usePatientCarePlanItemList
} from 'entities/CarePlan/sdk';
import { useSelectedPatient } from 'entities/Patient/sdk';
import { useSelectedCareTeam } from 'entities/CareTeam/sdk';
import CarePlanItemsAccordionList from 'entities/CarePlan/components/CarePlanItemsAccordionList';
import CarePlanItemCreateDialog from 'entities/CarePlan/components/CarePlanItemCreateDialog';
import CarePlanItemUpdateDialog from 'entities/CarePlan/components/CarePlanItemUpdateDialog';

import PageLoader from 'components/PageLoader';
import { getTodayDate } from 'utils/datetime';

interface ICarePlan {}

const CarePlan: React.FC<ICarePlan> = () => {
  useRedirectToOnboarding();

  const [isAddCarePlanDialogOpened, setIsAddCarePlanDialogOpened] =
    useState<boolean>(false);

  const [selectedCarePlanItem, setSelectedCarePlanItem] =
    useState<ICarePlanItem | null>(null);

  const [carePlanItemForDeletion, setCarePlanItemForDeletion] =
    useState<ICarePlanItem | null>(null);

  const { patient, loading: isFetchingPatient } = useSelectedPatient();
  const { data: carePlanItemCategories = [] } = useCarePlanItemCategories();

  const {
    careTeam,
    loading: isFetchingCareTeam,
    userIsPatient,
    userIsFamilyMember,
    userIsAide
  } = useSelectedCareTeam();

  const {
    data: plans = [],
    loading: isFetchingCarePlanItems,
    mutate: mutateCarePlanItems
  } = usePatientCarePlanItemList({
    patientId: patient?.id
  });

  const handleCarePlanItemCreate = useCallback(
    ({ carePlanItem }: { carePlanItem: ICarePlanItemValues }) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      return carePlanItemCreate({
        patientId: patient.id,
        carePlanItem
      }).then(() => {
        mutateCarePlanItems();
        setIsAddCarePlanDialogOpened(false);

        toast.info({
          content: 'Repeating task has been created successfully!'
        });
      });
    },
    [patient, mutateCarePlanItems]
  );

  const handleCarePlanItemDelete = async () => {
    if (!patient?.id) {
      return Promise.reject("There's no patient in the care team.");
    }

    if (!carePlanItemForDeletion?.id) {
      return Promise.reject('No item selected for deletion.');
    }

    return carePlanItemDelete({
      itemId: carePlanItemForDeletion.id,
      patientId: patient.id,
      selectedDate: getTodayDate()
    }).then(() => {
      mutateCarePlanItems();
      setCarePlanItemForDeletion(null);
      toast.info({ content: 'Repeating task has been deleted successfully!' });
    });
  };

  const handleCarePlanItemUpdate = useCallback(
    ({ carePlanItem }: { carePlanItem: ICarePlanItemValues }) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      if (!selectedCarePlanItem) {
        return Promise.reject("There's no care plan item selected.");
      }

      return carePlanItemUpdate({
        patientId: patient.id,
        carePlanItemId: selectedCarePlanItem.id,
        carePlanItem
      }).then(() => {
        mutateCarePlanItems();
        setSelectedCarePlanItem(null);
        toast.info({
          content: 'Repeating task has been updated successfully!'
        });
      });
    },
    [patient, mutateCarePlanItems, selectedCarePlanItem]
  );

  const loading =
    isFetchingCarePlanItems || isFetchingPatient || isFetchingCareTeam;

  const hasCareTeam = !isFetchingCareTeam && !_.isNil(careTeam);

  return (
    <>
      <Grid container gap={2} direction="column">
        <Grid item>
          <PageHeader heading="Care Plan" backTo={HOME_URL} />
        </Grid>

        {(userIsPatient || userIsFamilyMember) && (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                Complete a care plan to create recurring tasks in the schedule.
              </Typography>
            </Grid>

            <BottomFabAdd onClick={() => setIsAddCarePlanDialogOpened(true)} />
          </>
        )}

        {loading && <PageLoader />}

        {!loading && patient && _.isEmpty(plans) && userIsAide && (
          <Grid item xs={12}>
            <Typography variant="body1">No repeating tasks</Typography>
          </Grid>
        )}
      </Grid>

      {!loading &&
        hasCareTeam &&
        _.isEmpty(plans) &&
        (userIsPatient || userIsFamilyMember) && (
          <BlankState
            icon={PlaylistAddIcon}
            title="No repeating tasks"
            subtitle="Click on the '+' icon to add an item"
            onClick={() => setIsAddCarePlanDialogOpened(true)}
          />
        )}

      {!loading && hasCareTeam && !_.isEmpty(plans) && (
        <CarePlanItemsAccordionList
          items={plans}
          categories={carePlanItemCategories}
          onCarePlanItemDelete={({ item }) => setCarePlanItemForDeletion(item)}
          onCarePlanItemUpdate={setSelectedCarePlanItem}
        />
      )}

      <CarePlanItemCreateDialog
        categories={carePlanItemCategories}
        open={isAddCarePlanDialogOpened}
        onClose={() => setIsAddCarePlanDialogOpened(false)}
        onCarePlanItemCreate={handleCarePlanItemCreate}
      />

      {selectedCarePlanItem && (
        <CarePlanItemUpdateDialog
          open
          categories={carePlanItemCategories}
          onClose={() => setSelectedCarePlanItem(null)}
          onCarePlanItemUpdate={handleCarePlanItemUpdate}
          carePlanItem={selectedCarePlanItem}
        />
      )}
      <ConfirmationDialog
        open={!_.isNil(carePlanItemForDeletion)}
        title="Are you sure you want to remove this repeating task?"
        primaryButtonColor="error"
        onClose={() => setCarePlanItemForDeletion(null)}
        onConfirm={handleCarePlanItemDelete}
      >
        <Typography variant="subtitle1">
          {carePlanItemForDeletion?.is_added_to_schedule
            ? `The repeating task will be removed from the schedule for all dates which are past ${datetime().format(
                'dddd, MMM DD, YYYY'
              )}.`
            : ''}
        </Typography>
      </ConfirmationDialog>
    </>
  );
};

export default CarePlan;
