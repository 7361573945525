import _ from 'lodash';

import React, { useState } from 'react';

import { makeStyles } from '@mui/styles';

import {
  KeyboardArrowDownIcon,
  DeleteOutlinedIcon,
  EditOutlinedIcon
} from 'icons';

import Grid from 'components/Grid';
import Stack from 'components/Stack';
import Button from 'components/Button';
import Typography from 'components/Typography';
import IconButton from 'components/IconButton';
import Accordion from 'components/Accordion';

import { ICarePlanItem, ICarePlanItemCategory } from 'entities/CarePlan/sdk';
import { useSelectedCareTeam } from 'entities/CareTeam/sdk';

const useStyles = makeStyles(() => ({
  nonUppercasedButton: {
    width: 'auto',
    textTransform: 'none'
  }
}));

export interface ICarePlanItemsAccordionList {
  items: Array<ICarePlanItem>;
  categories: Array<ICarePlanItemCategory>;
  onCarePlanItemDelete: ({ item }: { item: ICarePlanItem }) => void;
  onCarePlanItemUpdate: (arg0: ICarePlanItem) => void;
}
const CarePlanItemsAccordionList: React.FC<ICarePlanItemsAccordionList> = ({
  items,
  categories,
  onCarePlanItemDelete,
  onCarePlanItemUpdate
}) => {
  const classes = useStyles();

  const { userIsPatient, userIsFamilyMember } = useSelectedCareTeam();

  const [expandedItems, setExpandedItems] = useState<Array<string>>([]);

  return (
    <>
      <Grid item container marginBottom={5} justifyContent="flex-end">
        <Grid item>
          <Button
            variant="text"
            className={classes.nonUppercasedButton}
            onClick={() => setExpandedItems(_.map(items, 'id'))}
          >
            <Typography variant="h5">Expand all</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="text"
            className={classes.nonUppercasedButton}
            onClick={() => setExpandedItems([])}
          >
            <Typography variant="h5">Collapse all</Typography>
          </Button>
        </Grid>
      </Grid>

      <Grid item container justifyContent="center">
        <Grid item width="100%">
          {items.map((item: ICarePlanItem) => (
            <Accordion
              square
              key={item.id}
              expanded={_.includes(expandedItems, item.id)}
              onChange={() => setExpandedItems(_.xor(expandedItems, [item.id]))}
            >
              <Accordion.Summary expandIcon={<KeyboardArrowDownIcon />}>
                <Stack direction="row" alignItems="center" gap={1}>
                  {item.custom_category ||
                    _.find(categories, {
                      value: item.category
                    })?.label}

                  {item.is_deleted && (
                    <Typography color="error" variant="subtitle2">
                      (Deleted)
                    </Typography>
                  )}
                </Stack>
              </Accordion.Summary>
              <Accordion.Details>
                {item.description}
                {(userIsPatient || userIsFamilyMember) && (
                  <Grid
                    container
                    spacing={2}
                    marginTop={2}
                    justifyContent="flex-end"
                  >
                    <Grid item>
                      <IconButton
                        size="small"
                        disabled={item.is_deleted}
                        onClick={() => onCarePlanItemUpdate(item)}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        disabled={item.is_deleted}
                        onClick={() => onCarePlanItemDelete({ item })}
                      >
                        <DeleteOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Accordion.Details>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default CarePlanItemsAccordionList;
