import { useMemo } from 'react';

import HealthTrendsChart from 'entities/HealthTrends/components/Chart';
import { useSelectedPatient } from 'entities/Patient/sdk';

import { datetime } from 'utils/datetime';
import Typography from 'components/Typography';
import Link from 'components/Link';
import { CUSTOM_VITALS_URL, HEALTH_TRENDS_URL } from 'config/urls';
import { REDIRECT_URL_PARAM_KEY } from 'constants/common';

import { useChartLines, useChartData } from './utils';

interface IHealthTrendsChartWrapper {
  selectedVital: string;
  resolution: string;
}

const HealthTrendsChartWrapper: React.FC<IHealthTrendsChartWrapper> = ({
  selectedVital,
  resolution
}) => {
  const { patient } = useSelectedPatient();

  const { chartData, startDateTime, endDateTime } = useChartData({
    selectedVital,
    patientId: patient?.id,
    resolution
  });

  const chartLines = useChartLines({
    patientId: patient?.id,
    selectedVital,
    data: chartData
  });

  const dateRangeText = useMemo(() => {
    const startDateTimeString = datetime(startDateTime).format('MMM DD');
    const endDateTimeString = datetime(endDateTime).format('MMM DD, YYYY');
    return `${startDateTimeString} - ${endDateTimeString}`;
  }, [startDateTime, endDateTime]);

  return (
    <div>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        {dateRangeText}
      </Typography>
      <Typography variant="subtitle2" sx={{ marginBottom: 2 }}>
        You can alter the normal range band(s) in the graph from<span> </span>
        <Link
          to={`${CUSTOM_VITALS_URL}?${REDIRECT_URL_PARAM_KEY}=${HEALTH_TRENDS_URL}`}
        >
          here
        </Link>
        .
      </Typography>
      <HealthTrendsChart
        selectedVital={selectedVital}
        period={[startDateTime, endDateTime]}
        data={{ lines: chartLines, chartData }}
      />
      <Typography variant="subtitle2" sx={{ marginTop: 2, marginBottom: 2 }}>
        The information provided above should not be used to make changes to the
        care recipient's medical management without a doctor’s guidance.
      </Typography>
    </div>
  );
};

export default HealthTrendsChartWrapper;
