import { makeStyles } from '@mui/styles';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';

export interface IAccordion extends AccordionProps {}

const useStyles = makeStyles((theme) => ({
  expandedSummary: {
    color: theme.palette.primary.main
  }
}));

const Accordion = (props: IAccordion) => <MuiAccordion {...props} />;

const AccordionSummary = (props: AccordionSummaryProps) => {
  const classes = useStyles();

  return (
    <MuiAccordionSummary
      classes={{ expanded: classes.expandedSummary, ...props.classes }}
      {...props}
    />
  );
};

Accordion.Summary = AccordionSummary;
Accordion.Details = MuiAccordionDetails;

export default Accordion;
