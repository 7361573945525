import { useState, useEffect, useCallback, createContext } from 'react';

import useIosInstallPrompt from '../useIosInstallPrompt';

export const PWAInstallPromptContext = createContext<{
  promptToInstallApp: () => void;
  canInstallAppWeb: boolean;
  canInstallAppIOS: boolean;
}>({
  promptToInstallApp: () => {},
  canInstallAppWeb: false,
  canInstallAppIOS: false
});

const useInstallPromptEvent = () => {
  const [installPromptEvent, setInstallPromptEvent] = useState<any>();

  useEffect(() => {
    const beforeInstallPromptHandler = (event: any) => {
      event.preventDefault();
      setInstallPromptEvent(event);
    };
    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    return () =>
      window.removeEventListener(
        'beforeinstallprompt',
        beforeInstallPromptHandler
      );
  }, []);

  return installPromptEvent;
};

const PWAInstallPromptProvider: React.FC<{}> = ({ children }) => {
  const installPromptEvent = useInstallPromptEvent();
  const { isIos, isAppInstalled } = useIosInstallPrompt();

  const promptToInstallApp = useCallback(() => {
    if (installPromptEvent) {
      installPromptEvent.prompt();
    }
  }, [installPromptEvent]);

  const canInstallAppWeb = !!installPromptEvent;
  const canInstallAppIOS = isIos && !isAppInstalled;

  return (
    <PWAInstallPromptContext.Provider
      value={{
        promptToInstallApp,
        canInstallAppWeb,
        canInstallAppIOS
      }}
    >
      {children}
    </PWAInstallPromptContext.Provider>
  );
};

export default PWAInstallPromptProvider;
