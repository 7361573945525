import _ from 'lodash';

import { useCallback } from 'react';
import { useFormik, FormikHelpers } from 'formik';

import { GENERIC_FORM_ERRORS_KEY, IFormError } from 'utils/sdk';
import { datetime } from 'utils/datetime';

import {
  BACKEND_DATETIME_FORMAT,
  HTML_INPUT_LOCAL_DATETIME_FORMAT
} from 'constants/time';

import Grid from 'components/Grid';
import Alert from 'components/Alert';
import Button from 'components/Button';

import TextField from 'components/TextField';
import Dialog, { IDialog } from 'components/Dialog';
import DateTimeField from 'components/DateTimeField';

import { ITaskValues } from 'entities/Schedule/sdk';

import { VALIDATION_SCHEMA } from './constants';

export interface ITaskFormDialog extends IDialog {
  onTaskSubmit: ({ task }: { task: ITaskValues }) => Promise<void>;
  initialValues: ITaskValues;
}

const TaskFormDialog = ({
  onClose,
  onTaskSubmit,
  title,
  initialValues,
  ...props
}: ITaskFormDialog) => {
  const handleSubmit = useCallback(
    (values: ITaskValues, formikHelpers: FormikHelpers<ITaskValues>) => {
      return onTaskSubmit({
        task: values
      })
        .then(() => {
          formikHelpers.resetForm();
        })
        .catch(formikHelpers.setErrors);
    },
    [onTaskSubmit]
  );

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validateOnChange: true,
    validationSchema: VALIDATION_SCHEMA
  });

  const formErrors: Array<IFormError> = _.get(
    formik.errors,
    GENERIC_FORM_ERRORS_KEY,
    []
  );

  const handleDialogClose = () => {
    onClose();
    formik.resetForm();
  };

  const onStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEnd = datetime(
      event.target.value,
      HTML_INPUT_LOCAL_DATETIME_FORMAT
    )
      .add(30, 'm')
      .format(BACKEND_DATETIME_FORMAT);

    formik.setFieldValue('end', newEnd);
    formik.setFieldTouched('end', true);
  };

  return (
    <Dialog {...props} onClose={handleDialogClose} title={title}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {!_.isEmpty(formErrors) && (
            <Grid item xs={12}>
              {formErrors.map((error, index) => (
                <Alert key={index} severity="error">
                  {error.message}
                </Alert>
              ))}
            </Grid>
          )}
        </Grid>

        <Grid container flexDirection="column" padding={2} rowGap={2}>
          <Grid item>
            <TextField.Outlined
              fullWidth
              label="Name *"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder="Enter a short appointment or reminder description"
              InputLabelProps={{
                shrink: true
              }}
              error={formik.touched.name && !_.isEmpty(formik.errors.name)}
              helperText={formik.touched.name ? formik.errors.name : ''}
            />
          </Grid>

          <Grid item>
            <TextField.Outlined
              fullWidth
              label="Location"
              name="location"
              value={formik.values.location}
              onChange={formik.handleChange}
              placeholder="Enter an address"
              InputLabelProps={{
                shrink: true
              }}
              error={
                formik.touched.location && !_.isEmpty(formik.errors.location)
              }
              helperText={formik.touched.location ? formik.errors.location : ''}
            />
          </Grid>

          <Grid item>
            <DateTimeField
              name="start"
              label="Start *"
              formik={formik}
              onChange={onStartChange}
            />
          </Grid>

          <Grid item>
            <DateTimeField name="end" label="End *" formik={formik} />
          </Grid>

          <Grid item>
            <TextField.Outlined
              fullWidth
              multiline
              minRows={5}
              name="description"
              label="Description"
              value={formik.values.description}
              onChange={formik.handleChange}
              placeholder="Add details such as documents to be brought, questions to ask or other reminders."
              InputLabelProps={{
                shrink: true
              }}
              error={
                formik.touched.description &&
                !_.isEmpty(formik.errors.description)
              }
              helperText={
                formik.touched.description ? formik.errors.description : ''
              }
            />
          </Grid>

          <Grid item>
            <Button
              type="submit"
              disabled={formik.isSubmitting}
              data-gtm="button-submit-task-create"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default TaskFormDialog;
