import _ from 'lodash';

import { useFormik, FormikHelpers } from 'formik';

import Alert from 'components/Alert';
import Button from 'components/Button';
import Typography from 'components/Typography';
import Grid from 'components/Grid';
import Select from 'components/Select';
import PageLoader from 'components/PageLoader';

import { GENERIC_FORM_ERRORS_KEY, IFormError } from 'utils/sdk';

import {
  IFamilyMemberRoleFormValues,
  useFamilyMemberRoleChoices
} from 'entities/CareTeam/sdk';

import { VALIDATION_SCHEMA } from './constants';

export interface IFamilyMemberRoleForm {
  onSubmit: (
    values: IFamilyMemberRoleFormValues,
    formikHelpers: FormikHelpers<IFamilyMemberRoleFormValues>
  ) => Promise<void>;
  initialValues: IFamilyMemberRoleFormValues;
}

const FamilyMemberRoleForm = ({
  initialValues,
  onSubmit
}: IFamilyMemberRoleForm) => {
  const { data: roleChoices = [], loading: fetchingFamilyMemberChoices } =
    useFamilyMemberRoleChoices();

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: VALIDATION_SCHEMA
  });

  const formErrors: Array<IFormError> = _.get(
    formik.errors,
    GENERIC_FORM_ERRORS_KEY,
    []
  );

  return (
    <>
      {fetchingFamilyMemberChoices ? (
        <Grid>
          <PageLoader />
        </Grid>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column" spacing={3} padding={2}>
            {!_.isEmpty(formErrors) && (
              <Grid item xs={12}>
                {formErrors.map((error, index) => (
                  <Alert key={index} severity="error">
                    {error.message}
                  </Alert>
                ))}
              </Grid>
            )}

            <Grid item>
              <Typography variant="h5">
                Select relationship to loved one
              </Typography>
            </Grid>

            <Grid item>
              <Select
                label=""
                options={roleChoices}
                name="family_member_role"
                value={formik.values.family_member_role}
                onChange={formik.handleChange}
                error={
                  formik.touched.family_member_role &&
                  !_.isEmpty(formik.errors.family_member_role)
                }
                helperText={
                  formik.touched.family_member_role
                    ? formik.errors.family_member_role
                    : ''
                }
              />
            </Grid>

            <Grid item>
              <Button
                type="submit"
                color="primary"
                disabled={formik.isSubmitting}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default FamilyMemberRoleForm;
