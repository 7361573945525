import React from 'react';
import ReactDOM from 'react-dom';
import GA4React from 'ga-4-react';
import App from './App';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import { getItem } from 'config/storage';

const userId = getItem('userId');
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID ?? 'GTM-XXXXXXX',
  dataLayer: {
    userId
  }
};

TagManager.initialize(tagManagerArgs);

const MEASUREMENT_ID =
  process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID ?? 'G-XXXXXXXXXX';
const ga4react = new GA4React(MEASUREMENT_ID);

(async () => {
  // The call to initialize ga4react can fail due to adblocks or other browser extensions
  // If it fails and we don't catch it - the React won't be initialized.
  try {
    await ga4react.initialize();
  } catch (e) {
    console.error(e);
  }

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const result = window.confirm(
      'New version of the app available!  Ready to update?'
    );

    if (result && registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });

      registration.waiting.addEventListener('statechange', (event: any) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/*
Register the service worker again and check for updates if:
* Divice is locked off and then locked on
* If device leaves the app in background for a while and then opens it again
* On desktop - if the user switches tabs and then go back to the app 
*/
document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
    navigator.serviceWorker.register(swUrl).then((reg) => {
      reg.update();
    });
  }
});
