import { makeStyles } from '@mui/styles';
import { colors } from 'theme/palette';
import { useState } from 'react';

import Dialog from 'components/Dialog';
import Typography from 'components/Typography';
import Dropzone from 'components/Dropzone';
import Stack from 'components/Stack';
import Button from 'components/Button';
import Paper from 'components/Paper';
import Loading from 'components/Loading';
import Backdrop from 'components/Backdrop';
import TextField from 'components/TextField';
import IconButton from 'components/IconButton';

import { EAcceptedFileTypes } from 'constants/common';
import { cleanFileName } from 'entities/Documents/utils';

import { PDFIcon, CloseIcon } from 'icons';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '5px'
  },
  fileUploadContainer: {
    display: 'flex',
    backgroundColor: colors.lightestGrey,
    height: '20vh',
    borderColor: colors.mediumLightGrey,
    overflow: 'auto',
    alignItems: 'center'
  },
  messageFormat: {
    color: colors.lightGrey,
    textAlign: 'center',
    padding: '5px'
  },
  imageFormat: {
    maxHeight: '20vh',
    height: '18vh',
    width: '100%',
    padding: '2px'
  },
  buttonUpload: {
    backgroundColor: colors.purpleMain,
    '&:hover': {
      backgroundColor: colors.purpleLightMedium
    },
    '&:disabled': {
      backgroundColor: colors.mediumLightGrey
    }
  },
  buttonCancel: {
    backgroundColor: 'white',
    colors: theme.palette.secondary.dark,
    outlinedSecondary: theme.palette.secondary.dark
  },
  pdfIcon: {
    height: '40px',
    width: 'auto'
  }
}));

interface IFileImageUploadDialog {
  open: boolean;
  message: string;
  instructions: string;
  acceptedFileTypes: Array<EAcceptedFileTypes>;
  showFileNameInput?: boolean;
  onClose: () => void;
  onUpload: (
    files: Array<File>,
    fileName?: string
  ) => Promise<never> | undefined;
}

const FileImageUploadDialog: React.FC<IFileImageUploadDialog> = ({
  open,
  message,
  instructions,
  acceptedFileTypes,
  showFileNameInput,
  onClose,
  onUpload
}) => {
  const classes = useStyles();
  const [uploadedFile, setUploadedFile] = useState<Array<File>>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>();

  const handleAddedFile = (files: Array<File>) => {
    const existingArray = uploadedFile;
    const newArray = existingArray.concat(files);
    setUploadedFile(newArray);
  };

  const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const userInputFileName = event.target.value;
    const cleanUserInputFileName = cleanFileName(userInputFileName).replace(
      '.pdf',
      ''
    );
    setFileName(cleanUserInputFileName);
  };

  const handleDeleteAddedFile = (fileName: string) => {
    const newArray = uploadedFile.filter((file) => file.name !== fileName);
    setUploadedFile(newArray);
  };

  return (
    <Dialog open onClose={onClose} title="Upload a new document">
      {isUploading && (
        <Backdrop
          open={open}
          onClick={() => setIsUploading(false)}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Loading color="primary" />
        </Backdrop>
      )}
      <Stack spacing={2} margin={2}>
        <Stack alignItems="stretch" justifyContent="center">
          <Typography variant="subtitle2" paddingBottom={1}>
            {instructions}
          </Typography>
          <Dropzone
            onAddedFile={handleAddedFile}
            acceptedFileTypes={acceptedFileTypes}
            className={classes.root}
          >
            <Paper className={classes.fileUploadContainer}>
              {uploadedFile.length === 0 ? (
                <Typography
                  variant="h5"
                  className={classes.messageFormat}
                  flexGrow={1}
                >
                  {message}
                </Typography>
              ) : (
                uploadedFile.map((file) => {
                  if (file.type.match('image')) {
                    return (
                      <Stack direction="row" alignItems="start" key={file.name}>
                        <img
                          src={URL.createObjectURL(file)}
                          alt=""
                          className={classes.imageFormat}
                        />
                        <IconButton
                          size="small"
                          onClick={() => handleDeleteAddedFile(file.name)}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Stack>
                    );
                  } else
                    return (
                      <Stack direction="row" alignItems="start" key={file.name}>
                        <Stack direction="column" padding={2}>
                          <PDFIcon className={classes.pdfIcon} />
                          <Typography variant="caption">{file.name}</Typography>
                        </Stack>
                        <IconButton
                          size="small"
                          onClick={() => handleDeleteAddedFile(file.name)}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Stack>
                    );
                })
              )}
            </Paper>
          </Dropzone>
        </Stack>
        {showFileNameInput && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <TextField.Outlined
              label="Document Name"
              placeholder="Enter a document name (optional)"
              fullWidth={true}
              type="text"
              onChange={handleFileNameChange}
            />
            <Typography variant="body1">.pdf</Typography>
          </Stack>
        )}
        <Stack direction="row" display="flex" justifyContent="space-between">
          <Stack alignItems="start">
            <Button
              variant="outlined"
              onClick={onClose}
              className={classes.buttonCancel}
            >
              <Typography variant="body1" color={colors.lightGrey}>
                Cancel
              </Typography>
            </Button>
          </Stack>
          <Stack alignItems="flex-end">
            <Button
              variant="text"
              disabled={isUploading}
              onClick={() => {
                if (uploadedFile.length > 0) {
                  setIsUploading(true);
                  onUpload(uploadedFile, fileName)?.then(() =>
                    setIsUploading(false)
                  );
                }
              }}
              className={classes.buttonUpload}
              data-gtm="button-submit-document-upload"
            >
              <Typography variant="body1" color={colors.white}>
                Upload
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default FileImageUploadDialog;
