import * as yup from 'yup';

export const VALIDATION_SCHEMA = yup.object().shape({
  first_name: yup.string().required('First name is required.'),
  last_name: yup.string().required('Last name is required.'),
  phone: yup
    .string()
    .matches(
      /^\+[1-9]\d{10,14}$/,
      'Invalid phone number. Make sure it matches the +XXXXXXXXXX format.'
    ),
  email: yup.string(), // Email should be required if the profile has a user, but we will handle this later on
  birthday: yup.string().nullable(),
  address: yup.string()
});
