import _ from 'lodash';

import { useCallback, useMemo, useState } from 'react';
import { datetime } from 'utils/datetime';
import { useFormik, FormikHelpers } from 'formik';
import { makeStyles, useTheme } from '@mui/styles';

import { GENERIC_FORM_ERRORS_KEY, IFormError } from 'utils/sdk';

import Grid from 'components/Grid';
import Alert from 'components/Alert';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Switch from 'components/Switch';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import Dialog, { IDialog } from 'components/Dialog';
import FormControlLabel from 'components/FormControlLabel';
import Link from 'components/Link';
import { AddCommentIcon } from 'icons';

import { IScheduleTask } from 'entities/CarePlan/sdk';
import DiaryNoteCreateDialog from 'entities/Diary/components/GeneralNoteCreateDialog';
import RelatedNotesDialog from 'entities/Schedule/components/ScheduleRelatedNotesDialog';
import { useSelectedPatient } from 'entities/Patient/sdk';
import { IGeneralNoteFormValues, generalNoteCreate } from 'entities/Diary/sdk';
import {
  IScheduleItem,
  usePatientScheduleItemList
} from 'entities/Schedule/sdk';

import {
  DATETIME_WITH_PERIOD_FORMAT,
  BACKEND_DATETIME_FORMAT,
  TIME_WITH_PERIOD_FORMAT,
  BACKEND_DATE_FORMAT
} from 'constants/time';

import TaskPeriod from './components/TaskPeriod';

import { VALIDATION_SCHEMA } from './constants';
import toast from 'utils/toast';

const useStyles = makeStyles(() => ({
  switchField: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 0
  }
}));

interface IValues {
  is_completed: boolean;
}

interface IScheduleTaskDetailDialog extends IDialog {
  item: IScheduleTask;
  onTaskCompletionStatusUpdate: (vales: IValues) => Promise<void>;
  onEditClick: () => void;
  onDeleteClick: () => void;
  mutateScheduleItemsInParent: () => void;
}

const ScheduleTaskDetailDialog: React.FC<IScheduleTaskDetailDialog> = ({
  item,
  onTaskCompletionStatusUpdate,
  onEditClick,
  onDeleteClick,
  mutateScheduleItemsInParent,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [isDiaryNoteCreateDialogOpen, setIsDiaryNoteCreateDialogOpen] =
    useState<boolean>(Boolean(false));
  const [isRelatedNotesDialogOpen, setIsRelatedNotesDialogOpen] =
    useState<boolean>(Boolean(false));
  const { patient } = useSelectedPatient();
  const selectedDate = datetime(item.start, BACKEND_DATE_FORMAT).format(
    BACKEND_DATE_FORMAT
  );
  const noteInitialValues: IGeneralNoteFormValues = {
    subject: item.name,
    text: '',
    schedule_date: selectedDate
  };
  const [relatedNoteCount, setRelatedNoteCount] = useState<number>(
    !_.isNil(item.related_notes) ? Object.keys(item.related_notes).length : 0
  );
  const [task, setTask] = useState<IScheduleTask>(item);

  const { loading: isFetchingTasks, refetch: refetchScheduleItems } =
    usePatientScheduleItemList({
      patientId: patient?.id,
      selectedDate
    });

  const setTaskState = useCallback(
    (refetchedScheduleItems: Array<IScheduleItem>) => {
      const updatedTask = _.find(
        refetchedScheduleItems,
        (refetchedScheduleItems) => refetchedScheduleItems.id === item.id
      ) as IScheduleTask;
      setTask(() => updatedTask);
      if (_.isNil(updatedTask.related_notes)) {
        setRelatedNoteCount(() => 0);
        setIsRelatedNotesDialogOpen(false);
      } else {
        setRelatedNoteCount(
          () => Object.keys(updatedTask.related_notes).length
        );
      }
    },
    [item]
  );

  const start = useMemo(
    () =>
      datetime(item.start, BACKEND_DATETIME_FORMAT).format(
        TIME_WITH_PERIOD_FORMAT
      ),
    [item]
  );

  const end = useMemo(
    () =>
      datetime(item.end, BACKEND_DATETIME_FORMAT).format(
        TIME_WITH_PERIOD_FORMAT
      ),
    [item]
  );

  const completedАt = useMemo(
    () =>
      datetime(item.completed_at, BACKEND_DATETIME_FORMAT).format(
        DATETIME_WITH_PERIOD_FORMAT
      ),
    [item]
  );

  const handleSubmit = useCallback(
    (values: IValues, formikHelpers: FormikHelpers<IValues>) =>
      onTaskCompletionStatusUpdate(values).catch(formikHelpers.setErrors),
    [onTaskCompletionStatusUpdate]
  );

  const initialValues: IValues = { is_completed: item.is_completed };
  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA
  });

  const formErrors: Array<IFormError> = _.get(
    formik.errors,
    GENERIC_FORM_ERRORS_KEY,
    []
  );

  const handleDiaryNoteCreate = useCallback(
    ({ diaryNote }: { diaryNote: IGeneralNoteFormValues }) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      return generalNoteCreate({
        patientId: patient.id,
        diaryNote,
        schedule_item_id: task.id,
        schedule_item_type: task.type
      }).then(() => {
        setRelatedNoteCount(() => relatedNoteCount + 1);
        mutateScheduleItemsInParent();
        refetchScheduleItems().then((result) => {
          if (!_.isNil(result)) {
            setTaskState(result);
          }
        });
        setIsDiaryNoteCreateDialogOpen(false);
        toast.info({
          content: 'Note successfully added!'
        });
      });
    },
    [
      patient,
      mutateScheduleItemsInParent,
      refetchScheduleItems,
      setTaskState,
      task,
      relatedNoteCount
    ]
  );

  const onDiaryNoteCreate = () => {
    setIsDiaryNoteCreateDialogOpen(true);
  };

  return (
    <Dialog
      {...props}
      title={item.name}
      asideTitleComponent={
        <TaskPeriod
          start={start}
          end={end}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
        />
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {!_.isEmpty(formErrors) && (
            <Grid item xs={12}>
              {formErrors.map((error, index) => (
                <Alert key={index} severity="error">
                  {error.message}
                </Alert>
              ))}
            </Grid>
          )}
        </Grid>
        <Grid
          container
          padding={2}
          component={Grid}
          alignItems="left"
          display="flex"
          flexDirection="column"
          rowGap={2}
        >
          <Grid item container spacing={1}>
            <Grid item>At: </Grid>
            <Grid item>
              <Typography
                variant="body1"
                color={
                  task.location === ''
                    ? theme.palette.text.disabled
                    : theme.palette.text.primary
                }
              >
                {task.location || 'No location specified'}
              </Typography>
            </Grid>
          </Grid>

          <Divider />

          <Grid item container spacing={1}>
            <Grid item>
              <Typography
                variant="body1"
                color={
                  task.description === ''
                    ? theme.palette.text.disabled
                    : theme.palette.text.primary
                }
              >
                {task.description || 'No details added'}
              </Typography>
            </Grid>
          </Grid>

          <Divider />
          <Grid
            container
            spacing={0.5}
            justifyContent="space-between"
            alignContent="center"
          >
            <Grid item>
              <Typography
                variant="body1"
                color={
                  relatedNoteCount === 0
                    ? theme.palette.text.disabled
                    : theme.palette.text.primary
                }
              >
                {relatedNoteCount === 0 ? (
                  'No notes have been added'
                ) : relatedNoteCount === 1 ? (
                  <Link.Primary
                    to="#"
                    onClick={() => setIsRelatedNotesDialogOpen(true)}
                    variant="body1"
                    underline="none"
                    data-gtm="link-related-notes-view"
                  >
                    1 note has been added
                  </Link.Primary>
                ) : (
                  <Link.Primary
                    to="#"
                    onClick={() => setIsRelatedNotesDialogOpen(true)}
                    variant="body1"
                    underline="none"
                    data-gtm="link-related-notes-view"
                  >
                    {`${relatedNoteCount} notes have been added`}
                  </Link.Primary>
                )}
              </Typography>
            </Grid>
            <Grid item alignItems="flex-end">
              <IconButton
                color="inherit"
                onClick={onDiaryNoteCreate}
                size="small"
                data-gtm="button-submit-relatednotes-create"
              >
                <AddCommentIcon data-gtm="button-submit-relatednotes-create" />
              </IconButton>
            </Grid>
          </Grid>
          {isRelatedNotesDialogOpen &&
            !_.isNil(task.related_notes) &&
            !isFetchingTasks && (
              <RelatedNotesDialog
                open
                onClose={() => setIsRelatedNotesDialogOpen(false)}
                notes={Object.values(task.related_notes)}
                scheduleItemID={task.id}
                scheduleItemName={task.name}
                selectedDate={selectedDate}
                refetchScheduleItemsInParent={refetchScheduleItems}
                updateRelatedNoteCountInParent={setTaskState}
              />
            )}
          {isDiaryNoteCreateDialogOpen && (
            <DiaryNoteCreateDialog
              onClose={() => setIsDiaryNoteCreateDialogOpen(false)}
              onDiaryNoteCreate={handleDiaryNoteCreate}
              selectedDate={selectedDate}
              initialFormValues={noteInitialValues}
            />
          )}
          <Divider />
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={formik.values.is_completed} // use this, as defaultChecked throws a console error
              />
            }
            label="Is this item complete?"
            labelPlacement="start"
            name="is_completed"
            className={classes.switchField}
            value={formik.values.is_completed}
            onChange={formik.handleChange}
          />

          {!_.isNil(item.completed_at) && (
            <Grid item textAlign="end">
              <Typography variant="subtitle2">
                Completed by {task.completed_by} on
              </Typography>
              <Typography variant="subtitle2">{completedАt}</Typography>
            </Grid>
          )}

          <Grid item>
            <Button
              type="submit"
              disabled={formik.isSubmitting}
              data-gtm="button-submit-schedule-task-save"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default ScheduleTaskDetailDialog;
