export const BASE_BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
export const BASE_API_URL = `${BASE_BACKEND_URL}/api`;

export const LOGIN_URL = '/auth/login';
export const REGISTER_URL = '/auth/register';
export const ONBOARDING_URL = '/auth/onboarding';
export const ONBOARDING_STEPPER_URL = '/auth/onboarding/questionnaire';
export const REGISTER_PENDING_URL = '/auth/register/pending';
export const REGISTER_ACTIVATE_URL = '/auth/register/:userId/activate';
export const FORGOT_PASSWORD_URL = '/auth/forgot-password';
export const RESET_PASSWORD_URL = '/auth/reset-password/:token/:email';
export const PASSWORD_RESET_PENDING_URL = '/auth/reset-password/pending';
export const PASSWORD_RESET_SUCCESS_URL = '/auth/reset-password/success';

export const HOME_URL = '/home';

export const MY_CARE_TEAMS_URL = '/my-care-teams';
export const CARE_TEAM_URL = '/care-team';
export const CARE_TEAM_CREATE_URL = '/care-team/create';
export const CARE_TEAM_MEMBER_INVITATION_URL =
  '/care-team/:careTeamId/members/invitation/:invitationId';

export const MESSAGES_URL = '/messages';

export const SCHEDULE_URL = '/schedule';

export const MEDICATIONS_URL = '/medications';
export const MEDICATION_CREATE_URL = '/medications/create';
export const MEDICATION_CREATE_WITH_FREQUENCY_URL = `${MEDICATION_CREATE_URL}/:intakeFrequency`;
export const MEDICATION_UPDATE_URL = '/medications/:medicationId/update';

export const PATIENT_PROFILE_BASE_URL = `${MY_CARE_TEAMS_URL}/patient-profile`;
export const PATIENT_PROFILE_URL = `${PATIENT_PROFILE_BASE_URL}/:careTeamId`;
export const PATIENT_PROFILE_UPDATE_URL = `${PATIENT_PROFILE_URL}/update`;

export const MEDICAL_HISTORY_URL = '/medical-history';
export const CUSTOM_VITALS_URL = '/custom-vitals';
export const CUSTOM_VITALS_UPDATE_URL = '/custom-vitals/update';
export const CARE_PLAN_URL = '/care-plan';
export const NOTIFICATION_PREFERENCES_URL = '/profile/notification-preferences';

export const PROFILE_URL = '/profile';
export const PROFILE_UPDATE_URL = '/profile/update';

export const HEALTH_DIARY_URL = '/health-diary';

export const VITALS_TO_TRACK_UPDATE_URL = '/vitals-to-track/update';

export const HEALTH_TRENDS_URL = '/health-trends';
export const DOCUMENTS_URL = '/documents';

export const SETTINGS_URL = '/settings';
