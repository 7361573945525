import _ from 'lodash';
import { useMemo } from 'react';

import { useFetch, post } from 'utils/sdk';
import { BASE_API_URL } from 'config/urls';

import { queryStringStringify } from 'utils/common';
import { localToUtc, datetime, utcToLocal } from 'utils/datetime';

import {
  EMedicationIntakeFrequency,
  EMedicationRelationToMeals,
  EMedicationDosageTaken
} from './constants';

import {
  transformWeeklyMedicationIntakeTimesUtcToLocal,
  transformDailyMedicationIntakeTimesUtcToLocal,
  convertMedicationRepeatAfterAndRepeatBeforeFromLocalToUtc
} from 'entities/Medications/utils';

import {
  BACKEND_DATETIME_FORMAT,
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT
} from 'constants/time';

export interface IMedicationCreateFormValues {
  name: string;
  quantity: number | null;
  relation_to_meals: EMedicationRelationToMeals;
  repeat_startdatetime: string;
  repeat_enddatetime: string | null;
  intake_days: Array<string>;
  intake_times: Array<string>;
  intake_frequency: EMedicationIntakeFrequency;
  description: string;
}

export interface IMedicationUpdateFormValues {
  name: string;
  quantity: number | null;
  relation_to_meals: EMedicationRelationToMeals;
  repeat_startdatetime: string;
  repeat_enddatetime: string | null;
  intake_days: Array<string>;
  intake_frequency: EMedicationIntakeFrequency;
  intake_times: Array<string>;
  description: string;
}

export interface IMedicationUpdateFormattedFormValues
  extends Omit<IMedicationUpdateFormValues, 'intake_times'> {
  intake_times: Array<IMedicationUpdateIntakeTime>;
}

export interface IMedicationUpdateIntakeTime {
  id?: string;
  time: string;
  day_of_the_week?: string;
}

export interface IMedicationIntakeTime {
  id: string;
  time: string;
  day_of_the_week?: string;
}

export interface IMedication {
  id: string;
  name: string;
  quantity: number | null;
  relation_to_meals: EMedicationRelationToMeals;
  description: string;
  repeat_startdatetime: string;
  repeat_enddatetime: string;
  intake_frequency: EMedicationIntakeFrequency;
  intake_times: Array<IMedicationIntakeTime>;
  is_deleted: boolean;
}

export const useMedicationsNames = ({ name }: { name: string | null }) =>
  useFetch<Array<string>>(
    name
      ? `${BASE_API_URL}/medications/search/?${queryStringStringify({
          data: { name }
        })}`
      : name,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

export const medicationCreate = ({
  patientId,
  medication
}: {
  patientId: string;
  medication: IMedicationCreateFormValues;
}) => {
  let intakeTimes: any = [];

  // Transform intake times to UTC
  if (medication.intake_frequency === EMedicationIntakeFrequency.DAILY) {
    const repeatAfterDatetime = datetime(
      medication.repeat_startdatetime,
      BACKEND_DATE_FORMAT,
      true
    );

    medication.intake_times.map((intakeTime) => {
      const time = datetime(intakeTime, BACKEND_TIME_FORMAT, true);
      const intakeTimeDatetime = localToUtc(
        repeatAfterDatetime.hour(time.hour()).minute(time.minute())
      );

      return intakeTimes.push({
        time: intakeTimeDatetime.format(BACKEND_TIME_FORMAT)
      });
    });
  }

  if (medication.intake_frequency === EMedicationIntakeFrequency.WEEKLY) {
    const nextSevenDays: Array<any> = [];
    const cursorStart = datetime(
      medication.repeat_startdatetime,
      BACKEND_DATE_FORMAT,
      true
    );
    let cursorEnd = datetime(
      medication.repeat_enddatetime,
      BACKEND_DATE_FORMAT,
      true
    );
    if (!cursorEnd.isValid()) {
      // If the user has not selected repeat before
      // we need to take a one week period from the selected
      // repeat after.
      cursorEnd = cursorStart.add(7, 'days');
    }

    let cursorDate = cursorStart;

    while (
      (cursorDate.isBefore(cursorEnd, 'day') ||
        cursorDate.isSame(cursorEnd, 'day')) &&
      cursorDate.isBefore(cursorStart.add(7, 'days'), 'day')
    ) {
      /* eslint-disable */
      medication.intake_times.map((intakeTime) => {
        const time = datetime(intakeTime, 'HH:mm:ss', true);
        nextSevenDays.push(cursorDate.hour(time.hour()).minute(time.minute()));
      });
      cursorDate = cursorDate.add(1, 'days');
    }

    intakeTimes = nextSevenDays
      .filter((day) =>
        medication.intake_days.includes(day.format('dddd').toLowerCase())
      )
      .map(localToUtc)
      .map((x) => ({
        time: x.format('HH:mm:ss'),
        day_of_the_week: x.format('dddd').toLowerCase()
      }));

    if (_.isEmpty(intakeTimes)) {
      return Promise.reject({
        errors: [
          {
            message:
              'No possible intake was found in the interval between start and end date.'
          }
        ]
      });
    }
  }

  // If the medication is forbidden or as needed we calculate the repeat after & repeat before by converting them from local to UTC.
  let repeatAfter = localToUtc(
    datetime(medication.repeat_startdatetime, BACKEND_DATE_FORMAT)
  );
  let repeatBefore = medication.repeat_enddatetime
    ? localToUtc(datetime(medication.repeat_enddatetime, BACKEND_DATE_FORMAT))
    : null;

  if (
    [
      EMedicationIntakeFrequency.WEEKLY,
      EMedicationIntakeFrequency.DAILY
    ].includes(medication.intake_frequency)
  ) {
    // If the medication is daily or weekly we have a special logic to calculate the repeat after/repeat before
    const convertedRepeatBeforeAndAfter =
      convertMedicationRepeatAfterAndRepeatBeforeFromLocalToUtc({
        medication,
        intakeTimes: medication.intake_times
      });

    repeatAfter = convertedRepeatBeforeAndAfter.repeatAfter;
    repeatBefore = convertedRepeatBeforeAndAfter.repeatBefore;
  }

  const data = {
    ...medication,
    repeat_startdatetime: repeatAfter,
    repeat_enddatetime: repeatBefore,
    intake_days: undefined, // Erase intake_days from "medication"
    intake_times: intakeTimes
  };

  return post(
    `${BASE_API_URL}/medications/patient/${patientId}/create/`,
    data
  );
};
export const medicationUpdate = ({
  patientId,
  medicationId,
  medication
}: {
  patientId: string;
  medicationId: string;
  medication: IMedicationUpdateFormattedFormValues;
}) => {
  let intakeTimes: any = [];

  // Transform intake times to UTC
  if (medication.intake_frequency === EMedicationIntakeFrequency.DAILY) {
    /*
      - If we change an existing time, we should send intake_times in this format:

    intake_times: [{id: undefined, time: X}]

    - If we add a new time and there is an existing one, we should send intake_times in this format:

    intake_times: [{id: existing_time_id, time: X}, {id: undefined, time: Y}]
    */

    const repeatAfterDatetime = datetime(
      medication.repeat_startdatetime,
      'YYYY-MM-DD',
      true
    );

    medication.intake_times.map((intakeTime) => {
      const time = datetime(intakeTime.time, 'HH:mm:ss', true);

      const intakeTimeDatetime = localToUtc(
        repeatAfterDatetime.hour(time.hour()).minute(time.minute())
      );

      return intakeTimes.push({
        id: intakeTime?.id,
        time: intakeTimeDatetime.format('HH:mm:ss')
      });
    });
  }

  if (medication.intake_frequency === EMedicationIntakeFrequency.WEEKLY) {
    /*
    - If we change an existing time, we should send intake_times in this format:

    intake_times: [{id: undefined, time:X, day_of_week: Y}]

    - If we add a new time and there is an existing one, we should send intake_times in this format:

    intake_times: [{id: existing_time_id, time: X, day_of_week: Y}, {id: undefined, time: Z, day_of_week: T}
    */
    const intakeTimesForNextSevenDays: Array<any> = [];
    const cursorStart = datetime(
      medication.repeat_startdatetime,
      BACKEND_DATE_FORMAT,
      true
    );
    let cursorEnd = datetime(
      medication.repeat_enddatetime,
      BACKEND_DATE_FORMAT,
      true
    );
    if (!cursorEnd.isValid()) {
      // If the user has not selected repeat before
      // we need to take a one week period from the selected
      // repeat after.
      cursorEnd = cursorStart.add(7, 'days');
    }
    let cursorDate = cursorStart;

    while (
      (cursorDate.isBefore(cursorEnd, 'day') ||
        cursorDate.isSame(cursorEnd, 'day')) &&
      cursorDate.isBefore(cursorStart.add(7, 'days'), 'day')
    ) {
      /* eslint-disable */
      medication.intake_times.map((intakeTime) => {
        const time = datetime(intakeTime.time, 'HH:mm:ss', true);
        return intakeTimesForNextSevenDays.push({
          id: intakeTime?.id,
          time: cursorDate.hour(time.hour()).minute(time.minute())
        });
      });
      cursorDate = cursorDate.add(1, 'days');
    }

    intakeTimes = intakeTimesForNextSevenDays
      .filter((intakeTime) =>
        medication.intake_days.includes(
          intakeTime.time.format('dddd').toLowerCase()
        )
      )
      .map((intakeTime) => ({
        ...intakeTime,
        id: !_.isEmpty(
          medication.intake_times.filter(
            (x) =>
              x.day_of_the_week === intakeTime.time.format('dddd').toLowerCase()
          )
        )
          ? intakeTime.id
          : undefined,
        time: localToUtc(intakeTime.time)
      }))
      .map((intakeTime) => ({
        id: intakeTime.id,
        time: intakeTime.time.format('HH:mm:ss'),
        day_of_the_week: intakeTime.time.format('dddd').toLowerCase()
      }));

    if (_.isEmpty(intakeTimes)) {
      return Promise.reject({
        errors: [
          {
            message:
              'No possible intake was found in the interval between start and end date.'
          }
        ]
      });
    }
  }

  const times = _.map(medication.intake_times, 'time');

  // If the medication is forbidden or as needed we calculate the repeat after & repeat before by converting them from local to UTC.
  let repeatAfter = localToUtc(
    datetime(medication.repeat_startdatetime, BACKEND_DATE_FORMAT)
  );

  let repeatBefore = medication.repeat_enddatetime
    ? localToUtc(datetime(medication.repeat_startdatetime, BACKEND_DATE_FORMAT))
    : null;

  if (
    [
      EMedicationIntakeFrequency.WEEKLY,
      EMedicationIntakeFrequency.DAILY
    ].includes(medication.intake_frequency)
  ) {
    // If the medication is daily or weekly we have a special logic to calculate the repeat after/repeat before
    const convertedRepeatBeforeAndAfter =
      convertMedicationRepeatAfterAndRepeatBeforeFromLocalToUtc({
        medication,
        intakeTimes: times
      });

    repeatAfter = convertedRepeatBeforeAndAfter.repeatAfter;
    repeatBefore = convertedRepeatBeforeAndAfter.repeatBefore;
  }

  const data = {
    ...medication,
    repeat_startdatetime: repeatAfter,
    repeat_enddatetime: repeatBefore,
    intake_days: undefined, // Erase intake_days from "medication"
    intake_times: intakeTimes
  };

  return post(
    `${BASE_API_URL}/medications/patient/${patientId}/medication/${medicationId}/update/`,
    data
  );
};

export const medicationDelete = ({
  medication,
  patientId,
  selectedDate
}: {
  medication: IMedication;
  selectedDate: string;
  patientId: string;
}) => {
  let postData = {};

  if (
    [
      EMedicationIntakeFrequency.DAILY,
      EMedicationIntakeFrequency.WEEKLY
    ].includes(medication.intake_frequency)
  ) {
    const times = _.map(medication.intake_times, 'time');

    const sortedTimes = times.sort((prev, next) => {
      const prevTime = datetime(prev, BACKEND_TIME_FORMAT, true);
      const nextTime = datetime(next, BACKEND_TIME_FORMAT, true);

      return prevTime.isAfter(nextTime) ? 1 : -1;
    });

    // Taking medication last intake time for the selected date, so we can delete all medications after it.
    const latestTime = datetime(_.last(sortedTimes), BACKEND_TIME_FORMAT, true);

    const selectedDatetime = datetime(selectedDate, BACKEND_DATE_FORMAT, true)
      .hour(latestTime.hour())
      .minute(latestTime.minute());

    // We convert the person's date to a UTC date as sometimes he is a day forward/back compared to UTC.
    postData = {
      selected_datetime: localToUtc(selectedDatetime).format(
        BACKEND_DATETIME_FORMAT
      )
    };
  } else {
    const selectedDatetime = datetime(selectedDate, BACKEND_DATE_FORMAT, true)
      .hour(23)
      .minute(59);

    postData = {
      selected_datetime: localToUtc(selectedDatetime).format(
        BACKEND_DATETIME_FORMAT
      )
    };
  }

  return post(
    `${BASE_API_URL}/medications/patient/${patientId}/medication/${medication.id}/delete/`,
    postData
  );
};

export const useMedication = ({
  patientId,
  medicationId
}: {
  patientId?: string;
  medicationId?: string;
}) => {
  const result = useFetch<IMedication>(
    medicationId && patientId
      ? `${BASE_API_URL}/medications/patient/${patientId}/medication/${medicationId}/retrieve/`
      : null
  );

  const medication = result.data;

  let transformedData: any = null;

  // Transform medication.intake_time.time from utc to local
  if (medication?.intake_frequency === EMedicationIntakeFrequency.DAILY) {
    const intakeTimes = transformDailyMedicationIntakeTimesUtcToLocal({
      intakeTimes: medication.intake_times
    });

    const repeatAfter = utcToLocal(
      datetime.utc(medication.repeat_startdatetime, BACKEND_DATETIME_FORMAT)
    );

    const repeatBefore = medication.repeat_enddatetime
      ? utcToLocal(
          datetime.utc(medication.repeat_enddatetime, BACKEND_DATETIME_FORMAT)
        )
      : null;

    transformedData = {
      ...result.data,
      repeat_startdatetime: repeatAfter,
      repeat_enddatetime: repeatBefore,
      intake_times: intakeTimes
    };
  }

  // Transform medication.intake_time.time and medication.intake_time.day_of_the_week from utc to local
  else if (medication?.intake_frequency === EMedicationIntakeFrequency.WEEKLY) {
    const intakeTimes = transformWeeklyMedicationIntakeTimesUtcToLocal({
      intakeTimes: medication.intake_times,
      startMoment: medication.repeat_startdatetime
    });

    const repeatAfter = utcToLocal(
      datetime.utc(medication.repeat_startdatetime, BACKEND_DATETIME_FORMAT)
    );

    const repeatBefore = medication.repeat_enddatetime
      ? utcToLocal(
          datetime.utc(medication.repeat_enddatetime, BACKEND_DATETIME_FORMAT)
        )
      : null;

    transformedData = {
      ...medication,
      repeat_startdatetime: repeatAfter,
      repeat_enddatetime: repeatBefore,
      intake_times: intakeTimes
    };
  }

  // For the forbidden & as needed medications we only transform the repeat before & repeat after from utc to local.
  else if (
    medication &&
    [
      EMedicationIntakeFrequency.FORBIDDEN,
      EMedicationIntakeFrequency.AS_NEEDED
    ].includes(medication.intake_frequency)
  ) {
    const repeatAfter = utcToLocal(
      datetime.utc(medication.repeat_startdatetime, BACKEND_DATETIME_FORMAT)
    );

    const repeatBefore = medication.repeat_enddatetime
      ? utcToLocal(
          datetime.utc(medication.repeat_enddatetime, BACKEND_DATETIME_FORMAT)
        )
      : null;

    transformedData = {
      ...medication,
      repeat_startdatetime: repeatAfter,
      repeat_enddatetime: repeatBefore
    };
  }

  return useMemo(
    () => ({
      ...result,
      data: transformedData
    }),
    [result]
  );
};

export const useMedications = ({
  patientId,
  intakeFrequency
}: {
  patientId?: string;
  intakeFrequency: EMedicationIntakeFrequency;
}) => {
  const result = useFetch<Array<IMedication>>(
    patientId
      ? `${BASE_API_URL}/medications/patient/${patientId}/list/?intake_frequency=${intakeFrequency}`
      : null
  );

  const medications = result.data || [];

  const dailyMedications: Array<IMedication> = medications.filter(
    (medication) =>
      medication.intake_frequency === EMedicationIntakeFrequency.DAILY
  );

  // Transform medication.intake_time.time from utc to local
  const transformedDailyMedications: Array<IMedication> = dailyMedications.map(
    (medication: IMedication) => ({
      ...medication,
      intake_times: transformDailyMedicationIntakeTimesUtcToLocal({
        intakeTimes: medication.intake_times
      })
    })
  );

  const weeklyMedications: Array<IMedication> = medications.filter(
    (medication) =>
      medication.intake_frequency === EMedicationIntakeFrequency.WEEKLY
  );

  // Transform medication.intake_time.time and medication.intake_time.day_of_the_week from utc to local
  const transformedWeeklyMedications: Array<IMedication> =
    weeklyMedications.map((medication: IMedication) => ({
      ...medication,
      intake_times: transformWeeklyMedicationIntakeTimesUtcToLocal({
        intakeTimes: medication.intake_times,
        startMoment: medication.repeat_startdatetime
      })
    }));

  const forbiddenMedications = medications.filter(
    (medication) =>
      medication.intake_frequency === EMedicationIntakeFrequency.FORBIDDEN
  );
  const asNeededMedications = medications.filter(
    (medication) =>
      medication.intake_frequency === EMedicationIntakeFrequency.AS_NEEDED
  );

  const transformedData = _.concat(
    transformedDailyMedications,
    transformedWeeklyMedications,
    forbiddenMedications,
    asNeededMedications
  );

  return useMemo(
    () => ({
      ...result,
      data: transformedData
    }),
    [result]
  );
};

export const useMedicationsWithIntakeFrequencies = ({
  patientId
}: {
  patientId?: string;
}) => {
  const {
    data: asNeededMedications = [],
    loading: isFetchingAsNeededMedications,
    mutate: mutateAsNeededMedications
  } = useMedications({
    patientId,
    intakeFrequency: EMedicationIntakeFrequency.AS_NEEDED
  });
  const {
    data: dailyMedications = [],
    loading: isFetchingDailyMedications,
    mutate: mutateDailyMedications
  } = useMedications({
    patientId,
    intakeFrequency: EMedicationIntakeFrequency.DAILY
  });
  const {
    data: weeklyMedications = [],
    loading: isFetchingWeeklyMedications,
    mutate: mutateWeeklyMedications
  } = useMedications({
    patientId,
    intakeFrequency: EMedicationIntakeFrequency.WEEKLY
  });
  const {
    data: forbiddenMedications = [],
    loading: isFetchingForbiddenMedications,
    mutate: mutateForbiddenMedications
  } = useMedications({
    patientId,
    intakeFrequency: EMedicationIntakeFrequency.FORBIDDEN
  });

  const isFetchingMedications =
    isFetchingAsNeededMedications ||
    isFetchingDailyMedications ||
    isFetchingWeeklyMedications ||
    isFetchingForbiddenMedications;

  const mutateMedications = () => {
    mutateAsNeededMedications();
    mutateDailyMedications();
    mutateWeeklyMedications();
    mutateForbiddenMedications();
  };

  return {
    asNeededMedications,
    dailyMedications,
    weeklyMedications,
    forbiddenMedications,
    isFetchingMedications,
    mutateMedications
  };
};

// Medication actions
export const medicationCompletionStatusUpdate = ({
  patientId,
  medicationId,
  medicationIntakeTimeId,
  selectedDate,
  dosageTaken,
  additionalNote,
  timeOfIntakeTime
}: {
  patientId: string;
  medicationId: string;
  medicationIntakeTimeId: string;
  selectedDate: string;
  dosageTaken: EMedicationDosageTaken;
  additionalNote?: string;
  timeOfIntakeTime: string;
}) => {
  /*
  We send the scheduled_at in utc to the server, having in mind the time of the intake time.
  If we complete a medication with intake time 1AM EST on 10.01, we should send scheduled_at as 23:00 PM UTC, 09.01.
  If we complete a medication for a future/past date (which is currently allowed), we should be aware for which intake time the medication is completed for.
  */
  const time = datetime(timeOfIntakeTime, 'HH:mm:ss', true);

  const scheduledAt = datetime(selectedDate, BACKEND_DATE_FORMAT)
    .hour(time.hour())
    .minute(time.minute());

  const data = {
    scheduled_at: localToUtc(scheduledAt).format(BACKEND_DATETIME_FORMAT),
    dosage_taken: dosageTaken,
    additional_note: additionalNote
  };

  return post<undefined>(
    `${BASE_API_URL}/medications/patient/${patientId}/schedule/medication/${medicationId}/${medicationIntakeTimeId}/update-completion/`,
    data
  );
};