import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { FormikHelpers, FormikProps, useFormik } from 'formik';

import { VALIDATION_SCHEMA } from 'pages/Onboarding/components/OnboardingStepper/constants';
import OnboardingSelectRole from 'pages/Onboarding/components/OnboardingSelectRole';
import PatientProfileCreate from 'pages/Onboarding/components/PatientProfileCreate';

import Stack from 'components/Stack';
import Button from 'components/Button';
import { onboardingSteps } from 'pages/Onboarding/components/OnboardingStepper/constants';

import { useSelectedPatient } from 'entities/Patient/sdk';
import { ONBOARDING_URL } from 'config/urls';
import toast from 'utils/toast';
import {
  useSelectedCareTeam,
  careTeamCreate,
  INewPatientCareTeamCreateFormValues
} from 'entities/CareTeam/sdk';
import { IProfile } from 'entities/Profile/sdk';
import { IUser } from 'entities/Auth/sdk';
import { ECareTeamRole } from 'entities/CareTeam/constants';

const useStyles = makeStyles((theme) => ({
  nextButton: {
    maxWidth: '100px',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  backButton: {
    maxWidth: '100px',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

interface IOnboardingForm {
  activeStep: number;
  user: IUser;
  setActiveStep: (arg0: number) => void;
  formData: INewPatientCareTeamCreateFormValues;
  setFormData: (arg0: INewPatientCareTeamCreateFormValues) => void;
  refetchProfile: () => Promise<IProfile | undefined>;
}

const OnboardingForm: React.FC<IOnboardingForm> = ({
  activeStep,
  user,
  setActiveStep,
  formData,
  setFormData,
  refetchProfile
}) => {
  const classes = useStyles();

  const { refetchCareTeams } = useSelectedPatient();
  const { switchCareTeam } = useSelectedCareTeam();

  const history = useHistory();

  const renderOnboardingSteps = (
    step: number,
    formik: FormikProps<INewPatientCareTeamCreateFormValues>
  ) => {
    switch (step) {
      case 0:
        return <OnboardingSelectRole formik={formik} />;
      case 1:
        return <PatientProfileCreate formik={formik} />;
    }
  };

  const isLastStep = activeStep === onboardingSteps.length - 1;

  const handleBack = () => setActiveStep(activeStep - 1);

  const maintainingFormDataBetweenSteps = (
    step: number,
    formValues: INewPatientCareTeamCreateFormValues
  ) => {
    if (step === 0) {
      if (formValues.care_team_role === ECareTeamRole.PATIENT) {
        return {
          care_team_role: formValues.care_team_role,
          patient: {
            first_name: user.profile.first_name,
            last_name: user.profile.last_name
          }
        };
      } else
        return {
          ...formData,
          care_team_role: formValues.care_team_role
        };
    } else if (step === 1) {
      return {
        ...formData,
        patient: {
          first_name: formValues.patient.first_name,
          last_name: formValues.patient.last_name
        }
      };
    } else
      return {
        care_team_role: '',
        patient: {
          first_name: '',
          last_name: ''
        }
      };
  };

  const handleSubmit = (
    values: INewPatientCareTeamCreateFormValues,
    formikHelpers: FormikHelpers<INewPatientCareTeamCreateFormValues>
  ) => {
    // This is necessary as StepContent in the OnboardinStepper component
    // resets the onboarding form and the prior steps data is lost
    const newFormData = maintainingFormDataBetweenSteps(activeStep, values);
    setFormData(newFormData);

    if (isLastStep || values.care_team_role === ECareTeamRole.PATIENT) {
      formikHelpers.setFieldValue('care_team_role', formData.care_team_role);

      careTeamCreate({
        careTeamInputData: newFormData
      })
        .then((data) => {
          refetchCareTeams();
          switchCareTeam(data.care_team_id);

          if (newFormData.care_team_role === ECareTeamRole.PATIENT) {
            toast.info({
              content: 'You are now tracking your health!'
            });
          } else {
            toast.info({
              content: 'You have added a new patient!'
            });
          }

          refetchProfile().then(() => {
            history.push(ONBOARDING_URL);
          });
        })
        .catch(formikHelpers.setErrors);
    } else {
      setActiveStep(activeStep + 1);
      formikHelpers.setTouched({});
      formikHelpers.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: formData,
    validationSchema: VALIDATION_SCHEMA[activeStep],
    onSubmit: handleSubmit
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {renderOnboardingSteps(activeStep, formik)}
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        {activeStep > 0 && (
          <Button
            variant="text"
            className={classes.backButton}
            onClick={handleBack}
          >
            Back
          </Button>
        )}
        <Button type="submit" className={classes.nextButton}>
          {isLastStep ? 'Finish' : 'Next'}
        </Button>
      </Stack>
    </form>
  );
};

export default OnboardingForm;
