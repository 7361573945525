import { BASE_API_URL } from 'config/urls';
import { useFetch, post } from 'utils/sdk';

import { EOnboardingState } from './constants';
import { ICareTeamMembership } from 'entities/CareTeam/sdk';

export interface IPhoneNumber {
  id: string;
  number: string;
  is_verified: boolean;
}

export interface IProfile {
  id: string;
  first_name: string;
  last_name: string;
  onboarding_state: EOnboardingState;
  avatar: string | null;
  phone_number: null | IPhoneNumber;
  birthday: string | null;
  address: string;
  role?: string;
  family_member_role?: string;
  notification_preferences?: {
    are_sms_notifications_enabled: boolean;
    are_web_app_notifications_enabled: boolean;
  };
  user: {
    email?: string;
  } | null;
  care_team_membership: ICareTeamMembership[];
  patient: {
    patient_id: string;
  } | null;
}

export interface INotificationPreferencesUpdateFormValues {
  are_sms_notifications_enabled: boolean;
  are_web_app_notifications_enabled: boolean;
}

export interface IProfileUpdateFormValues {
  first_name?: string;
  last_name?: string;
  onboarding_state?: EOnboardingState;
  phone?: string | null;
  birthday?: string | null;
  address?: string;
  email?: string;
}

export interface IPhoneNumberVerificationFormValues {
  verification_code: string;
  phone_number_id: string;
}

export const useProfile = ({ profileId }: { profileId: string | undefined }) =>
  useFetch<IProfile>(
    profileId ? `${BASE_API_URL}/users/profile/${profileId}/retrieve/` : null
  );

export const profileUpdate = ({
  profileId,
  profile,
  imageId
}: {
  profileId: string;
  profile: IProfileUpdateFormValues;
  imageId?: string;
}) =>
  post(`${BASE_API_URL}/users/profile/${profileId}/update/`, {
    ...profile,
    image_id: imageId
  });

export const phoneNumberVerificationTrigger = ({
  phoneNumberId
}: {
  phoneNumberId: string;
}) =>
  post(`${BASE_API_URL}/notifications/phone-number-verification-trigger/`, {
    phone_number_id: phoneNumberId
  });

export const phoneNumberVerify = (values: IPhoneNumberVerificationFormValues) =>
  post(`${BASE_API_URL}/notifications/phone-number-verify/`, values);

export const profileNotificationPreferencesUpdate = ({
  profileId,
  values
}: {
  profileId: string;
  values: INotificationPreferencesUpdateFormValues;
}) =>
  post(
    `${BASE_API_URL}/users/profile/${profileId}/notification-preferences/update/`,
    values
  );
