import MuiMenu, { MenuProps } from '@mui/material/Menu';
import MuiMenuItem, { MenuItemProps } from '@mui/material/MenuItem';

const Menu: React.FC<MenuProps> & { Item: React.FC<MenuItemProps> } = (
  props
) => <MuiMenu {...props} />;

Menu.Item = MuiMenuItem;

export default Menu;
