import _ from 'lodash';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { useFetch, post } from 'utils/sdk';

import { BASE_API_URL, ONBOARDING_URL } from 'config/urls';

import { IPhoneNumber } from 'entities/Profile/sdk';
import {
  IPatient,
  IPatientProfileCreateFormValues
} from 'entities/Patient/sdk';
import { EProfileRoles } from 'entities/Profile/constants';

import { SelectedPatientContext } from 'components/SelectedPatientContext';

import { ECareTeamRole } from './constants';

/* 
The ICareTeam interface is designed for the patient switcher.
It is necessary to have two different interfaces for care team 
because of the way we need to serve data from the backend
In ICareTeam, we build a list combining care teams where the user
is a member of a care team (i.e. not a patient) and where the user is a patient.
*/
export interface ICareTeam {
  care_team_id: string;
  patient: IPatient;
  care_team_role: ECareTeamRole;
}

/* 
The IProfileCareTeam interface is used within IProfile to indicate
all care teams where the user is a member and associated patient.
It was included to assist with the Onboarding/login flow in order to 
detect if the user has a care team.
*/
export interface IProfileCareTeam {
  care_team_id: string;
  patient_id: string;
}

// This interface is used for care team profiles
export interface ICareTeamMember {
  profile_id: string;
  care_team_member_id: string;
  first_name: string;
  last_name: string;
  avatar: string | null;
  phone_number: IPhoneNumber | null;
  address: string;
  user: {
    email: string;
  };
  care_team_role: string | null;
  family_member_role: string | null;
}

// This interface is used within IProfile. See notes above.
export interface ICareTeamMembership {
  care_team_member_id: string;
  care_teams: IProfileCareTeam[];
  care_team_role: string | null;
  family_member_role: string | null;
}

export interface IMemberCareTeamRole {
  care_team_role: string;
}

export interface INewPatientCareTeamCreateFormValues {
  patient: IPatientProfileCreateFormValues;
  care_team_role: string;
}

export interface ICareTeamMemberInvitation {
  id: string;
  role: string;
  accepted: boolean;
  expired: boolean;
  declined: boolean;

  created_by: {
    profile: {
      full_name: string;
    };
  };

  care_team: {
    patient: {
      profile: {
        full_name: string;
      };
    };
  };
}

export interface ICareTeamCreateValues {
  care_team_role: EProfileRoles.PATIENT | EProfileRoles.FAMILY_MEMBER;
  first_name: string;
  last_name: string;
  phone: string;
  birthday: string | null;
  address: string;
}

export interface IFamilyMemberRoleFormValues {
  family_member_role: string;
}

export const useCareTeamMembers = ({ careTeamId }: { careTeamId: string }) =>
  useFetch<ICareTeamMember[]>(
    careTeamId
      ? `${BASE_API_URL}/careteam/team/${careTeamId}/members/list/`
      : null
  );

export const useMyCareTeams = () =>
  useFetch<Array<ICareTeam>>(`${BASE_API_URL}/careteam/team/list/`);

export const useSelectedCareTeam = () => {
  const { careTeams, careTeamId, switchCareTeam, loading } = useContext(
    SelectedPatientContext
  );

  const careTeam: ICareTeam | undefined = _.find(careTeams, [
    'care_team_id',
    careTeamId
  ]);

  const userIsFamilyMember =
    careTeam && careTeam.care_team_role === ECareTeamRole.FAMILY_MEMBER;
  const userIsAide = careTeam && careTeam.care_team_role === ECareTeamRole.AIDE;
  const userIsPatient =
    careTeam && careTeam.care_team_role === ECareTeamRole.PATIENT;

  return {
    careTeam,
    careTeamId,
    switchCareTeam,
    userIsAide,
    userIsPatient,
    userIsFamilyMember,
    loading
  };
};

export const useGrantAccessToParticularCareTeamRoles = ({
  roles
}: {
  roles: Array<ECareTeamRole>;
}) => {
  const history = useHistory();
  const { careTeam } = useSelectedCareTeam();

  if (!_.isNil(careTeam) && !roles.includes(careTeam.care_team_role)) {
    history.push(ONBOARDING_URL);
  }
};

export const careTeamMemberDelete = ({
  careTeamId,
  memberId
}: {
  careTeamId: string;
  memberId: string;
}) =>
  post(
    `${BASE_API_URL}/careteam/team/${careTeamId}/member/${memberId}/delete/`
  );

export const useFamilyMemberRoleChoices = () =>
  useFetch<Array<{ label: string; value: string }>>(
    `${BASE_API_URL}/careteam/team/family-member/role/choices/list/`
  );

export const useCareTeamRoleChoices = () =>
  useFetch<Array<{ label: string; value: string }>>(
    `${BASE_API_URL}/careteam/team/role/choices/list/`
  );

export const useCareTeamMemberRole = ({ careTeamId }: { careTeamId: string }) =>
  useFetch<IMemberCareTeamRole>(
    `${BASE_API_URL}/careteam/team/${careTeamId}/care-team-role/`
  );

export const familyMemberRoleUpdate = ({
  memberId,
  care_team_id,
  values
}: {
  memberId: string;
  care_team_id: string;
  values: IFamilyMemberRoleFormValues;
}) =>
  post(
    `${BASE_API_URL}/careteam/team/${care_team_id}/member/${memberId}/family-member-role/update/`,
    values
  );

export const careTeamMemberRoleUpdate = ({
  memberId,
  careTeamId,
  values
}: {
  memberId: string;
  careTeamId: string;
  values: IMemberCareTeamRole;
}) =>
  post(
    `${BASE_API_URL}/careteam/team/${careTeamId}/member/${memberId}/care-team-role/update/`,
    values
  );

export const careTeamMemberInvitationCreate = ({
  careTeamId,
  values
}: {
  careTeamId: string;
  values: IMemberCareTeamRole;
}) =>
  post<ICareTeamMemberInvitation>(
    `${BASE_API_URL}/careteam/team/${careTeamId}/members/invitations/create/`,
    values
  );

export const useCareTeamMemberInvitation = ({
  careTeamId,
  invitationId
}: {
  careTeamId: string;
  invitationId: string;
}) =>
  useFetch<ICareTeamMemberInvitation>(
    `${BASE_API_URL}/careteam/team/${careTeamId}/members/invitations/${invitationId}/retrieve/`
  );

export const careTeamMemberInvitationAccept = ({
  careTeamId,
  invitationId
}: {
  careTeamId: string;
  invitationId: string;
}) =>
  post(
    `${BASE_API_URL}/careteam/team/${careTeamId}/members/invitations/${invitationId}/accept/`
  );

export const careTeamMemberInvitationDecline = ({
  careTeamId,
  invitationId
}: {
  careTeamId: string;
  invitationId: string;
}) =>
  post(
    `${BASE_API_URL}/careteam/team/${careTeamId}/members/invitations/${invitationId}/decline/`
  );

export const careTeamCreate = ({
  careTeamInputData
}: {
  careTeamInputData: INewPatientCareTeamCreateFormValues;
}) =>
  post<{ care_team_id: string }>(
    `${BASE_API_URL}/careteam/team/create/`,
    careTeamInputData
  );
