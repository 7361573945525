import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Grid from 'components/Grid';
import Avatar, { IAvatar } from 'components/Avatar';
import Typography from 'components/Typography';
import ScrollContainer from 'components/ScrollContainer';

import { ICareTeamMember } from 'entities/CareTeam/sdk';

import { colors } from 'theme/palette';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
    '&::-webkit-scrollbar': {
      width: 1,
      height: theme.spacing(1)
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '3px',
      backgroundColor: `${theme.palette.primary.main}33`
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: `${theme.palette.primary.main}66`
    }
  },
  item: {
    padding: '0 14px'
  },
  regularAvatar: {
    width: 50,
    height: 50
  },
  activeAvatar: {
    width: 50,
    height: 50,
    border: `3px solid ${colors.purpleMain}`,
    boxSizing: 'border-box'
  },
  activeText: {
    color: colors.purpleMain
  }
}));

interface ICareTeamSliderItem {
  name: string;
  active: boolean;
  onClick: () => void;
  image: IAvatar['src'];
}

const CareTeamSliderItem: React.FC<ICareTeamSliderItem> = ({
  image,
  name,
  active,
  onClick
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      onClick={onClick}
      alignItems="center"
      className={classes.item}
      sx={{ cursor: 'pointer' }}
      spacing={0.5}
    >
      <Grid item>
        <Avatar
          alt={name}
          src={image}
          className={active ? classes.activeAvatar : classes.regularAvatar}
        />
      </Grid>
      <Grid item>
        <Typography
          variant="body2"
          className={classNames({ [classes.activeText]: active })}
        >
          {name}
        </Typography>
      </Grid>
    </Grid>
  );
};

export interface ICareTeamSlider {
  members: Array<ICareTeamMember & { display_name?: string }>;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}

const CareTeamSlider: React.FC<ICareTeamSlider> = ({
  members,
  activeIndex,
  setActiveIndex
}) => {
  const classes = useStyles();

  return (
    <ScrollContainer
      horizontal
      className={classes.container}
      hideScrollbars={false}
    >
      {members.map((member, index) => (
        <CareTeamSliderItem
          key={index}
          name={member.display_name || member.first_name}
          image={member.avatar}
          active={index === activeIndex}
          onClick={() => setActiveIndex(index)}
        />
      ))}
    </ScrollContainer>
  );
};

export default CareTeamSlider;
