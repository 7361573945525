import _ from 'lodash';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';

import { CallIcon, ErrorOutlineOutlinedIcon, EmailIcon } from 'icons';

import Grid from 'components/Grid';
import Divider from 'components/Divider';
import Tooltip from 'components/Tooltip';
import Typography from 'components/Typography';
import Button from 'components/Button';

import { ICareTeamMember } from 'entities/CareTeam/sdk';
import { useSignedUser } from 'entities/Auth/sdk';
import PhoneNumberVerificationDialog from 'components/PhoneNumberVerificationDialog';
import { ECareTeamRole } from 'entities/CareTeam/constants';

export interface IDetails {
  member: ICareTeamMember;
  onFamilyMemberRoleEdit: () => void;
}

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  actionIcon: {
    color: theme.palette.secondary.main
  },
  addressEmail: {
    wordBreak: 'break-all'
  },
  familyMemberRoleEditButton: {
    padding: '0',
    color: theme.palette.text.secondary,
    textAlign: 'end'
  }
}));

const CareTeamMemberDetails: React.FC<IDetails> = ({
  member,
  onFamilyMemberRoleEdit
}) => {
  const [
    isPhoneNumberVerificationDialogOpen,
    setIsPhoneNumberVerificationDialogOpen
  ] = useState(false);

  const classes = useStyles();

  const { data: signedUser } = useSignedUser();

  const phoneNumber = member.phone_number;

  const showPhoneNumberVerification =
    signedUser?.profile.id === member.profile_id && phoneNumber;

  return (
    <>
      <Grid
        container
        display="flex"
        direction="row"
        columnSpacing={2}
        alignItems="center"
      >
        <Grid item xs={3} sm={4}>
          <Typography variant="body2">Phone</Typography>
        </Grid>
        <Grid
          item
          display="flex"
          flexGrow={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid display="flex" item alignItems="center">
            <Typography variant="body1">
              {member?.phone_number?.number || '-'}
            </Typography>

            {showPhoneNumberVerification ? (
              phoneNumber?.is_verified ? null : (
                <>
                  <Tooltip
                    title="Click to verify"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setIsPhoneNumberVerificationDialogOpen(true)}
                  >
                    <ErrorOutlineOutlinedIcon color="error" />
                  </Tooltip>
                  {isPhoneNumberVerificationDialogOpen && (
                    <PhoneNumberVerificationDialog
                      open
                      phoneNumber={phoneNumber}
                      onClose={() =>
                        setIsPhoneNumberVerificationDialogOpen(false)
                      }
                    />
                  )}
                </>
              )
            ) : null}
          </Grid>
          {!_.isUndefined(member.phone_number?.number) && (
            <Grid item alignItems="center">
              <a href={`tel:${member.phone_number?.number}`}>
                <CallIcon className={classes.actionIcon} />
              </a>
            </Grid>
          )}
        </Grid>
      </Grid>

      {member.user?.email && (
        <>
          <Divider className={classes.divider} />
          <Grid container alignItems="center" direction="row">
            <Grid item xs={3} sm={4}>
              <Typography variant="body2">Email</Typography>
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexGrow={1}
            >
              <Grid item>
                <Typography variant="body1" className={classes.addressEmail}>
                  {member.user?.email || '-'}
                </Typography>
              </Grid>
              <Grid item>
                <a href={`mailto:${member.user.email}`}>
                  <EmailIcon className={classes.actionIcon} />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      <Divider className={classes.divider} />

      <Grid container columnSpacing={2}>
        <Grid item xs={5} sm={4}>
          <Typography variant="body2">Address</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.addressEmail}>
            {member.address || '-'}
          </Typography>
        </Grid>
      </Grid>

      {member.care_team_role === ECareTeamRole.FAMILY_MEMBER && (
        <>
          <Divider className={classes.divider} />
          <Grid
            container
            display="flex"
            direction="row"
            flexWrap="nowrap"
            gap={2}
          >
            <Grid item xs={5} sm={4}>
              <Typography variant="body2">Relationship</Typography>
            </Grid>
            <Grid
              container
              display="flex"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              flexGrow={1}
            >
              <Grid item>
                <Typography
                  variant="body1"
                  whiteSpace="pre"
                  sx={{ textTransform: 'capitalize' }}
                >
                  {member.family_member_role || '-'}
                </Typography>
              </Grid>
              <Grid item alignItems="flex-end">
                <Button
                  fullWidth={false}
                  variant="text"
                  onClick={onFamilyMemberRoleEdit}
                  className={classes.familyMemberRoleEditButton}
                >
                  <Typography variant="caption">Edit</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default CareTeamMemberDetails;
