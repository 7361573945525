import { makeStyles } from '@mui/styles';
import Typography from 'components/Typography';
import Stack from 'components/Stack';
import Button from 'components/Button';
import SimpleDialog, { ISimpleDialog } from 'components/SimpleDialog';

import { IFormError } from 'utils/sdk';

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    color: theme.palette.action.active,
    borderColor: theme.palette.action.active
  }
}));

export interface IConfirmationDialog extends ISimpleDialog {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  primaryButtonColor?: 'primary' | 'error' | 'success';
  errors?: Array<IFormError>;
}

const ConfirmationDialog = ({
  open,
  title,
  onClose,
  onConfirm,
  children,
  primaryButtonColor = 'primary',
  errors = [],
  ...props
}: IConfirmationDialog) => {
  const classes = useStyles();
  return (
    <SimpleDialog open={open} onClose={onClose}>
      <Stack gap={2} padding={2} marginTop={2} marginBottom={1} spacing={2}>
        <Typography variant="h4">{title}</Typography>

        {children}

        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            onClick={onClose}
            className={classes.cancelButton}
          >
            No
          </Button>

          <Button color={primaryButtonColor} onClick={onConfirm}>
            Yes
          </Button>
        </Stack>
      </Stack>
    </SimpleDialog>
  );
};

export default ConfirmationDialog;
