import * as yup from 'yup';

export const VALIDATION_SCHEMA = yup.object().shape({
  first_name: yup.string().required('First name is required.'),
  last_name: yup.string().required('Last name is required.'),
  email: yup.string().required('Email is required.'),
  password: yup.string().required('Password is required.'),
  confirm_password: yup
    .string()
    .required('Password confirmation is required.')
    .oneOf([yup.ref('password')], 'Passwords must match.')
});
