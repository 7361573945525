import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { useState, useCallback } from 'react';
import { FormikHelpers, useFormik } from 'formik';

import toast from 'utils/toast';
import { GENERIC_FORM_ERRORS_KEY, IFormError } from 'utils/sdk';

import Alert from 'components/Alert';
import Stack from 'components/Stack';
import Button from 'components/Button';
import TextField from 'components/TextField';
import Typography from 'components/Typography';
import Dialog, { IDialog } from 'components/Dialog';

import { useProfile } from 'entities/Profile/sdk';
import { useSignedUser } from 'entities/Auth/sdk';
import { useSelectedPatient } from 'entities/Patient/sdk';
import {
  IPhoneNumber,
  phoneNumberVerify,
  phoneNumberVerificationTrigger,
  IPhoneNumberVerificationFormValues
} from 'entities/Profile/sdk';

interface IPhoneNumberVerificationDialog extends IDialog {
  onClose: () => void;
  phoneNumber: IPhoneNumber;
}

const useStyles = makeStyles((theme) => ({
  codeResend: {
    cursor: 'pointer',
    alignSelf: 'flex-end',
    textDecoration: 'underline'
  }
}));

const PhoneNumberVerificationDialog: React.FC<
  IPhoneNumberVerificationDialog
> = ({ onClose, phoneNumber, ...props }) => {
  const [codeResent, setCodeResent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasVerificationCodeBeenSent, setHasVerificationCodeBeenSent] =
    useState(false);

  const classes = useStyles();
  const { data: user } = useSignedUser();
  const { refetch: refetchProfile } = useProfile({
    profileId: user?.profile.id
  });
  const { refetchCareTeams, refetchPatient } = useSelectedPatient();

  const handleSubmit = (
    values: IPhoneNumberVerificationFormValues,
    formikHelpers: FormikHelpers<IPhoneNumberVerificationFormValues>
  ) => {
    setIsSubmitting(true);

    phoneNumberVerify(values)
      .then(() => {
        onClose();
        setIsSubmitting(false);
        toast.info({ content: 'Phone number verification succeeded!' });
        refetchProfile();
        refetchPatient();
        refetchCareTeams();
      })
      .catch((...params) => {
        setIsSubmitting(false);
        formikHelpers.setErrors(...params);
      });
  };

  const handleCodeResendClick = () => {
    if (isSubmitting || codeResent) {
      return;
    }

    setIsSubmitting(true);

    phoneNumberVerificationTrigger({ phoneNumberId: phoneNumber.id })
      .then(() => {
        setCodeResent(true);
        setIsSubmitting(false);
      })
      .catch((...params) => {
        setIsSubmitting(false);
        formik.setErrors(...params);
      });
  };

  const formik = useFormik<IPhoneNumberVerificationFormValues>({
    initialValues: {
      phone_number_id: phoneNumber.id,
      verification_code: ''
    },
    onSubmit: handleSubmit
  });

  const handleSendVerificationCodeClick = useCallback(() => {
    setIsSubmitting(true);

    phoneNumberVerificationTrigger({ phoneNumberId: phoneNumber.id })
      .then(() => {
        setIsSubmitting(false);
        setHasVerificationCodeBeenSent(true);
      })
      .catch((...params) => {
        setIsSubmitting(false);
        formik.setErrors(...params);
      });
  }, [formik, phoneNumber.id]);

  const formErrors: Array<IFormError> = _.get(
    formik.errors,
    GENERIC_FORM_ERRORS_KEY,
    []
  );

  return (
    <Dialog onClose={onClose} title="Verify phone number" {...props}>
      <Stack gap={2} paddingX={2} paddingBottom={2}>
        {formErrors.map((error, index) => (
          <Alert key={index} severity="error">
            {error.message}
          </Alert>
        ))}
        {hasVerificationCodeBeenSent ? (
          <form onSubmit={formik.handleSubmit}>
            <Stack gap={2}>
              <Typography>
                You should shortly receive a verification code on the provided
                phone number. Enter it below to complete the verification
                process.
              </Typography>
              <TextField.Outlined
                label="Verification code"
                name="verification_code"
                value={formik.values.verification_code}
                onChange={formik.handleChange}
              />
              <Typography
                variant="subtitle2"
                className={classes.codeResend}
                onClick={handleCodeResendClick}
              >
                {codeResent
                  ? 'Sent'
                  : "Didn't receive a verification code? Resend"}
              </Typography>
              <Button type="submit" disabled={isSubmitting}>
                Verify
              </Button>
            </Stack>
          </form>
        ) : (
          <>
            <Typography>
              We need to confirm your ownership of the phone number that has
              been entered in order to send you personalized SMS notifications.
            </Typography>
            <Button
              onClick={handleSendVerificationCodeClick}
              disabled={isSubmitting}
            >
              Send me a verification code
            </Button>
          </>
        )}
      </Stack>
    </Dialog>
  );
};

export default PhoneNumberVerificationDialog;
