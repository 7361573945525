import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { GENERIC_FORM_ERRORS_KEY } from 'utils/sdk';
import Stack from 'components/Stack';
import Image from 'components/Image';

import { REGISTER_URL, ONBOARDING_URL } from 'config/urls';
import LoginForm from 'entities/Auth/Login/components/Form';

import Link from 'components/Link';
import { saveToken, setItem } from 'config/storage';
import { login } from 'entities/Auth/sdk';

import logo from 'assets/blue_logo.png';

import { useRedirectUrl } from 'utils/hooks';
import TagManager from 'react-gtm-module';

const Login: React.FC<{}> = () => {
  const history = useHistory();
  const redirectUrl = useRedirectUrl();

  const handleSubmit = useCallback(
    (email: string, password: string) => {
      return login({ email, password })
        .then(({ token, user_id }) => {
          saveToken(token);
          setItem({
            key: 'userId',
            value: user_id
          });

          TagManager.dataLayer({
            dataLayer: {
              userId: user_id
            }
          });

          history.push(redirectUrl || ONBOARDING_URL);
        })
        .catch((err) => {
          if (!err) {
            return Promise.reject({
              [GENERIC_FORM_ERRORS_KEY]: [{ message: 'Something went wrong.' }]
            });
          }
          return Promise.reject(err);
        });
    },
    [history, redirectUrl]
  );

  let registerUrl = REGISTER_URL;
  if (redirectUrl) {
    registerUrl = `${REGISTER_URL}?redirectUrl=${redirectUrl}`;
  }

  return (
    <Stack
      minHeight="100%"
      justifyContent="center"
      alignItems="center"
      padding={4}
      gap={3}
    >
      <Image src={logo} alt="logo" />

      <LoginForm onSubmit={handleSubmit} />

      <Link.DarkGrey
        to={registerUrl}
        variant="h6"
        underline="none"
        data-gtm="link-register"
      >
        Register as a new user
      </Link.DarkGrey>
    </Stack>
  );
};

export default Login;
