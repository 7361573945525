import * as yup from 'yup';

import { datetime } from 'utils/datetime';

import { ECarePlanItemRepeatCycle } from 'entities/CarePlan/constants';

export const REPEAT_CYCLES = [
  { value: ECarePlanItemRepeatCycle.DAILY, label: 'Daily' },
  { value: ECarePlanItemRepeatCycle.WEEKLY, label: 'Specific days' }
];

export const DEFAULT_REPEAT_CYCLE_VALUE = REPEAT_CYCLES[0].value;

export const CUSTOM_CATEGORY_VALUE = 'custom';

export const VALIDATION_SCHEMA = yup.object().shape({
  category: yup.string().required('Category is required.'),
  custom_category: yup.string().when('category', {
    is: CUSTOM_CATEGORY_VALUE,
    then: yup.string().required('Custom category is required.'),
    otherwise: yup.string().nullable()
  }),
  is_added_to_schedule: yup.boolean().required('Add to schedule is required.'),
  repeat_cycle: yup.string().when('is_added_to_schedule', {
    is: true,
    then: yup
      .string()
      .required('Repeat cycle is required.')
      .matches(
        /(daily|weekly)/,
        'Repeat cycle should be either "specific days" or "daily".'
      ),
    otherwise: yup.string().nullable()
  }),
  repeat_cycle_days: yup
    .array()
    .when(['repeat_cycle', 'is_added_to_schedule'], {
      is: (repeat_cycle: string, is_added_to_schedule: boolean) =>
        repeat_cycle === ECarePlanItemRepeatCycle.WEEKLY &&
        is_added_to_schedule,
      then: yup.array().min(1, 'Select at least one repeat cycle day.'),
      otherwise: yup.array()
    }),
  repeat_after: yup.string().when('is_added_to_schedule', {
    is: true,
    then: yup.string().nullable().required('Repeat after is required'),
    otherwise: yup.string().nullable()
  }),
  repeat_before: yup.string().nullable(),

  is_all_day: yup.boolean().when('is_added_to_schedule', {
    is: true,
    then: yup.boolean().required('This field is required'),
    otherwise: yup.boolean().nullable()
  }),

  start_time: yup.string().when(['is_added_to_schedule', 'is_all_day'], {
    is: (is_added_to_schedule: boolean, is_all_day: boolean) =>
      is_added_to_schedule && !is_all_day,
    then: yup.string().nullable().required('Start time is required.'),
    otherwise: yup.string().nullable()
  }),

  end_time: yup.string().when(['is_added_to_schedule', 'is_all_day'], {
    is: (is_added_to_schedule: boolean, is_all_day: boolean) =>
      is_added_to_schedule && !is_all_day,
    then: yup
      .string()
      .nullable()
      .required('End time is required.')
      .test(
        'end-time-is-greater-than-start-time',
        'End time should be greater than start time',
        (endTime, ctx) => {
          if (!endTime) {
            return true;
          }

          const { start_time: startTime } = ctx.parent;
          const startTimeDatetime = datetime(startTime, 'HH:mm', true);
          const endTimeDatetime = datetime(endTime, 'HH:mm', true);

          return endTimeDatetime.isAfter(startTimeDatetime);
        }
      ),
    otherwise: yup.string().nullable()
  }),

  description: yup.string()
});
