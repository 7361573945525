import _ from 'lodash';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik, FormikHelpers } from 'formik';

import toast from 'utils/toast';
import { useRedirectUrl } from 'utils/hooks';
import { PATIENT_PROFILE_URL } from 'config/urls';

import Grid from 'components/Grid';
import Button from 'components/Button';
import Alert from 'components/Alert';
import FormControlLabel from 'components/FormControlLabel';
import Switch from 'components/Switch';

import { useSignedUser } from 'entities/Auth/sdk';
import {
  IProfile,
  INotificationPreferencesUpdateFormValues,
  profileNotificationPreferencesUpdate
} from 'entities/Profile/sdk';

import { ARE_WEB_APP_NOTIFICATIONS_ENABLED } from 'constants/common';

import { VALIDATION_SCHEMA } from './constants';

interface INotificationPreferencesUpdateForm {
  profile: IProfile;
}

const NotificationPreferencesUpdateForm: React.FC<
  INotificationPreferencesUpdateForm
> = ({ profile }) => {
  const history = useHistory();
  const redirectUrl = useRedirectUrl();

  const initialValues: INotificationPreferencesUpdateFormValues = {
    are_sms_notifications_enabled:
      !_.isNil(profile.notification_preferences) &&
      profile.notification_preferences.are_sms_notifications_enabled,
    are_web_app_notifications_enabled:
      !_.isNil(profile.notification_preferences) &&
      profile.notification_preferences.are_web_app_notifications_enabled
  };

  const { data: signedUser } = useSignedUser();

  const canEditNotificationPreferences = profile.id === signedUser?.profile.id;

  const handleSubmit = useCallback(
    (
      values: INotificationPreferencesUpdateFormValues,
      formikHelpers: FormikHelpers<INotificationPreferencesUpdateFormValues>
    ) =>
      profileNotificationPreferencesUpdate({
        profileId: profile.id,
        values
      })
        .then(() => {
          toast.info({
            content: 'Notification preferences have been updated successfully!'
          });
          history.push(redirectUrl || PATIENT_PROFILE_URL);
        })
        .catch(formikHelpers.setErrors),
    [profile.id, history, redirectUrl]
  );

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA
  });

  const formErrors: { message: string; code: string }[] = _.get(
    formik.errors,
    'errors',
    []
  );
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={3}>
        {!_.isEmpty(formErrors) && (
          <Grid item xs={12}>
            {formErrors.map((error, index) => (
              <Alert key={index} severity="error">
                {error.message}
              </Alert>
            ))}
          </Grid>
        )}

        <FormControlLabel
          control={
            <Switch
              color="primary"
              defaultChecked={formik.values.are_sms_notifications_enabled}
            />
          }
          label="Are SMS notifications enabled?"
          labelPlacement="start"
          name="are_sms_notifications_enabled"
          value={formik.values.are_sms_notifications_enabled}
          onChange={formik.handleChange}
          disabled={!canEditNotificationPreferences}
          sx={{
            width: '100%',
            justifyContent: 'space-between'
          }}
        />

        {ARE_WEB_APP_NOTIFICATIONS_ENABLED && (
          <FormControlLabel
            control={
              <Switch
                color="primary"
                defaultChecked={formik.values.are_web_app_notifications_enabled}
              />
            }
            label="Are Web App notifications enabled?"
            labelPlacement="start"
            name="are_web_app_notifications_enabled"
            value={formik.values.are_web_app_notifications_enabled}
            onChange={formik.handleChange}
            disabled={!canEditNotificationPreferences}
            sx={{
              width: '100%',
              justifyContent: 'space-between'
            }}
          />
        )}

        {canEditNotificationPreferences && (
          <Grid item xs={12} paddingBottom={3}>
            <Button type="submit" disabled={formik.isSubmitting}>
              Save
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default NotificationPreferencesUpdateForm;
