import _ from 'lodash';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { MedicationOutlinedIcon } from 'icons';
import {
  HOME_URL,
  MEDICATION_CREATE_URL,
  MEDICATION_CREATE_WITH_FREQUENCY_URL
} from 'config/urls';
import toast from 'utils/toast';
import { substituteUrl } from 'utils/common';
import { datetime, getTodayDate } from 'utils/datetime';
import { useRedirectToOnboarding } from 'utils/hooks';

import Stack from 'components/Stack';
import BlankState from 'components/BlankState';
import Typography from 'components/Typography';
import PageHeader from 'components/PageHeader';
import PageLoader from 'components/PageLoader';
import BottomFabAdd from 'components/BottomFabAdd';
import ConfirmationDialog from 'components/ConfirmationDialog';

import { useSelectedPatient } from 'entities/Patient/sdk';
import {
  IMedication,
  medicationDelete,
  useMedicationsWithIntakeFrequencies
} from 'entities/Medications/sdk';
import { useSelectedCareTeam } from 'entities/CareTeam/sdk';
import { EMedicationIntakeFrequency } from 'entities/Medications/constants';

import MedicationList from './components/List';

interface IMedications {}

const Medications: React.FC<IMedications> = () => {
  useRedirectToOnboarding();

  const history = useHistory();

  const [medicationToBeDeleted, setMedicationToBeDeleted] =
    useState<IMedication | null>(null);
  const [expandedMedications, setExpandedMedications] = useState<Array<string>>(
    []
  );

  const { patient, loading: isPatientLoading } = useSelectedPatient();
  const {
    userIsPatient,
    userIsFamilyMember,
    loading: isFetchingCareTeam
  } = useSelectedCareTeam();
  const {
    asNeededMedications,
    dailyMedications,
    weeklyMedications,
    forbiddenMedications,
    isFetchingMedications,
    mutateMedications
  } = useMedicationsWithIntakeFrequencies({ patientId: patient?.id });

  const toggleMedication = ({ medicationId }: { medicationId: string }) => {
    setExpandedMedications(_.xor(expandedMedications, [medicationId]));
  };

  const handleMedicationDelete = async ({
    medication
  }: {
    medication: IMedication;
  }) => {
    if (!patient?.id) {
      return Promise.reject("There's no patient.");
    }

    return medicationDelete({
      medication,
      patientId: patient.id,
      selectedDate: getTodayDate()
    }).then(() => {
      mutateMedications();
      setMedicationToBeDeleted(null);
      toast.info({ content: 'Medication has been deleted sucessfully!' });
    });
  };

  const commonMedicationListProps = {
    toggleMedication,
    expandedMedications,
    setMedicationToBeDeleted
  };

  const loading =
    isFetchingMedications || isPatientLoading || isFetchingCareTeam;

  const noMedicationsPresent =
    !loading &&
    _.isEmpty([
      ...asNeededMedications,
      ...dailyMedications,
      ...weeklyMedications,
      ...forbiddenMedications
    ]);

  const userHasEditPermissions = userIsPatient || userIsFamilyMember;

  return (
    <Stack gap={2} marginBottom={2}>
      <PageHeader heading="Medications" backTo={HOME_URL} />

      {noMedicationsPresent && userHasEditPermissions && (
        <BlankState
          icon={MedicationOutlinedIcon}
          subtitle="No medications have been added."
          onClick={() => history.push(MEDICATION_CREATE_URL)}
        />
      )}

      {noMedicationsPresent && !userHasEditPermissions && (
        <Typography textAlign="center" marginTop={5}>
          No medications have been added.
        </Typography>
      )}

      {loading ? (
        <PageLoader />
      ) : (
        <>
          <MedicationList
            title="Daily"
            showIntake
            medications={dailyMedications}
            createUrl={substituteUrl({
              url: MEDICATION_CREATE_WITH_FREQUENCY_URL,
              kwargs: {
                intakeFrequency: EMedicationIntakeFrequency.DAILY
              }
            })}
            {...commonMedicationListProps}
          />

          <MedicationList
            title="Specific days"
            showIntake
            medications={weeklyMedications}
            createUrl={substituteUrl({
              url: MEDICATION_CREATE_WITH_FREQUENCY_URL,
              kwargs: {
                intakeFrequency: EMedicationIntakeFrequency.WEEKLY
              }
            })}
            {...commonMedicationListProps}
          />

          <MedicationList
            title="As needed"
            medications={asNeededMedications}
            createUrl={substituteUrl({
              url: MEDICATION_CREATE_WITH_FREQUENCY_URL,
              kwargs: {
                intakeFrequency: EMedicationIntakeFrequency.AS_NEEDED
              }
            })}
            {...commonMedicationListProps}
          />

          <MedicationList
            forbidden
            titleColor="error"
            title="Do not take"
            medications={forbiddenMedications}
            createUrl={substituteUrl({
              url: MEDICATION_CREATE_WITH_FREQUENCY_URL,
              kwargs: {
                intakeFrequency: EMedicationIntakeFrequency.FORBIDDEN
              }
            })}
            {...commonMedicationListProps}
          />
        </>
      )}

      {userHasEditPermissions && (
        <BottomFabAdd onClick={() => history.push(MEDICATION_CREATE_URL)} />
      )}

      {!_.isNil(medicationToBeDeleted) && (
        <ConfirmationDialog
          open
          title="Are you sure you want to remove this medication?"
          primaryButtonColor="error"
          onClose={() => setMedicationToBeDeleted(null)}
          onConfirm={() => {
            handleMedicationDelete({ medication: medicationToBeDeleted });
          }}
        >
          <Typography variant="subtitle1">
            {[
              EMedicationIntakeFrequency.WEEKLY,
              EMedicationIntakeFrequency.DAILY
            ].includes(medicationToBeDeleted.intake_frequency)
              ? `The medication will be removed from the schedule for all dates which are past ${datetime().format(
                  'dddd, MMM DD, YYYY'
                )}.`
              : ''}
          </Typography>
        </ConfirmationDialog>
      )}
    </Stack>
  );
};

export default Medications;
