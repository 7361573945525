import _ from 'lodash';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { makeStyles } from '@mui/styles';

import { ICustomVitals } from 'entities/Patient/sdk';

import { removeTrailingZeros } from 'utils/common';

import Grid from 'components/Grid';
import Button from 'components/Button';
import Divider from 'components/Divider';
import TextField from 'components/TextField';
import Typography from 'components/Typography';
import InputAdornment from 'components/InputAdornment';
import Switch from 'components/Switch';

import { ETemperatureUnit } from 'constants/common';

import { VALIDATION_SCHEMA } from './constants';

const useStyles = makeStyles((theme) => ({
  customVitalsRow: {
    display: 'grid',
    alignItems: 'center',
    gridGap: theme.spacing(1),
    gridTemplateColumns: '125px 13px 2fr 1fr'
  },
  doubleCustomVitalsRow: {
    display: 'grid',
    alignItems: 'center',
    gridGap: theme.spacing(1),
    gridTemplateColumns: '125px 1fr'
  },
  doubleCustomVitalsRowData: {
    display: 'grid',
    alignItems: 'center',
    gridGap: theme.spacing(1),
    gridTemplateColumns: '13px 1fr',
    gridColumnStart: 2
  },

  switchChecked: {
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.grey[800]
    }
  }
}));

const CustomVitalsUpdateTable = ({
  customVitals,
  onSubmit
}: {
  customVitals: ICustomVitals;
  onSubmit: ({ values }: { values: ICustomVitals }) => void;
}) => {
  const classes = useStyles();

  const initialValues = useMemo(
    () =>
      _.transform(
        customVitals,
        (result, value, key) => {
          if (key === 'temperature_unit') {
            result[key] = value as ETemperatureUnit;
          } else {
            result[key] = removeTrailingZeros(value);
          }
        },
        customVitals
      ),
    [customVitals]
  );

  const {
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    errors,
    touched
  } = useFormik<ICustomVitals>({
    initialValues,
    onSubmit: (values) => onSubmit({ values }),
    validationSchema: VALIDATION_SCHEMA
  });

  const handleTemperatureUnitChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) =>
    event.target.value === ETemperatureUnit.FAHRENHEIT
      ? setFieldValue('temperature_unit', ETemperatureUnit.CELSIUS)
      : setFieldValue('temperature_unit', ETemperatureUnit.FAHRENHEIT);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item container className={classes.customVitalsRow}>
          <Grid item>
            <Typography variant="h5">Temperature</Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">&lt;</Typography>
          </Grid>
          <Grid item alignItems="center" display="flex">
            <TextField.Outlined
              error={
                touched.temperature_max && !_.isNil(errors.temperature_max)
              }
              helperText={touched.temperature_max ? errors.temperature_max : ''}
              name="temperature_max"
              value={values.temperature_max}
              onChange={handleChange}
              type="number"
              size="small"
              inputProps={{
                step: '.1'
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    °{values.temperature_unit}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item display="flex" alignItems="center">
            <Typography>°F</Typography>
            <Switch
              value={values.temperature_unit}
              name="temperature_unit"
              color="default"
              classes={{
                checked: classes.switchChecked
              }}
              onChange={handleTemperatureUnitChange}
              checked={values.temperature_unit === ETemperatureUnit.CELSIUS}
              inputProps={{ 'aria-label': 'ant design' }}
            />
            <Typography>°C</Typography>
          </Grid>
        </Grid>

        <Grid item width="100%">
          <Divider />
        </Grid>

        <Grid item container className={classes.doubleCustomVitalsRow}>
          <Grid item gridRow="span 2">
            <Typography variant="h5">Blood pressure</Typography>
          </Grid>

          <Grid item container className={classes.doubleCustomVitalsRowData}>
            <Grid item>
              <Typography variant="h5">&gt;</Typography>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              display="flex"
              flexWrap="nowrap"
              alignItems="center"
            >
              <Grid item>
                <TextField.Outlined
                  error={
                    touched.blood_pressure_systolic_min &&
                    !_.isNil(errors.blood_pressure_systolic_min)
                  }
                  helperText={
                    touched.blood_pressure_systolic_min
                      ? errors.blood_pressure_systolic_min
                      : ''
                  }
                  name="blood_pressure_systolic_min"
                  value={values.blood_pressure_systolic_min}
                  onChange={handleChange}
                  type="number"
                  size="small"
                />
              </Grid>
              <Grid item>/</Grid>
              <Grid item>
                <TextField.Outlined
                  error={
                    touched.blood_pressure_diastolic_min &&
                    !_.isNil(errors.blood_pressure_diastolic_min)
                  }
                  helperText={
                    touched.blood_pressure_diastolic_min
                      ? errors.blood_pressure_diastolic_min
                      : ''
                  }
                  name="blood_pressure_diastolic_min"
                  value={values.blood_pressure_diastolic_min}
                  onChange={handleChange}
                  type="number"
                  size="small"
                />
              </Grid>

              <Grid item>mmHg</Grid>
            </Grid>
          </Grid>

          <Grid item container className={classes.doubleCustomVitalsRowData}>
            <Grid item>
              <Typography variant="h5">&lt;</Typography>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              display="flex"
              flexWrap="nowrap"
              alignItems="center"
            >
              <Grid item>
                <TextField.Outlined
                  error={
                    touched.blood_pressure_systolic_max &&
                    !_.isNil(errors.blood_pressure_systolic_max)
                  }
                  helperText={
                    touched.blood_pressure_systolic_max
                      ? errors.blood_pressure_systolic_max
                      : ''
                  }
                  name="blood_pressure_systolic_max"
                  value={values.blood_pressure_systolic_max}
                  onChange={handleChange}
                  type="number"
                  size="small"
                />
              </Grid>
              <Grid item>/</Grid>
              <Grid item>
                <TextField.Outlined
                  error={
                    touched.blood_pressure_diastolic_max &&
                    !_.isNil(errors.blood_pressure_diastolic_max)
                  }
                  helperText={
                    touched.blood_pressure_diastolic_max
                      ? errors.blood_pressure_diastolic_max
                      : ''
                  }
                  name="blood_pressure_diastolic_max"
                  value={values.blood_pressure_diastolic_max}
                  onChange={handleChange}
                  type="number"
                  size="small"
                />
              </Grid>

              <Grid item>mmHg</Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item width="100%">
          <Divider />
        </Grid>

        <Grid item container className={classes.doubleCustomVitalsRow}>
          <Grid item gridRow="span 2">
            <Typography variant="h5">Pulse</Typography>
          </Grid>

          <Grid item container className={classes.doubleCustomVitalsRowData}>
            <Grid item>
              <Typography variant="h5">&gt;</Typography>
            </Grid>
            <Grid item>
              <TextField.Outlined
                error={touched.pulse_min && !_.isNil(errors.pulse_min)}
                helperText={touched.pulse_min ? errors.pulse_min : ''}
                name="pulse_min"
                value={values.pulse_min}
                onChange={handleChange}
                type="number"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">bpm</InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
          <Grid item container className={classes.doubleCustomVitalsRowData}>
            <Grid item>
              <Typography variant="h5">&lt;</Typography>
            </Grid>
            <Grid item>
              <TextField.Outlined
                error={touched.pulse_max && !_.isNil(errors.pulse_max)}
                helperText={touched.pulse_max ? errors.pulse_max : ''}
                name="pulse_max"
                value={values.pulse_max}
                onChange={handleChange}
                type="number"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">bpm</InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item width="100%">
          <Divider />
        </Grid>

        <Grid item container className={classes.customVitalsRow}>
          <Grid item>
            <Typography variant="h5">Blood oxygen</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">&gt;</Typography>
          </Grid>
          <Grid item>
            <TextField.Outlined
              error={
                touched.blood_oxygen_min && !_.isNil(errors.blood_oxygen_min)
              }
              helperText={
                touched.blood_oxygen_min ? errors.blood_oxygen_min : ''
              }
              name="blood_oxygen_min"
              value={values.blood_oxygen_min}
              onChange={handleChange}
              type="number"
              size="small"
              inputProps={{
                step: '.1'
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
            />
          </Grid>
        </Grid>

        <Grid item width="100%">
          <Divider />
        </Grid>

        <Grid item container className={classes.doubleCustomVitalsRow}>
          <Grid item gridRow="span 2">
            <Typography variant="h5">
              Blood sugar <Typography variant="subtitle2">Fasting</Typography>
            </Typography>
          </Grid>

          <Grid item container className={classes.doubleCustomVitalsRowData}>
            <Grid item>
              <Typography variant="h5">&gt;</Typography>
            </Grid>
            <Grid item>
              <TextField.Outlined
                error={
                  touched.blood_sugar_fasting_min &&
                  !_.isNil(errors.blood_sugar_fasting_min)
                }
                helperText={
                  touched.blood_sugar_fasting_min
                    ? errors.blood_sugar_fasting_min
                    : ''
                }
                name="blood_sugar_fasting_min"
                value={values.blood_sugar_fasting_min}
                onChange={handleChange}
                type="number"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mg/dL</InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
          <Grid item container className={classes.doubleCustomVitalsRowData}>
            <Grid item>
              <Typography variant="h5">&lt;</Typography>
            </Grid>
            <Grid item>
              <TextField.Outlined
                error={
                  touched.blood_sugar_fasting_max &&
                  !_.isNil(errors.blood_sugar_fasting_max)
                }
                helperText={
                  touched.blood_sugar_fasting_max
                    ? errors.blood_sugar_fasting_max
                    : ''
                }
                name="blood_sugar_fasting_max"
                value={values.blood_sugar_fasting_max}
                onChange={handleChange}
                type="number"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mg/dL</InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item container className={classes.doubleCustomVitalsRow}>
          <Grid item gridRow="span 2">
            <Typography variant="h5">
              Blood sugar
              <Typography variant="subtitle2">Non Fasting</Typography>
            </Typography>
          </Grid>

          <Grid item container className={classes.doubleCustomVitalsRowData}>
            <Grid item>
              <Typography variant="h5">&gt;</Typography>
            </Grid>
            <Grid item>
              <TextField.Outlined
                error={
                  touched.blood_sugar_non_fasting_min &&
                  !_.isNil(errors.blood_sugar_non_fasting_min)
                }
                helperText={
                  touched.blood_sugar_non_fasting_min
                    ? errors.blood_sugar_non_fasting_min
                    : ''
                }
                name="blood_sugar_non_fasting_min"
                value={values.blood_sugar_non_fasting_min}
                onChange={handleChange}
                type="number"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mg/dL</InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>

          <Grid item container className={classes.doubleCustomVitalsRowData}>
            <Grid item>
              <Typography variant="h5">&lt;</Typography>
            </Grid>
            <Grid item>
              <TextField.Outlined
                error={
                  touched.blood_sugar_non_fasting_max &&
                  !_.isNil(errors.blood_sugar_non_fasting_max)
                }
                helperText={
                  touched.blood_sugar_non_fasting_max
                    ? errors.blood_sugar_non_fasting_max
                    : ''
                }
                name="blood_sugar_non_fasting_max"
                value={values.blood_sugar_non_fasting_max}
                onChange={handleChange}
                type="number"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mg/dL</InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item width="100%" marginY={3}>
          <Button type="submit" disabled={isSubmitting}>
            save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CustomVitalsUpdateTable;
