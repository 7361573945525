import { makeStyles } from '@mui/styles';

import Box from 'components/Box';
import Stack from 'components/Stack';
import Image from 'components/Image';
import Container from 'components/Container';

import logo from 'assets/blue_logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
    overflow: 'auto'
  }
}));

export interface IBlankPageLayout {
  children: React.ReactNode;
}

const BlankPageLayout: React.FC<IBlankPageLayout> = ({ children }) => {
  const classes = useStyles();

  return (
    <Stack className={classes.root} alignItems="center">
      <Box paddingTop={10} paddingBottom={5}>
        <Image src={logo} />
      </Box>
      <Container sx={{ height: '100%' }} maxWidth="sm">
        {children}
      </Container>
    </Stack>
  );
};

export default BlankPageLayout;
