import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

import Stack from 'components/Stack';
import Paper from 'components/Paper';
import Link from 'components/Link';
import Typography from 'components/Typography';
import IconButton from 'components/IconButton';
import Menu from 'components/Menu';
import { MenuItem } from '@mui/material';

import {
  MEDICATIONS_URL,
  SCHEDULE_URL,
  HEALTH_DIARY_URL,
  MEDICATION_CREATE_URL,
  DOCUMENTS_URL,
  HEALTH_TRENDS_URL
} from 'config/urls';
import { REDIRECT_URL_PARAM_KEY } from 'constants/common';

import {
  AddIcon,
  PillsIcon,
  VolunteerActivismIcon,
  AutoStoriesIcon,
  FolderIcon,
  InsightsIcon
} from 'icons';

import {
  IS_TASK_CREATE_DIALOG_OPENED_URL_PARAM_KEY,
  IS_ADD_CARE_PLAN_DIALOG_OPENED_URL_PARAM_KEY
} from 'pages/Schedule/constants';
import {
  IS_VITAL_NOTE_CREATE_DIALOG_OPEN_URL_PARAM_KEY,
  IS_DIARY_NOTE_CREATE_DIALOG_OPEN_URL_PARAM_KEY
} from 'pages/HealthDiary/constants';
import {
  SHOW_DOCUMENTS_ADD_DEVICE_DIALOG_OPEN,
  SHOW_DOCUMENTS_ADD_PHOTOS_DIALOG_OPEN
} from 'pages/Documents/constants';

import { useSelectedCareTeam } from 'entities/CareTeam/sdk';
import { ECareTeamRole } from 'entities/CareTeam/constants';

const useStyles = makeStyles((theme) => ({
  svgIcons: {
    height: '30px',
    width: '30px',
    fill:
      theme.palette.mode === 'light'
        ? theme.palette.primary.dark
        : theme.palette.primary.light
  },
  linkStyle: {
    textDecoration: 'none',
    color: 'initial',
    display: 'flex',
    flexDirection: 'row'
  },
  innerStackStyle: {
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '16px'
  },
  textStyle: {
    color: theme.palette.text.primary
  }
}));

interface IHomeDashboard {}

const HomeDashboard: React.FC<IHomeDashboard> = () => {
  const classes = useStyles();
  const redirectPath = window.location.pathname;

  const history = useHistory();

  const { careTeam } = useSelectedCareTeam();

  const [diaryAnchorEl, setDiaryAnchorEl] = useState<null | HTMLElement>(null);
  const [careActivitiesAnchorEl, setCareActivitiesAnchorEl] =
    useState<null | HTMLElement>(null);
  const [documentsAnchorEl, setDocumentsAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleButtonClick = (targetUrl: string) => {
    history.push(targetUrl);
  };

  const handleDiaryAddClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setDiaryAnchorEl(event.currentTarget);

  const handleCareActivitiesAddClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => setCareActivitiesAnchorEl(event.currentTarget);

  const handleDocumentsAddClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => setDocumentsAnchorEl(event.currentTarget);

  return (
    <>
      <Stack spacing={3} direction="column">
        <Paper elevation={3}>
          <Stack direction="row" className={classes.innerStackStyle}>
            <Link
              to={`${SCHEDULE_URL}?${REDIRECT_URL_PARAM_KEY}=${redirectPath}`}
              className={classes.linkStyle}
              data-gtm="home-link-schedule"
            >
              <VolunteerActivismIcon className={classes.svgIcons} />
              <Typography
                variant="h4"
                paddingLeft={2}
                className={classes.textStyle}
              >
                Care activities
              </Typography>
            </Link>
            <IconButton
              data-gtm="home-button-add-care-activity"
              onClick={(event) => {
                handleCareActivitiesAddClick(event);
              }}
              aria-controls={
                Boolean(careActivitiesAnchorEl)
                  ? 'care-activities-add-menu'
                  : undefined
              }
              aria-haspopup={true}
              aria-expanded={
                Boolean(careActivitiesAnchorEl) ? 'true' : undefined
              }
            >
              <AddIcon />
            </IconButton>
          </Stack>
        </Paper>

        <Paper elevation={3}>
          <Stack direction="row" className={classes.innerStackStyle}>
            <Link
              to={`${MEDICATIONS_URL}?${REDIRECT_URL_PARAM_KEY}=${redirectPath}`}
              className={classes.linkStyle}
              data-gtm="home-link-medications"
            >
              <PillsIcon className={classes.svgIcons} />
              <Typography
                variant="h4"
                paddingLeft={2}
                className={classes.textStyle}
              >
                Medications
              </Typography>
            </Link>
            <IconButton
              disabled={
                careTeam?.care_team_role === ECareTeamRole.AIDE ? true : false
              }
              data-gtm="home-button-add-a-medication"
              onClick={() =>
                handleButtonClick(
                  `${MEDICATION_CREATE_URL}?${REDIRECT_URL_PARAM_KEY}=${redirectPath}`
                )
              }
            >
              <AddIcon />
            </IconButton>
          </Stack>
        </Paper>

        <Paper elevation={3}>
          <Stack direction="row" className={classes.innerStackStyle}>
            <Link
              to={`${HEALTH_DIARY_URL}?${REDIRECT_URL_PARAM_KEY}=${redirectPath}`}
              className={classes.linkStyle}
              data-gtm="home-link-diary"
            >
              <AutoStoriesIcon className={classes.svgIcons} />
              <Typography
                variant="h4"
                paddingLeft={2}
                className={classes.textStyle}
              >
                Journal
              </Typography>
            </Link>
            <IconButton
              data-gtm="home-button-add-diary-note"
              onClick={(event) => {
                handleDiaryAddClick(event);
              }}
              aria-controls={
                Boolean(diaryAnchorEl) ? 'diary-add-menu' : undefined
              }
              aria-haspopup={true}
              aria-expanded={Boolean(diaryAnchorEl) ? 'true' : undefined}
            >
              <AddIcon />
            </IconButton>
          </Stack>
        </Paper>

        <Paper elevation={3}>
          <Stack direction="row" className={classes.innerStackStyle}>
            <Link
              to={`${DOCUMENTS_URL}?${REDIRECT_URL_PARAM_KEY}=${redirectPath}`}
              className={classes.linkStyle}
              data-gtm="home-link-documents"
            >
              <FolderIcon className={classes.svgIcons} />
              <Typography
                variant="h4"
                paddingLeft={2}
                className={classes.textStyle}
              >
                Documents
              </Typography>
            </Link>
            <IconButton
              data-gtm="home-button-add-document"
              onClick={(event) => {
                handleDocumentsAddClick(event);
              }}
              aria-controls={
                Boolean(diaryAnchorEl) ? 'documents-add-menu' : undefined
              }
              aria-haspopup={true}
              aria-expanded={Boolean(documentsAnchorEl) ? 'true' : undefined}
            >
              <AddIcon />
            </IconButton>
          </Stack>
        </Paper>

        <Paper elevation={3}>
          <Stack direction="row" className={classes.innerStackStyle}>
            <Link
              to={`${HEALTH_TRENDS_URL}?${REDIRECT_URL_PARAM_KEY}=${redirectPath}`}
              className={classes.linkStyle}
              data-gtm="home-link-health-trends"
            >
              <InsightsIcon className={classes.svgIcons} />
              <Typography
                variant="h4"
                paddingLeft={2}
                className={classes.textStyle}
              >
                Health trends
              </Typography>
            </Link>
          </Stack>
        </Paper>
      </Stack>

      <Menu
        id="diary-add-menu"
        anchorEl={diaryAnchorEl}
        open={Boolean(diaryAnchorEl)}
        onClose={() => setDiaryAnchorEl(null)}
      >
        <MenuItem
          onClick={() =>
            handleButtonClick(
              `${HEALTH_DIARY_URL}?${IS_DIARY_NOTE_CREATE_DIALOG_OPEN_URL_PARAM_KEY}=true&${REDIRECT_URL_PARAM_KEY}=${redirectPath}`
            )
          }
        >
          New general note
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleButtonClick(
              `${HEALTH_DIARY_URL}?${IS_VITAL_NOTE_CREATE_DIALOG_OPEN_URL_PARAM_KEY}=true&${REDIRECT_URL_PARAM_KEY}=${redirectPath}`
            )
          }
        >
          New vitals reading
        </MenuItem>
      </Menu>

      <Menu
        id="care-activities-add-menu"
        anchorEl={careActivitiesAnchorEl}
        open={Boolean(careActivitiesAnchorEl)}
        onClose={() => setCareActivitiesAnchorEl(null)}
      >
        <MenuItem
          onClick={() =>
            handleButtonClick(
              `${SCHEDULE_URL}?${IS_ADD_CARE_PLAN_DIALOG_OPENED_URL_PARAM_KEY}=true&${REDIRECT_URL_PARAM_KEY}=${redirectPath}`
            )
          }
        >
          New care activity
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleButtonClick(
              `${SCHEDULE_URL}?${IS_TASK_CREATE_DIALOG_OPENED_URL_PARAM_KEY}=true&${REDIRECT_URL_PARAM_KEY}=${redirectPath}`
            )
          }
        >
          New appointment
        </MenuItem>
      </Menu>

      <Menu
        id="documents-add-menu"
        anchorEl={documentsAnchorEl}
        open={Boolean(documentsAnchorEl)}
        onClose={() => setDocumentsAnchorEl(null)}
      >
        <MenuItem
          onClick={() =>
            handleButtonClick(
              `${DOCUMENTS_URL}?${SHOW_DOCUMENTS_ADD_PHOTOS_DIALOG_OPEN}=true&${REDIRECT_URL_PARAM_KEY}=${redirectPath}`
            )
          }
        >
          Take a photo
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleButtonClick(
              `${DOCUMENTS_URL}?${SHOW_DOCUMENTS_ADD_DEVICE_DIALOG_OPEN}=true&${REDIRECT_URL_PARAM_KEY}=${redirectPath}`
            )
          }
        >
          Upload from device
        </MenuItem>
      </Menu>
    </>
  );
};

export default HomeDashboard;
