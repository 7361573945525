import { getNearestHalfHour } from 'utils/common';
import { getDatetimeObjectFromDate } from 'utils/datetime';

import { IDialog } from 'components/Dialog';

import { ITaskValues } from 'entities/Schedule/sdk';
import TaskFormDialog from 'entities/Schedule/components/TaskFormDialog';

import { BACKEND_DATETIME_FORMAT } from 'constants/time';

export interface ITaskCreateDialog extends IDialog {
  onTaskCreate: ({ task }: { task: ITaskValues }) => Promise<void>;
  selectedDate: string;
}

const TaskCreateDialog: React.FC<ITaskCreateDialog> = ({
  onTaskCreate,
  selectedDate,
  ...props
}) => {
  const selectedDateDatetime = getDatetimeObjectFromDate({
    date: selectedDate
  });

  const initialValues = {
    name: '',
    description: '',
    location: '',
    start: getNearestHalfHour({ time: selectedDateDatetime }).format(
      BACKEND_DATETIME_FORMAT
    ),
    end: getNearestHalfHour({
      time: selectedDateDatetime.add(30, 'm')
    }).format(BACKEND_DATETIME_FORMAT)
  };

  return (
    <TaskFormDialog
      {...props}
      onTaskSubmit={onTaskCreate}
      title="New appointment"
      initialValues={initialValues}
    />
  );
};

export default TaskCreateDialog;
