import { default as MuiCheckbox, CheckboxProps } from '@mui/material/Checkbox';
import {
  default as FormControlLabel,
  FormControlLabelProps
} from '@mui/material/FormControlLabel';

export interface ICheckbox extends Omit<FormControlLabelProps, 'control'> {
  checkboxProps?: CheckboxProps;
}

const Checkbox = ({ checkboxProps, ...props }: ICheckbox) => (
  <FormControlLabel {...props} control={<MuiCheckbox {...checkboxProps} />} />
);

export default Checkbox;
