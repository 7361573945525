import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Stack from 'components/Stack';
import PageLoader from 'components/PageLoader';
import PageHeader from 'components/PageHeader';
import Typography from 'components/Typography';

import {
  useSelectedPatient,
  vitalsToTrackUpdate,
  IVitalsToTrackFromValues,
  useVitalsToTrack
} from 'entities/Patient/sdk';

import VitalsToTrackUpdateForm from 'entities/Patient/components/VitalsToTrackUpdateForm';

import toast from 'utils/toast';
import { CUSTOM_VITALS_URL } from 'config/urls';
import { useRedirectToOnboarding } from 'utils/hooks';

const VitalsToTrackUpdate = () => {
  useRedirectToOnboarding();

  const history = useHistory();
  const { patient } = useSelectedPatient();
  const {
    data: vitalsToTrack,
    loading: vitalsToTrackLoading,
    refetch: refetchVitalsToTrack
  } = useVitalsToTrack({ patientId: patient?.id });

  const onVitalsToTrackUpdate = useCallback(
    ({ vitalsToTrack }: { vitalsToTrack: IVitalsToTrackFromValues }) => {
      if (!patient) {
        return Promise.reject("There's no patient in the care team.");
      }

      return vitalsToTrackUpdate({
        vitalsToTrack,
        patientId: patient.id
      }).then(() => {
        toast.info({
          content: 'Vitals to track have been updated successfully!'
        });
        refetchVitalsToTrack().then(() => {
          history.push(CUSTOM_VITALS_URL);
        });
      });
    },
    [history, patient, refetchVitalsToTrack]
  );

  return (
    <Stack spacing={2} direction="column">
      {vitalsToTrackLoading ? (
        <PageLoader />
      ) : (
        <>
          <PageHeader
            heading="Edit Vitals To Track"
            backTo={CUSTOM_VITALS_URL}
          />

          <Typography variant="subtitle2">
            Select the vitals you wish to track
          </Typography>

          {vitalsToTrack && (
            <VitalsToTrackUpdateForm
              vitalsToTrack={vitalsToTrack}
              onVitalsToTrackUpdate={onVitalsToTrackUpdate}
            />
          )}
        </>
      )}
    </Stack>
  );
};

export default VitalsToTrackUpdate;
