import _ from 'lodash';
import { jsPDF } from 'jspdf';
import { datetime } from 'utils/datetime';
import { DATETIME_FILENAME_FORMAT } from 'constants/time';
import { UserImage } from 'utils/images';

interface IDimensions {
  width: number;
  height: number;
}

export const generatePDFFileFromImages = ({
  images,
  paperDimensions,
  fileName
}: {
  images: UserImage[];
  paperDimensions: IDimensions;
  fileName?: string;
}) => {
  // Converts a set of images into a PDF file
  const newPDFFile = new jsPDF({
    unit: 'in'
  });
  newPDFFile.deletePage(1);

  images.forEach((image: UserImage) => {
    newPDFFile.addPage();
    newPDFFile.addImage(
      image.src,
      image.imageType,
      (paperDimensions.width - image.width / 96) / 2,
      (paperDimensions.height - image.height / 96) / 2,
      image.width / 96,
      image.height / 96
    );
  });

  let userFileName = '';
  if (_.isNil(fileName)) {
    const fileDateTimeStamp = datetime().format(DATETIME_FILENAME_FORMAT);
    userFileName = 'photos_'.concat(fileDateTimeStamp, '.pdf');
  } else userFileName = fileName.concat('.pdf');

  const blobPDFFile = new File([newPDFFile.output('blob')], userFileName, {
    type: 'application/pdf'
  });

  return blobPDFFile;
};

export const cleanFileName = (fileName: string) => {
  // No control chars, no: /, \, ?, %, *, :, |, ", <, >
  const unacceptableCharacters = new RegExp(/[?%*:|"<>\\/]/g);
  const cleanUserInputFileName = fileName.replace(unacceptableCharacters, '');

  return cleanUserInputFileName;
};

export const isBackgroundDark = (hexColor: string) => {
  if (hexColor.length === 7) {
    const rgb = getRgbColorFromHex(hexColor);

    if (rgb[0] * 0.299 + rgb[1] * 0.587 + rgb[2] * 0.114 < 130) {
      return false; // no -> so, font should be black
    } else return true; // no -> so, font should be white
  } else return false; // default: black
};

const getRgbColorFromHex = (hex: string) => {
  hex = hex.slice(1);
  const value = parseInt(hex, 16);
  const r = (value >> 16) & 255;
  const g = (value >> 8) & 255;
  const b = value & 255;

  return [r, g, b] as RGB;
};

type RGB = [number, number, number];
