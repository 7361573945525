import _ from 'lodash';
import { useCallback, useState } from 'react';

import { useFormik, FormikHelpers } from 'formik';

import Stack from 'components/Stack';
import Grid from 'components/Grid';
import Button from 'components/Button';
import Alert from 'components/Alert';
import DateField from 'components/DateField';
import TextField from 'components/TextField';
import PhoneField from 'components/PhoneField';
import AvatarDropzone from 'components/AvatarDropzone';

import { IProfile, IProfileUpdateFormValues } from 'entities/Profile/sdk';

import { VALIDATION_SCHEMA } from './constants';

interface IProfileUpdateForm {
  profile: IProfile;
  onSubmit: ({
    profileId,
    profile,
    imageId
  }: {
    profileId: string;
    profile: IProfileUpdateFormValues;
    imageId?: string;
  }) => Promise<any>;
}

const ProfileUpdateForm: React.FC<IProfileUpdateForm> = ({
  profile,
  onSubmit
}) => {
  const [imageId, setImageId] = useState<string | undefined>();

  const initialValues: IProfileUpdateFormValues = {
    first_name: profile.first_name,
    last_name: profile.last_name,
    phone: profile.phone_number?.number,
    birthday: profile?.birthday,
    address: profile.address,
    email: profile.user?.email
  };

  const handleSubmit = useCallback(
    (
      values: IProfileUpdateFormValues,
      formikHelpers: FormikHelpers<IProfileUpdateFormValues>
    ) => {
      if (values.phone === '') {
        values.phone = undefined;
      }
      return onSubmit({
        profileId: profile.id,
        profile: values,
        imageId
      }).catch(formikHelpers.setErrors);
    },
    [onSubmit, profile.id, imageId]
  );

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA
  });

  const formErrors: { message: string; code: string }[] = _.get(
    formik.errors,
    'errors',
    []
  );
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={3}>
        {!_.isEmpty(formErrors) && (
          <Grid item xs={12}>
            {formErrors.map((error, index) => (
              <Alert key={index} severity="error">
                {error.message}
              </Alert>
            ))}
          </Grid>
        )}

        <Grid item container gap={3} alignItems="center">
          <Grid item md={2}>
            <AvatarDropzone
              avatar={profile?.avatar}
              onAvatarUploaded={({ imageId }) => setImageId(imageId)}
            />
          </Grid>

          <Grid item xs={12} md={10}>
            <Stack direction="column" spacing={3}>
              <TextField.Outlined
                fullWidth
                label="First name"
                type="text"
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                helperText={
                  formik.touched.first_name ? formik.errors.first_name : ''
                }
                error={!_.isNil(formik.errors.first_name)}
              />
              <TextField.Outlined
                fullWidth
                label="Last name"
                type="text"
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                helperText={
                  formik.touched.last_name ? formik.errors.last_name : ''
                }
                error={!_.isNil(formik.errors.last_name)}
              />
            </Stack>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <PhoneField
            label="Phone"
            name="phone"
            formik={formik}
            phoneNumber={profile.phone_number}
          />
        </Grid>

        {profile.user?.email && (
          <Grid item xs={12}>
            <TextField.Outlined
              fullWidth
              label="E-mail"
              type="email"
              name="email"
              value={formik.values.email}
              placeholder="Email address"
              onChange={formik.handleChange}
              helperText={formik.touched.email ? formik.errors.email : ''}
              error={!_.isNil(formik.errors.email)}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <DateField.Formik
            clearable
            label="Birthday"
            name="birthday"
            formik={formik}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField.Outlined
            fullWidth
            multiline
            rows={3}
            value={formik.values.address}
            onChange={formik.handleChange}
            helperText={formik.touched.address ? formik.errors.address : ''}
            error={!_.isNil(formik.errors.address)}
            label="Address"
            name="address"
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>

        <Grid item xs={12} paddingBottom={3}>
          <Button type="submit" disabled={formik.isSubmitting}>
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProfileUpdateForm;
