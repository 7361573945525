import _ from 'lodash';
import { useMemo, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { datetime, getTodayDate, localToUtc } from 'utils/datetime';
import { queryStringParse } from 'utils/common';
import { REDIRECT_URL_PARAM_KEY } from 'constants/common';
import {
  MEDICATION_CREATE_URL,
  SCHEDULE_URL,
  ONBOARDING_URL,
  CARE_PLAN_URL
} from 'config/urls';
import toast from 'utils/toast';
import { useRedirectToOnboarding } from 'utils/hooks';

import Stack from 'components/Stack';
import ScheduleMenu from 'entities/Schedule/components/ScheduleMenu';
import Typography from 'components/Typography';
import ConfirmationDialog from 'components/ConfirmationDialog';

import { useSelectedPatient } from 'entities/Patient/sdk';

import {
  ITaskValues,
  taskDelete,
  taskUpdate,
  taskCreate,
  usePatientScheduleItemList,
  taskCompletionStatusUpdate,
  carePlanItemCompletionStatusUpdate
} from 'entities/Schedule/sdk';
import { medicationCompletionStatusUpdate } from 'entities/Medications/sdk';
import ScheduleContent from 'entities/Schedule/components/Content';
import TaskCreateDialog from 'entities/Schedule/components/TaskCreateDialog';
import TaskUpdateDialog from 'entities/Schedule/components/TaskUpdateDialog';
import ScheduleTaskDetailDialog from 'entities/Schedule/components/ScheduleTaskDetailDialog';
import ScheduleMedicationDetailDialog from 'entities/Schedule/components/ScheduleMedicationDetailDialog';
import ScheduleCarePlanItemDetailDialog from 'entities/Schedule/components/ScheduleCarePlanItemDetailDialog';
import CarePlanItemCreateDialog from 'entities/CarePlan/components/CarePlanItemCreateDialog';
import {
  IScheduleTask,
  carePlanItemCreate,
  IScheduleMedication,
  IScheduleCarePlanItem,
  ICarePlanItemValues,
  useCarePlanItemCategories
} from 'entities/CarePlan/sdk';
import { useSelectedCareTeam } from 'entities/CareTeam/sdk';

import { EMedicationDosageTaken } from 'entities/Medications/constants';

import {
  IS_TASK_CREATE_DIALOG_OPENED_URL_PARAM_KEY,
  IS_ADD_CARE_PLAN_DIALOG_OPENED_URL_PARAM_KEY,
  EScheduleMenuItems
} from './constants';
import {
  BACKEND_DATETIME_FORMAT,
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT
} from 'constants/time';

interface ISchedule {}

const Schedule: React.FC<ISchedule> = () => {
  useRedirectToOnboarding();

  const history = useHistory();

  const location = useLocation();
  const queryParams = queryStringParse({
    queryParams: location.search
  });
  const initialDate = _.get(queryParams, 'selectedDate');
  const initialIsTaskCreateDialogOpened = _.get(
    queryParams,
    IS_TASK_CREATE_DIALOG_OPENED_URL_PARAM_KEY
  );
  const initialIsAddCarePlanDialogOpened = _.get(
    queryParams,
    IS_ADD_CARE_PLAN_DIALOG_OPENED_URL_PARAM_KEY
  );

  const initialSelectedDate = useMemo(
    () => (!_.isNil(initialDate) ? (initialDate as string) : getTodayDate()),
    [initialDate]
  );

  const [selectedDate, setSelectedDate] = useState<string>(initialSelectedDate);

  const [isAddCarePlanDialogOpened, setIsAddCarePlanDialogOpened] =
    useState<boolean>(Boolean(initialIsAddCarePlanDialogOpened));
  const [isTaskCreateDialogOpened, setIsTaskCreateDialogOpened] =
    useState<boolean>(Boolean(initialIsTaskCreateDialogOpened));
  const [isTaskUpdateDialogOpened, setIsTaskUpdateDialogOpened] =
    useState<boolean>(false);
  const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] =
    useState<boolean>(false);

  const { userIsPatient, userIsFamilyMember } = useSelectedCareTeam();
  const { data: carePlanItemCategories = [] } = useCarePlanItemCategories();

  const addMenuItems = [
    {
      label: EScheduleMenuItems.NEW_CARE_TASK,
      onClick: () => setIsAddCarePlanDialogOpened(true)
    },
    {
      label: EScheduleMenuItems.NEW_APPOINTMENT,
      onClick: () => setIsTaskCreateDialogOpened(true)
    },
    {
      label: EScheduleMenuItems.NEW_MEDICATION,
      onClick: () => {
        const redirectTo = `${SCHEDULE_URL}?selectedDate=${selectedDate}`;
        history.push(
          `${MEDICATION_CREATE_URL}?${REDIRECT_URL_PARAM_KEY}=${redirectTo}&selectedDate=${selectedDate}`
        );
      }
    }
  ];

  const [selectedTask, setSelectedTask] = useState<IScheduleTask | null>(null);
  const [selectedMedication, setSelectedMedication] =
    useState<IScheduleMedication | null>(null);
  const [selectedCarePlanItem, setSelectedCarePlanItem] =
    useState<IScheduleCarePlanItem | null>(null);

  const { patient } = useSelectedPatient();

  const {
    data: items = [],
    loading: isFetchingItems,
    mutate: mutateScheduleItems
  } = usePatientScheduleItemList({
    patientId: patient?.id,
    selectedDate
  });
  const { careTeam, loading: isFetchingCareTeam } = useSelectedCareTeam();
  const noCareTeam = !isFetchingCareTeam && _.isNil(careTeam);

  const handleItemCompletionStatusUpdate = useCallback(
    ({
      is_completed,
      dosage_taken,
      additional_note
    }: {
      is_completed?: boolean;
      additional_note?: string;
      dosage_taken?: EMedicationDosageTaken | null;
    }) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      if (selectedTask) {
        if (is_completed === undefined) {
          return Promise.reject('Completion has not been provided.');
        }

        return taskCompletionStatusUpdate({
          patientId: patient.id,
          taskId: selectedTask.id,
          isCompleted: is_completed
        }).then(() => {
          setSelectedTask(null);
          mutateScheduleItems();
          toast.info({
            content: 'Appointment has been updated successfully!'
          });
        });
      }

      if (selectedCarePlanItem) {
        if (is_completed === undefined) {
          return Promise.reject('Completion has not been provided.');
        }

        let time = datetime().hour(0).minute(0).second(0);

        if (selectedCarePlanItem.start_time) {
          time = datetime(
            selectedCarePlanItem.start_time,
            BACKEND_TIME_FORMAT,
            true
          );
        }

        const selectedDatetime = localToUtc(
          datetime(selectedDate, BACKEND_DATE_FORMAT, true)
            .hour(time.hour())
            .minute(time.minute())
        ).format(BACKEND_DATETIME_FORMAT);

        return carePlanItemCompletionStatusUpdate({
          patientId: patient.id,
          carePlanItemId: selectedCarePlanItem.id,
          isCompleted: is_completed,
          selectedDatetime
        }).then(() => {
          setSelectedCarePlanItem(null);
          mutateScheduleItems();
          toast.info({
            content: 'Task has been updated successfully!'
          });
        });
      }

      if (selectedMedication) {
        if (!dosage_taken) {
          return Promise.reject('No dosage taken provided');
        }

        return medicationCompletionStatusUpdate({
          patientId: patient.id,
          medicationId: selectedMedication.id,
          medicationIntakeTimeId: selectedMedication.intake_time.id,
          dosageTaken: dosage_taken,
          additionalNote: additional_note,
          selectedDate,
          timeOfIntakeTime: selectedMedication.intake_time.time
        }).then(() => {
          setSelectedMedication(null);
          mutateScheduleItems();
          toast.info({
            content:
              'Medication completion status has been updated successfully!'
          });
        });
      }

      return Promise.reject('No item selected.');
    },
    [
      patient,
      selectedTask,
      selectedMedication,
      selectedCarePlanItem,
      mutateScheduleItems,
      selectedDate
    ]
  );

  const handleCarePlanItemCreate = useCallback(
    ({ carePlanItem }: { carePlanItem: ICarePlanItemValues }) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      return carePlanItemCreate({
        patientId: patient.id,
        carePlanItem
      }).then(() => {
        mutateScheduleItems();
        setIsAddCarePlanDialogOpened(false);
        toast.actionable({
          content: 'Repeating task has been created successfully!',
          action: () => history.push(CARE_PLAN_URL),
          actionButtonText: 'View all'
        });
      });
    },
    [patient, mutateScheduleItems, history]
  );

  const handleTaskCreate = useCallback(
    ({ task }: { task: ITaskValues }) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      return taskCreate({
        patientId: patient.id,
        task
      }).then(() => {
        mutateScheduleItems();
        setIsTaskCreateDialogOpened(false);
        toast.info({
          content: 'Appointment or reminder has been created successfully!'
        });
      });
    },
    [patient, mutateScheduleItems]
  );

  const handleTaskUpdate = useCallback(
    ({ task }: { task: ITaskValues }) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      if (!selectedTask) {
        return Promise.reject("There's no task selected.");
      }

      return taskUpdate({
        patientId: patient.id,
        taskId: selectedTask.id,
        task
      }).then(() => {
        mutateScheduleItems();
        setIsTaskUpdateDialogOpened(false);
        setSelectedTask(null);
        toast.info({
          content: 'Appointment or reminder has been updated successfully!'
        });
      });
    },
    [patient, selectedTask, mutateScheduleItems]
  );

  const handleTaskDelete = useCallback(() => {
    if (!patient?.id) {
      return Promise.reject("There's no patient in the care team.");
    }

    if (!selectedTask) {
      return Promise.reject('No task was selected.');
    }

    return taskDelete({
      patientId: patient.id,
      taskId: selectedTask.id
    }).then(() => {
      mutateScheduleItems();
      setSelectedTask(null);
      setIsConfirmationDialogOpened(false);
      toast.info({
        content: 'Appointment or reminder has been deleted successfully!'
      });
    });
  }, [patient, selectedTask, mutateScheduleItems]);

  const handleCompleteSetupClick = () => history.push(ONBOARDING_URL);

  return (
    <>
      <Stack paddingY={3}>
        <Stack marginBottom={3}>
          <Typography variant="h1">Schedule</Typography>
        </Stack>
        {noCareTeam ? (
          <Typography variant="body1" component="div">
            You're not tracking anyone's health.{' '}
            <Typography
              color="primary"
              onClick={handleCompleteSetupClick}
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              component="div"
            >
              Complete your setup to continue.
            </Typography>
          </Typography>
        ) : (
          <ScheduleContent
            isFetching={isFetchingItems}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            scheduleItems={items}
            onTaskSelect={({ item }: { item: IScheduleTask | null }) =>
              setSelectedTask(item)
            }
            onMedicationSelect={({
              item
            }: {
              item: IScheduleMedication | null;
            }) => setSelectedMedication(item)}
            onCarePlanItemSelect={({
              item
            }: {
              item: IScheduleCarePlanItem | null;
            }) => setSelectedCarePlanItem(item)}
            onBlankStateClick={() => setIsTaskCreateDialogOpened(true)}
          />
        )}
        {(userIsPatient || userIsFamilyMember) && (
          <ScheduleMenu items={addMenuItems} />
        )}
      </Stack>
      {selectedTask && (
        <ScheduleTaskDetailDialog
          open
          item={selectedTask}
          onClose={() => {
            setSelectedTask(null);
            setIsConfirmationDialogOpened(false);
          }}
          onEditClick={() => setIsTaskUpdateDialogOpened(true)}
          onDeleteClick={() => setIsConfirmationDialogOpened(true)}
          onTaskCompletionStatusUpdate={handleItemCompletionStatusUpdate}
          mutateScheduleItemsInParent={mutateScheduleItems}
        />
      )}

      {selectedMedication && (
        <ScheduleMedicationDetailDialog
          open
          onClose={() => {
            setSelectedMedication(null);
          }}
          medicationId={selectedMedication.id}
          intakeTime={selectedMedication.intake_time}
          dosageTaken={selectedMedication.dosage_taken}
          additionalNote={selectedMedication.additional_note}
          completedAt={selectedMedication.completed_at}
          completedBy={selectedMedication.completed_by}
          isDeleted={selectedMedication.is_deleted}
          refetchInParent={mutateScheduleItems}
          selectedDate={selectedDate}
          onMedicationCompletionStatusUpdate={handleItemCompletionStatusUpdate}
        />
      )}

      {selectedCarePlanItem && (
        <ScheduleCarePlanItemDetailDialog
          selectedDate={selectedDate}
          onClose={() => setSelectedCarePlanItem(null)}
          mutateScheduleItemsInParent={mutateScheduleItems}
          item={selectedCarePlanItem}
          onCompletionStatusUpdate={handleItemCompletionStatusUpdate}
        />
      )}
      {isAddCarePlanDialogOpened && (
        <CarePlanItemCreateDialog
          open
          title="Title"
          selectedDate={selectedDate}
          categories={carePlanItemCategories}
          onClose={() => setIsAddCarePlanDialogOpened(false)}
          onCarePlanItemCreate={handleCarePlanItemCreate}
        />
      )}
      {selectedTask && (
        <TaskUpdateDialog
          open={isTaskUpdateDialogOpened}
          onClose={() => setIsTaskUpdateDialogOpened(false)}
          onTaskEdit={handleTaskUpdate}
          task={selectedTask}
        />
      )}

      {isTaskCreateDialogOpened && (
        <TaskCreateDialog
          open
          onClose={() => setIsTaskCreateDialogOpened(false)}
          onTaskCreate={handleTaskCreate}
          selectedDate={selectedDate}
        />
      )}

      {!_.isNil(selectedTask) && (
        <ConfirmationDialog
          open={isConfirmationDialogOpened}
          title="Are you sure you want to remove this item?"
          primaryButtonColor="error"
          onClose={() => setIsConfirmationDialogOpened(false)}
          onConfirm={handleTaskDelete}
        />
      )}
    </>
  );
};

export default Schedule;
