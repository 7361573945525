import { makeStyles } from '@mui/styles';
import { useState, useEffect, useCallback } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from 'icons';

import Stack from 'components/Stack';
import PickersDay from 'components/PickersDay';
import { EDiaryScheduleItemType } from 'entities/Diary/constants';
import MobileDatePicker from 'components/MobileDatePicker';
import TextField from '@mui/material/TextField';

import { getPreviousDate, getNextDate, datetime } from 'utils/datetime';
import { useSelectedPatient } from 'entities/Patient/sdk';
import { IDiaryScheduleItem, useDiarySchedule } from 'entities/Diary/sdk';
import { BACKEND_DATE_FORMAT } from 'constants/time';

import { HEALTH_DIARY_URL } from 'config/urls';
import _ from 'lodash';
import { colors } from 'theme/palette';

export interface IDiaryCalendar {
  selectedDate: string;
  setSelectedDate: (arg0: string) => void;
}

const useStyles = makeStyles((theme) => ({
  cursorPointer: {
    cursor: 'pointer'
  },
  dayWithDotContainer: {
    position: 'relative'
  },
  dayWithDot: {
    position: 'absolute',
    height: 0,
    width: 0,
    border: '2px solid',
    borderRadius: 4,
    borderColor: colors.veryGreen,
    right: '50%',
    transform: 'translateX(1px)',
    top: '80%'
  }
}));

const DiaryCalendar = ({ selectedDate, setSelectedDate }: IDiaryCalendar) => {
  const classes = useStyles();
  const [daysWithDot, setDaysWithDot] = useState<Array<string>>([]);
  const [activeMonthDate, setActiveMonthDate] = useState<string>(selectedDate);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { patient } = useSelectedPatient();
  const { refetch: refetchDiarySchedule } = useDiarySchedule({
    patientId: patient?.id,
    selectedDate: activeMonthDate,
    isForFullMonth: true
  });

  const handleChange = (value: string | null) => {
    if (!value) {
      return;
    }
    const formattedDate = datetime(value, BACKEND_DATE_FORMAT).format(
      BACKEND_DATE_FORMAT
    );
    setSelectedDate(formattedDate);
    setActiveMonthDate(formattedDate);
  };

  const getHighlightedDays = useCallback(
    (result: Array<IDiaryScheduleItem> | undefined) => {
      if (
        window.location.pathname === HEALTH_DIARY_URL &&
        !_.isUndefined(result)
      ) {
        const listOfNoteItems = result.map((item: IDiaryScheduleItem) => {
          if (item.type === EDiaryScheduleItemType.DIARY_NOTE) {
            return {
              id: item.id,
              createdDate: datetime(
                item.schedule_datetime,
                BACKEND_DATE_FORMAT
              ).format(BACKEND_DATE_FORMAT)
            };
          } else {
            return {
              id: item.id,
              createdDate: datetime(
                item.measured_at,
                BACKEND_DATE_FORMAT
              ).format(BACKEND_DATE_FORMAT)
            };
          }
        });
        setDaysWithDot(Object.keys(_.groupBy(listOfNoteItems, 'createdDate')));
        setIsLoading(false);
      }
    },
    []
  );

  const handlePickerViewChange = (value: string | null) => {
    const formattedDate = datetime(value, BACKEND_DATE_FORMAT).format(
      BACKEND_DATE_FORMAT
    );
    setActiveMonthDate(formattedDate);
  };

  const handleOpen = () => {
    if (datetime(activeMonthDate, 'MM') !== datetime(selectedDate, 'MM')) {
      setActiveMonthDate(selectedDate);
    }
    refetchDiarySchedule().then((result) => {
      getHighlightedDays(result);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    refetchDiarySchedule()
      .then((result) => {
        getHighlightedDays(result);
      })
      .catch((err) => console.log('useEffect error: ', err));
  }, [activeMonthDate, getHighlightedDays, refetchDiarySchedule]);

  return (
    <Stack spacing={1} maxHeight="100%">
      <Stack direction="row" spacing={1} alignItems="center">
        <ChevronLeftIcon
          onClick={() =>
            setSelectedDate(getPreviousDate({ date: selectedDate }))
          }
          className={classes.cursorPointer}
        />
        <MobileDatePicker
          renderInput={(params) => <TextField fullWidth {...params} />}
          loading={isLoading}
          inputFormat="dddd, MMM DD, YYYY"
          value={selectedDate}
          onChange={handleChange}
          onMonthChange={handlePickerViewChange}
          onOpen={handleOpen}
          renderDay={(day, _value, pickersDayProps) => {
            const date = datetime(day, BACKEND_DATE_FORMAT);
            const showBadge =
              !pickersDayProps.outsideCurrentMonth &&
              daysWithDot.indexOf(date.format(BACKEND_DATE_FORMAT)) >= 0;
            if (showBadge) {
              return (
                <div
                  key={day.toString()}
                  className={classes.dayWithDotContainer}
                >
                  <PickersDay {...pickersDayProps} />
                  <div className={classes.dayWithDot} />
                </div>
              );
            } else {
              return <PickersDay {...pickersDayProps} />;
            }
          }}
        ></MobileDatePicker>
        <ChevronRightIcon
          onClick={() => setSelectedDate(getNextDate({ date: selectedDate }))}
          className={classes.cursorPointer}
        />
      </Stack>
    </Stack>
  );
};

export default DiaryCalendar;
