import { default as MuiAvatar, AvatarProps } from '@mui/material/Avatar';

export interface IAvatar extends Omit<AvatarProps, 'src'> {
  src: string | null | undefined;
}

const Avatar: React.FC<IAvatar> = ({ src, ...props }) => {
  if (src === null) {
    // The MuiAvatar interface does not accept "null".
    src = undefined;
  }

  return <MuiAvatar src={src} {...props} />;
};

export default Avatar;
