import _ from 'lodash';
import { FormikProps } from 'formik';
import { makeStyles } from '@mui/styles';

import Stack from 'components/Stack';
import Typography from 'components/Typography';
import Radio from '@mui/material/Radio';
import { RadioGroup } from '@mui/material';
import FormControlLabel from 'components/FormControlLabel';
import FormControl from 'components/FormControl';

import { ECareTeamRole } from 'entities/CareTeam/constants';
import { colors } from 'theme/palette';
import { INewPatientCareTeamCreateFormValues } from 'entities/CareTeam/sdk';

import { useProfile } from 'entities/Profile/sdk';
import { useSignedUser } from 'entities/Auth/sdk';

import { CancelIcon } from 'icons';

const useStyles = makeStyles((theme) => ({
  radioStyle: {
    '& .MuiSvgIcon-root': {
      fontSize: 28
    }
  },
  errorStyle: {
    fontColor: 'red'
  }
}));

interface IOnboardingSelectRole {
  formik: FormikProps<INewPatientCareTeamCreateFormValues>;
}

const OnboardingSelectRole: React.FC<IOnboardingSelectRole> = ({ formik }) => {
  const classes = useStyles();

  const { data: user } = useSignedUser();
  const { data: profile } = useProfile({
    profileId: user?.profile.id
  });

  return (
    <Stack alignItems="flex-start" padding={3}>
      <Stack spacing={2}>
        <Typography variant="h4">Whose health do you want to track?</Typography>
        <FormControl component="fieldset">
          <RadioGroup
            id="care_team_role"
            name="care_team_role"
            value={formik.values.care_team_role.toString()}
            onChange={formik.handleChange}
          >
            <FormControlLabel
              disabled={_.isNull(profile?.patient) ? false : true}
              value={ECareTeamRole.PATIENT}
              control={<Radio />}
              label={
                _.isNull(profile?.patient) ? (
                  <Typography sx={{ fontSize: '18px' }}>Mine</Typography>
                ) : (
                  <Typography
                    color={colors.mediumGrey}
                    sx={{ fontSize: '18px' }}
                  >
                    Mine
                  </Typography>
                )
              }
              className={classes.radioStyle}
            />
            <FormControlLabel
              value={ECareTeamRole.FAMILY_MEMBER}
              control={<Radio />}
              label={
                <Typography sx={{ fontSize: '18px' }}>A loved one's</Typography>
              }
              className={classes.radioStyle}
            />
            <FormControlLabel
              disabled={true}
              control={<Radio />}
              label={
                <Typography color={colors.mediumGrey} sx={{ fontSize: '18px' }}>
                  A client's
                </Typography>
              }
              className={classes.radioStyle}
            />
          </RadioGroup>
          {formik.errors.care_team_role ? (
            <>
              <Stack direction="row" paddingY={1} spacing={1}>
                <CancelIcon color="error" />
                <Typography color="darkred">
                  {formik.errors.care_team_role}
                </Typography>
              </Stack>
            </>
          ) : null}
        </FormControl>
      </Stack>
    </Stack>
  );
};

export default OnboardingSelectRole;
