import _ from 'lodash';
import { Redirect } from 'react-router-dom';

import PageLoader from 'components/PageLoader';

import { useSignedUser } from 'entities/Auth/sdk';
import { useProfile } from 'entities/Profile/sdk';

import { HOME_URL, ONBOARDING_STEPPER_URL } from 'config/urls';

const Onboarding: React.FC<{}> = () => {
  const { data: user } = useSignedUser();
  const { data: profile } = useProfile({
    profileId: user?.profile.id
  });

  const userHasACareTeamOrIsAPatient =
    _.isNull(profile?.care_team_membership) && _.isNull(profile?.patient)
      ? false
      : true;

  if (!userHasACareTeamOrIsAPatient) {
    return <Redirect to={ONBOARDING_STEPPER_URL} />;
  }

  if (userHasACareTeamOrIsAPatient) {
    return <Redirect to={HOME_URL} />;
  }
  return <PageLoader />;
};

export default Onboarding;
