export const REDIRECT_URL_PARAM_KEY = 'redirectUrl';

export const DAYS_OF_THE_WEEK_CHOICES = [
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
  { label: 'Sunday', value: 'sunday' }
];

export const DAYS_OF_THE_WEEK_SHORTENED_CHOICES = [
  { label: 'Mon', value: 'monday' },
  { label: 'Tue', value: 'tuesday' },
  { label: 'Wed', value: 'wednesday' },
  { label: 'Thu', value: 'thursday' },
  { label: 'Fri', value: 'friday' },
  { label: 'Sat', value: 'saturday' },
  { label: 'Sun', value: 'sunday' }
];

export enum EDaysOfTheWeek {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday'
}

export enum ETemperatureUnit {
  FAHRENHEIT = 'F',
  CELSIUS = 'C'
}
export const ARE_WEB_APP_NOTIFICATIONS_ENABLED =
  process.env.REACT_APP_ARE_WEB_APP_NOTIFICATIONS_ENABLED === 'true';

export enum EAcceptedFileTypes {
  IMAGE = 'image/*',
  PDF = 'application/pdf'
}
