import _ from 'lodash';

import { useLocation } from 'react-router-dom';

import { PATIENT_PROFILE_URL } from 'config/urls';
import { queryStringParse } from 'utils/common';

import Grid from 'components/Grid';
import PageHeader from 'components/PageHeader';
import PageLoader from 'components/PageLoader';

import { useProfile } from 'entities/Profile/sdk';

import INotificationPreferencesUpdateForm from 'entities/Notifications/components/NotificationPreferencesUpdateForm';

const NotificationPreferences = () => {
  const location = useLocation();

  const queryParams = queryStringParse({
    queryParams: location.search
  });
  const profileId = _.get(queryParams, 'profile') as string;

  const { data: profile, loading: isFetchingProfile } = useProfile({
    profileId
  });

  return (
    <Grid container gap={2} direction="column">
      <Grid item>
        <PageHeader
          heading="Notification Preferences"
          backTo={PATIENT_PROFILE_URL}
        />
      </Grid>

      {isFetchingProfile && <PageLoader />}

      {!isFetchingProfile && !_.isNil(profile) && (
        <INotificationPreferencesUpdateForm profile={profile} />
      )}
    </Grid>
  );
};

export default NotificationPreferences;
