import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';

import {
  AddIcon,
  KeyboardArrowDownIcon,
  EditOutlinedIcon,
  DeleteOutlinedIcon
} from 'icons';

import { datetime } from 'utils/datetime';
import { substituteUrl } from 'utils/common';
import { MEDICATION_UPDATE_URL } from 'config/urls';

import { useSelectedCareTeam } from 'entities/CareTeam/sdk';
import { IMedication } from 'entities/Medications/sdk';
import { MEDICATION_RELATION_TO_MEALS_CHOICES } from 'entities/Medications/constants';
import { getReadableIntakeTimes } from 'entities/Medications/utils';

import Grid from 'components/Grid';
import Stack from 'components/Stack';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Accordion from 'components/Accordion';
import Typography from 'components/Typography';
import IconButton from 'components/IconButton';

import DataLine from 'pages/Medications/components/DataLine';

import { getIntakeDays } from 'entities/Medications/utils';

interface IMedicationList {
  medications: Array<IMedication>;
  title: string;
  createUrl: string;
  expandedMedications: Array<string>;
  setMedicationToBeDeleted: (arg0: IMedication) => void;
  toggleMedication: ({ medicationId }: { medicationId: string }) => void;
  forbidden?: boolean;
  titleColor?: string;
  showIntake?: boolean;
}

const useStyles = makeStyles(() => ({
  nonUppercasedButton: {
    width: 'auto',
    alignSelf: 'flex-start',
    textTransform: 'none'
  }
}));

const MedicationList: React.FC<IMedicationList> = ({
  medications,
  title,
  createUrl,
  titleColor,
  forbidden = false,
  expandedMedications,
  toggleMedication,
  setMedicationToBeDeleted,
  showIntake = false
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { userIsPatient, userIsFamilyMember } = useSelectedCareTeam();

  const canModifyMedications = userIsPatient || userIsFamilyMember;

  return _.isEmpty(medications) ? null : (
    <>
      <Typography variant="h2" color={titleColor} paddingTop={3}>
        {title}
      </Typography>
      <Divider />
      <Grid container>
        {medications.map((medication) => (
          <Grid item width="100%" key={medication.id}>
            <Accordion
              square
              key={medication.id}
              expanded={_.includes(expandedMedications, medication.id)}
              onChange={() =>
                toggleMedication({
                  medicationId: medication.id
                })
              }
            >
              <Accordion.Summary expandIcon={<KeyboardArrowDownIcon />}>
                <Stack direction="row" alignItems="center" gap={1}>
                  {medication.name}

                  {medication.is_deleted && (
                    <Typography color="error" variant="subtitle2">
                      (Deleted)
                    </Typography>
                  )}
                </Stack>
              </Accordion.Summary>
              <Accordion.Details>
                <Stack spacing={1}>
                  <DataLine label="Quantity:">
                    {medication.quantity ? `${medication.quantity} ` : '-'}
                  </DataLine>

                  {showIntake && (
                    <DataLine label="Intake:">
                      {getIntakeDays({ medication })} at{' '}
                      {getReadableIntakeTimes({
                        intakeTimes: medication.intake_times
                      })}
                    </DataLine>
                  )}

                  <DataLine label="From:">
                    {datetime(medication.repeat_startdatetime).format(
                      'MMM Do, YYYY'
                    )}{' '}
                    {medication.repeat_enddatetime
                      ? ` - ${datetime(medication.repeat_startdatetime).format(
                          'MMM Do, YYYY'
                        )}`
                      : 'onwards'}
                  </DataLine>

                  <DataLine label="Relation to meals:">
                    {_.find(MEDICATION_RELATION_TO_MEALS_CHOICES, [
                      'value',
                      medication.relation_to_meals
                    ])?.label || '-'}
                  </DataLine>

                  <DataLine label="Description:">
                    {medication.description || '-'}
                  </DataLine>
                </Stack>
                <Grid
                  container
                  spacing={2}
                  marginTop={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    {forbidden && (
                      <Typography color="error" fontWeight="bold">
                        Do not take
                      </Typography>
                    )}
                  </Grid>

                  {canModifyMedications && (
                    <Grid item>
                      <Stack direction="row" spacing={2}>
                        <IconButton
                          size="small"
                          disabled={medication.is_deleted}
                          onClick={() =>
                            history.push(
                              substituteUrl({
                                url: MEDICATION_UPDATE_URL,
                                kwargs: { medicationId: medication.id }
                              })
                            )
                          }
                        >
                          <EditOutlinedIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          disabled={medication.is_deleted}
                          onClick={() => setMedicationToBeDeleted(medication)}
                        >
                          <DeleteOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </Accordion.Details>
            </Accordion>
          </Grid>
        ))}
      </Grid>
      {canModifyMedications && (
        <Button
          variant="text"
          className={classes.nonUppercasedButton}
          onClick={() => history.push(createUrl)}
        >
          <AddIcon fontSize="small" />
          <Typography variant="subtitle2">
            Add any "{title.toLowerCase()}" medications
          </Typography>
        </Button>
      )}
    </>
  );
};

export default MedicationList;
