import _ from 'lodash';
import { getItem, setItem } from 'config/storage';
import { VITALS } from 'entities/HealthTrends/constants';

const LAST_SEEN_HEALTH_TREND_CHART_LOCAL_STORAGE_KEY =
  'lastSeenHealthTrendChart';

export const getLastSeenHealthTrendChart = (): Array<string> => {
  const previousValue = getItem(LAST_SEEN_HEALTH_TREND_CHART_LOCAL_STORAGE_KEY);
  if (!_.isNil(previousValue)) {
    return JSON.parse(previousValue);
  }

  return [VITALS.TEMPERATURE];
};

export const updateLastSeenHealthTrendChart = (chartName: string) => {
  const previousValue = getLastSeenHealthTrendChart();

  const newValue = _.uniq([chartName, ...previousValue]);
  const stringifiedNewValue = JSON.stringify(newValue);

  setItem({
    key: LAST_SEEN_HEALTH_TREND_CHART_LOCAL_STORAGE_KEY,
    value: stringifiedNewValue
  });
};

export const sortHealthTrendChartsByLastSeen = (chartList: Array<string>) => {
  const lastSeenHealthTrendChart = getLastSeenHealthTrendChart();

  return _.sortBy(chartList, (chart) => {
    const hasLastBeenSeen = _.includes(lastSeenHealthTrendChart, chart);

    if (hasLastBeenSeen) {
      return lastSeenHealthTrendChart.indexOf(chart);
    }
    return Infinity;
  });
};
