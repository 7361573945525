import _ from 'lodash';

import { ICustomVitals } from 'entities/Patient/sdk';

import { IVitalNote } from 'entities/Diary/sdk';

export const getVitalsOutOfRange = ({
  customVitals,
  vitalNote
}: {
  customVitals?: ICustomVitals;
  vitalNote: IVitalNote;
}) => {
  const vitalsOutOfRange: Array<string> = [];

  if (!customVitals) {
    return { vitalsOutOfRange };
  }

  if (
    !_.isNil(vitalNote.temperature) &&
    Number(vitalNote.temperature) > Number(customVitals.temperature_max)
  ) {
    vitalsOutOfRange.push('temperature');
  }

  if (
    (!_.isNil(vitalNote.blood_pressure_systolic) &&
      Number(vitalNote.blood_pressure_systolic) <
        Number(customVitals.blood_pressure_systolic_min)) ||
    Number(vitalNote.blood_pressure_systolic) >
      Number(customVitals.blood_pressure_systolic_max)
  ) {
    vitalsOutOfRange.push('blood_pressure_systolic');
  }

  if (
    (!_.isNil(vitalNote.blood_pressure_diastolic) &&
      Number(vitalNote.blood_pressure_diastolic) <
        Number(customVitals.blood_pressure_diastolic_min)) ||
    Number(vitalNote.blood_pressure_diastolic) >
      Number(customVitals.blood_pressure_diastolic_max)
  ) {
    vitalsOutOfRange.push('blood_pressure_diastolic');
  }

  if (
    (!_.isNil(vitalNote.pulse) &&
      Number(vitalNote.pulse) < Number(customVitals.pulse_min)) ||
    Number(vitalNote.pulse) > Number(customVitals.pulse_max)
  ) {
    vitalsOutOfRange.push('pulse');
  }

  if (
    !_.isNil(vitalNote.blood_oxygen) &&
    Number(vitalNote.blood_oxygen) < Number(customVitals.blood_oxygen_min)
  ) {
    vitalsOutOfRange.push('blood_oxygen');
  }

  if (
    (!_.isNil(vitalNote.blood_sugar_fasting) &&
      Number(vitalNote.blood_sugar_fasting) <
        Number(customVitals.blood_sugar_fasting_min)) ||
    Number(vitalNote.blood_sugar_fasting) >
      Number(customVitals.blood_sugar_fasting_max)
  ) {
    vitalsOutOfRange.push('blood_sugar_fasting');
  }

  if (
    (!_.isNil(vitalNote.blood_sugar_non_fasting) &&
      Number(vitalNote.blood_sugar_non_fasting) <
        Number(customVitals.blood_sugar_non_fasting_min)) ||
    Number(vitalNote.blood_sugar_non_fasting) >
      Number(customVitals.blood_sugar_non_fasting_max)
  ) {
    vitalsOutOfRange.push('blood_sugar_non_fasting');
  }

  return { vitalsOutOfRange };
};
