import * as yup from 'yup';

// declaring constants for paper as used by JSPDF
export enum PAPER_SIZE {
  US_LETTER = 'letter',
  A4 = 'a4'
}

export enum PAPER_ORIENTATION {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait'
}

export const PAPER_DIMENSIONS = {
  US_LETTER: {
    unit: 'in',
    width: 8.5,
    height: 11
  },
  A4: {
    unit: 'in',
    width: 8.27,
    height: 11.69
  }
};

export const MAX_UPLOAD_FILE_SIZE = 5242880;

export const VALID_FILENAME_REGEX = new RegExp(/[\w. -]+/gm);

export const VALIDATION_SCHEMA_FILENAME = yup.object().shape({
  file_name: yup
    .string()
    .required('A document name is required')
    .matches(
      VALID_FILENAME_REGEX,
      'Document name contains one or more invalid characters'
    )
});

export enum EPermission_Level {
  HIDDEN = 'hidden',
  VIEW = 'view',
  EDIT = 'edit',
  ADMIN = 'admin'
}

export const VALIDATION_SCHEMA_PERMISSIONS = yup.object({
  permission_level: yup
    .string()
    .required('Permission level is required for every user.')
});

export enum EFile_Type {
  IMAGE = 'image',
  OTHER = 'other'
}

export const COLOR_PICKER_WIDTH = 245;

export const COLOR_PICKER_BUFFER = 10;
