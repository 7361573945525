import _ from 'lodash';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik, FormikHelpers } from 'formik';

import { useRedirectUrl } from 'utils/hooks';
import { REDIRECT_URL_PARAM_KEY } from 'constants/common';
import { GENERIC_FORM_ERRORS_KEY, IFormError } from 'utils/sdk';

import { REGISTER_PENDING_URL, FORGOT_PASSWORD_URL } from 'config/urls';

import Link from 'components/Link';
import Alert from 'components/Alert';
import Stack from 'components/Stack';
import TextField from 'components/TextField';
import Typography from 'components/Typography';
import Button from 'components/Button';
import PasswordField from 'components/PasswordField';

import { activationEmailResend } from 'entities/Auth/sdk';

import { VALIDATION_SCHEMA } from './constants';

interface ILoginFormValues {
  email: string;
  password: string;
}

interface ILoginForm {
  onSubmit: (email: string, password: string) => Promise<any>;
}

const useStyles = makeStyles(() => ({
  emailResendButton: {
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}));

const LoginForm: React.FC<ILoginForm> = ({ onSubmit }) => {
  const classes = useStyles();
  const history = useHistory();
  const redirectUrl = useRedirectUrl();
  const theme = useTheme();

  const handleSubmit = useCallback(
    (
      values: ILoginFormValues,
      formikHelpers: FormikHelpers<ILoginFormValues>
    ) => onSubmit(values.email, values.password).catch(formikHelpers.setErrors),
    [onSubmit]
  );

  const initialValues: ILoginFormValues = { email: '', password: '' };
  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA
  });

  const handleEmailResendButtonClick = useCallback(
    () =>
      activationEmailResend({ email: formik.values.email, redirectUrl })
        .then(() => {
          const registerPendingUrl = redirectUrl
            ? `${REGISTER_PENDING_URL}?${REDIRECT_URL_PARAM_KEY}=${redirectUrl}`
            : REGISTER_PENDING_URL;

          history.push(registerPendingUrl);
        })
        .catch(formik.setErrors),
    [formik.values.email, formik.setErrors, history, redirectUrl]
  );

  const formErrors: Array<IFormError> = _.get(
    formik.errors,
    GENERIC_FORM_ERRORS_KEY,
    []
  );

  const showAccountActivationHelperMessage = useMemo(
    () =>
      _.map(formErrors, 'message').includes(
        'Please check your email to activate this account.'
      ),
    [formErrors]
  );

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack
        spacing={3}
        alignItems="center"
        minWidth={mobile ? '100%' : '390px'}
      >
        {!_.isEmpty(formErrors) && (
          <Stack gap={2}>
            {formErrors.map((error, index) => (
              <Alert key={index} severity="error">
                {error.message}
              </Alert>
            ))}
            {showAccountActivationHelperMessage && (
              <Typography variant="subtitle1" textAlign="center">
                If you did not receive the activation email, click{' '}
                <span
                  className={classes.emailResendButton}
                  onClick={handleEmailResendButtonClick}
                >
                  here
                </span>{' '}
                to resend the email. Be sure to check your junk or spam folder.
              </Typography>
            )}
          </Stack>
        )}

        <TextField.Outlined
          inputProps={{
            'data-gtm': 'input-email'
          }}
          fullWidth
          autoFocus
          label="E-mail"
          type="email"
          name="email"
          placeholder="Email address"
          onChange={formik.handleChange}
          helperText={formik.errors?.email}
          error={!!formik.errors?.email}
        />

        <PasswordField
          inputProps={{
            'data-gtm': 'input-password'
          }}
          name="password"
          label="Password"
          placeholder="Password"
          formik={formik}
        />

        <Link
          data-gtm="link-forgot-password"
          to={FORGOT_PASSWORD_URL}
          alignSelf="flex-end"
        >
          Forgot your password?
        </Link>

        <Button
          type="submit"
          color="primary"
          onClick={formik.submitForm}
          disabled={formik.isSubmitting}
          data-gtm="button-submit-login"
        >
          Sign In
        </Button>
      </Stack>
    </form>
  );
};

export default LoginForm;
