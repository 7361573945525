import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import toast from 'utils/toast';
import { PROFILE_URL } from 'config/urls';
import { useRedirectToOnboarding } from 'utils/hooks';

import Grid from 'components/Grid';
import PageHeader from 'components/PageHeader';

import {
  useProfile,
  profileUpdate,
  IProfileUpdateFormValues
} from 'entities/Profile/sdk';
import { useSignedUser } from 'entities/Auth/sdk';
import { useSelectedPatient } from 'entities/Patient/sdk';
import ProfileUpdateForm from 'entities/Patient/components/ProfileUpdateForm';

const ProfileUpdate = () => {
  useRedirectToOnboarding();

  const history = useHistory();

  const { data: user } = useSignedUser();
  const { data: profile, refetch: refetchProfile } = useProfile({
    profileId: user?.profile.id
  });
  const { refetchCareTeams, refetchPatient } = useSelectedPatient();

  const handleSubmit = useCallback(
    ({
      profileId,
      profile,
      imageId
    }: {
      profileId: string;
      profile: IProfileUpdateFormValues;
      imageId?: string;
    }) =>
      profileUpdate({
        profileId,
        profile,
        imageId
      }).then(() => {
        refetchProfile();
        refetchPatient(); // So that we can refetch the patient information in the top navigation
        refetchCareTeams(); // So that we can refetch the patient information in the drawer
        history.push(PROFILE_URL);
        toast.info({ content: 'Profile has been updated successfully!' });
      }),
    [history, refetchProfile, refetchPatient, refetchCareTeams]
  );

  return (
    <Grid container direction="column" gap={2}>
      <Grid item>
        <PageHeader heading="Edit My Profile" backTo={PROFILE_URL} />
      </Grid>

      {profile && (
        <Grid item>
          <ProfileUpdateForm profile={profile} onSubmit={handleSubmit} />
        </Grid>
      )}
    </Grid>
  );
};
export default ProfileUpdate;
