export enum EMedicationIntakeFrequency {
  AS_NEEDED = 'as_needed',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  FORBIDDEN = 'forbidden'
}

export const MEDICATION_INTAKE_FREQUENCIES_THAT_REQUIRE_INTAKE_TIME = [
  EMedicationIntakeFrequency.DAILY,
  EMedicationIntakeFrequency.WEEKLY
];

export enum EMedicationRelationToMeals {
  BEFORE = 'before',
  WITH = 'with',
  DOES_NOT_APPLY = 'does_not_apply'
}

export enum EMedicationDosageTaken {
  FULLY_TAKEN = 'fully_taken',
  PARTIALLY_TAKEN = 'partially_taken',
  NOT_TAKEN = 'not_taken'
}

export const MEDICATION_INTAKE_FREQUENCY_CHOICES = [
  { label: 'Daily', value: EMedicationIntakeFrequency.DAILY },
  { label: 'Specific days', value: EMedicationIntakeFrequency.WEEKLY },
  { label: 'As needed', value: EMedicationIntakeFrequency.AS_NEEDED },
  { label: 'Do not take', value: EMedicationIntakeFrequency.FORBIDDEN }
];

export const MEDICATION_RELATION_TO_MEALS_CHOICES = [
  { label: 'Does not apply', value: EMedicationRelationToMeals.DOES_NOT_APPLY },
  { label: 'Before meals', value: EMedicationRelationToMeals.BEFORE },
  { label: 'With meals', value: EMedicationRelationToMeals.WITH }
];

export const MEDICATION_DOSAGE_TAKEN_CHOICES = [
  { label: 'Fully taken', value: EMedicationDosageTaken.FULLY_TAKEN },
  {
    label: 'Partially taken',
    value: EMedicationDosageTaken.PARTIALLY_TAKEN
  },
  { label: 'Not taken', value: EMedicationDosageTaken.NOT_TAKEN }
];
