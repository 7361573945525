import _ from 'lodash';
import { useCallback, useState, useReducer } from 'react';
import { useLocation } from 'react-router-dom';

import { queryStringParse } from 'utils/common';

import PageHeader from 'components/PageHeader';
import Stack from 'components/Stack';
import PageLoader from 'components/PageLoader';
import BlankState from 'components/BlankState';
import ConfirmationDialog from 'components/ConfirmationDialog';
import FileImageUploadDialog from 'components/FileImageUploadDialog';
import Menu from 'components/Menu';
import { MenuItem } from '@mui/material';
import TextField from 'components/TextField';
import InputAdornment from 'components/InputAdornment';
import IconButton from 'components/IconButton';

import { PlaylistAddIcon, SearchIcon, CancelIcon } from 'icons';

import { HOME_URL } from 'config/urls';

import { useSelectedPatient } from 'entities/Patient/sdk';
import {
  useDocumentsList,
  IUserDocument,
  documentDelete,
  documentUpdate,
  documentPermissionsUpdate,
  IUserDocumentEditableValues,
  IUserDocumentPermissions
} from 'entities/Documents/sdk';

import { userDocUpload } from 'entities/Files/sdk';

import { EDocumentsMenuItems } from './constants';
import { EAcceptedFileTypes } from 'constants/common';
import {
  PAPER_DIMENSIONS,
  EPermission_Level
} from 'entities/Documents/constants';

import DocumentPreviewDialog from 'entities/Documents/components/DocumentPreviewDialog';
import DocumentsMenu from 'entities/Documents/components/DocumentsMenu';
import DocumentEditDialog from 'entities/Documents/components/DocumentEditDialog';
import DocumentPermissionsDialog from 'entities/Documents/components/DocumentPermissionsDialog';
import DocumentsContent from 'entities/Documents/components/DocumentsContent';
import toast from 'utils/toast';
import { generatePDFFileFromImages } from 'entities/Documents/utils';
import { createNewImage, getImageUrl } from 'utils/images';

import {
  SHOW_DOCUMENTS_ADD_PHOTOS_DIALOG_OPEN,
  SHOW_DOCUMENTS_ADD_DEVICE_DIALOG_OPEN
} from './constants';

interface IDocuments {}

type DocumentState = {
  showDocumentFromDeviceDialog: boolean;
  showDocumentFromPhotosDialog: boolean;
  showDocumentPermissionsDialog: boolean;
  showDocumentEditDialog: boolean;
  showDeleteConfirmationDialog: boolean;
  showDocumentPreviewDialog: boolean;
  showColorPicker: boolean;
};

export type DocumentAction =
  | { type: 'new-doc-device-open' }
  | { type: 'new-doc-device-close' }
  | { type: 'new-doc-device-permissions' }
  | { type: 'new-doc-photo-open' }
  | { type: 'new-doc-photo-close' }
  | { type: 'new-doc-photo-permissions' }
  | { type: 'permissions-open' }
  | { type: 'permissions-close' }
  | { type: 'delete-open' }
  | { type: 'delete-close' }
  | { type: 'preview-open' }
  | { type: 'preview-close' }
  | { type: 'edit-open' }
  | { type: 'edit-close' }
  | { type: 'color-picker-open' }
  | { type: 'color-picker-close' };

const Documents: React.FC<IDocuments> = () => {
  const { patient } = useSelectedPatient();
  const [searchTerm, setSearchTerm] = useState<string | undefined>('');
  const {
    data: documents = [],
    loading: isFetchingDocuments,
    refetch: refetchDocumentList
  } = useDocumentsList({
    patientId: patient?.id,
    searchTerm
  });

  // Get query string from URL
  const location = useLocation();
  const queryParams = queryStringParse({
    queryParams: location.search
  });

  const queryShowDocumentFromPhotosDialog = _.get(
    queryParams,
    SHOW_DOCUMENTS_ADD_PHOTOS_DIALOG_OPEN
  );
  const queryShowDocumentFromDeviceDialog = _.get(
    queryParams,
    SHOW_DOCUMENTS_ADD_DEVICE_DIALOG_OPEN
  );

  const initialDialogValues = {
    showDocumentFromDeviceDialog:
      Boolean(queryShowDocumentFromDeviceDialog) || false,
    showDocumentFromPhotosDialog:
      Boolean(queryShowDocumentFromPhotosDialog) || false,
    showDocumentPermissionsDialog: false,
    showDocumentEditDialog: false,
    showDeleteConfirmationDialog: false,
    showDocumentPreviewDialog: false,
    showColorPicker: false
  };

  const documentReducer = (state: DocumentState, action: DocumentAction) => {
    switch (action.type) {
      case 'new-doc-device-open':
        return {
          ...state,
          showDocumentFromDeviceDialog: true
        };
      case 'new-doc-device-close':
        return {
          ...state,
          showDocumentFromDeviceDialog: false
        };
      case 'new-doc-device-permissions':
        return {
          ...state,
          showDocumentFromDeviceDialog: false,
          showDocumentPermissionsDialog: true
        };
      case 'new-doc-photo-open':
        return {
          ...state,
          showDocumentFromPhotosDialog: true
        };
      case 'new-doc-photo-close':
        return {
          ...state,
          showDocumentFromPhotosDialog: false
        };
      case 'new-doc-photo-permissions':
        return {
          ...state,
          showDocumentFromPhotosDialog: false,
          showDocumentPermissionsDialog: true
        };
      case 'permissions-open':
        return {
          ...state,
          showDocumentPermissionsDialog: true
        };
      case 'permissions-close':
        return {
          ...state,
          showDocumentPermissionsDialog: false
        };
      case 'delete-open':
        return {
          ...state,
          showDeleteConfirmationDialog: true
        };
      case 'delete-close':
        return {
          ...state,
          showDeleteConfirmationDialog: false
        };
      case 'preview-open':
        return {
          ...state,
          showDocumentPreviewDialog: true
        };
      case 'preview-close':
        return {
          ...state,
          showDocumentPreviewDialog: false
        };
      case 'edit-open':
        return {
          ...state,
          showDocumentEditDialog: true
        };
      case 'edit-close':
        return {
          ...state,
          showDocumentEditDialog: false
        };
      case 'color-picker-open':
        return {
          ...state,
          showColorPicker: true
        };
      case 'color-picker-close':
        return {
          ...state,
          showColorPicker: false
        };
    }
  };

  const [isDialogOpen, dispatchIsDialogOpen] = useReducer(
    documentReducer,
    initialDialogValues
  );

  const [selectedDocument, setSelectedDocument] =
    useState<IUserDocument | null>(null);
  const [documentAnchorEl, setDocumentAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const addFABMenuItems = [
    {
      label: EDocumentsMenuItems.NEW_DOC_FROM_DEVICE,
      onClick: () => dispatchIsDialogOpen({ type: 'new-doc-device-open' })
    },
    {
      label: EDocumentsMenuItems.NEW_DOC_FROM_PHOTOS,
      onClick: () => dispatchIsDialogOpen({ type: 'new-doc-photo-open' })
    }
  ];

  const handleDocumentMoreClose = () => setDocumentAnchorEl(null);

  const handleDocumentDelete = useCallback(() => {
    if (!patient?.id) {
      return Promise.reject("There's no patient in the care team.");
    }

    if (!selectedDocument) {
      return Promise.reject('No document has been selected!');
    }

    documentDelete({
      patientId: patient?.id,
      documentId: selectedDocument?.id
    }).then(() => {
      refetchDocumentList();
      dispatchIsDialogOpen({ type: 'delete-close' });
      setSelectedDocument(null);
      setDocumentAnchorEl(null);
      toast.info({
        content: 'Document deleted!'
      });
    });
  }, [patient, selectedDocument, refetchDocumentList]);

  const handleDocumentUpload = useCallback(
    (files: Array<File>, fileName?: string) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      files.forEach((file, index) => {
        userDocUpload({
          patientID: patient.id,
          document: file
        }).then((result) => {
          refetchDocumentList();
          if (result['document'][0].number_of_permissioned_users > 1) {
            setSelectedDocument(result['document'][0]);
            dispatchIsDialogOpen({ type: 'new-doc-device-permissions' });
          } else dispatchIsDialogOpen({ type: 'new-doc-device-close' });
          toast.info({
            content: 'Document uploaded!'
          });
        });
      });
    },
    [patient, refetchDocumentList]
  );

  const handlePhotoUpload = useCallback(
    (files: Array<File>, fileName?: string) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      if (files.length === 0) {
        return Promise.reject('No images were added!');
      }

      const imageURLs = files.map((file) =>
        getImageUrl(file, PAPER_DIMENSIONS.US_LETTER.width * 96)
      );
      Promise.all(imageURLs).then((imageURLs) => {
        const imageArray = imageURLs.map(createNewImage);

        Promise.all(imageArray).then((result) => {
          const imageConsolidatedPDF = generatePDFFileFromImages({
            images: result,
            paperDimensions: {
              width: PAPER_DIMENSIONS.US_LETTER.width,
              height: PAPER_DIMENSIONS.US_LETTER.height
            },
            fileName
          });

          userDocUpload({
            patientID: patient.id,
            document: imageConsolidatedPDF
          }).then((result) => {
            refetchDocumentList();
            if (result['document'][0].number_of_permissioned_users > 1) {
              setSelectedDocument(result['document'][0]);
              dispatchIsDialogOpen({ type: 'new-doc-photo-permissions' });
            } else dispatchIsDialogOpen({ type: 'new-doc-photo-close' });
            toast.info({
              content: 'Document successfully uploaded!'
            });
          });
        });
      });
    },
    [refetchDocumentList, patient]
  );

  const handleDocumentEdit = useCallback(
    (documentData: IUserDocumentEditableValues) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      if (!selectedDocument) {
        return Promise.reject('No document has been selected!');
      }

      documentUpdate({
        patientId: patient.id,
        documentId: selectedDocument.id,
        documentData
      }).then(() => {
        refetchDocumentList();
        dispatchIsDialogOpen({ type: 'edit-close' });
        setSelectedDocument(null);
        toast.info({
          content: 'Document successfully updated!'
        });
      });
    },
    [refetchDocumentList, patient, selectedDocument]
  );

  const handleDocumentPermissionsEdit = useCallback(
    (documentPermissionsData: IUserDocumentPermissions[]) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      if (!selectedDocument) {
        return Promise.reject('No document has been selected!');
      }

      documentPermissionsUpdate({
        documentId: selectedDocument.id,
        patientId: patient.id,
        documentPermissionsData
      }).then(() => {
        refetchDocumentList();
        dispatchIsDialogOpen({ type: 'permissions-close' });
        setSelectedDocument(null);
        toast.info({
          content: 'Document permissions successfully updated!'
        });
      });
    },
    [refetchDocumentList, patient, selectedDocument]
  );

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);

      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      if (!searchTerm) {
        return Promise.reject('No search term specified!');
      }

      refetchDocumentList();
    },
    [searchTerm, refetchDocumentList, patient?.id]
  );

  return (
    <>
      <Stack paddingY={2}>
        <PageHeader heading="Documents" backTo={HOME_URL} />

        <Stack paddingY={2} alignItems="flex-start">
          <TextField.Outlined
            fullWidth
            id="search"
            size="small"
            value={searchTerm}
            placeholder="Search document name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ visibility: searchTerm ? 'visible' : 'hidden' }}
                    onClick={() => {
                      setSearchTerm('');
                      refetchDocumentList();
                    }}
                  >
                    <CancelIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
            onChange={handleSearch}
          />
        </Stack>

        {isFetchingDocuments && <PageLoader />}

        {!isFetchingDocuments &&
          patient &&
          _.isEmpty(documents) &&
          searchTerm && (
            <BlankState
              icon={PlaylistAddIcon}
              title="No documents match your search!"
            />
          )}

        {!isFetchingDocuments &&
          patient &&
          _.isEmpty(documents) &&
          !searchTerm && (
            <BlankState
              icon={PlaylistAddIcon}
              title="No documents uploaded yet!"
            />
          )}

        <DocumentsMenu items={addFABMenuItems} />

        <Stack>
          <DocumentsContent
            documents={documents}
            setSelectedDocument={setSelectedDocument}
            isFetchingDocuments={isFetchingDocuments}
            dispatchIsDialogOpen={dispatchIsDialogOpen}
            documentAnchorEl={null}
            setDocumentAnchorEl={setDocumentAnchorEl}
            setSearchTerm={setSearchTerm}
            refetchDocumentsListInParent={refetchDocumentList}
          />
          <Menu
            id="document-menu"
            anchorEl={documentAnchorEl}
            open={Boolean(documentAnchorEl)}
            onClose={handleDocumentMoreClose}
          >
            <MenuItem
              onClick={() => {
                dispatchIsDialogOpen({ type: 'edit-open' });
                handleDocumentMoreClose();
              }}
              disabled={
                selectedDocument?.user_permission === EPermission_Level.VIEW
                  ? true
                  : false
              }
              data-gtm="documents-menu-select-edit"
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatchIsDialogOpen({ type: 'permissions-open' });
                handleDocumentMoreClose();
              }}
              data-gtm="documents-menu-set-permissions"
              disabled={
                selectedDocument?.user_permission === EPermission_Level.ADMIN
                  ? false
                  : true
              }
            >
              Set permissions
            </MenuItem>
            <MenuItem disabled={true}>Download</MenuItem>
            <MenuItem
              onClick={() => dispatchIsDialogOpen({ type: 'delete-open' })}
              data-gtm="documents-menu-select-delete"
              disabled={
                selectedDocument?.user_permission === EPermission_Level.VIEW
                  ? true
                  : false
              }
            >
              Delete
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>

      {isDialogOpen.showDocumentFromDeviceDialog && (
        <FileImageUploadDialog
          open={true}
          message="Drag 'n' drop files here. Or, click to select files."
          instructions="Select one or more PDF files to upload from your device."
          acceptedFileTypes={[EAcceptedFileTypes.PDF]}
          onClose={() => dispatchIsDialogOpen({ type: 'new-doc-device-close' })}
          onUpload={handleDocumentUpload}
        />
      )}
      {isDialogOpen.showDocumentFromPhotosDialog && (
        <FileImageUploadDialog
          open={true}
          message="Drag 'n' drop files here. Or, click to select."
          instructions="Use your phone's camera to convert one or more photos into a single PDF."
          acceptedFileTypes={[EAcceptedFileTypes.IMAGE]}
          showFileNameInput={true}
          onClose={() => dispatchIsDialogOpen({ type: 'new-doc-photo-close' })}
          onUpload={handlePhotoUpload}
        />
      )}
      {isDialogOpen.showDocumentPreviewDialog && selectedDocument && (
        <DocumentPreviewDialog
          open={true}
          onClose={() => dispatchIsDialogOpen({ type: 'preview-close' })}
          file={selectedDocument}
          refetchDocumentListInParent={refetchDocumentList}
        />
      )}
      {isDialogOpen.showDeleteConfirmationDialog && selectedDocument && (
        <ConfirmationDialog
          open={true}
          onClose={() => {
            dispatchIsDialogOpen({ type: 'delete-close' });
            setDocumentAnchorEl(null);
          }}
          title="Are you sure you want to delete this file?"
          onConfirm={handleDocumentDelete}
        />
      )}
      {isDialogOpen.showDocumentEditDialog && selectedDocument && (
        <DocumentEditDialog
          patientId={patient?.id}
          document={selectedDocument}
          onDocumentEdit={handleDocumentEdit}
          onClose={() => {
            dispatchIsDialogOpen({ type: 'edit-close' });
            setSelectedDocument(null);
          }}
        />
      )}
      {isDialogOpen.showDocumentPermissionsDialog && selectedDocument && (
        <DocumentPermissionsDialog
          document={selectedDocument}
          onDocumentPermissionEdit={handleDocumentPermissionsEdit}
          onClose={() => {
            dispatchIsDialogOpen({ type: 'permissions-close' });
            setSelectedDocument(null);
          }}
        />
      )}
      {/*isDialogOpen.showColorPicker && selectedDocument && (
        <ColorPicker
          //setSelectedColor={dispatchSelectedTag}
          onClose={() => {
            dispatchIsDialogOpen({ type: 'color-picker-close' });
          }}
        />
        )*/}
    </>
  );
};

export default Documents;
