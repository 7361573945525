import _ from 'lodash';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';

import {
  AddIcon,
  DeleteOutlinedIcon,
  EditOutlinedIcon,
  KeyboardArrowDownIcon
} from 'icons';

import toast from 'utils/toast';
import { PATIENT_PROFILE_URL } from 'config/urls';
import { useRedirectToOnboarding } from 'utils/hooks';

import Grid from 'components/Grid';
import Button from 'components/Button';

import BlankState from 'components/BlankState';
import Loading from 'components/Loading';
import Accordion from 'components/Accordion';
import PageHeader from 'components/PageHeader';
import Typography from 'components/Typography';
import IconButton from 'components/IconButton';
import BottomFabAdd from 'components/BottomFabAdd';
import ConfirmationDialog from 'components/ConfirmationDialog';

import {
  conditionDelete,
  conditionUpdate,
  conditionCreate,
  IMedicalCondition,
  INewMedicalCondition,
  useMedicalConditionChoices,
  usePatientMedicalConditions
} from 'entities/Patient/sdk';
import { useSelectedPatient } from 'entities/Patient/sdk';
import { useSelectedCareTeam } from 'entities/CareTeam/sdk';
import ConditionDetailsDialog from 'entities/Profile/components/ConditionDetailsDialog';

interface IMedicalHistory {}

const useStyles = makeStyles(() => ({
  nonUppercasedButton: {
    width: 'auto',
    textTransform: 'none'
  }
}));

const MedicalHistory: React.FC<IMedicalHistory> = () => {
  useRedirectToOnboarding();

  const classes = useStyles();

  const [conditionDetailsDialogCondition, setConditionDetailsDialogCondition] =
    useState<IMedicalCondition | INewMedicalCondition | null>(null);
  const [conditionToBeDeleted, setConditionToBeDeleted] =
    useState<IMedicalCondition | null>(null);
  const [expandedConditions, setExpandedConditions] = useState<Array<string>>(
    []
  );

  const { patient } = useSelectedPatient();
  const { data: conditionChoices = [] } = useMedicalConditionChoices();
  const {
    userIsPatient,
    userIsFamilyMember,
    loading: isFetchingCareTeam
  } = useSelectedCareTeam();

  const userCanEditMedicalHistory = userIsPatient || userIsFamilyMember;

  const {
    data: conditions = [],
    loading: isFetchingConditions,
    mutate: mutateConditions
  } = usePatientMedicalConditions({
    patientId: patient?.id
  });

  const expandAll = () => {
    setExpandedConditions(_.map(conditions, 'medical_condition_id'));
  };

  const collapseAll = () => {
    setExpandedConditions([]);
  };

  const toggleCondition = ({ conditionId }: { conditionId: string }) => {
    setExpandedConditions(_.xor(expandedConditions, [conditionId]));
  };

  const handleConditionUpdate = async ({
    condition
  }: {
    condition: IMedicalCondition | INewMedicalCondition;
  }) => {
    if (!patient?.id) {
      return Promise.reject("There's no patient in the care team.");
    }

    if ('medical_condition_id' in condition) {
      return conditionUpdate({
        patientId: patient.id,
        conditionId: condition.medical_condition_id,
        condition
      }).then(() => {
        mutateConditions();
        setConditionDetailsDialogCondition(null);
        toast.info({
          content: 'Medical condition has been updated successfully!'
        });
      });
    } else {
      return conditionCreate({
        condition,
        patientId: patient?.id
      }).then(() => {
        mutateConditions();
        setConditionDetailsDialogCondition(null);
        toast.info({
          content: 'Medical condition has been created successfully!'
        });
      });
    }
  };

  const handleConditionDelete = async ({
    condition
  }: {
    condition: IMedicalCondition;
  }) => {
    if (!patient?.id) {
      return Promise.reject("There's no patient in the care team.");
    }

    conditionDelete({
      patientId: patient?.id,
      conditionId: condition.medical_condition_id
    }).then(() => {
      mutateConditions();
      setConditionToBeDeleted(null);
      toast.info({
        content: 'Medical condition has been deleted successfully!'
      });
    });
  };

  const conditionsAreEmpty = !isFetchingConditions && _.isEmpty(conditions);

  const conditionsAreEmptyAndEditable =
    conditionsAreEmpty && !isFetchingCareTeam && userCanEditMedicalHistory;

  const conditionsAreEmptyAndUneditable =
    conditionsAreEmpty && !isFetchingCareTeam && !userCanEditMedicalHistory;

  return (
    <>
      <Grid container gap={2}>
        <Grid item>
          <PageHeader heading="Medical History" backTo={PATIENT_PROFILE_URL} />
        </Grid>

        <Grid item container flexDirection="column">
          {userCanEditMedicalHistory && (
            <Grid item>
              <Typography variant="subtitle2" marginBottom={3}>
                Add relevant medical conditions
              </Typography>
            </Grid>
          )}

          <Grid item container marginBottom={5} justifyContent="flex-end">
            <Grid item>
              <Button variant="text" onClick={expandAll}>
                <Typography
                  variant="h5"
                  className={classes.nonUppercasedButton}
                >
                  Expand all
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                onClick={collapseAll}
                className={classes.nonUppercasedButton}
              >
                <Typography variant="h5">Collapse all</Typography>
              </Button>
            </Grid>
          </Grid>

          <Grid item container justifyContent="center">
            <Grid item container>
              {/* TODO: Add a state for when there aren't any conditions yet.  */}
              {isFetchingConditions && (
                <Grid item width="100%" justifyContent="center" display="flex">
                  <Loading />
                </Grid>
              )}
              {conditionsAreEmptyAndEditable && (
                <BlankState
                  icon={AddIcon}
                  onClick={() =>
                    setConditionDetailsDialogCondition({
                      description: '',
                      condition_type: ''
                    })
                  }
                  subtitle="No medical history has been added yet."
                />
              )}
              {conditionsAreEmptyAndUneditable && (
                <Grid item width="100%" justifyContent="center" display="flex">
                  <Typography variant="subtitle1">
                    No medical history has been added yet.
                  </Typography>
                </Grid>
              )}
              {conditions.map((condition: IMedicalCondition) => (
                <Grid item width="100%">
                  <Accordion
                    square
                    key={condition.medical_condition_id}
                    expanded={_.includes(
                      expandedConditions,
                      condition.medical_condition_id
                    )}
                    onChange={() =>
                      toggleCondition({
                        conditionId: condition.medical_condition_id
                      })
                    }
                  >
                    <Accordion.Summary expandIcon={<KeyboardArrowDownIcon />}>
                      {
                        _.find(conditionChoices, {
                          value: condition.condition_type
                        })?.label
                      }
                    </Accordion.Summary>
                    <Accordion.Details>
                      {condition.description}
                      {userCanEditMedicalHistory && (
                        <Grid
                          container
                          spacing={2}
                          marginTop={2}
                          justifyContent="flex-end"
                        >
                          <Grid item>
                            <IconButton
                              size="small"
                              onClick={() =>
                                setConditionDetailsDialogCondition(condition)
                              }
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <IconButton
                              size="small"
                              onClick={() => setConditionToBeDeleted(condition)}
                            >
                              <DeleteOutlinedIcon fontSize="small" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}
                    </Accordion.Details>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!_.isNil(conditionDetailsDialogCondition) && (
        <ConditionDetailsDialog
          open
          onClose={() => setConditionDetailsDialogCondition(null)}
          onSave={handleConditionUpdate}
          title="Add Condition Details"
          previousConditions={conditions}
          condition={conditionDetailsDialogCondition}
        />
      )}

      {!_.isNil(conditionToBeDeleted) && (
        <ConfirmationDialog
          open
          title="Are you sure you want to remove this condition?"
          primaryButtonColor="error"
          onClose={() => setConditionToBeDeleted(null)}
          onConfirm={() => {
            handleConditionDelete({ condition: conditionToBeDeleted });
          }}
        />
      )}
      {userCanEditMedicalHistory && (
        <BottomFabAdd
          onClick={() =>
            setConditionDetailsDialogCondition({
              description: '',
              condition_type: ''
            })
          }
        />
      )}
    </>
  );
};

export default MedicalHistory;
