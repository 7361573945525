import _ from 'lodash';

import { useCallback, useState } from 'react';
import { useFormik, FormikHelpers } from 'formik';

import { useTheme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';

import Grid from 'components/Grid';
import Radio from 'components/Radio';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Dialog from 'components/Dialog';
import RadioGroup from 'components/RadioGroup';
import Typography from 'components/Typography';
import CopyToClipboard from 'components/CopyToClipboard';
import FormControlLabel from 'components/FormControlLabel';

import { ShareIcon } from 'icons';

import { PROFILE_ROLES } from 'entities/Profile/constants';

import { useSelectedPatient } from 'entities/Patient/sdk';

import {
  useSelectedCareTeam,
  careTeamMemberInvitationCreate,
  IMemberCareTeamRole,
  ICareTeamMemberInvitation
} from 'entities/CareTeam/sdk';

import { useProfile } from 'entities/Profile/sdk';
import { useSignedUser } from 'entities/Auth/sdk';

import { GENERIC_FORM_ERRORS_KEY, IFormError } from 'utils/sdk';

import { VALIDATION_SCHEMA } from './constants';

interface IMemberInvitationCreateDialog {
  onClose: () => void;
}

const MemberInvitationCreateDialog = ({
  onClose
}: IMemberInvitationCreateDialog) => {
  const { data: user } = useSignedUser();
  const { data: profile } = useProfile({
    profileId: user?.profile.id
  });

  const { patient } = useSelectedPatient();

  const { careTeamId } = useSelectedCareTeam();

  const [inviteLink, setInviteLink] = useState<string | undefined>();

  const handleSubmit = useCallback(
    (
      values: IMemberCareTeamRole,
      formikHelpers: FormikHelpers<IMemberCareTeamRole>
    ) => {
      if (!careTeamId) {
        return Promise.reject('No care team found.');
      }
      return careTeamMemberInvitationCreate({ careTeamId, values })
        .then((invitation: ICareTeamMemberInvitation) => {
          setInviteLink(
            `${window.location.origin}/care-team/${careTeamId}/members/invitation/${invitation.id}`
          );
        })
        .catch(formikHelpers.setErrors);
    },
    [careTeamId]
  );

  const initialValues: IMemberCareTeamRole = {
    care_team_role: PROFILE_ROLES.FAMILY_MEMBER
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA
  });

  const formErrors: Array<IFormError> = _.get(
    formik.errors,
    GENERIC_FORM_ERRORS_KEY,
    []
  );

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const hasShareDialog = !_.isNil(navigator.share);

  const openShareDialog = useCallback(() => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Invitation to join a Care Team in Carederly',
          text: `${profile?.first_name} has invited you to join ${patient?.profile.first_name} ${patient?.profile.last_name}'s Care Team on Carederly. Please click on this link to accept this invitation. The invitation expires in 24 hours.`,
          url: inviteLink
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  }, [inviteLink, patient, profile]);

  return (
    <Dialog onClose={onClose} open title="Add care team member">
      <form onSubmit={formik.handleSubmit}>
        {!_.isEmpty(formErrors) && (
          <Grid item xs={12}>
            {formErrors.map((error, index) => (
              <Alert key={index} severity="error">
                {error.message}
              </Alert>
            ))}
          </Grid>
        )}

        <Grid
          container
          direction="column"
          spacing={1}
          paddingX={2}
          paddingBottom={6}
        >
          {!inviteLink && (
            <>
              <Grid item>
                <RadioGroup
                  row
                  defaultValue={PROFILE_ROLES.FAMILY_MEMBER}
                  name="care_team_role"
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value={PROFILE_ROLES.FAMILY_MEMBER}
                    control={<Radio />}
                    label="Family member"
                  />
                  <FormControlLabel
                    value={PROFILE_ROLES.AIDE}
                    control={<Radio />}
                    label="Aide"
                  />
                </RadioGroup>
              </Grid>

              <Grid item>
                <Typography variant="body1">
                  Generate a referral link to invite a person
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  disabled={formik.isSubmitting || !_.isNil(inviteLink)}
                  data-gtm="button-submit-generate-invitation-link"
                >
                  Generate a link
                </Button>
              </Grid>
            </>
          )}

          {inviteLink && (
            <>
              {/*Not all desktop browsers support navigator share.
              There are some extensions that attach navigator.share even when it's not supported.
              For now we are hiding the share button on desktop.*/}
              {mobile && hasShareDialog ? (
                <>
                  <Grid item>
                    <Typography variant="body1">
                      You can share link by pressing the Share button.
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Button
                      onClick={openShareDialog}
                      color="primary"
                      startIcon={<ShareIcon />}
                      sx={{ marginBottom: 4 }}
                    >
                      Share
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item>
                    <Typography variant="body1">
                      Copy this link and paste it into a text or email message
                      to the person you would like to invite.
                    </Typography>
                  </Grid>

                  <Grid item>
                    <CopyToClipboard>
                      {({ copy }) => (
                        <Button onClick={() => copy(inviteLink)}>
                          Copy the link
                        </Button>
                      )}
                    </CopyToClipboard>
                  </Grid>
                </>
              )}
            </>
          )}
          <Grid item>
            <Typography variant="body1">
              The invitation expires in 24 hours.
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default MemberInvitationCreateDialog;
