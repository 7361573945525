import Link from 'components/Link';
import Grid from 'components/Grid';
import Stack from 'components/Stack';
import Typography from 'components/Typography';

import { CheckIcon, CloseIcon, EditOutlinedIcon } from 'icons';

import { useSelectedCareTeam } from 'entities/CareTeam/sdk';
import { IVitalsToTrack as IPatientVitalsToTrack } from 'entities/Patient/sdk';

import { VITALS_TO_TRACK_UPDATE_URL } from 'config/urls';

interface IVitalsToTrack {
  vitals: IPatientVitalsToTrack;
}

const VitalsToTrack: React.FC<IVitalsToTrack> = ({ vitals }) => {
  const { userIsPatient, userIsFamilyMember } = useSelectedCareTeam();

  return (
    <Stack paddingTop={4} spacing={2}>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Grid item>
          <Typography variant="h2">Vitals to track</Typography>
        </Grid>

        {(userIsPatient || userIsFamilyMember) && (
          <Grid item>
            <Link.DarkGrey to={VITALS_TO_TRACK_UPDATE_URL}>
              <EditOutlinedIcon fontSize="large" />
            </Link.DarkGrey>
          </Grid>
        )}
      </Grid>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">Temperature</Typography>
        {vitals.temperature ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        )}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">Blood pressure</Typography>
        {vitals.blood_pressure ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        )}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">Pulse</Typography>
        {vitals.pulse ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        )}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">Blood oxygen</Typography>
        {vitals.blood_oxygen ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        )}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">Blood sugar</Typography>
        {vitals.blood_sugar ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        )}
      </Stack>
    </Stack>
  );
};

export default VitalsToTrack;
