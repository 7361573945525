import { useMemo } from 'react';
import _ from 'lodash';

import { usePatientCustomVitals } from 'entities/Patient/sdk';
import { useVitalNotes, IVitalNote, IChartDataItem } from 'entities/Diary/sdk';
import { RESOLUTION } from 'entities/HealthTrends/constants';

import { datetime } from 'utils/datetime';
import { roundUpToTheNearestTenth } from 'utils/common';
import { BACKEND_DATETIME_FORMAT } from 'constants/time';
import { ETemperatureUnit } from 'constants/common';

import { VITALS } from 'entities/HealthTrends/constants';

import {
  VITAL_TO_KEYS_MAPPING,
  VITALS_LINE_COLORS,
  VITALS_NORMAL_RANGE_COLORS,
  VITAL_TO_DOMAINS_MAPPING
} from './constants';

const getVitalData = ({
  data,
  vital
}: {
  data: Array<IVitalNote>;
  vital: string;
}) =>
  data.filter((item: IVitalNote) =>
    VITAL_TO_KEYS_MAPPING[vital].some(
      (key: string) => !_.isNil(_.get(item, key))
    )
  );

const getVitalReferenceDomain = ({
  selectedVital,
  temperatureUnit,
  selectedVitalNotes,
  selectedVitalReferenceAreas
}: {
  selectedVital: string;
  temperatureUnit: ETemperatureUnit;
  selectedVitalNotes: Array<IChartDataItem>;
  selectedVitalReferenceAreas: { [key: string]: Array<number> };
}): [number, number] | [] => {
  const domains = VITAL_TO_DOMAINS_MAPPING({ selectedVital, temperatureUnit });

  const values = _.reduce(
    selectedVitalNotes,
    (result: Array<number>, vitalNote: IChartDataItem) => {
      VITAL_TO_KEYS_MAPPING[selectedVital].forEach((key) => {
        const value = _.get(vitalNote, key);

        if (!_.isNull(value)) {
          result.push(_.toNumber(value));
        }
      });

      return result;
    },
    []
  );

  const referenceDomains = [
    ...values,
    ...domains,
    ...VITAL_TO_KEYS_MAPPING[selectedVital].flatMap(
      (key) => selectedVitalReferenceAreas[key]
    )
  ];

  return [
    roundUpToTheNearestTenth({
      number: Math.min(...referenceDomains) - 10
    }),
    roundUpToTheNearestTenth({
      number: Math.max(...referenceDomains) + 10
    })
  ];
};

export const useChartLines = ({
  patientId,
  selectedVital,
  data
}: {
  patientId: string | undefined;
  selectedVital: string;
  data: Array<IChartDataItem>;
}) => {
  const { data: customVitals } = usePatientCustomVitals({ patientId });

  return useMemo(() => {
    const getValueFromVitals = (key: string) =>
      parseInt(_.get(customVitals, key, ''));

    const VITALS_VALUES = {
      temperature: [
        VITAL_TO_DOMAINS_MAPPING({
          selectedVital: VITALS.TEMPERATURE,
          temperatureUnit: customVitals?.temperature_unit
        })[0],
        getValueFromVitals('temperature_max')
      ],
      blood_pressure_systolic: [
        getValueFromVitals('blood_pressure_systolic_min'),
        getValueFromVitals('blood_pressure_systolic_max')
      ],
      blood_pressure_diastolic: [
        getValueFromVitals('blood_pressure_diastolic_min'),
        getValueFromVitals('blood_pressure_diastolic_max')
      ],
      pulse: [getValueFromVitals('pulse_min'), getValueFromVitals('pulse_max')],
      blood_oxygen: [
        getValueFromVitals('blood_oxygen_min'),
        VITAL_TO_DOMAINS_MAPPING({
          selectedVital: VITALS.BLOOD_OXYGEN,
          temperatureUnit: customVitals?.temperature_unit
        })[1]
      ],
      blood_sugar_fasting: [
        getValueFromVitals('blood_sugar_fasting_min'),
        getValueFromVitals('blood_sugar_fasting_max')
      ],
      blood_sugar_non_fasting: [
        getValueFromVitals('blood_sugar_non_fasting_min'),
        getValueFromVitals('blood_sugar_non_fasting_max')
      ]
    };

    const selectedVitalReferenceDomains = customVitals
      ? getVitalReferenceDomain({
          selectedVital,
          temperatureUnit: customVitals.temperature_unit,
          selectedVitalNotes: data,
          selectedVitalReferenceAreas: VITALS_VALUES
        })
      : [];

    let lines = _.get(VITAL_TO_KEYS_MAPPING, selectedVital, []).map(
      (vitalKey: string) => ({
        key: vitalKey,
        color: _.get(VITALS_LINE_COLORS, vitalKey, 'red'),
        domain: selectedVitalReferenceDomains,
        referenceArea: _.get(VITALS_VALUES, vitalKey, []),
        referenceAreaColor: _.get(
          VITALS_NORMAL_RANGE_COLORS,
          vitalKey,
          '#94E31580'
        )
      })
    );

    if (selectedVital === VITALS.BLOOD_PRESSURE) {
      // If "blood pressure" has been selected we want to put
      // the systolic values first, as it affects both the legend of the chart
      // and the tooltips.
      lines = _.orderBy(lines, ['key'], ['desc']);
    }

    return lines;
  }, [customVitals, selectedVital, data]);
};

const PERIOD_PER_RESOLUTION: { [key: string]: [number, string] } = {
  [RESOLUTION.WEEKLY]: [7, 'days'],
  [RESOLUTION.MONTHLY]: [4, 'weeks'],
  [RESOLUTION.QUARTERLY]: [3, 'months']
};

const getDateRangeForResolution = (
  resolution:
    | typeof RESOLUTION.WEEKLY
    | typeof RESOLUTION.MONTHLY
    | typeof RESOLUTION.QUARTERLY
) => {
  const [periodAmount, periodType] = _.get(PERIOD_PER_RESOLUTION, resolution, [
    0,
    ''
  ]);
  const startDate = datetime()
    .subtract(periodAmount, periodType)
    .format(BACKEND_DATETIME_FORMAT);

  const endDate = datetime().format(BACKEND_DATETIME_FORMAT);

  return [startDate, endDate];
};

export const useChartData = ({
  patientId,
  selectedVital,
  resolution
}: {
  patientId: string | undefined;
  selectedVital: string;
  resolution: string;
}): {
  chartData: Array<IChartDataItem>;
  startDateTime: string;
  endDateTime: string;
} => {
  const [startDateTime, endDateTime] = useMemo(
    () => getDateRangeForResolution(resolution),
    [resolution]
  );

  const { data: vitalNotes = [] } = useVitalNotes({
    patientId,
    startDateTime,
    endDateTime
  });

  const selectedVitalNotes = useMemo(
    () =>
      getVitalData({
        data: vitalNotes,
        vital: selectedVital
      }),
    [selectedVital, vitalNotes]
  );

  const chartData = useMemo(() => {
    return selectedVitalNotes.map((note: IVitalNote) => ({
      ...note,
      timestamp: datetime(note.measured_at, BACKEND_DATETIME_FORMAT).valueOf(),
      label: selectedVital
    }));
  }, [selectedVitalNotes, selectedVital]);

  return { chartData, startDateTime, endDateTime };
};
