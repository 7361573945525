import _ from 'lodash';
import { useMemo, useCallback } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { datetime, getTodayDate } from 'utils/datetime';
import { useParams, useHistory } from 'react-router-dom';

import toast from 'utils/toast';
import { useRedirectUrl } from 'utils/hooks';
import { GENERIC_FORM_ERRORS_KEY, IFormError } from 'utils/sdk';
import { useRedirectToOnboarding } from 'utils/hooks';

import { MEDICATIONS_URL } from 'config/urls';
import {
  BACKEND_TIME_FORMAT,
  TIME_WITH_PERIOD_FORMAT,
  BACKEND_DATE_FORMAT,
  BACKEND_DATETIME_FORMAT
} from 'constants/time';
import { DAYS_OF_THE_WEEK_CHOICES } from 'constants/common';

import { useSelectedPatient } from 'entities/Patient/sdk';
import { useGrantAccessToParticularCareTeamRoles } from 'entities/CareTeam/sdk';
import {
  IMedicationUpdateFormValues,
  medicationUpdate,
  useMedication,
  IMedicationUpdateIntakeTime
} from 'entities/Medications/sdk';
import { ECareTeamRole } from 'entities/CareTeam/constants';
import {
  EMedicationIntakeFrequency,
  EMedicationRelationToMeals,
  MEDICATION_INTAKE_FREQUENCIES_THAT_REQUIRE_INTAKE_TIME,
  MEDICATION_INTAKE_FREQUENCY_CHOICES,
  MEDICATION_RELATION_TO_MEALS_CHOICES
} from 'entities/Medications/constants';

import Grid from 'components/Grid';
import Alert from 'components/Alert';
import Stack from 'components/Stack';
import Radio from 'components/Radio';
import Button from 'components/Button';
import Select from 'components/Select';
import Loading from 'components/Loading';
import TextField from 'components/TextField';
import DateField from 'components/DateField';
import InputLabel from 'components/InputLabel';
import PageHeader from 'components/PageHeader';
import RadioGroup from 'components/RadioGroup';
import FormControl from 'components/FormControl';
import NumberField from 'components/NumberField';
import ArrayTimeField from 'components/ArrayTimeField';
import FormControlLabel from 'components/FormControlLabel';
import MedicationsAutocomplete from 'pages/MedicationCreate/MedicationsAutocomplete';

import { VALIDATION_SCHEMA } from './constants';

interface IMedicationUpdate {}

const MedicationUpdate: React.FC<IMedicationUpdate> = () => {
  useRedirectToOnboarding();
  useGrantAccessToParticularCareTeamRoles({
    roles: [ECareTeamRole.PATIENT, ECareTeamRole.FAMILY_MEMBER]
  });

  const { medicationId }: { medicationId: string } = useParams();

  const history = useHistory();
  const redirectUrl = useRedirectUrl();
  const { patient } = useSelectedPatient();
  const { data: medication, loading: isFetchingMedication } = useMedication({
    medicationId,
    patientId: patient?.id
  });

  const handleSubmit = useCallback(
    (
      values: IMedicationUpdateFormValues,
      formikHelpers: FormikHelpers<IMedicationUpdateFormValues>
    ) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient.");
      }

      if (_.isNil(medication)) {
        return Promise.reject("There's no initial medication.");
      }

      const formattedValues = {
        ...values
      };

      const formattedIntakeTimes = formattedValues.intake_times.map(
        (intakeTime) => {
          // Check if the time we have is equal to any of the ones we already had.
          const oldMedicationIntakeTime = _.find(
            medication.intake_times,
            (medicationIntakeTime) =>
              datetime(medicationIntakeTime.time, BACKEND_TIME_FORMAT).format(
                TIME_WITH_PERIOD_FORMAT
              ) === intakeTime
          );

          // If the medication frequency is changed, we should not preserve any intake times.
          if (
            !_.isNil(oldMedicationIntakeTime) &&
            medication.intake_frequency === formattedValues.intake_frequency
          ) {
            return {
              ...oldMedicationIntakeTime,
              time: datetime(intakeTime, TIME_WITH_PERIOD_FORMAT).format(
                BACKEND_TIME_FORMAT
              )
            };
          }

          const newMedicationIntakeTime: IMedicationUpdateIntakeTime = {
            time: datetime(intakeTime, TIME_WITH_PERIOD_FORMAT).format(
              BACKEND_TIME_FORMAT
            )
          };

          return newMedicationIntakeTime;
        }
      );

      return medicationUpdate({
        medicationId,
        medication: { ...formattedValues, intake_times: formattedIntakeTimes },
        patientId: patient.id
      })
        .then(() => {
          formikHelpers.resetForm();
          history.push(redirectUrl || MEDICATIONS_URL);
          if (redirectUrl) {
            toast.actionable({
              content: 'Medication has been updated sucessfully!',
              action: () => history.push(MEDICATIONS_URL),
              actionButtonText: 'View all'
            });
          } else {
            toast.info({
              content: 'Medication has been updated successfully!'
            });
          }
        })
        .catch(formikHelpers.setErrors);
    },
    [patient?.id, medication, history, medicationId, redirectUrl]
  );

  const initialIntakeTimesValue = useMemo(
    () =>
      _.uniq(
        medication?.intake_times.map(
          (intake_time: IMedicationUpdateIntakeTime) =>
            datetime(intake_time.time, BACKEND_TIME_FORMAT).format(
              TIME_WITH_PERIOD_FORMAT
            )
        )
      ) || [],
    [medication]
  );

  const initialIntakeDays = _.uniq(
    medication?.intake_times.map(
      (time: IMedicationUpdateIntakeTime) => time.day_of_the_week
    )
  );

  const initialValues = useMemo(
    () => ({
      name: '',
      quantity: null,
      description: '',
      intake_days: initialIntakeDays,
      intake_frequency: EMedicationIntakeFrequency.DAILY,
      relation_to_meals: EMedicationRelationToMeals.DOES_NOT_APPLY,
      ...medication,
      repeat_after:
        datetime(medication?.repeat_after, BACKEND_DATETIME_FORMAT).format(
          BACKEND_DATE_FORMAT
        ) || getTodayDate(),
      repeat_before: medication?.repeat_before
        ? datetime(medication?.repeat_before, BACKEND_DATETIME_FORMAT).format(
            BACKEND_DATE_FORMAT
          )
        : null,
      intake_times: initialIntakeTimesValue
    }),
    [initialIntakeTimesValue, medication, initialIntakeDays]
  );

  const formik = useFormik<IMedicationUpdateFormValues>({
    initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA
  });

  const formErrors: Array<IFormError> = _.get(
    formik.errors,
    GENERIC_FORM_ERRORS_KEY,
    []
  );

  const displayQuantity =
    formik.values.intake_frequency !== EMedicationIntakeFrequency.FORBIDDEN;
  const displayRelationToMeals =
    formik.values.intake_frequency !== EMedicationIntakeFrequency.FORBIDDEN;
  const displayIntakeDays =
    formik.values.intake_frequency === EMedicationIntakeFrequency.WEEKLY;
  const displayIntakeTimes =
    MEDICATION_INTAKE_FREQUENCIES_THAT_REQUIRE_INTAKE_TIME.includes(
      formik.values.intake_frequency
    );

  return (
    <Stack gap={2} marginBottom={2}>
      <PageHeader heading="Edit Medication" backTo={MEDICATIONS_URL} />

      {patient && !isFetchingMedication && medication ? (
        <>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2} marginTop={3}>
              {!_.isEmpty(formErrors) && (
                <Stack spacing={1}>
                  {formErrors.map((error, index) => (
                    <Alert key={index} severity="error">
                      {error.message}
                    </Alert>
                  ))}
                </Stack>
              )}

              <MedicationsAutocomplete
                value={formik.values.name}
                onChange={(value) => formik.setFieldValue('name', value)}
                renderInput={(params) => (
                  <TextField.Outlined
                    {...params}
                    name="name"
                    InputLabelProps={{
                      shrink: true
                    }}
                    label="Name *"
                    placeholder="Enter medication (e.g., Aspirin 1 tablet)"
                    error={
                      formik.touched.name && !_.isEmpty(formik.errors.name)
                    }
                    helperText={formik.touched.name ? formik.errors.name : ''}
                  />
                )}
              />

              <FormControl component="fieldset">
                <InputLabel shrink>Intake frequency</InputLabel>
                <RadioGroup
                  value={formik.values.intake_frequency}
                  defaultValue={medication.intake_frequency}
                  name="intake_frequency"
                  onChange={formik.handleChange}
                >
                  {MEDICATION_INTAKE_FREQUENCY_CHOICES.map(
                    ({ value, label }) => (
                      <FormControlLabel
                        value={value}
                        control={<Radio />}
                        label={label}
                      />
                    )
                  )}
                </RadioGroup>
              </FormControl>
              {displayIntakeDays && (
                <Select
                  multiple
                  label="Intake days"
                  options={DAYS_OF_THE_WEEK_CHOICES}
                  name="intake_days"
                  value={formik.values.intake_days}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.intake_days &&
                    !_.isEmpty(formik.errors.intake_days)
                  }
                  helperText={
                    formik.touched.intake_days &&
                    !_.isNil(formik.errors.intake_days)
                      ? `${formik.errors.intake_days}`
                      : ''
                  }
                />
              )}

              {displayIntakeTimes && (
                <ArrayTimeField
                  addFieldText="Add intake time"
                  label="Intake time"
                  name="intake_times"
                  formik={formik}
                />
              )}

              {displayQuantity && (
                <NumberField
                  fullWidth
                  label="Quantity"
                  name="quantity"
                  formik={formik}
                  placeholder="Enter number of units (e.g., 1, 2, 3)"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}

              {displayRelationToMeals && (
                <FormControl component="fieldset">
                  <InputLabel shrink>Relation to meals</InputLabel>
                  <RadioGroup
                    value={formik.values.relation_to_meals}
                    defaultValue={medication.relation_to_meals}
                    name="relation_to_meals"
                    onChange={formik.handleChange}
                  >
                    {MEDICATION_RELATION_TO_MEALS_CHOICES.map(
                      ({ value, label }) => (
                        <FormControlLabel
                          value={value}
                          control={<Radio />}
                          label={label}
                        />
                      )
                    )}
                  </RadioGroup>
                </FormControl>
              )}

              <TextField.Outlined
                fullWidth
                multiline
                minRows={3}
                name="description"
                InputLabelProps={{
                  shrink: true
                }}
                label="Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                placeholder="Enter relevant information (e.g., side effects) to assist the caregiver."
                error={
                  formik.touched.description &&
                  !_.isEmpty(formik.errors.description)
                }
                helperText={
                  formik.touched.description ? formik.errors.description : ''
                }
              />

              <DateField.Formik
                label="Start date *"
                name="repeat_after"
                formik={formik}
              />

              <DateField.Formik
                clearable
                label="End date"
                name="repeat_before"
                formik={formik}
              />

              <Button
                type="submit"
                disabled={formik.isSubmitting}
                data-gtm="button-submit-medications-update"
              >
                Save
              </Button>
            </Stack>
          </form>
        </>
      ) : (
        <Grid container justifyContent="center">
          <Grid item>
            <Loading />
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};

export default MedicationUpdate;
