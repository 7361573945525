import { useMemo } from 'react';
import _ from 'lodash';
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ReferenceArea,
  ResponsiveContainer
} from 'recharts';

import { datetime } from 'utils/datetime';
import { removeTrailingZeros } from 'utils/common';
import { BACKEND_DATETIME_FORMAT } from 'constants/time';

import {
  useSelectedPatient,
  usePatientCustomVitals
} from 'entities/Patient/sdk';
import {
  MEASUREMENT_TO_UNIT_MAPPING,
  VITALS
} from 'entities/HealthTrends/constants';
import { useTicksForChart } from './utils';

const SELECT_OPTIONS = [
  { label: 'Temperature', value: VITALS.TEMPERATURE },
  { label: 'Systolic', value: 'blood_pressure_systolic' },
  { label: 'Diastolic', value: 'blood_pressure_diastolic' },
  { label: 'Pulse', value: VITALS.PULSE },
  { label: 'Blood oxygen', value: VITALS.BLOOD_OXYGEN },
  { label: 'Fasting', value: 'blood_sugar_fasting' },
  { label: 'Non-fasting', value: 'blood_sugar_non_fasting' }
];

export interface IHealthTrendsChart {
  selectedVital: string;
  period: [startDate: string, endDate: string];
  data: any;
}

const CustomizedXAxisTick: React.FC<any> = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={45} y={15} dy={0} textAnchor="end" fill="#666" fontSize={14}>
        {datetime(payload.value).format('DD MMM')}
      </text>
    </g>
  );
};

const CustomizedYAxisTick: React.FC<any> = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={5}
        y={0}
        dx={0}
        textAnchor="start"
        fill="#666"
        fontSize={14}
        text-align="left"
      >
        {payload.value}
      </text>
    </g>
  );
};

const HealthTrendsChart: React.FC<IHealthTrendsChart> = ({
  selectedVital,
  period: [startDate, endDate],
  data: { chartData, lines }
}) => {
  const { patient } = useSelectedPatient();
  const { data: customVitals } = usePatientCustomVitals({
    patientId: patient?.id
  });

  const domain = useMemo(
    () => [
      datetime(startDate, BACKEND_DATETIME_FORMAT).valueOf(),
      datetime(endDate, BACKEND_DATETIME_FORMAT).valueOf()
    ],
    [startDate, endDate]
  );

  const ticks = useTicksForChart({ startDate, endDate });

  const yaxisDomain = _.reduce(
    lines.map((line: any) => line.domain),
    (res, curr) => {
      const [low1, high1] = curr;
      const [low2, high2] = res;
      return [Math.min(low1, low2), Math.max(high1, high2)];
    }
  );

  const data = useMemo(
    () =>
      chartData.map((obj: any) =>
        _.mapKeys(obj, (value, key) => {
          const res = _.find(SELECT_OPTIONS, (x) => x.value === key);
          if (res) {
            return res.label;
          }
          return key;
        })
      ),
    [chartData]
  );

  lines = _.orderBy(lines, ['key'], ['desc']);

  return (
    <ResponsiveContainer minHeight={270} height="100%">
      <LineChart data={data} margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
        <CartesianGrid strokeDasharray="1 2" />
        <XAxis
          interval={0}
          tickSize={1}
          domain={domain}
          ticks={ticks}
          tick={<CustomizedXAxisTick />}
          dataKey="timestamp"
          type="number"
          name="Time"
          //tickFormatter={(unixTime) => datetime(unixTime).format('DD MMM')}
        />

        <YAxis
          width={20}
          tickSize={1}
          domain={yaxisDomain}
          orientation="right"
          tick={<CustomizedYAxisTick />}
          label={{
            value: MEASUREMENT_TO_UNIT_MAPPING({
              vital: selectedVital,
              temperatureMeasuringUnit: customVitals?.temperature_unit
            }),
            position: 'right',
            angle: -90,
            offset: 20,
            textAnchor: 'middle',
            fill: '#666',
            fontSize: 14
          }}
        />

        <Tooltip
          formatter={(value: string) =>
            `${removeTrailingZeros(value)} ${MEASUREMENT_TO_UNIT_MAPPING({
              vital: selectedVital,
              temperatureMeasuringUnit: customVitals?.temperature_unit
            })}`
          }
          labelFormatter={(value) =>
            datetime(value).format('HH:mm DD MMM YYYY')
          }
        />
        <Legend />

        {lines.map((line: any, index: number) => (
          <ReferenceArea
            key={index}
            y1={line.referenceArea[0]}
            y2={line.referenceArea[1]}
            fill={line.referenceAreaColor}
          />
        ))}

        {lines.map((line: any) => (
          <Line
            connectNulls={true}
            key={line.key}
            type="monotone"
            dataKey={
              _.find(SELECT_OPTIONS, (x) => x.value === line.key)?.label ||
              line.key
            }
            stroke={line.color}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default HealthTrendsChart;
