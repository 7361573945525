const isAppInstalled = () => {
  // @ts-ignore
  if (navigator.standalone) {
    //user has already installed the app
    return true;
  }
  return false;
};

const isIOS = (): boolean => {
  const ua = window.navigator.userAgent.toLowerCase();

  const isIPad = !!ua.match(/ipad/i);
  const isIPhone = !!ua.match(/iphone/i);
  const isMac = !!ua.match(/ mac /i);

  return isIPad || isIPhone || isMac;
};

const useIosInstallPrompt = () => {
  return { isIos: isIOS(), isAppInstalled: isAppInstalled() };
};

export default useIosInstallPrompt;
