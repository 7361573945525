import {
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext
} from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { usePatient, IPatient } from 'entities/Patient/sdk';
import { useMyCareTeams, ICareTeam } from 'entities/CareTeam/sdk';

import { ONBOARDING_URL } from 'config/urls';

import {
  sortCareTeamsByRecentlySeen,
  updateRecentlySeenCareTeamIds
} from './utils';

// Not used -- can be deleted
// interface ISwitchButton {
//   patient: IPatient;
//   onClick: () => void;
//   isSelected: boolean;
// }

// export const SwitchButton: React.FC<ISwitchButton> = ({
//   patient,
//   onClick,
//   isSelected
// }) => {
//   const { data: signedUser } = useSignedUser();

//   return (
//     <Button
//       fullWidth={false}
//       variant={isSelected ? 'contained' : 'text'}
//       onClick={onClick}
//       startIcon={<Avatar src={patient?.profile.avatar} />}
//       sx={{ paddingX: 2 }}
//     >
//       <Typography variant="h5" sx={{ textTransform: 'none' }}>
//         {signedUser?.profile.id === patient.profile.id
//           ? 'Me'
//           : `${patient?.profile.first_name} ${patient?.profile.last_name}`}
//       </Typography>
//     </Button>
//   );
// };

export const SelectedPatientContext = createContext<{
  patient: IPatient | undefined;
  refetchPatient: () => void;
  refetchCareTeams: () => Promise<Array<ICareTeam> | undefined>;
  careTeamId: string | undefined;
  careTeams: Array<ICareTeam>;
  switchCareTeam: (careTeamId: string) => void;
  loading: boolean;
}>({
  patient: undefined,
  refetchPatient: () => {},
  refetchCareTeams: () => Promise.reject('SDK not ready'),
  careTeamId: undefined,
  careTeams: [],
  switchCareTeam: (careTeamId: string) => {},
  loading: false
});

const SelectedPatientContextProvider: React.FC<{}> = ({ children }) => {
  const {
    data: careTeams = [],
    loading: careTeamsLoading,
    refetch: refetchCareTeams
  } = useMyCareTeams();

  const [selectedCareTeamId, setSelectedCareTeamId] = useState('');

  useEffect(() => {
    setSelectedCareTeamId((id) => {
      if (id === '' && !_.isEmpty(careTeams)) {
        return _.get(
          sortCareTeamsByRecentlySeen({ careTeams }),
          '[0].care_team_id',
          ''
        );
      }

      return id;
    });
  }, [careTeams]);

  const {
    data: patient,
    refetch: refetchPatient,
    loading: patientLoading
  } = usePatient({
    careTeamId: selectedCareTeamId
  });

  const history = useHistory();

  const switchCareTeam = useCallback(
    (careTeamId: string) => {
      setSelectedCareTeamId(careTeamId);

      updateRecentlySeenCareTeamIds({ careTeamId });

      history.push(ONBOARDING_URL);
    },
    [history]
  );

  const loading = useMemo(
    () => careTeamsLoading || patientLoading,
    [careTeamsLoading, patientLoading]
  );

  return (
    <SelectedPatientContext.Provider
      value={{
        patient,
        refetchPatient,
        refetchCareTeams,
        careTeamId: selectedCareTeamId,
        careTeams,
        switchCareTeam,
        loading
      }}
    >
      {children}
    </SelectedPatientContext.Provider>
  );
};

export default SelectedPatientContextProvider;
