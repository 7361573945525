import PageLoader from 'components/PageLoader';

import { IVitalNoteFormValues, IVitalNote } from 'entities/Diary/sdk';

import VitalNoteFormDialog from 'entities/Diary/components/VitalNoteFormDialog';

import {
  useVitalsToTrack,
  useSelectedPatient,
  usePatientCustomVitals
} from 'entities/Patient/sdk';

import { getVitalsOutOfRange } from 'entities/Patient/utils';

export interface IVitalNoteUpdateDialog {
  onVitalNoteUpdate: ({
    vitalNote
  }: {
    vitalNote: IVitalNoteFormValues;
  }) => Promise<void>;
  onClose: () => void;
  vitalNote: IVitalNote;
}

const VitalNoteUpdateDialog = ({
  onClose,
  onVitalNoteUpdate,
  vitalNote
}: IVitalNoteUpdateDialog) => {
  const { patient, loading: isPatientLoading } = useSelectedPatient();

  const { data: customVitals, loading: areCustomVitalsLoading } =
    usePatientCustomVitals({
      patientId: patient?.id
    });

  const { data: vitalsToTrack, loading: areVitalsToTrackLoading } =
    useVitalsToTrack({
      patientId: patient?.id
    });

  const initialValues: IVitalNoteFormValues = {
    measured_at: vitalNote.measured_at,
    temperature: vitalNote.temperature || undefined,
    blood_pressure_systolic: vitalNote.blood_pressure_systolic || undefined,
    blood_pressure_diastolic: vitalNote.blood_pressure_diastolic || undefined,
    pulse: vitalNote.pulse || undefined,
    blood_oxygen: vitalNote.blood_oxygen || undefined,
    blood_sugar_fasting: vitalNote.blood_sugar_fasting || undefined,
    blood_sugar_non_fasting: vitalNote.blood_sugar_non_fasting || undefined
  };

  const isLoading =
    isPatientLoading || areCustomVitalsLoading || areVitalsToTrackLoading;

  const { vitalsOutOfRange } = getVitalsOutOfRange({
    vitalNote,
    customVitals
  });

  return (
    <>
      {isLoading || !vitalsToTrack || !customVitals ? (
        <PageLoader />
      ) : (
        <VitalNoteFormDialog
          open
          title="Edit Vitals"
          onClose={onClose}
          vitalsToTrack={vitalsToTrack}
          customVitals={customVitals}
          initialValues={initialValues}
          onVitalNoteSubmit={onVitalNoteUpdate}
          vitalsOutOfRange={vitalsOutOfRange || []}
        />
      )}
    </>
  );
};

export default VitalNoteUpdateDialog;
