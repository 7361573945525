import { useState, useCallback } from 'react';

import { useRedirectToOnboarding } from 'utils/hooks';

import Grid from 'components/Grid';
import Stack from 'components/Stack';
import PageHeader from 'components/PageHeader';

import { RESOLUTION } from 'entities/HealthTrends/constants';
import HealthTrendsChartWrapper from 'entities/HealthTrends/components/HealthTrendsChartWrapper';
import SelectResolutionButtons from 'entities/HealthTrends/components/SelectResolutionButtons';

import { HOME_URL } from 'config/urls';
import { getLastSeenHealthTrendChart } from 'entities/HealthTrends/utils';
import ChartToggleButton from 'entities/HealthTrends/components/ChartToggleButton';

const HealthTrends: React.FC<{}> = () => {
  const [selectedChart, setSelectedChart] = useState(
    getLastSeenHealthTrendChart()[0]
  );

  useRedirectToOnboarding();

  const [selectedResolution, setSelectedResolution] = useState(
    RESOLUTION.WEEKLY
  );

  const handleChartSelect = useCallback((value: string) => {
    setSelectedChart(value);
  }, []);

  const handleResolutionSelect = useCallback((value: string) => {
    setSelectedResolution(value);
  }, []);

  return (
    <Grid container flexDirection="column">
      <Grid item>
        <Stack spacing={2}>
          <PageHeader heading="Health trends" backTo={HOME_URL} />

          <ChartToggleButton
            selectedChart={selectedChart}
            onChange={handleChartSelect}
          />

          <SelectResolutionButtons
            selectedResolution={selectedResolution}
            onChange={handleResolutionSelect}
          />
        </Stack>
      </Grid>

      <Grid item xs>
        <HealthTrendsChartWrapper
          selectedVital={selectedChart}
          resolution={selectedResolution}
        />
      </Grid>
    </Grid>
  );
};

export default HealthTrends;
