import _ from 'lodash';
import { colors } from 'theme/palette';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

import Stack from 'components/Stack';
import Divider from 'components/Divider';
import Accordion from 'components/Accordion';
import PageLoader from 'components/PageLoader';
import IconButton from 'components/IconButton';
import BlankState from 'components/BlankState';
import Typography from 'components/Typography';

import { StickyNote2OutlinedIcon } from 'icons';

import { EditOutlinedIcon, DeleteOutlinedIcon } from 'icons';

import { EDiaryScheduleItemType } from 'entities/Diary/constants';
import {
  IGeneralNote,
  IDiaryScheduleItem,
  IVitalNote
} from 'entities/Diary/sdk';

import { ICustomVitals } from 'entities/Patient/sdk';
import { getVitalsOutOfRange } from 'entities/Patient/utils';

import { datetime } from 'utils/datetime';
import {
  BACKEND_DATETIME_FORMAT,
  DATETIME_WITH_PERIOD_SHORTENED_FORMAT
} from 'constants/time';

interface IDiarySchedule {
  isLoading: boolean;
  data: Array<IDiaryScheduleItem>;
  onDiaryNoteDelete: ({ diaryNote }: { diaryNote: IGeneralNote }) => void;
  onDiaryNoteUpdate: ({ diaryNote }: { diaryNote: IGeneralNote }) => void;
  onDiaryNoteCreate: () => void;
  onVitalNoteDelete: ({ vitalNote }: { vitalNote: IVitalNote }) => void;
  onVitalNoteUpdate: ({ vitalNote }: { vitalNote: IVitalNote }) => void;
  customVitals?: ICustomVitals;
}

const useStyles = makeStyles(() => ({
  highlightedVital: {
    color: colors.purpleMain,
    fontWeight: 'bold'
  }
}));

const DiarySchedule: React.FC<IDiarySchedule> = ({
  isLoading,
  data,
  onDiaryNoteDelete,
  onDiaryNoteUpdate,
  onDiaryNoteCreate,
  onVitalNoteDelete,
  onVitalNoteUpdate,
  customVitals
}) => {
  const classes = useStyles();

  const diaryNotes = _.filter(
    data,
    (item) => item.type === EDiaryScheduleItemType.DIARY_NOTE
  ) as Array<IGeneralNote & { type: EDiaryScheduleItemType.DIARY_NOTE }>;

  const vitalNotes = data.filter(
    (item) => item.type === EDiaryScheduleItemType.VITAL_NOTE
  ) as Array<IVitalNote & { type: EDiaryScheduleItemType.VITAL_NOTE }>;

  return (
    <Stack gap={2}>
      {isLoading && <PageLoader />}

      {!isLoading && _.isEmpty(data) && (
        <Stack maxWidth="100%" alignItems="center">
          <BlankState
            icon={StickyNote2OutlinedIcon}
            onClick={onDiaryNoteCreate}
            subtitle="No diary notes have been added for the selected date."
          />
        </Stack>
      )}

      {!isLoading && !_.isEmpty(diaryNotes) && (
        <>
          <Typography variant="h3" paddingTop={3}>
            Other notes
          </Typography>

          <Divider />

          <Stack>
            {diaryNotes.map((item) => (
              <Accordion square key={item.id} expanded={true}>
                <Accordion.Summary>
                  <Stack direction="row" alignItems="center" gap={1}>
                    {item.subject}
                  </Stack>
                </Accordion.Summary>
                <Accordion.Details>
                  <Stack>{item.text}</Stack>

                  <Stack
                    direction="row"
                    paddingTop={2}
                    justifyContent="space-between"
                  >
                    <Stack>
                      <Typography variant="subtitle2">
                        Created by {item.created_by.profile.full_name} on{' '}
                        {datetime(
                          item.created_at,
                          BACKEND_DATETIME_FORMAT
                        ).format(DATETIME_WITH_PERIOD_SHORTENED_FORMAT)}
                      </Typography>
                    </Stack>

                    <Stack direction="row">
                      <IconButton
                        size="small"
                        onClick={() => onDiaryNoteUpdate({ diaryNote: item })}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => onDiaryNoteDelete({ diaryNote: item })}
                      >
                        <DeleteOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Accordion.Details>
              </Accordion>
            ))}
          </Stack>
        </>
      )}

      {!isLoading && !_.isEmpty(vitalNotes) && (
        <>
          <Typography variant="h3" paddingTop={3}>
            Vitals
          </Typography>

          <Divider />

          <Stack>
            {vitalNotes.map((item) => {
              const { vitalsOutOfRange } = getVitalsOutOfRange({
                vitalNote: item,
                customVitals
              });

              return (
                <Accordion square key={item.id} expanded={true}>
                  <Accordion.Details>
                    <Stack direction="row">
                      <Typography variant="body2" minWidth="140px">
                        Measured at:{' '}
                      </Typography>
                      <Typography variant="body1">
                        {datetime(
                          item.measured_at,
                          BACKEND_DATETIME_FORMAT
                        ).format(DATETIME_WITH_PERIOD_SHORTENED_FORMAT)}
                      </Typography>
                    </Stack>

                    {item.temperature && (
                      <Stack direction="row">
                        <Typography variant="body2" minWidth="140px">
                          Temperature:{' '}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classnames({
                            [classes.highlightedVital]: _.includes(
                              vitalsOutOfRange,
                              'temperature'
                            )
                          })}
                        >
                          {item.temperature} °{item.temperature_unit}
                        </Typography>
                      </Stack>
                    )}

                    {item.blood_pressure_systolic &&
                      item.blood_pressure_diastolic && (
                        <Stack direction="row">
                          <Typography variant="body2" minWidth="140px">
                            Blood pressure:
                          </Typography>
                          <Stack direction="row" spacing={1}>
                            <Typography
                              variant="body1"
                              className={classnames({
                                [classes.highlightedVital]: _.includes(
                                  vitalsOutOfRange,
                                  'blood_pressure_systolic'
                                )
                              })}
                            >
                              {item.blood_pressure_systolic}
                            </Typography>
                            <Typography>/</Typography>
                            <Typography
                              variant="body1"
                              className={classnames({
                                [classes.highlightedVital]: _.includes(
                                  vitalsOutOfRange,
                                  'blood_pressure_diastolic'
                                )
                              })}
                            >
                              {item.blood_pressure_diastolic} mmHg
                            </Typography>
                          </Stack>
                        </Stack>
                      )}

                    {item.pulse && (
                      <Stack direction="row">
                        <Typography variant="body2" minWidth="140px">
                          Pulse:
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classnames({
                            [classes.highlightedVital]: _.includes(
                              vitalsOutOfRange,
                              'pulse'
                            )
                          })}
                        >
                          {item.pulse} bpm
                        </Typography>
                      </Stack>
                    )}

                    {item.blood_oxygen && (
                      <Stack direction="row">
                        <Typography variant="body2" minWidth="140px">
                          Blood oxygen:
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classnames({
                            [classes.highlightedVital]: _.includes(
                              vitalsOutOfRange,
                              'blood_oxygen'
                            )
                          })}
                        >
                          {item.blood_oxygen} %
                        </Typography>
                      </Stack>
                    )}

                    {item.blood_sugar_fasting && (
                      <Stack direction="row">
                        <div>
                          <Typography variant="body2" minWidth="140px">
                            Blood sugar
                          </Typography>
                          <Typography variant="subtitle2">Fasting</Typography>
                        </div>
                        <Typography
                          variant="body1"
                          className={classnames({
                            [classes.highlightedVital]: _.includes(
                              vitalsOutOfRange,
                              'blood_sugar_fasting'
                            )
                          })}
                        >
                          {item.blood_sugar_fasting} mg/dL
                        </Typography>
                      </Stack>
                    )}

                    {item.blood_sugar_non_fasting && (
                      <Stack direction="row">
                        <div>
                          <Typography variant="body2" minWidth="140px">
                            Blood sugar
                          </Typography>
                          <Typography variant="subtitle2">
                            Non Fasting
                          </Typography>
                        </div>
                        <Typography
                          variant="body1"
                          className={classnames({
                            [classes.highlightedVital]: _.includes(
                              vitalsOutOfRange,
                              'blood_sugar_non_fasting'
                            )
                          })}
                        >
                          {item.blood_sugar_non_fasting} mg/dL
                        </Typography>
                      </Stack>
                    )}

                    <Stack
                      direction="row"
                      paddingTop={2}
                      justifyContent="space-between"
                    >
                      <Stack>
                        <Typography variant="subtitle2">
                          Created by {item.created_by.profile.full_name} on{' '}
                          {datetime(
                            item.created_at,
                            BACKEND_DATETIME_FORMAT
                          ).format(DATETIME_WITH_PERIOD_SHORTENED_FORMAT)}
                        </Typography>
                      </Stack>

                      <Stack direction="row">
                        <IconButton
                          size="small"
                          onClick={() => onVitalNoteUpdate({ vitalNote: item })}
                        >
                          <EditOutlinedIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => onVitalNoteDelete({ vitalNote: item })}
                        >
                          <DeleteOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Accordion.Details>
                </Accordion>
              );
            })}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default DiarySchedule;
