import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colors } from 'theme/palette';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'; // source: https://github.com/wojtekmaj/react-pdf#readme
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import SimpleDialog from 'components/SimpleDialog';
import Grid from 'components/Grid';
import Typography from 'components/Typography';
import IconButton from 'components/IconButton';
import ConfirmationDialog from 'components/ConfirmationDialog';

import { useSelectedPatient } from 'entities/Patient/sdk';
import { documentDelete } from 'entities/Documents/sdk';
import { IUserDocument } from 'entities/Documents/sdk';

import {
  CloseIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon
} from 'icons';
import toast from 'utils/toast';

interface IPDFViewer {
  open: boolean;
  file: IUserDocument;
  onClose: () => void;
  refetchDocumentListInParent: () => void;
}

const useStyles = makeStyles((theme) => ({
  topNavContainer: {
    position: 'sticky',
    paddingTop: '2px',
    backgroundColor: colors.lightestGrey,
    alignItems: 'center',
    top: '0px'
  },
  pdfContainer: {
    padding: '0px',
    position: 'relative',
    top: '10px',
    overflow: 'auto'
  },
  pdfPageCanvas: {
    display: 'inline',
    width: '10px'
  },
  pageNav: {
    position: 'sticky',
    alignItems: 'center',
    justifyContent: 'center',
    bottom: '0px'
  }
}));

const DocumentPreviewDialog: React.FC<IPDFViewer> = ({
  open,
  file,
  onClose,
  refetchDocumentListInParent
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const { patient } = useSelectedPatient();

  const [numPages, setNumPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState<Boolean>(false);

  const onDocumentLoadSuccess = (pdf: any) => {
    setNumPages(pdf.numPages);
    setPageNumber(1);
  };

  const changePage = (offset: number) =>
    setPageNumber((prevPageNumber) => prevPageNumber + offset);

  const previousPage = () => changePage(-1);

  const nextPage = () => changePage(1);

  const handleDialogClose = () => onClose();

  const handleDocumentDelete = () => {
    if (!patient?.id) {
      return Promise.reject("There's no patient in the care team.");
    }

    documentDelete({
      patientId: patient?.id,
      documentId: file.id
    }).then(() => {
      refetchDocumentListInParent();
      setIsDeleteConfirmationDialogOpen(false);
      toast.info({
        content: 'Document successfully deleted!'
      });
      handleDialogClose();
    });
  };

  return (
    <>
      <SimpleDialog open={open} onClose={handleDialogClose}>
        <Grid container className={classes.topNavContainer}>
          <Grid item xs flexGrow={1} paddingX={2}>
            <Typography variant="caption" color={colors.darkestGrey}>
              {file.file_name}
            </Typography>
          </Grid>
          <Grid item alignItems="end" justifyContent="center">
            <IconButton onClick={() => setIsDeleteConfirmationDialogOpen(true)}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={onClose}>
              <CloseIcon color="action" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container direction="column" className={classes.pdfContainer}>
          <Grid item>
            <Document
              file={file.document}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={console.error}
            >
              <Page pageNumber={pageNumber} scale={mobile ? 0.62 : 0.9} />
            </Document>
          </Grid>
        </Grid>
        <Grid container className={classes.pageNav}>
          <Grid item>
            <IconButton disabled={pageNumber <= 1} onClick={previousPage}>
              <ChevronLeftIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="body1" color={colors.lightGrey}>
              {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton disabled={pageNumber >= numPages} onClick={nextPage}>
              <ChevronRightIcon />
            </IconButton>
          </Grid>
        </Grid>
      </SimpleDialog>

      {isDeleteConfirmationDialogOpen && (
        <ConfirmationDialog
          open={true}
          onClose={() => setIsDeleteConfirmationDialogOpen(false)}
          title="Are you sure you want to delete this file?"
          onConfirm={handleDocumentDelete}
        />
      )}
    </>
  );
};

export default DocumentPreviewDialog;
