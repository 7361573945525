import { useContext } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { useHistory } from 'react-router-dom';

import Stack from 'components/Stack';
import Typography from 'components/Typography';
import Avatar from 'components/Avatar';
import Paper from 'components/Paper';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import { ChevronRightIcon } from 'icons';

import { SelectedPatientContext } from 'components/SelectedPatientContext';
import { useSignedUser } from 'entities/Auth/sdk';

import { PROFILE_ROLES_MAPPER } from 'entities/Profile/constants';

import { ONBOARDING_STEPPER_URL, PATIENT_PROFILE_BASE_URL } from 'config/urls';

import { sortCareTeamsByRecentlySeen } from 'components/SelectedPatientContext/utils';

const useStyles = makeStyles((theme) => ({
  patientNotSelected: {
    textTransform: 'none',
    justifyContent: 'start',
    color: theme.palette.getContrastText(theme.palette.background.default)
  },
  patientSelected: {
    textTransform: 'none',
    justifyContent: 'start',
    color: 'white'
  },
  newTeamButton: {
    color: theme.palette.text.secondary
  },
  chevronIconPatientSelected: {
    color: 'white'
  },
  chevronIconPatientNotSelected: {
    color: theme.palette.getContrastText(theme.palette.background.default)
  }
}));

interface IMyCareTeams {}

const MyCareTeams: React.FC<IMyCareTeams> = () => {
  const { data: user } = useSignedUser();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const {
    careTeamId: selectedCareTeamId,
    careTeams,
    switchCareTeam
  } = useContext(SelectedPatientContext);

  const handleRightArrowClick = (careTeamId: string) =>
    history.push(`${PATIENT_PROFILE_BASE_URL}/${careTeamId}`);

  const handleAddNewCareTeamClick = () => history.push(ONBOARDING_STEPPER_URL);

  return (
    <>
      <Stack display="flex" paddingY={3} direction="column">
        <Stack
          marginBottom={5}
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h1">My Care Teams</Typography>
          <Button
            fullWidth={false}
            variant="text"
            onClick={handleAddNewCareTeamClick}
            className={classes.newTeamButton}
          >
            <Typography variant="caption">New Team</Typography>
          </Button>
        </Stack>
        {sortCareTeamsByRecentlySeen({ careTeams }).map((team) => (
          <Stack key={team.care_team_id} paddingBottom={3}>
            <Paper
              elevation={2}
              component="div"
              sx={
                team.care_team_id === selectedCareTeamId
                  ? { backgroundColor: theme.palette.primary.main }
                  : { backgroundColor: theme.palette.background.default }
              }
            >
              <Stack direction="row" display="flex">
                <Button
                  variant="text"
                  startIcon={<Avatar src={team.patient.profile.avatar} />}
                  onClick={() => switchCareTeam(team.care_team_id)}
                  sx={{ padding: 2 }}
                  className={
                    team.care_team_id === selectedCareTeamId
                      ? classes.patientSelected
                      : classes.patientNotSelected
                  }
                >
                  <Stack direction="column" display="flex" textAlign="start">
                    <Typography variant="h5" sx={{ textTransform: 'none' }}>
                      {user?.profile.id === team.patient.profile.id
                        ? 'Me'
                        : `${team.patient.profile.first_name} ${team.patient.profile.last_name}`}
                    </Typography>
                    <Typography variant="caption">
                      {PROFILE_ROLES_MAPPER[team.care_team_role]}
                    </Typography>
                  </Stack>
                </Button>
                <IconButton
                  onClick={() => handleRightArrowClick(team.care_team_id)}
                  className={
                    team.care_team_id === selectedCareTeamId
                      ? classes.chevronIconPatientSelected
                      : classes.chevronIconPatientNotSelected
                  }
                >
                  <ChevronRightIcon />
                </IconButton>
              </Stack>
            </Paper>
          </Stack>
        ))}
      </Stack>
    </>
  );
};

export default MyCareTeams;
