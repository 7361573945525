export const IS_VITAL_NOTE_CREATE_DIALOG_OPEN_URL_PARAM_KEY =
  'isVitalNoteCreateDialogOpen';

export const IS_DIARY_NOTE_CREATE_DIALOG_OPEN_URL_PARAM_KEY =
  'isDiaryNoteCreateDialogOpen';

export enum EDiaryMenuItems {
  NEW_GENERAL_NOTE = 'General note',
  NEW_VITALS_NOTE = 'Vitals'
}
