import { colors } from 'theme/palette';
import { PaletteMode } from '@mui/material';

import { ThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material/styles';
import { createContext, useState, useMemo } from 'react';
import { getCurrentColorMode, setCurrentColorMode } from './utils';

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    primary: {
      main: colors.purpleMain
    },
    secondary: {
      main: colors.pinkMain
    }
  }
});

export const ColorModeContext = createContext<{
  toggleColorMode: () => void;
}>({
  toggleColorMode: () => {}
});

const ColorModeContextProvider: React.FC<{}> = ({ children }) => {
  const currentColorMode = getCurrentColorMode();
  const [mode, setMode] = useState<'light' | 'dark'>(currentColorMode);
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        setCurrentColorMode(currentColorMode === 'light' ? 'dark' : 'light');
      }
    }),
    [currentColorMode]
  );

  let theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  theme = createTheme(theme, {
    shape: {
      borderRadius: 20
    },
    typography: {
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      h1: {
        fontSize: 32,
        fontWeight: 700
      },
      h2: {
        fontSize: 28,
        fontWeight: 700
      },
      h3: {
        fontSize: 24,
        fontWeight: 700
      },
      h4: {
        fontSize: 22,
        fontWeight: 300
      },
      h5: {
        fontSize: 18,
        fontWeight: 300
      },
      h6: {
        fontSize: 18,
        fontWeight: 700,
        textTransform: 'none'
      },
      body1: {
        fontSize: 16,
        fontWeight: 400
      },
      body2: {
        fontSize: 16,
        fontWeight: 700
      },
      subtitle1: {
        fontSize: 14,
        fontWeight: 300
      },
      subtitle2: {
        fontSize: 14,
        fontWeight: 300,
        fontStyle: 'italic'
      },
      button: {
        fontSize: 18,
        fontWeight: 700
      }
    },
    components: {
      MuiAppBar: {
        defaultProps: {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.background.paper
        }
      },
      MuiTextField: {
        root: {
          color: theme.palette.background.default
        }
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          fullWidth: true
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            margin: '32px 4px'
          },
          paperFullWidth: {
            width: 'calc(100% - 8px)'
          }
        }
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            '@media (min-width: 600px)': {
              paddingLeft: 16,
              paddingRight: 16
            }
          }
        }
      },
      MuiGrid: {
        styleOverrides: {
          root: { marginTop: 0 }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: 10,
            borderRadius: 13
          },
          switchBase: {
            margin: 2,
            padding: 0,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                border: 0
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5
              },
              '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#fafafa',
                border: '6px solid #fff'
              },
              '&.Mui-disabled .MuiSwitch-thumb': {
                color: '#E9E9EA'
              }
            },
            '& .MuiSwitch-thumb': {
              boxSizing: 'border-box',
              width: 22,
              height: 22
            },
            '& .MuiSwitch-track': {
              borderRadius: 26 / 2,
              border: '1px solid #bdbdbd',
              backgroundColor: '#fafafa',
              opacity: 1,
              transition:
                'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
            }
          }
        }
      }
    }
  });

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default ColorModeContextProvider;
