import _ from 'lodash';
import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

import Grid from 'components/Grid';
import Button from 'components/Button';
import Dialog, { IDialog } from 'components/Dialog';
import TextField from 'components/TextField';
import Typography from 'components/Typography';
import {
  IMedicalCondition,
  INewMedicalCondition,
  useMedicalConditionChoices
} from 'entities/Patient/sdk';
import Select from 'components/Select';
import FormControl from 'components/FormControl';

import { VALIDATION_SCHEMA } from './constants';

interface IFormValues {
  description: string;
  condition_type: string;
  medical_condition_id?: string;
}

export interface IConditionDetailsDialog extends IDialog {
  previousConditions: Array<IMedicalCondition>;
  condition: IMedicalCondition | INewMedicalCondition;
  onSave: ({
    condition
  }: {
    condition: IMedicalCondition | INewMedicalCondition;
  }) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  menuItem: {
    justifyContent: 'space-between'
  }
}));

const ConditionDetailsDialog = ({
  onSave,
  onClose,
  previousConditions,
  condition: initialConditionData,
  ...props
}: IConditionDetailsDialog) => {
  const handleSubmit = useCallback(
    (values: IMedicalCondition | INewMedicalCondition) =>
      onSave({ condition: values }),
    [onSave]
  );

  const classes = useStyles();
  const theme = useTheme();

  const formik = useFormik<IFormValues>({
    initialValues: initialConditionData,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA
  });

  const { data: conditionChoices = [] } = useMedicalConditionChoices();

  const previousConditionTypes = useMemo(
    () => _.map(previousConditions, 'condition_type'),
    [previousConditions]
  );

  return (
    <Dialog
      {...props}
      onClose={onClose}
      title={`${
        'medical_condition_id' in formik.values ? 'Update' : 'Add'
      } Condition Details`}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          className={classes.container}
          flexDirection="column"
          rowGap={theme.spacing(2)}
        >
          <Grid item>
            <FormControl fullWidth>
              <Select
                name="condition_type"
                labelId="select"
                label="Condition type"
                value={formik.values.condition_type}
                onChange={formik.handleChange}
                error={!_.isNil(formik.errors.condition_type)}
                helperText={
                  formik.touched.condition_type
                    ? formik.errors.condition_type
                    : ''
                }
              >
                {conditionChoices.map(({ label, value }) => {
                  const conditionHasAlreadyBeenAdded =
                    value !== 'other' &&
                    _.includes(previousConditionTypes, value);

                  return (
                    <Select.MenuItem
                      value={value}
                      key={value}
                      disabled={conditionHasAlreadyBeenAdded}
                      className={classes.menuItem}
                    >
                      {label}{' '}
                      {conditionHasAlreadyBeenAdded && (
                        <Typography
                          variant="subtitle2"
                          align="right"
                          color="error"
                        >
                          Already added
                        </Typography>
                      )}
                    </Select.MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField.Outlined
              fullWidth
              multiline
              minRows={5}
              name="description"
              label="Description"
              placeholder="Enter relevant medical history(optional)"
              value={formik.values.description}
              onChange={formik.handleChange}
              helperText={
                formik.touched.description ? formik.errors.description : ''
              }
              error={!_.isNil(formik.errors.description)}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item>
            <Button type="submit" disabled={formik.isSubmitting}>
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default ConditionDetailsDialog;
