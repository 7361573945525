import * as yup from 'yup';

import { ETemperatureUnit } from 'constants/common';

export const VALIDATION_SCHEMA = yup.object().shape({
  temperature_max: yup
    .number()
    .required('Required.')
    .positive('Must be positive.'),
  temperature_unit: yup
    .string()
    .oneOf([ETemperatureUnit.CELSIUS, ETemperatureUnit.FAHRENHEIT])
    .required('Required.'),
  blood_pressure_systolic_min: yup
    .number()
    .required('Required.')
    .positive('Must be positive.'),
  blood_pressure_diastolic_min: yup
    .number()
    .required('Required.')
    .positive('Must be positive.'),
  blood_pressure_systolic_max: yup
    .number()
    .required('Required.')
    .positive('Must be positive.'),
  blood_pressure_diastolic_max: yup
    .number()
    .required('Required.')
    .positive('Must be positive.'),
  pulse_min: yup.number().required('Required.').positive('Must be positive.'),
  pulse_max: yup.number().required('Required.').positive('Must be positive.'),
  blood_oxygen_min: yup
    .number()
    .required('Required.')
    .positive('Must be positive.'),
  blood_sugar_fasting_min: yup
    .number()
    .required('Required.')
    .positive('Must be positive.'),
  blood_sugar_fasting_max: yup
    .number()
    .required('Required.')
    .positive('Must be positive.'),
  blood_sugar_non_fasting_min: yup
    .number()
    .required('Required.')
    .positive('Must be positive.'),
  blood_sugar_non_fasting_max: yup
    .number()
    .required('Required.')
    .positive('Must be positive.')
});
