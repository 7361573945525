import _ from 'lodash';

import { BASE_API_URL } from 'config/urls';
import { EDaysOfTheWeek } from 'constants/common';
import {
  EMedicationDosageTaken,
  EMedicationIntakeFrequency
} from 'entities/Medications/constants';
import { IMedicationIntakeTime } from 'entities/Medications/sdk';
import { IGeneralNote } from 'entities/Diary/sdk';

import { useFetch, post } from 'utils/sdk';
import {
  datetime,
  deepTransformUTCToLocal,
  utcToLocal,
  localToUtc
} from 'utils/datetime';
import { orderDaysOfTheWeek } from 'utils/common';

import {
  BACKEND_DATE_FORMAT,
  BACKEND_DATETIME_FORMAT,
  BACKEND_TIME_FORMAT
} from 'constants/time';

import { getCarePlanItemPostData } from './utils';
import { EScheduleItem, ECarePlanItemRepeatCycle } from './constants';

export interface ICarePlanItem {
  id: string;
  category: string;
  custom_category: string;
  description: string;
  repeat_cycle: ECarePlanItemRepeatCycle;
  repeat_cycle_days: Array<EDaysOfTheWeek>;
  is_all_day: boolean;
  start_time: string | null;
  end_time: string | null;
  repeat_after: string;
  repeat_before: string | null;
  is_added_to_schedule: boolean;
  is_deleted: boolean;
}

export interface ICarePlanItemCategory {
  value: string;
  label: string;
}

export interface ICarePlanItemValues {
  category: string;
  custom_category: string;
  description: string;
  repeat_cycle: ECarePlanItemRepeatCycle;
  repeat_cycle_days: Array<EDaysOfTheWeek>;
  is_all_day: boolean;
  start_time: string | null;
  end_time: string | null;
  repeat_after: string;
  repeat_before: string | null;
  is_added_to_schedule: boolean;
}

export interface IScheduleCarePlanItem {
  id: string;
  type: EScheduleItem.CARE_PLAN_ITEM;
  category: string;
  custom_category: string;
  description: string;
  recurring: boolean;
  repeat_cycle: ECarePlanItemRepeatCycle;
  repeat_cycle_days: Array<EDaysOfTheWeek>;
  is_all_day: boolean;
  start_time: string | null;
  end_time: string | null;
  repeat_before: string | null;
  repeat_after: string;
  is_completed: boolean;
  completed_at: string;
  completed_by: string;
  created_by: string;
  is_added_to_schedule: boolean;
  is_deleted: boolean;
  related_notes: IGeneralNote[];
}

export interface IScheduleTask {
  id: string;
  type: EScheduleItem.TASK;
  name: string;
  location: string;
  start: string;
  end: string;
  description: string;
  is_completed: boolean;
  completed_at: string;
  completed_by: string;
  created_by: string;
  related_notes: IGeneralNote[];
}

export interface IScheduleMedication {
  id: string;
  additional_note: string | null;
  type: EScheduleItem.MEDICATION;
  name: string;
  quantity: string;
  description: string;
  repeat_after: string;
  repeat_before?: string;
  is_completed: boolean;
  relation_to_meals: string;
  dosage_taken: EMedicationDosageTaken | null;
  completed_at: null | string;
  completed_by: null | string;
  intake_frequency: EMedicationIntakeFrequency;
  intake_time: IMedicationIntakeTime;
  is_deleted: boolean;
  related_notes: IGeneralNote[];
}

export const useCarePlanItemCategories = () =>
  useFetch<Array<ICarePlanItemCategory>>(
    `${BASE_API_URL}/activities/care-plan/item/category/list/`
  );

export const carePlanItemCreate = ({
  patientId,
  carePlanItem
}: {
  patientId: string;
  carePlanItem: ICarePlanItemValues;
}) => {
  const postData = getCarePlanItemPostData({ carePlanItem });

  return post(
    `${BASE_API_URL}/activities/patient/${patientId}/care-plan/item/create/`,
    postData
  );
};

export const carePlanItemUpdate = ({
  patientId,
  carePlanItemId,
  carePlanItem
}: {
  patientId: string;
  carePlanItemId: string;
  carePlanItem: ICarePlanItemValues;
}) => {
  const postData = getCarePlanItemPostData({ carePlanItem });

  return post(
    `${BASE_API_URL}/activities/patient/${patientId}/care-plan/item/${carePlanItemId}/update/`,
    postData
  );
};
export const carePlanItemDelete = ({
  itemId,
  patientId,
  selectedDate
}: {
  itemId: string;
  patientId: string;
  selectedDate: string;
}) => {
  const selectedDatetime = datetime(selectedDate, BACKEND_DATE_FORMAT, true)
    .hour(23)
    .minute(59);

  const postData = {
    selected_datetime: localToUtc(selectedDatetime).format(
      BACKEND_DATETIME_FORMAT
    )
  };

  return post<undefined>(
    `${BASE_API_URL}/activities/patient/${patientId}/care-plan/item/${itemId}/delete/`,
    postData
  );
};
export const usePatientCarePlanItemList = ({
  patientId
}: {
  patientId: string | null | undefined;
}) => {
  const result = useFetch<Array<ICarePlanItem>>(
    patientId
      ? `${BASE_API_URL}/activities/patient/${patientId}/care-plan/items/list/`
      : null
  );

  let transformedData = deepTransformUTCToLocal({ data: result.data }) || [];

  transformedData = transformedData.map((carePlanItem: ICarePlanItem) => {
    const startTime = carePlanItem.start_time
      ? datetime(carePlanItem.start_time, BACKEND_TIME_FORMAT, true)
      : null;
    const transformedStartTime = startTime
      ? utcToLocal(
          datetime.utc().hour(startTime.hour()).minute(startTime.minute())
        ).format(BACKEND_TIME_FORMAT)
      : null;

    const endTime = carePlanItem.end_time
      ? datetime(carePlanItem.end_time, BACKEND_TIME_FORMAT, true)
      : null;
    const transformedEndTime = endTime
      ? utcToLocal(
          datetime.utc().hour(endTime.hour()).minute(endTime.minute())
        ).format(BACKEND_TIME_FORMAT)
      : null;

    // We need to transform each of the repeat cycle days.
    let transformedRepeatCycleDays: Array<string> = [];
    if (!_.isEmpty(carePlanItem.repeat_cycle_days)) {
      // If the care plan item does not have a start time(it's all day) we don't transform the days.
      if (!carePlanItem.start_time) {
        transformedRepeatCycleDays = carePlanItem.repeat_cycle_days;
      } else {
        const cursorStart = datetime(
          carePlanItem.repeat_after,
          BACKEND_DATETIME_FORMAT,
          true
        ).utc(); // We have previously transformed the repeat after to local so we need to bring it back to UTC.
        let cursorDate = cursorStart;

        while (cursorDate.isBefore(cursorStart.add(7, 'days'), 'day')) {
          // We go through the next 7 days and find the selected days of the week.
          // For each of them we append the day transformed in UTC to transformedRepeatCycleDays.
          const cursorDateDayOfTheWeek = cursorDate
            .format('dddd')
            .toLowerCase();
          const selectedRepeatCycleDays = carePlanItem.repeat_cycle_days.map(
            (day) => day.toString()
          );

          if (selectedRepeatCycleDays.includes(cursorDateDayOfTheWeek)) {
            const transformedCursorDate = utcToLocal(cursorDate);
            const transformedCursorDateDayOfTheWeek = transformedCursorDate
              .format('dddd')
              .toLowerCase();

            transformedRepeatCycleDays.push(transformedCursorDateDayOfTheWeek);
          }

          cursorDate = cursorDate.add(1, 'days');
        }
      }
      transformedRepeatCycleDays = orderDaysOfTheWeek({
        days: transformedRepeatCycleDays
      });
    }

    return {
      ...carePlanItem,
      repeat_cycle_days: transformedRepeatCycleDays,
      start_time: transformedStartTime,
      end_time: transformedEndTime
    };
  });

  return {
    ...result,
    data: transformedData
  };
};
