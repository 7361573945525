import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useState, useCallback, useMemo } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import { useTheme } from '@mui/styles';

import toast from 'utils/toast';
import { datetime } from 'utils/datetime';
import { REDIRECT_URL_PARAM_KEY } from 'constants/common';
import { GENERIC_FORM_ERRORS_KEY, IFormError } from 'utils/sdk';
import {
  MEDICATIONS_URL,
  MEDICATION_UPDATE_URL,
  SCHEDULE_URL
} from 'config/urls';

import Grid from 'components/Grid';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Select from 'components/Select';
import Divider from 'components/Divider';
import TextField from 'components/TextField';
import Typography from 'components/Typography';
import PageLoader from 'components/PageLoader';
import ButtonGroup from 'components/ButtonGroup';
import Dialog, { IDialog } from 'components/Dialog';
import ConfirmationDialog from 'components/ConfirmationDialog';
import IconButton from 'components/IconButton';
import Link from 'components/Link';

import {
  EditIcon,
  DeleteIcon,
  AddCommentIcon,
  RestaurantIcon,
  NoMealsIcon
} from 'icons';

import { useSelectedPatient } from 'entities/Patient/sdk';
import DiaryNoteCreateDialog from 'entities/Diary/components/GeneralNoteCreateDialog';
import RelatedNotesDialog from 'entities/Schedule/components/ScheduleRelatedNotesDialog';

import { IGeneralNoteFormValues, generalNoteCreate } from 'entities/Diary/sdk';
import {
  IScheduleItem,
  usePatientScheduleItemList
} from 'entities/Schedule/sdk';
import { IScheduleMedication } from 'entities/CarePlan/sdk';

import { useSelectedCareTeam } from 'entities/CareTeam/sdk';
import {
  medicationDelete,
  useMedication,
  IMedicationIntakeTime,
  IMedication
} from 'entities/Medications/sdk';
import {
  MEDICATION_DOSAGE_TAKEN_CHOICES,
  EMedicationDosageTaken,
  EMedicationRelationToMeals
} from 'entities/Medications/constants';
import { getIntakeDays } from 'entities/Medications/utils';

import { substituteUrl } from 'utils/common';

import { VALIDATION_SCHEMA } from './constants';
import {
  BACKEND_DATETIME_FORMAT,
  BACKEND_TIME_FORMAT,
  DATETIME_WITH_PERIOD_FORMAT,
  TIME_WITH_PERIOD_FORMAT,
  BACKEND_DATE_FORMAT
} from 'constants/time';

interface IValues {
  dosage_taken: EMedicationDosageTaken | null;
  additional_note: string;
}

interface IScheduleMedicationDetailDialog extends IDialog {
  refetchInParent: () => void;
  selectedDate: string;
  medicationId: string;
  intakeTime: IMedicationIntakeTime;
  dosageTaken: EMedicationDosageTaken | null;
  additionalNote: string | null;
  completedAt: string | null;
  completedBy: string | null;
  isDeleted: boolean;
  onMedicationCompletionStatusUpdate: (vales: IValues) => Promise<void>;
}

const ScheduleMedicationDetailDialog: React.FC<
  IScheduleMedicationDetailDialog
> = ({
  medicationId,
  intakeTime,
  dosageTaken,
  selectedDate,
  completedAt,
  completedBy,
  additionalNote,
  isDeleted,
  refetchInParent,
  onMedicationCompletionStatusUpdate,
  ...props
}) => {
  const [isDeletionModalOpen, setIsDeletionModalOpen] =
    useState<boolean>(false);

  const history = useHistory();
  const theme = useTheme();
  const { patient } = useSelectedPatient();
  const { userIsPatient, userIsFamilyMember } = useSelectedCareTeam();

  const { data: medication, loading: isFetchingMedication } = useMedication({
    medicationId,
    patientId: patient?.id
  });

  const {
    data: scheduleItems = [],
    loading: isFetchingTasks,
    refetch: refetchScheduleItems
  } = usePatientScheduleItemList({
    patientId: patient?.id,
    selectedDate
  });

  const medicationScheduleItem = _.find(
    scheduleItems,
    (scheduleItem) => scheduleItem.id === medicationId
  ) as IScheduleMedication;

  const [isDiaryNoteCreateDialogOpen, setIsDiaryNoteCreateDialogOpen] =
    useState<boolean>(Boolean(false));
  const [isRelatedNotesDialogOpen, setIsRelatedNotesDialogOpen] =
    useState<boolean>(Boolean(false));

  const noteInitialValues: IGeneralNoteFormValues = useMemo(
    () => ({
      subject: medicationScheduleItem.name,
      text: '',
      schedule_date: selectedDate
    }),
    [medicationScheduleItem, selectedDate]
  );

  const [relatedNoteCount, setRelatedNoteCount] = useState<number>(
    !_.isNil(medicationScheduleItem.related_notes)
      ? Object.keys(medicationScheduleItem.related_notes).length
      : 0
  );

  const [medicationTask, setMedicationTask] = useState<IScheduleMedication>(
    medicationScheduleItem
  );

  const handleSubmit = useCallback(
    (values: IValues, formikHelpers: FormikHelpers<IValues>) =>
      onMedicationCompletionStatusUpdate(values).catch(formikHelpers.setErrors),
    [onMedicationCompletionStatusUpdate]
  );

  const handleMedicationDelete = async ({
    medication
  }: {
    medication: IMedication;
  }) => {
    if (!patient?.id) {
      return Promise.reject("There's no patient.");
    }

    return medicationDelete({
      medication,
      selectedDate,
      patientId: patient.id
    }).then(() => {
      refetchInParent();
      props.onClose();
      setIsDeletionModalOpen(false);
      toast.actionable({
        content: 'Medication has been deleted sucessfully!',
        action: () => history.push(MEDICATIONS_URL),
        actionButtonText: 'View all'
      });
    });
  };

  const initialValues: IValues = useMemo(
    () => ({
      dosage_taken: dosageTaken,
      additional_note: additionalNote || ''
    }),
    [dosageTaken, additionalNote]
  );

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA
  });

  const formErrors: Array<IFormError> = _.get(
    formik.errors,
    GENERIC_FORM_ERRORS_KEY,
    []
  );

  const redirectTo = `${SCHEDULE_URL}?selectedDate=${selectedDate}`;

  const AsideTitleComponent = isDeleted ? (
    <Typography variant="subtitle2" color="error">
      Deleted
    </Typography>
  ) : (
    (userIsPatient || userIsFamilyMember) && (
      <ButtonGroup fullWidth variant="text" color="inherit">
        <Button
          color="inherit"
          onClick={() =>
            history.push(
              `${substituteUrl({
                url: MEDICATION_UPDATE_URL,
                kwargs: {
                  medicationId
                }
              })}?${REDIRECT_URL_PARAM_KEY}=${redirectTo}`
            )
          }
        >
          <EditIcon />
        </Button>
        <Button color="inherit" onClick={() => setIsDeletionModalOpen(true)}>
          <DeleteIcon />
        </Button>
      </ButtonGroup>
    )
  );

  const setMedicationTaskState = useCallback(
    (refetchedScheduleItems: Array<IScheduleItem>) => {
      const updatedTask = _.find(
        refetchedScheduleItems,
        (refetchedScheduleItems) => refetchedScheduleItems.id === medication.id
      ) as IScheduleMedication;
      setMedicationTask(() => updatedTask);
      if (_.isNil(updatedTask.related_notes)) {
        setRelatedNoteCount(() => 0);
        setIsRelatedNotesDialogOpen(false);
      } else {
        setRelatedNoteCount(
          () => Object.keys(updatedTask.related_notes).length
        );
      }
    },
    [medication]
  );

  const handleDiaryNoteCreate = useCallback(
    ({ diaryNote }: { diaryNote: IGeneralNoteFormValues }) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      return generalNoteCreate({
        patientId: patient.id,
        diaryNote,
        schedule_item_id: medicationTask.id,
        schedule_item_type: medicationTask.type
      }).then(() => {
        setRelatedNoteCount(() => relatedNoteCount + 1);
        refetchInParent();
        refetchScheduleItems().then((result) => {
          if (!_.isNil(result)) {
            setMedicationTaskState(result);
          }
        });
        setIsDiaryNoteCreateDialogOpen(false);
        toast.info({
          content: 'Note successfully added!'
        });
      });
    },
    [
      patient,
      refetchInParent,
      refetchScheduleItems,
      setMedicationTaskState,
      medicationTask,
      relatedNoteCount
    ]
  );

  const onDiaryNoteCreate = () => {
    setIsDiaryNoteCreateDialogOpen(true);
  };

  return (
    <>
      {isFetchingMedication && <PageLoader />}
      {!isFetchingMedication && medication && (
        <Dialog
          {...props}
          title={medication.name}
          asideTitleComponent={AsideTitleComponent}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              {!_.isEmpty(formErrors) && (
                <Grid item xs={12}>
                  {formErrors.map((error, index) => (
                    <Alert key={index} severity="error">
                      {error.message}
                    </Alert>
                  ))}
                </Grid>
              )}
            </Grid>
            <Grid
              container
              padding={2}
              component={Grid}
              alignItems="left"
              display="flex"
              flexDirection="column"
              rowGap={2}
            >
              <Grid container alignItems="center">
                {!_.isNil(medication.quantity) && (
                  <>
                    <Grid
                      item
                      textAlign="center"
                      marginLeft={3}
                      marginRight={3}
                      xs={3}
                    >
                      <Typography
                        sx={{ fontSize: { lg: 42, md: 36, sm: 32, xs: 30 } }}
                        fontWeight={700}
                      >
                        {medication.quantity}
                      </Typography>
                      {medication.quantity > 1 ? (
                        <Typography variant="caption">units</Typography>
                      ) : (
                        <Typography variant="caption">unit</Typography>
                      )}
                    </Grid>
                  </>
                )}
                {_.isNil(medication.quantity) && (
                  <>
                    <Grid
                      item
                      textAlign="center"
                      marginLeft={3}
                      marginRight={3}
                      xs={3}
                    >
                      <Typography color={theme.palette.text.disabled}>
                        No units specified
                      </Typography>
                    </Grid>
                  </>
                )}
                <Divider orientation="vertical" flexItem />
                <Grid item marginLeft={5} xs>
                  <Grid item marginTop={2} marginBottom={2}>
                    <Typography
                      variant="caption"
                      color={theme.palette.text.secondary}
                    >
                      DOSAGE FREQUENCY:
                    </Typography>
                    <Typography variant="body1">
                      {getIntakeDays({ medication })}
                      {' at '}
                      {datetime(intakeTime.time, BACKEND_TIME_FORMAT).format(
                        TIME_WITH_PERIOD_FORMAT
                      )}
                    </Typography>
                    <Typography variant="body1">
                      {datetime(medication.repeat_after).format('MMM Do, YYYY')}{' '}
                      {medication.repeat_before
                        ? ` - ${datetime(medication.repeat_before).format(
                            'MMM Do, YYYY'
                          )}`
                        : 'onwards'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />

              <Grid item>
                <Typography
                  variant="caption"
                  color={theme.palette.text.secondary}
                >
                  ADDITIONAL INFORMATION:
                </Typography>
                {[
                  EMedicationRelationToMeals.BEFORE,
                  EMedicationRelationToMeals.WITH
                ].indexOf(medication.relation_to_meals) !== -1 && (
                  <>
                    <Grid container spacing={1} alignItems="center">
                      {medication.relation_to_meals ===
                        EMedicationRelationToMeals.BEFORE && (
                        <>
                          <Grid item paddingTop={0.5}>
                            <NoMealsIcon fontSize="small" color="inherit" />
                          </Grid>
                          <Grid item marginX={0.05}>
                            Before meals
                          </Grid>
                        </>
                      )}
                      {medication.relation_to_meals ===
                        EMedicationRelationToMeals.WITH && (
                        <>
                          <Grid item paddingTop={0.5}>
                            <RestaurantIcon fontSize="small" color="inherit" />
                          </Grid>
                          <Grid item marginX={0.05}>
                            With meals
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}
                {medication.description !== '' && (
                  <Typography variant="body1">
                    {medication.description}
                  </Typography>
                )}
                {medication.description === '' &&
                  [
                    EMedicationRelationToMeals.BEFORE,
                    EMedicationRelationToMeals.WITH
                  ].indexOf(medication.relation_to_meals) === -1 && (
                    <Typography color={theme.palette.text.disabled}>
                      None provided
                    </Typography>
                  )}
              </Grid>

              <Divider />

              <Grid
                container
                spacing={0.5}
                justifyContent="space-between"
                alignContent="center"
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    color={
                      relatedNoteCount === 0
                        ? theme.palette.text.disabled
                        : 'primary'
                    }
                  >
                    {relatedNoteCount === 0 ? (
                      'No notes have been added'
                    ) : relatedNoteCount === 1 ? (
                      <Link.Primary
                        to="#"
                        onClick={() => setIsRelatedNotesDialogOpen(true)}
                        variant="body1"
                        underline="none"
                        data-gtm="link-related-notes-view"
                      >
                        1 note has been added
                      </Link.Primary>
                    ) : (
                      <Link.Primary
                        to="#"
                        onClick={() => setIsRelatedNotesDialogOpen(true)}
                        variant="body1"
                        underline="none"
                        data-gtm="link-related-notes-view"
                      >
                        {`${relatedNoteCount} notes have been added`}
                      </Link.Primary>
                    )}
                  </Typography>
                </Grid>
                <Grid item alignItems="flex-end">
                  <IconButton
                    color="inherit"
                    onClick={onDiaryNoteCreate}
                    size="small"
                    data-gtm="button-submit-relatednotes-create"
                  >
                    <AddCommentIcon data-gtm="button-submit-relatednotes-create" />
                  </IconButton>
                </Grid>
              </Grid>
              {isRelatedNotesDialogOpen &&
                !_.isNil(medicationTask.related_notes) &&
                !isFetchingTasks && (
                  <RelatedNotesDialog
                    open
                    onClose={() => setIsRelatedNotesDialogOpen(false)}
                    notes={Object.values(medicationTask.related_notes)}
                    scheduleItemID={medicationTask.id}
                    scheduleItemName={medicationTask.name}
                    selectedDate={selectedDate}
                    refetchScheduleItemsInParent={refetchScheduleItems}
                    updateRelatedNoteCountInParent={setMedicationTaskState}
                  />
                )}
              {isDiaryNoteCreateDialogOpen && (
                <DiaryNoteCreateDialog
                  onClose={() => setIsDiaryNoteCreateDialogOpen(false)}
                  onDiaryNoteCreate={handleDiaryNoteCreate}
                  selectedDate={selectedDate}
                  initialFormValues={noteInitialValues}
                />
              )}

              <Divider />
              <Typography
                variant="caption"
                color={theme.palette.text.secondary}
              >
                DOSAGE TAKEN:
              </Typography>
              <Select
                name="dosage_taken"
                labelId="select"
                //label="Dosage taken"
                size="small"
                //placeholder="Dosage taken"
                value={formik.values.dosage_taken || ''}
                onChange={formik.handleChange}
                options={MEDICATION_DOSAGE_TAKEN_CHOICES}
                error={
                  formik.touched.dosage_taken &&
                  !_.isEmpty(formik.errors.dosage_taken)
                }
                helperText={
                  formik.touched.dosage_taken ? formik.errors.dosage_taken : ''
                }
              />

              {formik.values.dosage_taken ===
                EMedicationDosageTaken.NOT_TAKEN && (
                <TextField.Outlined
                  fullWidth
                  label="Reason for not taking the medication"
                  name="additional_note"
                  value={formik.values.additional_note}
                  onChange={formik.handleChange}
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={
                    formik.touched.additional_note &&
                    !_.isEmpty(formik.errors.additional_note)
                  }
                  helperText={
                    formik.touched.additional_note
                      ? formik.errors.additional_note
                      : ''
                  }
                />
              )}

              {!_.isNil(completedAt) && (
                <Grid item textAlign="end">
                  <Typography variant="subtitle2">
                    Completed by {completedBy} at
                  </Typography>
                  <Typography variant="subtitle2">
                    {datetime(completedAt, BACKEND_DATETIME_FORMAT).format(
                      DATETIME_WITH_PERIOD_FORMAT
                    )}
                  </Typography>
                </Grid>
              )}

              <Grid item>
                <Button
                  type="submit"
                  disabled={formik.isSubmitting}
                  data-gtm="button-submit-schedule-medication-save"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Dialog>
      )}
      <ConfirmationDialog
        open={isDeletionModalOpen}
        title="Are you sure you want to remove this medication?"
        primaryButtonColor="error"
        onClose={() => setIsDeletionModalOpen(false)}
        onConfirm={() => {
          handleMedicationDelete({ medication });
        }}
      >
        <Typography variant="subtitle1">
          The medication will be removed from the schedule for all dates which
          are past{' '}
          {datetime(selectedDate, BACKEND_DATE_FORMAT).format(
            'dddd, MMM DD, YYYY'
          )}
          .
        </Typography>
      </ConfirmationDialog>
    </>
  );
};

export default ScheduleMedicationDetailDialog;
