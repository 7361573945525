import _ from 'lodash';
import { FormikProps } from 'formik';

import { CheckCircleOutlineIcon, ErrorOutlineOutlinedIcon } from 'icons';

import TextField from 'components/TextField';
import Typography from 'components/Typography';
import InputAdornment from 'components/InputAdornment';

import { IPhoneNumber } from 'entities/Profile/sdk';

interface IPhoneField {
  label: string;
  name: string;
  formik: FormikProps<any>;
  phoneNumber: null | IPhoneNumber;
}

const PhoneField: React.FC<IPhoneField> = ({
  label,
  name,
  formik,
  phoneNumber
}) => {
  const isVerified =
    formik.values[name] === phoneNumber?.number && phoneNumber?.is_verified;

  return (
    <TextField.Outlined
      fullWidth
      label={label}
      type="text"
      name={name}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && !_.isEmpty(formik.errors[name])}
      helperText={formik.touched[name] ? formik.errors[name] : ''}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ backgroundColor: 'grey' }}>
            {isVerified ? (
              <>
                <CheckCircleOutlineIcon color="success" />
                <Typography marginLeft={0.5}>Verified</Typography>
              </>
            ) : (
              <>
                <ErrorOutlineOutlinedIcon color="error" />
                <Typography marginLeft={0.5}>Not verified</Typography>
              </>
            )}
          </InputAdornment>
        )
      }}
    />
  );
};
export default PhoneField;
