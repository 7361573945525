import _ from 'lodash';
import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { colors } from 'theme/palette';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Dialog from 'components/Dialog';
import Grid from 'components/Grid';
import Select from 'components/Select';
import { SelectChangeEvent } from '@mui/material';
import Button from 'components/Button';
import Avatar from 'components/Avatar';
import Typography from 'components/Typography';
import Stack from 'components/Stack';
import FormControl from 'components/FormControl';
import CheckBox from 'components/Checkbox';

import {
  PROFILE_ROLES,
  PROFILE_ROLES_MAPPER
} from 'entities/Profile/constants';
import {
  IUserDocumentPermissions,
  IUserDocument,
  useDocumentPermissionLevelsList
} from 'entities/Documents/sdk';
import { useSignedUser } from 'entities/Auth/sdk';

import { EPermission_Level } from 'entities/Documents/constants';

const useStyles = makeStyles((theme) => ({
  regularAvatar: {
    width: 50,
    height: 50
  },
  gridContainer: {
    padding: '5px',
    alignItems: 'center',
    spacing: '2px'
  },
  paperStyle: {
    padding: '10px',
    component: 'Grid',
    display: 'flex',
    rowGap: '2px'
  },
  fileIcon: {
    color: theme.palette.secondary.dark
  },
  fileContainer: {
    backgroundColor: colors.lightestGrey,
    alignItems: 'center',
    height: '60px',
    marginBottom: '10px'
  }
}));

interface IDocumentPermissionsDialog {
  document: IUserDocument;
  onClose: () => void;
  onDocumentPermissionEdit: (
    documentPermissionData: IUserDocumentPermissions[]
  ) => Promise<never> | undefined;
}

const DocumentPermissionsDialog: React.FC<IDocumentPermissionsDialog> = ({
  document,
  onClose,
  onDocumentPermissionEdit
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: signed_in_user } = useSignedUser();
  const { data: permissionLevelChoices = [] } =
    useDocumentPermissionLevelsList();
  const [permissions, setPermissions] = useState(
    document.care_team_permissions
  );

  const handleSubmit = useCallback(
    (values: IUserDocumentPermissions[]) => {
      return onDocumentPermissionEdit(values);
    },
    [onDocumentPermissionEdit]
  );

  const handleSelectChange =
    (user_id: string) => (event: SelectChangeEvent<unknown>) => {
      const updatedPermissionsData = permissions.map((item) => {
        if (item.user_id === user_id)
          return { ...item, permission_level: event?.target.value as string };
        else return item;
      });
      setPermissions(updatedPermissionsData);
    };

  const handleMarkAsPrivateChange = (
    event: React.SyntheticEvent,
    checked: Boolean
  ) => {
    if (!signed_in_user) return setPermissions(document.care_team_permissions);

    if (checked) {
      const updatedPermissionsData = permissions.map((item) => {
        if (item.user_id !== signed_in_user.id) {
          return { ...item, permission_level: EPermission_Level.HIDDEN };
        } else return item;
      });
      setPermissions(updatedPermissionsData);
    } else setPermissions(document.care_team_permissions);
  };

  return (
    <Dialog open onClose={onClose} title="Set Permissions">
      <Stack>
        <Grid container className={classes.fileContainer}>
          <Grid item marginLeft={2} marginRight={1}>
            <Typography variant="subtitle2">Document Name:</Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              sx={{ fontSize: 14, color: colors.darkGrey }}
            >
              {mobile
                ? document.file_name.substring(0, 20)
                : document.file_name}
              {mobile ? document.file_name.length > 20 && '...' : ''}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
      <Stack alignItems="end">
        <CheckBox
          label={<Typography variant="subtitle1">Mark as private</Typography>}
          onChange={handleMarkAsPrivateChange}
        />
      </Stack>
      <Stack margin={2} display="flex">
        <Stack>
          {!_.isEmpty(permissions) &&
            permissions.map((item) => (
              <Grid
                container
                key={item.profile_id}
                columnSpacing={2}
                marginBottom={2}
                alignItems="center"
              >
                <Grid item>
                  <Avatar
                    alt={item.full_name}
                    src={item.avatar}
                    className={classes.regularAvatar}
                  />
                </Grid>
                <Grid item xs paddingLeft={2}>
                  <Grid item>{item.full_name}</Grid>
                  <Grid item>
                    <Typography variant="subtitle2">
                      {item.care_team_role === PROFILE_ROLES.FAMILY_MEMBER
                        ? PROFILE_ROLES_MAPPER[item.care_team_role]
                        : item.care_team_role === PROFILE_ROLES.PATIENT
                        ? PROFILE_ROLES_MAPPER[item.care_team_role]
                        : PROFILE_ROLES_MAPPER[item.care_team_role]}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <Select
                      name="permission_level"
                      value={item.permission_level}
                      defaultValue={item.permission_level}
                      size="small"
                      onChange={handleSelectChange(item.user_id)}
                    >
                      {permissionLevelChoices.map(({ label, value }) => (
                        <Select.MenuItem value={value} key={value}>
                          <Typography variant="body1" sx={{ fontSize: 14 }}>
                            {label}
                          </Typography>
                        </Select.MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            ))}
        </Stack>
        <Stack marginTop={2}>
          <Button
            type="submit"
            onClick={() => handleSubmit(permissions)}
            data-gtm="button-submit-document-permissions-update"
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default DocumentPermissionsDialog;
