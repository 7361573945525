export const PROFILE_ROLES = {
  PATIENT: 'patient',
  FAMILY_MEMBER: 'family_member',
  AIDE: 'aide'
};

export const PROFILE_ROLES_MAPPER = {
  [PROFILE_ROLES.PATIENT]: 'Patient',
  [PROFILE_ROLES.FAMILY_MEMBER]: 'Family member',
  [PROFILE_ROLES.AIDE]: 'Aide'
};

export const SETUP_ACCOUNT_LINK = 'https://www.youtube.com/embed/z8w6IkYfD10';

export enum EOnboardingState {
  INITIAL_STATE = 'Initial state',
  HAS_CARE_TEAM = 'Has care team',
  FAMILY_MEMBER_WITH_NO_CARE_TEAM = 'Family member with no care team',
  WAITING_FOR_A_CARE_TEAM_INVITE_AS_AN_AIDE = 'Waiting for a care team invite as an aide',
  NO_CARE_TEAMS = 'No care teams'
}

export enum EProfileRoles {
  PATIENT = 'patient',
  FAMILY_MEMBER = 'family_member',
  AIDE = 'aide'
}
