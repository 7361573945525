import { BACKEND_DATE_FORMAT } from 'constants/time';

import { getNearestHalfHour } from 'utils/common';
import { datetime, getDatetimeObjectFromDate } from 'utils/datetime';

import { IDialog } from 'components/Dialog';

import {
  ICarePlanItemCategory,
  ICarePlanItemValues
} from 'entities/CarePlan/sdk';

import CarePlanItemFormDialog from 'entities/CarePlan/components/CarePlanItemFormDialog';

import { DEFAULT_REPEAT_CYCLE_VALUE } from './constants';

export interface ICarePlanItemCreateDialog extends IDialog {
  onCarePlanItemCreate: ({
    carePlanItem
  }: {
    carePlanItem: ICarePlanItemValues;
  }) => Promise<void>;
  categories: Array<ICarePlanItemCategory>;
  selectedDate?: string;
}

const CarePlanItemCreateDialog = ({
  onClose,
  onCarePlanItemCreate,
  categories,
  selectedDate,
  ...props
}: ICarePlanItemCreateDialog) => {
  let selectedDateDatetime = datetime();

  if (selectedDate) {
    selectedDateDatetime = getDatetimeObjectFromDate({
      date: selectedDate
    });
  }

  const initialValues: ICarePlanItemValues = {
    category: '',
    custom_category: '',
    repeat_cycle: DEFAULT_REPEAT_CYCLE_VALUE,
    repeat_cycle_days: [],
    is_all_day: false,
    start_time: getNearestHalfHour({ time: selectedDateDatetime }).format(
      'HH:mm'
    ),
    end_time: getNearestHalfHour({
      time: selectedDateDatetime.add(30, 'm')
    }).format('HH:mm'),
    repeat_after: selectedDateDatetime.format(BACKEND_DATE_FORMAT),
    repeat_before: null,
    is_added_to_schedule: true,
    description: ''
  };

  return (
    <CarePlanItemFormDialog
      {...props}
      title="New care task"
      onClose={onClose}
      categories={categories}
      initialValues={initialValues}
      onCarePlanItemSubmit={onCarePlanItemCreate}
    />
  );
};

export default CarePlanItemCreateDialog;
