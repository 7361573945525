import { ReactNode } from 'react';

interface IExternalLink {
  to: string;
  children: ReactNode;
}

const ExternalLink: React.FC<IExternalLink> = ({ to, children }) => (
  <a target="_blank" rel="noopener noreferrer" href={to}>
    {children}
  </a>
);

export default ExternalLink;
