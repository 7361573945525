import { useCallback } from 'react';
import { FormikHelpers } from 'formik';

import Dialog from 'components/Dialog';

import {
  familyMemberRoleUpdate,
  IFamilyMemberRoleFormValues,
  useSelectedCareTeam
} from 'entities/CareTeam/sdk';

import FamilyMemberRoleForm from 'entities/CareTeam/components/FamilyMemberRoleForm';

import { ICareTeamMember } from 'entities/CareTeam/sdk';

export interface IFamilyMemberRoleCreateFormValuesDialog {
  mutateCareTeamMembers: any;
  member: ICareTeamMember;
  onClose: () => void;
}

const FamilyMemberRoleUpdateDialog = ({
  onClose,
  mutateCareTeamMembers,
  member
}: IFamilyMemberRoleCreateFormValuesDialog) => {
  const { careTeamId = '' } = useSelectedCareTeam();

  const handleSubmit = useCallback(
    (
      values: IFamilyMemberRoleFormValues,
      formikHelpers: FormikHelpers<IFamilyMemberRoleFormValues>
    ) => {
      return familyMemberRoleUpdate({
        memberId: member.care_team_member_id,
        care_team_id: careTeamId,
        values
      })
        .then(() => {
          mutateCareTeamMembers();
          onClose();
        })
        .catch(formikHelpers.setErrors);
    },

    [mutateCareTeamMembers, onClose, careTeamId, member.care_team_member_id]
  );

  const defaultFamilyMemberRoleValue = member.family_member_role
    ? member.family_member_role
    : '';

  const initialValues: IFamilyMemberRoleFormValues = {
    family_member_role: defaultFamilyMemberRoleValue
  };

  return (
    <Dialog open onClose={onClose} title="Add relationship">
      <FamilyMemberRoleForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default FamilyMemberRoleUpdateDialog;
