import _ from 'lodash';

import { datetime, localToUtc } from 'utils/datetime';

import { getUtcTimeFromLocalTime } from 'utils/datetime';
import { BACKEND_DATETIME_FORMAT, BACKEND_DATE_FORMAT } from 'constants/time';
import { ICarePlanItemValues } from './sdk';

export const getCarePlanItemPostData = ({
  carePlanItem
}: {
  carePlanItem: ICarePlanItemValues;
}) => {
  let transformedRepeatCycleDays: Array<string> = [];
  let repeatAfterDatetime = datetime(
    carePlanItem.repeat_after,
    BACKEND_DATE_FORMAT,
    true
  );

  let repeatBeforeDatetime = carePlanItem.repeat_before
    ? datetime(carePlanItem.repeat_before, BACKEND_DATE_FORMAT, true)
    : null;

  if (carePlanItem.start_time) {
    // If a start_time has been set we use it, along with repeatAfter
    // to construct the Care Plan Item's repeat after.
    const startTime = datetime(carePlanItem.start_time, 'HH:mm', true);

    repeatAfterDatetime = repeatAfterDatetime
      .hour(startTime.hour())
      .minute(startTime.minute());
  }

  if (repeatBeforeDatetime) {
    // We usually take the end of the day for the repeat before time.
    repeatBeforeDatetime = repeatBeforeDatetime.hour(23).minute(59);

    if (carePlanItem.end_time) {
      // If the care plan item has an end time - we use it, instead of the end of the day.
      const endTime = datetime(carePlanItem.end_time, 'HH:mm', true);

      repeatBeforeDatetime = repeatBeforeDatetime
        .hour(endTime.hour())
        .minute(endTime.minute());
    }
  }

  if (!_.isEmpty(carePlanItem.repeat_cycle_days)) {
    if (!carePlanItem.start_time) {
      // In case the care plan items are all-day ones we don't need to convert the selected repeat cycle days from the user timezone to UTC.
      transformedRepeatCycleDays = carePlanItem.repeat_cycle_days;
    } else {
      const cursorStart = repeatAfterDatetime;
      let cursorDate = repeatAfterDatetime;

      while (cursorDate.isBefore(cursorStart.add(7, 'days'), 'day')) {
        // We go through the next 7 days and find the selected days of the week.
        // For each of them we append the day transformed in UTC to transformedRepeatCycleDays.
        const cursorDateDayOfTheWeek = cursorDate.format('dddd').toLowerCase();
        const selectedRepeatCycleDays = carePlanItem.repeat_cycle_days.map(
          (day) => day.toString()
        );

        if (selectedRepeatCycleDays.includes(cursorDateDayOfTheWeek)) {
          const transformedCursorDate = localToUtc(cursorDate);
          const transformedCursorDateDayOfTheWeek = transformedCursorDate
            .format('dddd')
            .toLowerCase();

          transformedRepeatCycleDays.push(transformedCursorDateDayOfTheWeek);
        }

        cursorDate = cursorDate.add(1, 'days');
      }
    }
  }

  const utcRepeatAfterDatetime = localToUtc(repeatAfterDatetime).format(
    BACKEND_DATETIME_FORMAT
  );
  // If we didn't have a repeat_before date we should keep it that way.
  const utcRepeatBeforeDatetime = repeatBeforeDatetime
    ? localToUtc(repeatBeforeDatetime).format(BACKEND_DATETIME_FORMAT)
    : null;

  return {
    ...carePlanItem,
    repeat_after: utcRepeatAfterDatetime,
    repeat_before: utcRepeatBeforeDatetime,
    repeat_cycle_days: transformedRepeatCycleDays,
    start_time: carePlanItem.start_time
      ? getUtcTimeFromLocalTime({ time: carePlanItem.start_time })
      : null,
    end_time: carePlanItem.end_time
      ? getUtcTimeFromLocalTime({ time: carePlanItem.end_time })
      : null
  };
};
