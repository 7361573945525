import _ from 'lodash';
import queryString, { ParsedQuery, StringifyOptions } from 'query-string';

import { IDatetime } from 'utils/datetime';

import { EDaysOfTheWeek } from 'constants/common';

export const removeTrailingZeros = (number: string) =>
  parseFloat(number).toString();

export const substituteUrl = ({
  url,
  kwargs
}: {
  url: string;
  kwargs: { [key: string]: string };
}) => {
  if (kwargs) {
    Object.keys(kwargs).map((key) => {
      url = url.replace(`:${key}`, kwargs[key]);
      return url;
    });
  }

  return url;
};

export const queryStringParse = ({
  queryParams
}: {
  queryParams: string;
}): ParsedQuery => queryString.parse(queryParams);

export const queryStringStringify = ({
  data,
  options
}: {
  data: any;
  options?: StringifyOptions;
}): string => queryString.stringify(data, options);

export const openInNewTab = window.open;

export const getNearestHalfHour = ({
  time
}: {
  time: IDatetime;
}): IDatetime => {
  const hour = time.hour();
  const minutes = time.minute();

  if (minutes >= 30) {
    return time.add(1, 'h').minute(0);
  }

  return time.hour(hour).minute(30);
};

export const orderDaysOfTheWeek = ({ days }: { days: Array<string> }) => {
  const weekdays = _.values(EDaysOfTheWeek);

  return _.sortBy(days, (day) => _.indexOf(weekdays, day.toLowerCase()));
};

// Turns 235 into 240 and 234 into 230.
export const roundUpToTheNearestTenth = ({ number }: { number: number }) =>
  Math.round(number / 10) * 10;
