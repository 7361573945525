import _ from 'lodash';

import { useCallback } from 'react';
import { useFormik, FormikHelpers } from 'formik';

import Grid from 'components/Grid';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Select from 'components/Select';
import Typography from 'components/Typography';
import Dialog from 'components/Dialog';

import { GENERIC_FORM_ERRORS_KEY, IFormError } from 'utils/sdk';

import {
  careTeamMemberRoleUpdate,
  IMemberCareTeamRole,
  useSelectedCareTeam
} from 'entities/CareTeam/sdk';

import { ICareTeamMember } from 'entities/CareTeam/sdk';

import { VALIDATION_SCHEMA, MEMBER_ROLE_CHOICES } from './constants';

export interface IMemberCareTeamRoleUpdateDialog {
  mutateCareTeamMembers: () => void;
  onClose: () => void;
  member: ICareTeamMember;
}

const MemberRoleUpdateDialog = ({
  onClose,
  mutateCareTeamMembers,
  member
}: IMemberCareTeamRoleUpdateDialog) => {
  const { careTeamId = '' } = useSelectedCareTeam();

  const handleSubmit = useCallback(
    (
      values: IMemberCareTeamRole,
      formikHelpers: FormikHelpers<IMemberCareTeamRole>
    ) => {
      return careTeamMemberRoleUpdate({
        memberId: member.care_team_member_id,
        careTeamId,
        values
      })
        .then(() => {
          mutateCareTeamMembers();
          onClose();
        })
        .catch(formikHelpers.setErrors);
    },

    [mutateCareTeamMembers, onClose, careTeamId, member.care_team_member_id]
  );

  const initialValues: IMemberCareTeamRole = {
    care_team_role: member.care_team_role ? member.care_team_role : ''
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA
  });

  const formErrors: Array<IFormError> = _.get(
    formik.errors,
    GENERIC_FORM_ERRORS_KEY,
    []
  );

  return (
    <Dialog open onClose={onClose} title={'Update member role'}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column" spacing={3} padding={2}>
          {!_.isEmpty(formErrors) && (
            <Grid item xs={12}>
              {formErrors.map((error, index) => (
                <Alert key={index} severity="error">
                  {error.message}
                </Alert>
              ))}
            </Grid>
          )}

          <Grid item>
            <Typography variant="h5">Select member role</Typography>
          </Grid>

          <Grid item>
            <Select
              label=""
              options={MEMBER_ROLE_CHOICES}
              name="care_team_role"
              value={formik.values.care_team_role}
              onChange={formik.handleChange}
              error={
                formik.touched.care_team_role &&
                !_.isEmpty(formik.errors.care_team_role)
              }
              helperText={
                formik.touched.care_team_role
                  ? formik.errors.care_team_role
                  : ''
              }
            />
          </Grid>

          <Grid item>
            <Button
              type="submit"
              color="primary"
              disabled={formik.isSubmitting}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default MemberRoleUpdateDialog;
