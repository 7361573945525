import { useTheme } from '@mui/material/styles';

import {
  BACKEND_DATETIME_FORMAT,
  DATETIME_WITH_PERIOD_SHORTENED_FORMAT
} from 'constants/time';
import { datetime } from 'utils/datetime';

import Box from 'components/Box';
import Checkbox from 'components/Checkbox';
import Typography from 'components/Typography';

import { IWebAppNotification } from 'entities/Notifications/sdk';

interface IWebAppNotificationBox {
  disabled: boolean;
  webAppNotification: IWebAppNotification;
  handleSeenUpdate: ({
    webAppNotification
  }: {
    webAppNotification: IWebAppNotification;
  }) => void;
}

const WebAppNotificationBox = ({
  disabled,
  webAppNotification,
  handleSeenUpdate
}: IWebAppNotificationBox) => {
  const theme = useTheme();
  return (
    <Box
      width={275}
      paddingX={2}
      paddingY={1}
      onClick={() => handleSeenUpdate({ webAppNotification })}
      sx={{
        cursor: disabled ? 'wait' : 'pointer',
        borderBottom: '1px solid silver',
        backgroundColor: webAppNotification.seen
          ? 'white'
          : `${theme.palette.primary.main}1A`,
        transition: 'all 0.2s ease',
        opacity: webAppNotification.seen ? 0.5 : 1,
        '&:hover': {
          opacity: webAppNotification.seen ? 0.75 : 1,
          backgroundColor: webAppNotification.seen
            ? `${theme.palette.primary.main}1A`
            : `${theme.palette.primary.main}33`
        }
      }}
    >
      <Checkbox
        sx={{
          cursor: 'inherit'
        }}
        label={
          <>
            {webAppNotification.message}
            <Typography variant="subtitle2" fontSize={11}>
              {datetime(
                webAppNotification.created_at,
                BACKEND_DATETIME_FORMAT,
                true
              ).format(DATETIME_WITH_PERIOD_SHORTENED_FORMAT)}
            </Typography>
          </>
        }
        checkboxProps={{
          checked: webAppNotification.seen
        }}
      />
    </Box>
  );
};

export default WebAppNotificationBox;
