import { useHistory } from 'react-router-dom';

import {
  PROFILE_UPDATE_URL,
  NOTIFICATION_PREFERENCES_URL,
  PROFILE_URL,
  SETTINGS_URL
} from 'config/urls';
import { REDIRECT_URL_PARAM_KEY } from 'constants/common';

import { useRedirectToOnboarding } from 'utils/hooks';

import PageHeader from 'components/PageHeader';
import Grid from 'components/Grid';
import Divider from 'components/Divider';
import ExtendedAvatar from 'components/ExtendedAvatar';

import Details from 'entities/Profile/components/Details';

import { useSignedUser } from 'entities/Auth/sdk';
import { useProfile } from 'entities/Profile/sdk';

import MenuLabelArrowButton from 'components/MenuLabelArrowButton';

interface IProfile {}

const Profile: React.FC<IProfile> = () => {
  useRedirectToOnboarding();

  const { data: user } = useSignedUser();
  const { data: profile } = useProfile({ profileId: user?.profile.id });

  const history = useHistory();

  return (
    <Grid container gap={2} direction="column">
      <Grid item>
        <PageHeader
          //heading="My Profile"
          backTo={SETTINGS_URL}
          updateTo={profile ? PROFILE_UPDATE_URL : undefined}
        />
      </Grid>

      {profile && (
        <>
          <Grid item paddingBottom={2}>
            <ExtendedAvatar
              image={profile.avatar}
              size="medium"
              heading={profile.first_name}
              subHeading={profile.last_name}
            />
          </Grid>

          <Grid item width="100%">
            <Details profile={profile} />
          </Grid>

          <Grid item width="100%">
            <Divider />
          </Grid>

          <Grid item width="100%">
            <MenuLabelArrowButton
              label="Notification preferences"
              onClick={() =>
                history.push(
                  `${NOTIFICATION_PREFERENCES_URL}?profile=${profile.id}&${REDIRECT_URL_PARAM_KEY}=${PROFILE_URL}`
                )
              }
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default Profile;
