import _, { isUndefined } from 'lodash';
import { makeStyles } from '@mui/styles';
import { DialogProps } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

import Box from 'components/Box';
import IconButton from 'components/IconButton';
import SimpleDialog from 'components/SimpleDialog';
import Typography from 'components/Typography';
import Grid from 'components/Grid';

import { queryStringParse } from 'utils/common';

import { ArrowBackIcon } from 'icons';

export interface IDialog extends DialogProps {
  open: boolean;
  title?: string;
  asideTitleComponent?: React.ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginLeft: -12,
    marginTop: -12
  }
}));

const Dialog: React.FC<IDialog> = ({
  open,
  onClose,
  title,
  asideTitleComponent,
  children
}) => {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  const queryParams = queryStringParse({
    queryParams: location.search
  });
  const redirectPath = _.get(queryParams, 'redirectUrl')?.toString();

  const goBack = () => {
    if (isUndefined(redirectPath)) {
      onClose();
    } else {
      history.push(redirectPath);
    }
  };

  return (
    <SimpleDialog open={open} onClose={onClose}>
      <Box p={2}>
        <Grid container justifyContent="space-between">
          <Grid item xs zeroMinWidth>
            <IconButton
              className={classes.backButton}
              onClick={goBack}
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>

            <Typography variant="h2">{title}</Typography>
          </Grid>
          {asideTitleComponent && <Grid item>{asideTitleComponent}</Grid>}
        </Grid>
      </Box>
      {children}
    </SimpleDialog>
  );
};

export default Dialog;
