import _ from 'lodash';
import { useCallback, useState } from 'react';

import { datetime } from 'utils/datetime';
import { makeStyles } from '@mui/styles';

import Dialog, { IDialog } from 'components/Dialog';
import Stack from 'components/Stack';
import Accordion from 'components/Accordion';
import Typography from 'components/Typography';
import Grid from 'components/Grid';
import Paper from 'components/Paper';
import ButtonGroup from 'components/ButtonGroup';
import Button from 'components/Button';
import { EditIcon, DeleteIcon } from 'icons';

import DiaryNoteUpdateDialog from 'entities/Diary/components/GeneralNoteUpdateDialog';
import ConfirmationDialog from 'components/ConfirmationDialog';
import {
  generalNoteUpdate,
  generalNoteDelete,
  IGeneralNoteFormValues,
  IGeneralNote
} from 'entities/Diary/sdk';
import { useSelectedPatient } from 'entities/Patient/sdk';
import {
  IScheduleItem,
  usePatientScheduleItemList
} from 'entities/Schedule/sdk';

import {
  BACKEND_DATETIME_FORMAT,
  DATETIME_WITH_PERIOD_SHORTENED_FORMAT
} from 'constants/time';
import toast from 'utils/toast';

export interface IScheduleRelatedNotesDialog extends IDialog {
  notes: Array<IGeneralNote>;
  scheduleItemID: string;
  scheduleItemName?: string;
  selectedDate: string;
  refetchScheduleItemsInParent: () => Promise<IScheduleItem[] | undefined>;
  updateRelatedNoteCountInParent: (scheduleItems: Array<IScheduleItem>) => void;
}

const useStyles = makeStyles((theme) => ({
  note: {
    cursor: 'pointer',
    transition: 'all 0.3s linear',
    '&:hover': {
      'box-shadow': '0px 2px 4px rgba(0, 0, 0, 0.15)'
    }
  },
  cursorPointer: {
    cursor: 'pointer'
  }
}));

const ScheduleRelatedNotesDialog: React.FC<IScheduleRelatedNotesDialog> = ({
  notes,
  scheduleItemID,
  scheduleItemName,
  selectedDate,
  refetchScheduleItemsInParent,
  updateRelatedNoteCountInParent,
  ...props
}) => {
  const classes = useStyles();

  const { patient } = useSelectedPatient();
  const [isDiaryNoteUpdateDialogOpen, setIsDiaryNoteUpdateDialogOpen] =
    useState<boolean>(Boolean(false));
  const [selectedNote, setSelectedNote] = useState<null | IGeneralNote>(null);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState<boolean>(Boolean(false));

  const {
    data: scheduleItems = [],
    loading: isFetching,
    refetch: refetchScheduleItems
  } = usePatientScheduleItemList({
    patientId: patient?.id,
    selectedDate
  });

  const [itemRelatedNotes, setItemRelatedNotes] =
    useState<Array<IGeneralNote> | null>(
      scheduleItems.find(
        (scheduleItem: IScheduleItem) => scheduleItem.id === scheduleItemID
      )['related_notes']
    );

  const setRelatedNotesState = useCallback(
    (scheduleItems: Array<IScheduleItem>) => {
      const updatedItem = _.find(
        scheduleItems,
        (scheduleItems) => scheduleItems.id === scheduleItemID
      );
      if (!_.isNil(updatedItem)) {
        setItemRelatedNotes(updatedItem.related_notes);
      } else {
        setItemRelatedNotes(null);
      }
    },
    [scheduleItemID]
  );

  const handleDiaryNoteDelete = useCallback(() => {
    if (!patient?.id) {
      return Promise.reject("There's no patient in the care team.");
    }

    if (_.isNil(selectedNote)) {
      return Promise.reject('No note selected!');
    }

    generalNoteDelete({
      patientId: patient.id,
      diaryNoteId: selectedNote.id
    }).then(() => {
      refetchScheduleItems().then((result) => {
        if (!_.isNil(result)) {
          setRelatedNotesState(result);
          updateRelatedNoteCountInParent(result);
        }
      });
      setIsDeleteConfirmationDialogOpen(false);
      toast.info({
        content: 'Note successfully deleted!'
      });
    });
  }, [
    patient,
    selectedNote,
    refetchScheduleItems,
    setRelatedNotesState,
    updateRelatedNoteCountInParent
  ]);

  const handleDiaryNoteUpdate = useCallback(
    ({ diaryNote }: { diaryNote: IGeneralNoteFormValues }) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      if (_.isNil(selectedNote)) {
        return Promise.reject('No note selected!');
      }

      return generalNoteUpdate({
        patientId: patient.id,
        diaryNote,
        diaryNoteId: selectedNote.id
      }).then(() => {
        refetchScheduleItemsInParent();
        refetchScheduleItems().then((result) => {
          if (!_.isNil(result)) {
            setRelatedNotesState(result);
          }
        });
        setIsDiaryNoteUpdateDialogOpen(false);
        toast.info({
          content: 'Note successfully updated!'
        });
      });
    },
    [
      patient,
      refetchScheduleItemsInParent,
      refetchScheduleItems,
      setRelatedNotesState,
      selectedNote
    ]
  );

  return (
    <Dialog {...props} title="View Notes">
      <Paper
        container
        padding={2}
        component={Grid}
        display="flex"
        flexDirection="column"
        rowGap={2}
      >
        <Typography variant="h5" fontWeight={700}>
          {scheduleItemName}
        </Typography>
        {_.isNil(itemRelatedNotes) && (
          <Stack>
            <Typography variant="subtitle2">This task has no notes</Typography>
          </Stack>
        )}
        {!_.isNil(itemRelatedNotes) && !isFetching && (
          <Stack spacing={2}>
            {itemRelatedNotes.map((item) => (
              <Accordion className={classes.note} key={item.id}>
                <Accordion.Details>
                  <Stack alignItems="end">
                    <Typography variant="subtitle2">
                      {datetime(
                        item.created_at,
                        BACKEND_DATETIME_FORMAT
                      ).format(DATETIME_WITH_PERIOD_SHORTENED_FORMAT)}
                    </Typography>
                    <Typography variant="subtitle2">
                      {item.created_by}
                    </Typography>
                  </Stack>
                  <Stack alignItems="left" padding={2}>
                    <Typography variant="body1">{item.text}</Typography>
                  </Stack>
                  <Stack alignItems="end">
                    <ButtonGroup variant="text">
                      <Button
                        color="inherit"
                        onClick={() => {
                          setIsDiaryNoteUpdateDialogOpen(true);
                          setSelectedNote(item);
                        }}
                        size="small"
                      >
                        <EditIcon fontSize="small" />
                      </Button>
                      <Button
                        color="inherit"
                        onClick={() => {
                          setIsDeleteConfirmationDialogOpen(true);
                          setSelectedNote(item);
                        }}
                        size="small"
                      >
                        <DeleteIcon fontSize="small" />
                      </Button>
                    </ButtonGroup>
                    {isDiaryNoteUpdateDialogOpen && selectedNote && (
                      <DiaryNoteUpdateDialog
                        onClose={() => setIsDiaryNoteUpdateDialogOpen(false)}
                        diaryNote={selectedNote}
                        onDiaryNoteUpdate={handleDiaryNoteUpdate}
                      />
                    )}
                    {isDeleteConfirmationDialogOpen && selectedNote && (
                      <ConfirmationDialog
                        open
                        title="Are you sure you want to delete this note?"
                        primaryButtonColor="error"
                        onClose={() => setIsDeleteConfirmationDialogOpen(false)}
                        onConfirm={handleDiaryNoteDelete}
                      />
                    )}
                  </Stack>
                </Accordion.Details>
              </Accordion>
            ))}
          </Stack>
        )}
      </Paper>
    </Dialog>
  );
};

export default ScheduleRelatedNotesDialog;
