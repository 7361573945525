import Grid from 'components/Grid';
import Loading from 'components/Loading';

export interface IPageLoader {}

const PageLoader: React.FC<IPageLoader> = () => (
  <Grid container alignItems="center" justifyContent="center" padding={11}>
    <Grid item>
      <Loading />
    </Grid>
  </Grid>
);

export default PageLoader;
