import { toast, ToastContent, ToastOptions } from 'react-toastify';

import Stack from 'components/Stack';
import Typography from 'components/Typography';

const customToast = {
  info: ({
    content,
    options
  }: {
    content: ToastContent;
    options?: ToastOptions;
  }) => toast.info(content, options),
  actionable: ({
    content,
    action,
    actionButtonText,
    options
  }: {
    content: string;
    action: () => void;
    actionButtonText: string;
    options?: ToastOptions;
  }) =>
    toast.info(
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={action}
      >
        {content}
        <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap' }}>
          {actionButtonText}
        </Typography>
      </Stack>,
      options
    )
};

export { ToastContainer } from 'react-toastify';
export default customToast;
