import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import { ONBOARDING_URL } from 'config/urls';
import { queryStringParse } from 'utils/common';
import { REDIRECT_URL_PARAM_KEY } from 'constants/common';

import { useSignedUser } from 'entities/Auth/sdk';
import { useProfile } from 'entities/Profile/sdk';
import { EOnboardingState } from 'entities/Profile/constants';

export const useRedirectUrl = () => {
  const location = useLocation();

  const queryParams = queryStringParse({
    queryParams: location.search
  });
  const redirectUrl = _.get(queryParams, REDIRECT_URL_PARAM_KEY);

  return redirectUrl as string;
};

// This hook will need to be phased out as onboarding states are no
// longer in use.
export const useRedirectToOnboardingIfOnboardingStateIsNotInList = ({
  states
}: {
  states: Array<EOnboardingState>;
}) => {
  const history = useHistory();
  const { data: user } = useSignedUser();
  const { data: profile, loading } = useProfile({
    profileId: user?.profile.id
  });

  const onboardingState = profile?.onboarding_state;

  if (!loading && onboardingState && !states.includes(onboardingState)) {
    history.push(ONBOARDING_URL);
  }
};

export const useRedirectToOnboarding = () => {
  const history = useHistory();
  const { data: user } = useSignedUser();
  const { data: profile, loading } = useProfile({
    profileId: user?.profile.id
  });

  const userIsAPatientOrHasACareTeam =
    _.isNull(profile?.care_team_membership) && _.isNull(profile?.patient)
      ? false
      : true;

  if (!loading && !userIsAPatientOrHasACareTeam) {
    history.push(ONBOARDING_URL);
  }
};
