import _ from 'lodash';
import { useState } from 'react';
import { FormikProps } from 'formik';

import IconButton from 'components/IconButton';
import InputAdornment from 'components/InputAdornment';
import { default as TextField, IOutlinedTextField } from 'components/TextField';

import { VisibilityIcon, VisibilityOffIcon } from 'icons';

interface IPasswordField extends IOutlinedTextField {
  formik: FormikProps<any>;
}

const PasswordField: React.FC<IPasswordField> = ({
  formik,
  name,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const value = _.get(formik, `values.${name}`);
  const touched = _.get(formik, `touched.${name}`);
  const error = _.get(formik, `errors.${name}`);

  return (
    <TextField.Outlined
      fullWidth
      name={name}
      value={value}
      type={showPassword ? 'text' : 'password'}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      helperText={touched ? error : ''}
      error={touched && !_.isEmpty(error)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...props}
    />
  );
};

export default PasswordField;
