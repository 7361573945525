import _ from 'lodash';
import { BASE_API_URL } from 'config/urls';

import { useFetch, post } from 'utils/sdk';
import {
  getTodayDate,
  localToUtc,
  datetime,
  deepTransformLocalToUTC,
  deepTransformUTCToLocal
} from 'utils/datetime';
import { BACKEND_DATETIME_FORMAT, BACKEND_DATE_FORMAT } from 'constants/time';

import { getVitalsOutOfRange } from 'entities/Patient/utils';

import {
  useSelectedPatient,
  usePatientCustomVitals
} from 'entities/Patient/sdk';

import { EDiaryScheduleItemType } from 'entities/Diary/constants';

export interface IGeneralNoteFormValues {
  subject?: string;
  text: string;
  schedule_date: string;
}

export interface IVitalNoteFormValues {
  measured_at: string;
  temperature?: string;
  blood_pressure_systolic?: string;
  blood_pressure_diastolic?: string;
  pulse?: string;
  blood_oxygen?: string;
  blood_sugar_fasting?: string;
  blood_sugar_non_fasting?: string;
}

export interface IGeneralNote {
  id: string;
  subject: string;
  text: string;
  schedule_datetime: string;
  created_at: string;
  created_by: {
    profile: {
      full_name: string;
    };
  };
}

export interface IVitalNote {
  id: string;
  measured_at: string;
  temperature: string;
  temperature_unit: string;
  blood_pressure_systolic: string;
  blood_pressure_diastolic: string;
  pulse: string;
  blood_oxygen: string;
  blood_sugar_fasting: string;
  blood_sugar_non_fasting: string;
  created_at: string;
  created_by: {
    profile: {
      full_name: string;
    };
  };
}

export interface IChartDataItem extends IVitalNote {
  label: string;
  timestamp: number;
}

export type IDiaryScheduleItem =
  | (IGeneralNote & {
      type: EDiaryScheduleItemType.DIARY_NOTE;
    })
  | (IVitalNote & {
      type: EDiaryScheduleItemType.VITAL_NOTE;
    });

export const useDiarySchedule = ({
  patientId,
  selectedDate,
  isForFullMonth
}: {
  patientId: undefined | string;
  selectedDate: string;
  isForFullMonth: boolean;
}) => {
  const selectedDateAsDatetime = datetime(
    selectedDate,
    BACKEND_DATE_FORMAT,
    true
  );

  let startDatetime = localToUtc(selectedDateAsDatetime);
  let endDatetime = startDatetime.add(1, 'd');

  if (isForFullMonth) {
    startDatetime = localToUtc(selectedDateAsDatetime.startOf('month'));
    endDatetime = localToUtc(selectedDateAsDatetime.endOf('month'));
  }

  const formattedStartDatetime = startDatetime.format(BACKEND_DATETIME_FORMAT);
  const formattedEndDatetime = endDatetime.format(BACKEND_DATETIME_FORMAT);

  const result = useFetch<Array<IDiaryScheduleItem>>(
    patientId
      ? `${BASE_API_URL}/diary/patient/${patientId}/day/list/?start_datetime=${formattedStartDatetime}&end_datetime=${formattedEndDatetime}`
      : null
  );

  return {
    ...result,
    data: deepTransformUTCToLocal({ data: result.data })
  };
};

export const generalNoteCreate = ({
  patientId,
  diaryNote,
  schedule_item_id,
  schedule_item_type
}: {
  patientId: string;
  diaryNote: IGeneralNoteFormValues;
  schedule_item_id: string | null;
  schedule_item_type: string | null;
}) => {
  const scheduleDateAsUTCDatetime = localToUtc(
    datetime(diaryNote.schedule_date, BACKEND_DATE_FORMAT, true)
  ).format(BACKEND_DATETIME_FORMAT);

  const postData = {
    ...diaryNote,
    schedule_item_id,
    schedule_datetime: scheduleDateAsUTCDatetime
  };

  if (_.isNull(schedule_item_id)) {
    return post<{ id: string }>(
      `${BASE_API_URL}/diary/patient/${patientId}/general-note/create/`,
      postData
    );
  } else {
    return post<{ id: string }>(
      `${BASE_API_URL}/diary/patient/${patientId}/general-note/${schedule_item_type}/${schedule_item_id}/create/`,
      postData
    );
  }
};

export const generalNoteUpdate = ({
  patientId,
  diaryNote,
  diaryNoteId
}: {
  patientId: string;
  diaryNote: IGeneralNoteFormValues;
  diaryNoteId: string;
}) => {
  const scheduleDateAsUTCDatetime = localToUtc(
    datetime(diaryNote.schedule_date, BACKEND_DATE_FORMAT, true)
  ).format(BACKEND_DATETIME_FORMAT);

  const postData = {
    ...diaryNote,
    schedule_datetime: scheduleDateAsUTCDatetime
  };

  return post(
    `${BASE_API_URL}/diary/patient/${patientId}/general-note/${diaryNoteId}/update/`,
    postData
  );
};
export const generalNoteDelete = ({
  patientId,
  diaryNoteId
}: {
  patientId: string;
  diaryNoteId: string;
}) =>
  post(
    `${BASE_API_URL}/diary/patient/${patientId}/general-note/${diaryNoteId}/delete/`
  );

export const vitalNoteCreate = ({
  patientId,
  vitalNote
}: {
  patientId: string;
  vitalNote: IVitalNoteFormValues;
}) => {
  const data = {
    ...vitalNote,
    measured_at: localToUtc(
      datetime(vitalNote.measured_at, BACKEND_DATETIME_FORMAT)
    ).format(BACKEND_DATETIME_FORMAT)
  };

  return post<{ id: string }>(
    `${BASE_API_URL}/diary/patient/${patientId}/vital-note/create/`,
    data
  );
};

export const vitalNoteDelete = ({
  patientId,
  vitalNoteId
}: {
  patientId: string;
  vitalNoteId: string;
}) =>
  post(
    `${BASE_API_URL}/diary/patient/${patientId}/vital-note/${vitalNoteId}/delete/`
  );

export const vitalNoteUpdate = ({
  patientId,
  vitalNote,
  vitalNoteId
}: {
  patientId: string;
  vitalNote: IVitalNoteFormValues;
  vitalNoteId: string;
}) => {
  const data = {
    ...vitalNote,
    measured_at: localToUtc(
      datetime(vitalNote.measured_at, BACKEND_DATETIME_FORMAT)
    ).format(BACKEND_DATETIME_FORMAT)
  };

  return post(
    `${BASE_API_URL}/diary/patient/${patientId}/vital-note/${vitalNoteId}/update/`,
    data
  );
};

export const useDiaryUpdatesForToday = () => {
  const { patient } = useSelectedPatient();
  const { data: diarySchedule = [] } = useDiarySchedule({
    patientId: patient?.id,
    selectedDate: getTodayDate(),
    isForFullMonth: false
  });

  const { data: customVitals } = usePatientCustomVitals({
    patientId: patient?.id
  });

  const allNotesCount = diarySchedule.length;

  const vitalNotes = diarySchedule.filter(
    (item: IDiaryScheduleItem) =>
      item.type === EDiaryScheduleItemType.VITAL_NOTE
  ) as Array<IVitalNote & { type: EDiaryScheduleItemType.VITAL_NOTE }>;

  let notesOutOfRangeCount = 0;

  _.map(vitalNotes, (note) => {
    const { vitalsOutOfRange } = getVitalsOutOfRange({
      customVitals,
      vitalNote: note
    });

    if (!_.isEmpty(vitalsOutOfRange)) {
      notesOutOfRangeCount += 1;
    }
  });

  return { allNotesCount, notesOutOfRangeCount };
};

export const useVitalNotes = ({
  patientId,
  startDateTime,
  endDateTime
}: {
  patientId: string | undefined;
  startDateTime: string;
  endDateTime: string;
}) => {
  const [startDateTimeUtc, endDateTimeUtc] = deepTransformLocalToUTC({
    data: [startDateTime, endDateTime]
  });

  const result = useFetch<Array<IVitalNote>>(
    patientId
      ? `${BASE_API_URL}/diary/patient/${patientId}/vital-note/day/list/?start_date=${startDateTimeUtc}&end_date=${endDateTimeUtc}`
      : null,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  return {
    ...result,
    data: deepTransformUTCToLocal({ data: result.data }) as Array<IVitalNote>
  };
};
