import _ from 'lodash';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useCallback } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import { GENERIC_FORM_ERRORS_KEY, IFormError } from 'utils/sdk';

import Stack from 'components/Stack';
import Alert from 'components/Alert';
import TextField from 'components/TextField';
import Button from 'components/Button';
import PasswordField from 'components/PasswordField';

import { IRegisterFormValues } from 'entities/Auth/sdk';

import { VALIDATION_SCHEMA } from './constants';

interface IRegisterForm {
  onSubmit: ({ values }: { values: IRegisterFormValues }) => Promise<void>;
}

const RegisterForm: React.FC<IRegisterForm> = ({ onSubmit }) => {
  const handleSubmit = useCallback(
    (
      values: IRegisterFormValues,
      formikHelpers: FormikHelpers<IRegisterFormValues>
    ) => onSubmit({ values }).catch(formikHelpers.setErrors),
    [onSubmit]
  );

  const initialValues: IRegisterFormValues = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: '',
    user_platform_type: 'web'
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA
  });

  const formErrors: Array<IFormError> = _.get(
    formik.errors,
    GENERIC_FORM_ERRORS_KEY,
    []
  );

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack
        spacing={3}
        alignItems="center"
        minWidth={mobile ? '100%' : '390px'}
      >
        {formErrors.map((error, index) => (
          <Alert key={index} severity="error">
            {error.message}
          </Alert>
        ))}
        <TextField.Outlined
          fullWidth
          label="First name"
          type="text"
          name="first_name"
          placeholder="First name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          helperText={formik.touched.first_name ? formik.errors.first_name : ''}
          error={
            formik.touched.first_name && !_.isEmpty(formik.errors.first_name)
          }
        />
        <TextField.Outlined
          fullWidth
          label="Last name"
          type="text"
          name="last_name"
          placeholder="Last name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          helperText={formik.touched.last_name ? formik.errors.last_name : ''}
          error={
            formik.touched.last_name && !_.isEmpty(formik.errors.last_name)
          }
        />
        <TextField.Outlined
          fullWidth
          label="E-mail"
          type="email"
          name="email"
          placeholder="Email address"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          helperText={formik.touched.email ? formik.errors.email : ''}
          error={formik.touched.email && !_.isEmpty(formik.errors.email)}
        />

        <PasswordField
          name="password"
          placeholder="Password"
          label="Password"
          formik={formik}
        />

        <PasswordField
          name="confirm_password"
          placeholder="Confirm password"
          label="Confirm password"
          formik={formik}
        />

        <Button
          type="submit"
          color="primary"
          disabled={formik.isSubmitting}
          data-gtm="button-submit-register"
        >
          Register
        </Button>
      </Stack>
    </form>
  );
};

export default RegisterForm;
