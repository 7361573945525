import _ from 'lodash';
import cx from 'classnames';
import { colors } from 'theme/palette';
import { makeStyles, useTheme } from '@mui/styles';
import { datetime } from 'utils/datetime';

import { CheckCircleIcon, CircleOutlinedIcon, CalendarTodayIcon } from 'icons';

import {
  TIME_WITH_PERIOD_FORMAT,
  BACKEND_TIME_FORMAT,
  BACKEND_DATETIME_FORMAT
} from 'constants/time';

import {
  IScheduleCarePlanItem,
  IScheduleMedication,
  IScheduleTask,
  useCarePlanItemCategories
} from 'entities/CarePlan/sdk';
import type { IScheduleItem } from 'entities/Schedule/sdk';
import { EScheduleItem } from 'entities/CarePlan/constants';

import Grid from 'components/Grid';
import Stack from 'components/Stack';
import Paper from 'components/Paper';
import Loading from 'components/Loading';
import Typography from 'components/Typography';
import BlankState from 'components/BlankState';
import { default as GenericCalendar } from 'components/Calendar';
import { StickyNote2OutlinedIcon } from 'icons';

import { useSelectedCareTeam } from 'entities/CareTeam/sdk';

import { getMedicationCompletionIcon } from './utils';
import TaskDatePeriod from './components/TaskDatePeriod';

export interface IContent {
  isFetching: boolean;
  selectedDate: string;
  setSelectedDate: (arg0: string) => void;
  scheduleItems: Array<IScheduleItem>;
  onTaskSelect: ({ item }: { item: IScheduleTask }) => void;
  onMedicationSelect: ({ item }: { item: IScheduleMedication }) => void;
  onCarePlanItemSelect: ({ item }: { item: IScheduleCarePlanItem }) => void;
  onBlankStateClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  scheduleItem: {
    cursor: 'pointer',
    transition: 'all 0.3s linear',
    '&:hover': {
      'box-shadow': '0px 2px 4px rgba(0, 0, 0, 0.15)'
    }
  },
  lightPurpleBackground: {
    backgroundColor: colors.purpleLighter,
    color: theme.palette.mode === 'light' ? theme.palette.text.primary : 'black'
  },
  disabledIcon: {
    color:
      theme.palette.mode === 'light' ? theme.palette.action.disabled : 'grey'
  },
  cursorPointer: {
    cursor: 'pointer'
  }
}));

const Content = ({
  isFetching,
  scheduleItems,
  selectedDate,
  setSelectedDate,
  onTaskSelect,
  onMedicationSelect,
  onCarePlanItemSelect,
  onBlankStateClick
}: IContent) => {
  const classes = useStyles();
  const theme = useTheme();

  const allDayCarePlanItems = _.filter(
    scheduleItems,
    (scheduleItem) =>
      scheduleItem.type === EScheduleItem.CARE_PLAN_ITEM &&
      scheduleItem.is_all_day
  );

  const notAllDayScheduleItems = _.filter(
    scheduleItems,
    (scheduleItem) => !_.includes(allDayCarePlanItems, scheduleItem)
  );

  const orderedNotAllDayScheduleItems = _.orderBy(
    notAllDayScheduleItems,
    (item) => {
      if (item.type === EScheduleItem.MEDICATION) {
        return item.intake_time.time;
      }

      if (item.type === EScheduleItem.TASK) {
        return datetime(item.start, BACKEND_DATETIME_FORMAT).format(
          BACKEND_TIME_FORMAT
        );
      }

      if (item.type === EScheduleItem.CARE_PLAN_ITEM) {
        return item.start_time;
      }
    }
  );

  const orderedScheduleItems = [
    ...allDayCarePlanItems,
    ...orderedNotAllDayScheduleItems
  ];

  const { userIsPatient, userIsFamilyMember, userIsAide } =
    useSelectedCareTeam();

  const { data: carePlanItemCategories = [] } = useCarePlanItemCategories();

  return (
    <Stack spacing={1} maxHeight="100%">
      <GenericCalendar
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />

      <Typography variant="subtitle2">
        Repeating tasks and medications are shown in purple.
      </Typography>

      {isFetching && (
        <Stack alignItems="center">
          <Loading />
        </Stack>
      )}
      {!isFetching &&
        _.isEmpty(scheduleItems) &&
        (userIsPatient || userIsFamilyMember) && (
          <Stack maxWidth="100%" alignItems="center">
            <BlankState
              icon={CalendarTodayIcon}
              onClick={onBlankStateClick}
              subtitle="No items have been added to schedule for the selected date."
            />

            <Typography variant="subtitle1" textAlign="center"></Typography>
          </Stack>
        )}

      {!isFetching && _.isEmpty(scheduleItems) && userIsAide && (
        <Typography variant="subtitle1">
          No items have been added to schedule for the selected date.
        </Typography>
      )}

      {orderedScheduleItems.map((item) => {
        // TODO: Move to separate components.
        if (item.type === EScheduleItem.TASK) {
          return (
            <Paper
              container
              onClick={() => onTaskSelect({ item })}
              padding={1}
              component={Grid}
              variant="outlined"
              alignItems="center"
              className={classes.scheduleItem}
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              key={item.id}
            >
              <Grid item lineHeight={1}>
                {item.is_completed ? (
                  <CheckCircleIcon fontSize="small" color="success" />
                ) : (
                  <CircleOutlinedIcon
                    fontSize="small"
                    className={classes.disabledIcon}
                  />
                )}
              </Grid>

              <Grid
                container
                alignItems="center"
                overflow="hidden"
                flexWrap="nowrap"
              >
                <Grid item paddingY={1} marginLeft={1} overflow="hidden">
                  <Typography variant="body1" noWrap textOverflow="ellipsis">
                    {item.name}
                  </Typography>
                </Grid>
                <Grid item paddingTop={0.5} marginLeft={1}>
                  {!_.isNil(item.related_notes) && (
                    <StickyNote2OutlinedIcon
                      fontSize="small"
                      color="disabled"
                    />
                  )}
                </Grid>
              </Grid>

              <Grid container justifyContent="flex-end">
                <TaskDatePeriod task={item} />
              </Grid>
            </Paper>
          );
        }

        if (item.type === EScheduleItem.MEDICATION) {
          return (
            <Paper
              container
              className={cx(
                classes.scheduleItem,
                classes.lightPurpleBackground
              )}
              onClick={() => onMedicationSelect({ item })}
              padding={1}
              component={Grid}
              variant="outlined"
              alignItems="center"
              display="flex"
              flexWrap="nowrap"
              key={item.intake_time.id}
            >
              <Grid item lineHeight={1}>
                {getMedicationCompletionIcon({
                  medication: item,
                  theme
                })}
              </Grid>

              <Grid
                container
                alignItems="center"
                overflow="hidden"
                flexWrap="nowrap"
              >
                <Grid item paddingY={1} marginLeft={1} overflow="hidden">
                  <Typography variant="body1" noWrap textOverflow="ellipsis">
                    {item.name}
                    {item.is_deleted && (
                      <Typography
                        variant="subtitle2"
                        color="error"
                        display="inline-block"
                      >
                        {' '}
                        (Deleted)
                      </Typography>
                    )}
                  </Typography>
                </Grid>

                <Grid
                  item
                  paddingTop={0.5}
                  marginLeft={1}
                  marginRight={2}
                  justifyContent="flex-start"
                >
                  {!_.isNil(item.related_notes) && (
                    <StickyNote2OutlinedIcon
                      fontSize="small"
                      color="disabled"
                    />
                  )}
                </Grid>
              </Grid>

              <Grid item xs="auto" justifyContent="flex-end" textAlign="end">
                <Typography variant="subtitle1">
                  {datetime(item.intake_time.time, BACKEND_TIME_FORMAT).format(
                    TIME_WITH_PERIOD_FORMAT
                  )}
                </Typography>
              </Grid>
            </Paper>
          );
        }

        if (item.type === EScheduleItem.CARE_PLAN_ITEM) {
          return (
            <Paper
              container
              onClick={() => onCarePlanItemSelect({ item })}
              padding={1}
              component={Grid}
              variant="outlined"
              alignItems="center"
              display="flex"
              flexWrap="nowrap"
              className={cx(
                classes.lightPurpleBackground,
                classes.scheduleItem
              )}
              key={item.id}
            >
              <Grid item lineHeight={1}>
                {item.is_completed ? (
                  <CheckCircleIcon fontSize="small" color="success" />
                ) : (
                  <CircleOutlinedIcon
                    fontSize="small"
                    className={classes.disabledIcon}
                  />
                )}
              </Grid>

              <Grid
                container
                alignItems="center"
                overflow="hidden"
                flexWrap="nowrap"
              >
                <Grid item paddingY={1} marginLeft={1} overflow="hidden">
                  <Typography variant="body1" noWrap textOverflow="ellipsis">
                    {item.custom_category ||
                      _.find(carePlanItemCategories, {
                        value: item.category
                      })?.label}{' '}
                    {item.is_deleted && (
                      <Typography
                        variant="subtitle2"
                        color="error"
                        display="inline-block"
                      >
                        (Deleted)
                      </Typography>
                    )}
                  </Typography>
                </Grid>

                <Grid item paddingTop={0.5} marginLeft={1} marginRight={2}>
                  {!_.isNil(item.related_notes) && (
                    <StickyNote2OutlinedIcon
                      fontSize="small"
                      color="disabled"
                    />
                  )}
                </Grid>
              </Grid>

              <Grid item xs="auto" textAlign="end" justifyContent="flex-end">
                {item.is_all_day ? (
                  <Typography variant="subtitle1">all day</Typography>
                ) : (
                  <>
                    <Typography variant="subtitle1">
                      {datetime(item.start_time, BACKEND_TIME_FORMAT).format(
                        TIME_WITH_PERIOD_FORMAT
                      )}
                    </Typography>
                    <Typography variant="subtitle1">
                      {datetime(item.end_time, BACKEND_TIME_FORMAT).format(
                        TIME_WITH_PERIOD_FORMAT
                      )}
                    </Typography>
                  </>
                )}
              </Grid>
            </Paper>
          );
        }

        return null;
      })}
    </Stack>
  );
};

export default Content;
