import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import Stack from 'components/Stack';
import Button from 'components/Button';
import Avatar from 'components/Avatar';
import Skeleton from 'components/Skeleton';
import Typography from 'components/Typography';

import { useSignedUser } from 'entities/Auth/sdk';
import { useSelectedPatient } from 'entities/Patient/sdk';

import { MY_CARE_TEAMS_URL } from 'config/urls';

export interface IPatientSwitchButton {}

const PatientSwitchButton: React.FC<IPatientSwitchButton> = () => {
  const { data: signedUser, loading: isFetchingSignedUser } = useSignedUser();
  const { patient, loading: isFetchingPatient } = useSelectedPatient();
  const theme = useTheme();
  const history = useHistory();

  const careTeamName = useMemo(() => {
    if (isFetchingPatient || isFetchingSignedUser) {
      return <Skeleton width={125} />;
    }

    if (!patient) {
      return 'Unknown team';
    }

    if (patient.profile.id === signedUser?.profile.id) {
      return 'My team';
    }

    return `${patient?.profile.first_name}'s team`;
  }, [isFetchingPatient, isFetchingSignedUser, patient, signedUser]);

  const handlePatientSwitchButtonClick = () => history.push(MY_CARE_TEAMS_URL);

  return (
    <Button
      fullWidth={false}
      variant="text"
      color="inherit"
      onClick={handlePatientSwitchButtonClick}
      startIcon={
        <Stack direction="row" alignItems="center" gap={1}>
          <Avatar src={patient?.profile.avatar} />
        </Stack>
      }
    >
      <Typography
        textAlign="left"
        variant="h6"
        sx={{
          textTransform: 'none',
          color: theme.palette.text.primary
        }}
      >
        {careTeamName}
      </Typography>
    </Button>
  );
};

export default PatientSwitchButton;
