import _ from 'lodash';
import { makeStyles, useTheme } from '@mui/styles';
import { useFormik, FormikHelpers } from 'formik';
import { useCallback, useMemo, useState } from 'react';

import toast from 'utils/toast';
import { datetime } from 'utils/datetime';
import { CARE_PLAN_URL } from 'config/urls';
import { GENERIC_FORM_ERRORS_KEY, IFormError } from 'utils/sdk';

import Grid from 'components/Grid';
import Alert from 'components/Alert';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Switch from 'components/Switch';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import Dialog from 'components/Dialog';
import FormControlLabel from 'components/FormControlLabel';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Link from 'components/Link';
import { AddCommentIcon } from 'icons';

import {
  IScheduleCarePlanItem,
  useCarePlanItemCategories,
  ICarePlanItemValues,
  carePlanItemUpdate,
  carePlanItemDelete
} from 'entities/CarePlan/sdk';
import CarePlanItemUpdateDialog from 'entities/CarePlan/components/CarePlanItemUpdateDialog';
import { useSelectedPatient } from 'entities/Patient/sdk';
import { IGeneralNoteFormValues, generalNoteCreate } from 'entities/Diary/sdk';
import {
  IScheduleItem,
  usePatientScheduleItemList
} from 'entities/Schedule/sdk';

import CarePlanItemPeriod from './components/CarePlanItemPeriod';
import DiaryNoteCreateDialog from 'entities/Diary/components/GeneralNoteCreateDialog';
import RelatedNotesDialog from 'entities/Schedule/components/ScheduleRelatedNotesDialog';

import { VALIDATION_SCHEMA } from './constants';

import {
  DATETIME_WITH_PERIOD_FORMAT,
  BACKEND_DATETIME_FORMAT,
  BACKEND_DATE_FORMAT
} from 'constants/time';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  switchField: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 0
  }
}));

interface IValues {
  is_completed: boolean;
}

interface IScheduleCarePlanItemDetailDialog {
  selectedDate: string;
  item: IScheduleCarePlanItem;
  onCompletionStatusUpdate: (values: IValues) => Promise<void>;
  mutateScheduleItemsInParent: () => void;
  onClose: () => void;
}

const ScheduleCarePlanItemDetailDialog: React.FC<
  IScheduleCarePlanItemDetailDialog
> = ({
  selectedDate,
  item,
  onCompletionStatusUpdate,
  onClose,
  mutateScheduleItemsInParent
}) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const [isUpdateCarePlanDialogOpened, setIsUpdateCarePlanDialogOpened] =
    useState<boolean>(false);
  const [isRelatedNotesDialogOpen, setIsRelatedNotesDialogOpen] =
    useState<boolean>(Boolean(false));
  const [isDiaryNoteCreateDialogOpen, setIsDiaryNoteCreateDialogOpen] =
    useState<boolean>(Boolean(false));
  const [carePlanItemForDeletion, setCarePlanItemForDeletion] =
    useState<IScheduleCarePlanItem | null>(null);

  const { data: carePlanItemCategories = [] } = useCarePlanItemCategories();
  const { patient } = useSelectedPatient();

  const noteInitialValues: IGeneralNoteFormValues = {
    subject:
      item.custom_category ||
      _.find(carePlanItemCategories, { value: item.category })?.label,
    text: '',
    schedule_date: selectedDate
  };

  const [relatedNoteCount, setRelatedNoteCount] = useState<number>(
    !_.isNil(item.related_notes) ? Object.keys(item.related_notes).length : 0
  );

  const [carePlanTask, setCarePlanTask] = useState<IScheduleCarePlanItem>(item);

  const { loading: isFetchingTasks, refetch: refetchScheduleItems } =
    usePatientScheduleItemList({
      patientId: patient?.id,
      selectedDate
    });

  const setCarePlanTaskState = useCallback(
    (refetchedScheduleItems: Array<IScheduleItem>) => {
      const updatedTask = _.find(
        refetchedScheduleItems,
        (refetchedScheduleItems) => refetchedScheduleItems.id === item.id
      ) as IScheduleCarePlanItem;
      setCarePlanTask(() => updatedTask);
      if (_.isNil(updatedTask.related_notes)) {
        setRelatedNoteCount(() => 0);
        setIsRelatedNotesDialogOpen(false);
      } else {
        setRelatedNoteCount(
          () => Object.keys(updatedTask.related_notes).length
        );
      }
    },
    [item]
  );

  const handleSubmit = useCallback(
    (values: IValues, formikHelpers: FormikHelpers<IValues>) =>
      onCompletionStatusUpdate(values).catch(formikHelpers.setErrors),
    [onCompletionStatusUpdate]
  );

  const handleCarePlanItemUpdate = useCallback(
    ({ carePlanItem }: { carePlanItem: ICarePlanItemValues }) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      return carePlanItemUpdate({
        patientId: patient.id,
        carePlanItemId: item.id,
        carePlanItem
      }).then(() => {
        refetchScheduleItems();
        onClose();
        toast.actionable({
          content: 'Repeating task has been updated successfully!',
          action: () => history.push(CARE_PLAN_URL),
          actionButtonText: 'View all'
        });
      });
    },
    [patient, refetchScheduleItems, item, onClose, history]
  );

  const handleCarePlanItemDelete = useCallback(() => {
    if (!patient?.id) {
      return Promise.reject("There's no patient in the care team.");
    }

    if (!carePlanItemForDeletion?.id) {
      return Promise.reject('No item selected for deletion.');
    }

    return carePlanItemDelete({
      itemId: carePlanItemForDeletion.id,
      patientId: patient.id,
      selectedDate
    }).then(() => {
      refetchScheduleItems();
      setCarePlanItemForDeletion(null);
      onClose();
      toast.info({
        content: 'Repeating task has been deleted successfully!'
      });
    });
  }, [
    patient,
    carePlanItemForDeletion,
    refetchScheduleItems,
    onClose,
    selectedDate
  ]);

  const initialValues: IValues = { is_completed: item.is_completed };
  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: VALIDATION_SCHEMA
  });

  const formErrors: Array<IFormError> = _.get(
    formik.errors,
    GENERIC_FORM_ERRORS_KEY,
    []
  );

  const completedАt = useMemo(
    () =>
      datetime(item.completed_at, BACKEND_DATETIME_FORMAT).format(
        DATETIME_WITH_PERIOD_FORMAT
      ),
    [item]
  );

  const handleDiaryNoteCreate = useCallback(
    ({ diaryNote }: { diaryNote: IGeneralNoteFormValues }) => {
      if (!patient?.id) {
        return Promise.reject("There's no patient in the care team.");
      }

      return generalNoteCreate({
        patientId: patient.id,
        diaryNote,
        schedule_item_id: carePlanTask.id,
        schedule_item_type: carePlanTask.type
      }).then(() => {
        setRelatedNoteCount(() => relatedNoteCount + 1);
        mutateScheduleItemsInParent();
        refetchScheduleItems().then((result) => {
          if (!_.isNil(result)) {
            setCarePlanTaskState(result);
          }
        });
        setIsDiaryNoteCreateDialogOpen(false);
        toast.info({
          content: 'Note successfully added!'
        });
      });
    },
    [
      patient,
      mutateScheduleItemsInParent,
      refetchScheduleItems,
      setCarePlanTaskState,
      carePlanTask,
      relatedNoteCount
    ]
  );

  const onDiaryNoteCreate = () => {
    setIsDiaryNoteCreateDialogOpen(true);
  };

  return (
    <>
      <Dialog
        open
        onClose={onClose}
        title={
          carePlanTask.custom_category ||
          _.find(carePlanItemCategories, {
            value: carePlanTask.category
          })?.label
        }
        asideTitleComponent={
          <CarePlanItemPeriod
            item={item} // setting to item as refetch does not adjust start/end times
            onEditClick={() => setIsUpdateCarePlanDialogOpened(true)}
            onDeleteClick={() => setCarePlanItemForDeletion(carePlanTask)}
          />
        }
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {!_.isEmpty(formErrors) && (
              <Grid item xs={12}>
                {formErrors.map((error, index) => (
                  <Alert key={index} severity="error">
                    {error.message}
                  </Alert>
                ))}
              </Grid>
            )}
          </Grid>
          <Grid
            container
            padding={2}
            component={Grid}
            alignItems="left"
            display="flex"
            flexDirection="column"
            rowGap={2}
          >
            <Grid item container spacing={1}>
              <Grid item>
                <Typography
                  variant="body1"
                  color={
                    carePlanTask.description === ''
                      ? theme.palette.text.disabled
                      : 'black'
                  }
                >
                  {item.description || 'No description provided'}
                </Typography>
              </Grid>
            </Grid>
            <Divider />

            <Grid
              container
              spacing={0.5}
              justifyContent="space-between"
              alignContent="center"
            >
              <Grid item>
                <Typography
                  variant="body1"
                  color={
                    relatedNoteCount === 0
                      ? theme.palette.text.disabled
                      : 'primary'
                  }
                >
                  {relatedNoteCount === 0 ? (
                    'No notes have been added'
                  ) : relatedNoteCount === 1 ? (
                    <Link.Primary
                      to="#"
                      onClick={() => setIsRelatedNotesDialogOpen(true)}
                      variant="body1"
                      underline="none"
                      data-gtm="link-related-notes-view"
                    >
                      1 note has been added
                    </Link.Primary>
                  ) : (
                    <Link.Primary
                      to="#"
                      onClick={() => setIsRelatedNotesDialogOpen(true)}
                      variant="body1"
                      underline="none"
                      data-gtm="link-related-notes-view"
                    >
                      {`${relatedNoteCount} notes have been added`}
                    </Link.Primary>
                  )}
                </Typography>
              </Grid>
              <Grid item alignItems="flex-end">
                <IconButton
                  color="inherit"
                  onClick={onDiaryNoteCreate}
                  size="small"
                  data-gtm="button-submit-relatednotes-create"
                >
                  <AddCommentIcon data-gtm="button-submit-relatednotes-create" />
                </IconButton>
              </Grid>
            </Grid>
            {isRelatedNotesDialogOpen &&
              !_.isNil(carePlanTask.related_notes) &&
              !isFetchingTasks && (
                <RelatedNotesDialog
                  open
                  onClose={() => setIsRelatedNotesDialogOpen(false)}
                  notes={Object.values(carePlanTask.related_notes)}
                  scheduleItemID={carePlanTask.id}
                  scheduleItemName={
                    carePlanTask.custom_category ||
                    _.find(carePlanItemCategories, {
                      value: carePlanTask.category
                    })?.label
                  }
                  selectedDate={selectedDate}
                  refetchScheduleItemsInParent={refetchScheduleItems}
                  updateRelatedNoteCountInParent={setCarePlanTaskState}
                />
              )}
            {isDiaryNoteCreateDialogOpen && (
              <DiaryNoteCreateDialog
                onClose={() => setIsDiaryNoteCreateDialogOpen(false)}
                onDiaryNoteCreate={handleDiaryNoteCreate}
                selectedDate={selectedDate}
                initialFormValues={noteInitialValues}
              />
            )}

            <Divider />
            <FormControlLabel
              control={
                <Switch color="primary" checked={formik.values.is_completed} />
              }
              label="Is this item complete?"
              labelPlacement="start"
              name="is_completed"
              className={classes.switchField}
              value={formik.values.is_completed}
              onChange={formik.handleChange}
            />

            {item.completed_at && (
              <Grid item textAlign="end">
                <Typography variant="subtitle2">
                  Completed by {item.completed_by} at
                </Typography>
                <Typography variant="subtitle2">{completedАt}</Typography>
              </Grid>
            )}

            <Grid item>
              <Button
                type="submit"
                disabled={formik.isSubmitting}
                data-gtm="button-submit-schedule-care-plan-item-save"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Dialog>
      {isUpdateCarePlanDialogOpened && (
        <CarePlanItemUpdateDialog
          open
          categories={carePlanItemCategories}
          onClose={() => setIsUpdateCarePlanDialogOpened(false)}
          onCarePlanItemUpdate={handleCarePlanItemUpdate}
          carePlanItem={item}
        />
      )}

      <ConfirmationDialog
        open={!_.isNil(carePlanItemForDeletion)}
        title="Are you sure you want to remove this repeating task?"
        primaryButtonColor="error"
        onClose={() => setCarePlanItemForDeletion(null)}
        onConfirm={handleCarePlanItemDelete}
      >
        <Typography variant="subtitle1">
          The repeating task will be removed from the schedule for all dates
          which are past{' '}
          {datetime(selectedDate, BACKEND_DATE_FORMAT).format(
            'dddd, MMM DD, YYYY'
          )}
          .
        </Typography>
      </ConfirmationDialog>
    </>
  );
};

export default ScheduleCarePlanItemDetailDialog;
