import _ from 'lodash';

import { useState } from 'react';

import Menu from 'components/Menu';
import BottomFabAdd from 'components/BottomFabAdd';
import ListItemText from 'components/ListItemText';
import ListItemIcon from 'components/ListItemIcon';

import {
  MedicationLiquidTwoToneIcon,
  VolunteerActivismTwoToneIcon,
  EventTwoToneIcon
} from 'icons';

import { EScheduleMenuItems } from 'pages/Schedule/constants';

export interface IAddMenuItem {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

export interface IAddMenu {
  items: Array<IAddMenuItem>;
}

const ScheduleMenu: React.FC<IAddMenu> = ({ items }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAddMenuClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = ({ item }: { item: IAddMenuItem }) => {
    item.onClick();
    handleAddMenuClose();
  };

  return (
    <>
      <BottomFabAdd onClick={handleClick} />
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleAddMenuClose}
      >
        {_.map(items, (item) => (
          <Menu.Item
            key={item.label}
            onClick={() => handleItemClick({ item })}
            disabled={item.disabled}
            sx={{ minWidth: '200px' }}
          >
            {item.label === EScheduleMenuItems.NEW_CARE_TASK && (
              <ListItemIcon>
                <VolunteerActivismTwoToneIcon />
              </ListItemIcon>
            )}
            {item.label === EScheduleMenuItems.NEW_APPOINTMENT && (
              <ListItemIcon>
                <EventTwoToneIcon />
              </ListItemIcon>
            )}
            {item.label === EScheduleMenuItems.NEW_MEDICATION && (
              <ListItemIcon>
                <MedicationLiquidTwoToneIcon />
              </ListItemIcon>
            )}
            <ListItemText>{item.label}</ListItemText>
          </Menu.Item>
        ))}
      </Menu>
    </>
  );
};

export default ScheduleMenu;
