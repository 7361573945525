import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import Stack from 'components/Stack';
import Stepper from 'components/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Box from 'components/Box';
import Typography from 'components/Typography';
import OnboardingForm from 'entities/OnboardingForm';
import Button from 'components/Button';

import { INewPatientCareTeamCreateFormValues } from 'entities/CareTeam/sdk';
import { onboardingSteps } from './constants';
import { useProfile } from 'entities/Profile/sdk';
import { useSignedUser, useUserLogout } from 'entities/Auth/sdk';

const useStyles = makeStyles((theme) => ({
  stepper: {
    color: theme.palette.getContrastText(theme.palette.background.default),
    padding: '2px'
  },
  cancelLogoutButton: {
    maxWidth: '100px',
    display: 'flex',
    color: theme.palette.text.secondary
  },
  step: {
    color: theme.palette.secondary.main
  }
}));

interface IOnboardingStepper {}

const OnboardingStepper: React.FC<IOnboardingStepper> = () => {
  const classes = useStyles();
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();

  const { data: user } = useSignedUser();
  const { data: profile, refetch: refetchProfile } = useProfile({
    profileId: user?.profile.id
  });

  const { handleLogout } = useUserLogout();

  const [activeStep, setActiveStep] = useState<number>(0);

  const formInitialValues = {
    care_team_role: '',
    patient: {
      first_name: '',
      last_name: ''
    }
  };

  // Tracking onboarding formData state is necessary in order to use a vertical stepper
  // StepContent reloads the OnboardingForm where prior values disappear from Formik.
  const [formData, setFormData] =
    useState<INewPatientCareTeamCreateFormValues>(formInitialValues);

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <>
      <Stack>
        {_.isNull(profile?.care_team_membership) &&
        _.isNull(profile?.patient) ? (
          <>
            <Stack justifyContent="flex-end" direction="row">
              <Button
                variant="text"
                onClick={handleLogout}
                className={classes.cancelLogoutButton}
              >
                <Typography variant="subtitle1">Logout</Typography>
              </Button>
            </Stack>
            <Stack textAlign="center">
              <Typography variant="h1">Getting Started</Typography>
            </Stack>
          </>
        ) : (
          <>
            <Stack justifyContent="flex-end" direction="row">
              <Button
                variant="text"
                onClick={handleCancel}
                className={classes.cancelLogoutButton}
              >
                <Typography variant="subtitle1">Cancel</Typography>
              </Button>
            </Stack>
            <Stack textAlign="center">
              <Typography variant="h1">New Care Team</Typography>
            </Stack>
          </>
        )}
        <Stack>
          <Box paddingX={2} paddingY={3} sx={{ width: '100%' }}>
            <Stepper
              activeStep={activeStep}
              className={classes.stepper}
              orientation={mobile ? 'vertical' : 'horizontal'}
            >
              {onboardingSteps.map((step, index) => (
                <Step key={index}>
                  <StepLabel>{step}</StepLabel>
                  {mobile && !_.isUndefined(user) && (
                    <StepContent TransitionProps={{ unmountOnExit: false }}>
                      <OnboardingForm
                        activeStep={activeStep}
                        user={user}
                        setActiveStep={setActiveStep}
                        formData={formData}
                        setFormData={setFormData}
                        refetchProfile={refetchProfile}
                      />
                    </StepContent>
                  )}
                </Step>
              ))}
            </Stepper>
            {!mobile && !_.isUndefined(user) && (
              <OnboardingForm
                activeStep={activeStep}
                user={user}
                setActiveStep={setActiveStep}
                formData={formData}
                setFormData={setFormData}
                refetchProfile={refetchProfile}
              />
            )}
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default OnboardingStepper;
