import axios from 'axios';

import { post } from 'utils/sdk';
import { IUserDocument } from 'entities/Documents/sdk';

import { BASE_API_URL } from 'config/urls';

interface IImageGeneratePresignedPostData {
  identifier: string;
  fields: Array<string>;
  url: string;
  params?: Object;
}

interface IUserDocumentPresignedPostData {
  file_id: string;
  fields: Array<string>;
  patient: string;
  url: string;
  document: IUserDocument[];
  params?: Object;
}

export const imageGeneratePresignedPost = ({
  fileName,
  fileType
}: {
  fileName: string;
  fileType: string;
}) =>
  post<IImageGeneratePresignedPostData>(
    `${BASE_API_URL}/files/images/presigned-post/`,
    {
      file_name: fileName,
      file_type: fileType
    }
  );

export const imageUpload = ({ image }: { image: File }) =>
  imageGeneratePresignedPost({
    fileName: image.name,
    fileType: image.type
  }).then((data: IImageGeneratePresignedPostData) => {
    const postData = new FormData();

    for (const key in data?.fields) {
      postData.append(key, data.fields[key]);
    }
    postData.append('file', image);

    return axios
      .post(data.url, postData, data.params)
      .then(() => Promise.resolve({ imageId: data.identifier }));
  });

export const userDocGeneratePresignedPost = ({
  fileName,
  fileType,
  patientID
}: {
  fileName: string;
  fileType: string;
  patientID: string;
}) => {
  return post<IUserDocumentPresignedPostData>(
    `${BASE_API_URL}/files/patient/${patientID}/documents/upload/`,
    {
      file_name: fileName,
      file_type: fileType
    }
  );
};

export const userDocUpload = ({
  document,
  patientID
}: {
  document: File;
  patientID: string;
}) =>
  userDocGeneratePresignedPost({
    fileName: document.name,
    fileType: document.type,
    patientID
  }).then((data: IUserDocumentPresignedPostData) => {
    const postData = new FormData();

    for (const key in data?.fields) {
      postData.append(key, data.fields[key]);
    }
    postData.append('file', document);

    return axios
      .post(data.url, postData, data.params)
      .then(() => Promise.resolve({ document: data.document }));
  });
