import { useMemo } from 'react';

import PageLoader from 'components/PageLoader';

import { IVitalNoteFormValues } from 'entities/Diary/sdk';

import VitalNoteFormDialog from 'entities/Diary/components/VitalNoteFormDialog';

import {
  useSelectedPatient,
  useVitalsToTrack,
  usePatientCustomVitals
} from 'entities/Patient/sdk';

import { getDatetimeObjectFromDate } from 'utils/datetime';
import { BACKEND_DATETIME_FORMAT } from 'constants/time';

export interface IVitalNoteCreateDialog {
  onVitalNoteCreate: ({
    vitalNote
  }: {
    vitalNote: IVitalNoteFormValues;
  }) => Promise<void>;
  onClose: () => void;
  selectedDate: string;
}

const VitalNoteCreateDialog = ({
  onClose,
  onVitalNoteCreate,
  selectedDate
}: IVitalNoteCreateDialog) => {
  const { patient, loading: isPatientLoading } = useSelectedPatient();

  const { data: customVitals, loading: areCustomVitalsLoading } =
    usePatientCustomVitals({
      patientId: patient?.id
    });

  const { data: vitalsToTrack, loading: areVitalsToTrackLoading } =
    useVitalsToTrack({
      patientId: patient?.id
    });

  const initialValues: IVitalNoteFormValues = useMemo(
    () => ({
      measured_at: getDatetimeObjectFromDate({ date: selectedDate }).format(
        BACKEND_DATETIME_FORMAT
      ),
      temperature: '',
      blood_pressure_systolic: '',
      blood_pressure_diastolic: '',
      pulse: '',
      blood_oxygen: '',
      blood_sugar_fasting: '',
      blood_sugar_non_fasting: ''
    }),
    [selectedDate]
  );

  const isLoading =
    isPatientLoading || areCustomVitalsLoading || areVitalsToTrackLoading;

  return (
    <>
      {isLoading || !vitalsToTrack || !customVitals ? (
        <PageLoader />
      ) : (
        <VitalNoteFormDialog
          open
          title="Add Vitals"
          onClose={onClose}
          vitalsToTrack={vitalsToTrack}
          customVitals={customVitals}
          initialValues={initialValues}
          onVitalNoteSubmit={onVitalNoteCreate}
          vitalsOutOfRange={[]}
        />
      )}
    </>
  );
};

export default VitalNoteCreateDialog;
