import { useState, useCallback } from 'react';
import _ from 'lodash';

import Drawer from 'components/Drawer';
import { VITALS } from 'entities/HealthTrends/constants';

import List from 'components/List';
import ListItemButton from 'components/ListItemButton';
import ListItemText from 'components/ListItemText';
import Stack from 'components/Stack';
import Typography from 'components/Typography';
import Button from 'components/Button';
import { useSelectedPatient, useVitalsToTrack } from 'entities/Patient/sdk';

import { useTheme } from '@mui/material/styles';
import { InsightsIcon } from 'icons';

import { updateLastSeenHealthTrendChart } from 'entities/HealthTrends/utils';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  chartToggleButton: {
    '&:hover': {
      zIndex: 10,
      border: '2px currentColor'
    }
  }
}));

const SELECT_OPTIONS = [
  { label: 'Temperature', value: VITALS.TEMPERATURE },
  { label: 'Blood pressure', value: VITALS.BLOOD_PRESSURE },
  { label: 'Pulse', value: VITALS.PULSE },
  { label: 'Blood oxygen', value: VITALS.BLOOD_OXYGEN },
  { label: 'Blood sugar', value: VITALS.BLOOD_SUGAR }
];

interface IChartToggleButton {
  selectedChart: string;
  onChange: (value: string) => void;
}

const ChartToggleButton: React.FC<IChartToggleButton> = ({
  selectedChart,
  onChange
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const openDrawer = useCallback(() => {
    setOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setOpen(false);
  }, []);

  const selectedOption = _.find(
    SELECT_OPTIONS,
    (option) => option.value === selectedChart
  );

  const onSelect = (value: string) => {
    setOpen(false);
    onChange(value);
  };

  return (
    <div>
      <Stack direction="row" display="flex" alignItems="center">
        <Button
          variant="text"
          fullWidth={false}
          onClick={openDrawer}
          color="primary"
          startIcon={<InsightsIcon />}
          className={classes.chartToggleButton}
        >
          <Typography variant="h5" color="primary.main">
            {selectedOption?.label}
          </Typography>
        </Button>
      </Stack>
      <Drawer open={open} onClose={closeDrawer}>
        <DrawerContents onSelect={onSelect} selectedChart={selectedChart} />
      </Drawer>
    </div>
  );
};

const DrawerContents: React.FC<{
  onSelect: (value: string) => void;
  selectedChart: string;
}> = ({ selectedChart, onSelect }) => {
  const theme = useTheme();
  const { patient } = useSelectedPatient();

  const { data: vitalsToTrack, loading: areVitalsToTrackLoading } =
    useVitalsToTrack({
      patientId: patient?.id
    });

  const vitalsOptions = vitalsToTrack
    ? SELECT_OPTIONS.filter((obj) => _.get(vitalsToTrack, obj.value, false))
    : [];
  updateLastSeenHealthTrendChart(selectedChart);

  return (
    <List sx={{ minWidth: 240 }}>
      <ListItemButton
        sx={{
          bgcolor: theme.palette.primary.dark,
          color: theme.palette.common.white,
          '&:hover': {
            bgcolor: theme.palette.primary.dark
          }
        }}
      >
        <ListItemText
          primary="Vitals"
          primaryTypographyProps={{ variant: 'h6' }}
        />
      </ListItemButton>
      {vitalsOptions.map((option) => (
        <DrawerContentsListItem
          key={option.value}
          disabled={areVitalsToTrackLoading}
          vitalObject={option}
          onClick={onSelect}
          selected={selectedChart === option.value}
        />
      ))}
    </List>
  );
};

interface IDrawerContentsListItem {
  vitalObject: {
    label: string;
    value: string;
  };
  onClick: (value: string) => void;
  selected: boolean;
  disabled: boolean;
}

const DrawerContentsListItem: React.FC<IDrawerContentsListItem> = ({
  vitalObject,
  onClick,
  selected,
  disabled
}) => {
  const theme = useTheme();
  const handleClick = useCallback(() => {
    onClick(vitalObject.value);
  }, [onClick, vitalObject.value]);

  return (
    <ListItemButton
      disabled={disabled}
      sx={{
        pl: 4,
        bgcolor: selected ? theme.palette.primary.main : 'transparent',
        color: selected
          ? theme.palette.common.white
          : theme.palette.mode === 'dark'
          ? theme.palette.common.white
          : theme.palette.common.black,
        '&:hover': {
          bgcolor: theme.palette.primary.dark,
          color: theme.palette.common.black
        }
      }}
      onClick={handleClick}
    >
      <ListItemText primary={vitalObject.label} />
    </ListItemButton>
  );
};

export default ChartToggleButton;
