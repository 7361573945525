import Button from 'components/Button';
import ButtonGroup from 'components/ButtonGroup';
import Typography from 'components/Typography';
import { useSelectedCareTeam } from 'entities/CareTeam/sdk';
import { EditIcon, DeleteIcon } from 'icons';

interface ITaskPeriod {
  start: string;
  end: string;
  onEditClick: () => void;
  onDeleteClick: () => void;
}

const TaskPeriod: React.FC<ITaskPeriod> = ({
  start,
  end,
  onEditClick,
  onDeleteClick
}) => {
  const { userIsPatient, userIsFamilyMember } = useSelectedCareTeam();

  return (
    <>
      {(userIsPatient || userIsFamilyMember) && (
        <ButtonGroup fullWidth variant="text" color="inherit">
          <Button color="inherit" onClick={onEditClick}>
            <EditIcon />
          </Button>
          <Button color="inherit" onClick={onDeleteClick}>
            <DeleteIcon />
          </Button>
        </ButtonGroup>
      )}
      <Typography variant="subtitle1" textAlign="end">
        {start}
      </Typography>
      <Typography variant="subtitle1" textAlign="end">
        {end}
      </Typography>
    </>
  );
};

export default TaskPeriod;
