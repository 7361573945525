import _ from 'lodash';

import { orderDaysOfTheWeek } from 'utils/common';
import { datetime, utcToLocal, IDatetime, localToUtc } from 'utils/datetime';
import {
  BACKEND_TIME_FORMAT,
  BACKEND_DATETIME_FORMAT,
  TIME_WITH_PERIOD_FORMAT,
  BACKEND_DATE_FORMAT
} from 'constants/time';

import { EMedicationIntakeFrequency } from 'entities/Medications/constants';

import {
  IMedicationIntakeTime,
  IMedication,
  IMedicationUpdateIntakeTime,
  IMedicationCreateFormValues,
  IMedicationUpdateFormattedFormValues
} from 'entities/Medications/sdk';

export const getReadableIntakeTimes = ({
  intakeTimes
}: {
  intakeTimes: Array<IMedicationIntakeTime>;
}) => {
  const intakeTimesObjects = intakeTimes.map((intakeTime) =>
    datetime(intakeTime.time, BACKEND_TIME_FORMAT)
  );

  const sortedTimes = intakeTimesObjects.sort((prev, next) =>
    prev.isAfter(next) ? 1 : -1
  );

  return (
    _.uniq(
      sortedTimes.map((intakeTime) =>
        intakeTime.format(TIME_WITH_PERIOD_FORMAT)
      )
    ).join(', ') || '-'
  );
};

export const getIntakeDays = ({ medication }: { medication: IMedication }) => {
  if (medication.intake_frequency === EMedicationIntakeFrequency.WEEKLY) {
    const initialIntakeDays = _.uniq(
      medication.intake_times.map((time: IMedicationUpdateIntakeTime) =>
        _.upperFirst(time.day_of_the_week)
      )
    );
    return orderDaysOfTheWeek({ days: initialIntakeDays }).join(', ') || '-';
  }

  return 'Daily';
};

// TODO: Add tests
export const transformWeeklyMedicationIntakeTimesUtcToLocal = ({
  intakeTimes,
  startMoment
}: {
  intakeTimes: Array<IMedicationIntakeTime>;
  startMoment: string;
}) => {
  // Transforms medication.intake_time.time and medication.intake_time.day_of_the_week from utc to local
  const nextSevenDaysFromRepeatAfter: Array<{ time: IDatetime; id: string }> =
    [];
  const cursorStart = datetime.utc(startMoment, BACKEND_DATETIME_FORMAT, true);

  let cursorDate = cursorStart;

  /*
  For each intake_time in  [
    {
      day_of_the_week: "friday"
      id: "4dd975b6-c429-4c96-8b1d-eb3d8a4c797d"
      time: "09:01:00"
    },
    {
      day_of_the_week: "friday"
      id: "af695cdc-e86d-4a7d-bea5-8613029908d6"
      time: "02:01:00"
    },
    {
      day_of_the_week: "monday"
      id: "48de211d-1b58-4595-9034-a4124e71a00b"
      time: "09:01:00"
    }
  ]

  We create datetime objects in utc with time = intake_time.time and day of the week = intake_time.day_of_the_week.
  */
  while (cursorDate.isBefore(cursorStart.add(7, 'days'), 'day')) {
    /* eslint-disable */
    intakeTimes.map((intakeTime) => {
      const timeFromatted = datetime(
        intakeTime.time,
        BACKEND_TIME_FORMAT,
        true
      );

      const time = cursorDate
        .hour(timeFromatted.hour())
        .minute(timeFromatted.minute());

      if (intakeTime.day_of_the_week === time.format('dddd').toLowerCase()) {
        nextSevenDaysFromRepeatAfter.push({
          id: intakeTime.id,
          time
        });
      }
    });
    cursorDate = cursorDate.add(1, 'days');
  }

  /*
    We transform each datetime oject from utc to local and we take their corresponding days of the week.

    Example: If we are in Bulgaria and in utc an intake time is on Friday at 22:10:00,
    in local(+2 hours) timezone it will be on Saturday at 00:10:00 and we will take Saturday.
  */

  const transformedIntakeTimes = nextSevenDaysFromRepeatAfter.map(
    (dayObject) => {
      const dayObjectTimeInLocalTimeZone = utcToLocal(dayObject.time);

      return {
        id: dayObject.id,
        time: dayObjectTimeInLocalTimeZone.format(BACKEND_TIME_FORMAT),
        day_of_the_week: dayObjectTimeInLocalTimeZone
          .format('dddd')
          .toLowerCase()
      };
    }
  );

  return transformedIntakeTimes;
};

export const convertMedicationRepeatAfterAndRepeatBeforeFromLocalToUtc = ({
  medication,
  intakeTimes
}: {
  medication:
    | IMedicationCreateFormValues
    | IMedicationUpdateFormattedFormValues;
  intakeTimes: Array<string>;
}) => {
  const sortedTimes = intakeTimes.sort((prev, next) => {
    const prevTime = datetime(prev, BACKEND_TIME_FORMAT, true);
    const nextTime = datetime(next, BACKEND_TIME_FORMAT, true);

    return prevTime.isAfter(nextTime) ? 1 : -1;
  });

  const earliestTime = datetime(_.head(sortedTimes), BACKEND_TIME_FORMAT, true);
  const latestTime = datetime(_.last(sortedTimes), BACKEND_TIME_FORMAT, true);

  const repeatAfter = localToUtc(
    datetime(medication.repeat_startdatetime, BACKEND_DATE_FORMAT)
      .hour(earliestTime.hour())
      .minute(earliestTime.minute())
  );

  const repeatBefore = medication.repeat_enddatetime
    ? localToUtc(
        datetime(medication.repeat_enddatetime, BACKEND_DATE_FORMAT)
          .hour(latestTime.hour())
          .minute(latestTime.minute())
      )
    : null;

  return { repeatAfter, repeatBefore };
};

export const transformDailyMedicationIntakeTimesUtcToLocal = ({
  intakeTimes
}: {
  intakeTimes: Array<IMedicationIntakeTime>;
}) =>
  intakeTimes.map((intakeTime) => {
    const time = datetime(intakeTime.time, BACKEND_TIME_FORMAT, true);
    const intakeTimeDatetime = utcToLocal(
      datetime
        .utc()
        .hour(time.hour())
        .minute(time.minute())
        .second(time.second())
    );

    return {
      ...intakeTime,
      time: intakeTimeDatetime.format(BACKEND_TIME_FORMAT)
    };
  });
